import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

const ITabs = (props) => {
    // props
    const { children, width, title, style, ...attribute } = props;
    // Styled
    const useStyles = makeStyles(theme => ({ tabs: { width, borderRight: `1px solid ${theme.palette.divider}` }, }));
    // classes
    const classes = useStyles();
    // Render
    return (
        <Tabs
            variant="scrollable"
            aria-label="Vertical"
            className={classes.tabs}
            {...attribute}
        >
            <label
                className="text-center font-weight-bold mb-0 text-white"
                style={{ borderBottom: 'inset', backgroundColor: 'lightslategray', position: 'sticky', top: '0px', display: 'block', zIndex: '10', ...style }}>
                {title.toUpperCase()}
            </label>
            {children}
        </Tabs>
    );
}

ITabs.defaultProps = {
    orientation: "vertical",
};

export default ITabs;