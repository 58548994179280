import { Meet } from "./Meet";

class PhuongAnTemplatesEndpoint extends Meet {
    constructor() {
        super("/PhuongAnTemplates");
    }

    getParent = (id) => this.GetAllow(`?$filter=parentId eq null`)
    add= (body) => this.InsertAUTH(body);
    //addPhuongAnTemplates(data) { return this.PostAuthen('', data); }
}
export default new PhuongAnTemplatesEndpoint();