import React, { useState, forwardRef, useImperativeHandle, useCallback } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Typography from '@tiptap/extension-typography'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
// import { HexColorDecorator } from '../extensions'
// import { formatHtml, markdownToHtml, htmlToMarkdown } from '../helpers'
// eslint-disable-next-line no-unused-vars
import { formatHtml } from '../helpers'
import { Toolbar } from './Toolbar'
import './TipTap.scss'

const Tiptap = forwardRef(({
    isFullExtension = false,
    content = '',
    editable = true,
    placeholder = "Nhập dữ liệu...",
    withToolbar = false,
    withTypographyExtension = false,
    withCodeBlockLowlightExtension = false,
    withTaskListExtension = false,
    withPlaceholderExtension = false,
    withLinkExtension = false,
    isAnimate = false.valueOf,
    actions
    // withHexColorsDecorator = false,
}, ref) => {

    const extensions = [
        StarterKit.configure({
            ...(withCodeBlockLowlightExtension && { codeBlock: false }),
        }),
        TextAlign.configure({
            types: ['heading', 'paragraph'],
            alignments: ['left', 'center', 'right', 'justify'],
        }),
        Link.configure({
            autolink: false,
        })
    ]

    if (withTypographyExtension) { extensions.push(Typography) }

    if (withTaskListExtension) { extensions.push(TaskList, TaskItem) }

    if (withLinkExtension) {
        extensions.push(
            Link.configure({
                linkOnPaste: true,
                openOnClick: true,
            }),
        )
    }

    if (withPlaceholderExtension) {
        extensions.push(
            Placeholder.configure({
                placeholder,
            }),
        )
    }

    // if (withMentionSuggestion) {
    //     extensions.push(MentionSuggestion)

    //     /*extensions.push(
    //         MentionSuggestion.configure({
    //             suggestion: {
    //                 char: '+',
    //             },
    //         }),
    //     )*/
    // }

    // if (withHexColorsDecorator) {
    //     extensions.push(HexColorDecorator)
    // }

    const [editorHtmlContent, setEditorHtmlContent] = useState(typeof content === 'string' ? content : '');
    // const [turndownMarkdownContent, setTurndownMarkdownContent] = React.useState('');
    // const [markedHtmlContent, setMarkedHtmlContent] = React.useState('');

    const editor = useEditor({
        content,
        extensions,
        editable,
        onUpdate: ({ editor }) => {
            setEditorHtmlContent(editor.getHTML())
        },
    })
    const insertData = useCallback((data) => {
        editor.commands.insertContent(data)
    }, [editor])

    const clearData = useCallback((data) => {
        editor.commands.clearContent()
    }, [editor])

    useImperativeHandle(ref, () => ({
        getValue: () => {
            console.log(editorHtmlContent)
            return editorHtmlContent;
            // return formatHtml(editorHtmlContent)
        },
        insertData,
        clearData
    }), [clearData, editorHtmlContent, insertData]);


    // useEffect(function convertHtmlToMarkdown() {
    //     setTurndownMarkdownContent(htmlToMarkdown(editorHtmlContent))
    // }, [editorHtmlContent])

    // useEffect(
    //     function convertMarkdownToHtml() {
    //         setMarkedHtmlContent(markdownToHtml(turndownMarkdownContent))
    //     },
    //     [turndownMarkdownContent],
    // )

    if (!editor) { return null }

    return (
        <>
            <div className="WhiteCard">
                {withToolbar ? <Toolbar actions={actions} editor={editor} isFullExtension={isFullExtension} isAnimate={isAnimate} /> : null}
                <EditorContent allowSpaces className='content-tiptap' editor={editor} />
            </div>
            {/* <div className="WhiteCard">
                <pre>{editor.getText()}</pre>
            </div> */}
            {/* <h2>HTML Output</h2> */}
            {/* <div className="WhiteCard">
                <pre>{formatHtml(editorHtmlContent)}</pre>
            </div>
            <h2>HTML Output (tiptap) ➡ Turndown (lib) ➡ Markdown</h2>
            <div className="WhiteCard">
                <pre>{turndownMarkdownContent}</pre>
            </div>
            <h2>Markdown ➡ Marked (lib) ➡ DOMPurify (lib) ➡ HTML</h2>
            <div className="WhiteCard">
                <pre>{formatHtml(markedHtmlContent)}</pre>
            </div> */}
        </>
    )
})

export default Tiptap
