import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Rounded = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5rem;
    border-top: 1px solid #c8ced3;
`;

const FormFooter = (props) => {
    const { children, ...attribute } = props;
    return (
        <Rounded {...attribute}>
            {children}
        </Rounded>
    );
};

FormFooter.propTypes = {
    children: PropTypes.node,
};

FormFooter.defaultProps = {

};

export default FormFooter;