import React from 'react';
// import PropTypes from 'prop-types';
import IInput from "./Input";

const Password = (props) => {
    const { type, children, ...attribute } = props;
    return (
        <IInput {...attribute} type="password">
            {children}
        </IInput>
    );
};

Password.propTypes = {

};

Password.defaultProps = {

};

export default Password;