import { Service } from '../Service';
// import { PUBLIC_API_URL, ADMIN_API_URL } from '../../actions/constants';

class Meet extends Service {
    constructor(path) {
        super('/meet', path);
    }
    // Tìm kiếm
    // getAll = (param) => this.SearchAll(PUBLIC_API_URL + "/category", param, null, true);
    getAll = (param) => this.SearchAllow(param, null, null, true);

    // Xóa
    delete = (id) => this.DeleteAUTH(`(${id})`);

    addWithBatch = (data) => this.InsertBatch(``, data)

    update(id, body) {
        return this.UpdateAUTH(`(${id})`, body);
    }
}

export { Meet };