import { Admin } from "./Admin";

class RolesEndpoint extends Admin {
    constructor() {
        super("/Roles");
    }
    // lấy danh sách quyền
    getAllRoles = () => this.SearchAllow('?$select=name,isRoot,id,normalizedName', null, null, true);

    // lấy quyền của khách mời
    getGuestRoles = () => this.SearchAllow(`?$filter=normalizedName eq 'KM'`, null, null, true);

    // lấy quyền theo Id
    getRoleById = (Id) => this.GetAuthen(`(${Id})?$select=Name`, null, true)
    // Lưu dữ liệu theo cây với Role
    setAllMenuAdministrationWithRole = (body) => this.PostAuthen(`/ThemMenus`, body);
    // update vai trò quyền
    saveAllWithRoleAndGroupName = (body) => this.PostAuthen(`/ThemEndpoints`, body);

    // Danh sách Menu với Role
    getMenuWithIDRole = (id) => {
        return this.SearchAllow(`?$filter=id eq ${id}&$expand=menus`);
    }
}

export default new RolesEndpoint()