import { Meet } from "./Meet";

class HuyensEndpoint extends Meet {
  constructor() {
    super("/public/Huyens");
  }

  getFindById(id) {
    return this.getAll('/findById?id=' + id + '&select=uuid%2Cname%2Cprefix')
  }
  findByUuid(uuid) {
    return this.getAll(`/findById?id=${uuid}&select=name,uuid,prefix`)
  }

  getHuyensByProvinceUuid(uuid) {
    return this.getAll(`/CityOrProvince?id=${uuid}&select=name,uuid,prefix`);
  }
}

export default new HuyensEndpoint();
