import { combineReducers } from 'redux';
import meetingReducer from '../views/don-vi/PhienHop/Redux/ReducerMeeting';

// export interface RootReducer {
//     meetingProcess: StateMeetingProcess
// }

const rootReducer = combineReducers({
    meetingRProcess: meetingReducer
});
export default rootReducer