import { Badge } from "reactstrap";
import { TOKEN_CLIENT_INFO } from "../../../../../actions/types"
import { _Enum_Trang_Thai_Van_De } from "../../../../action/defaultEnums";

export const idNhanSu = TOKEN_CLIENT_INFO && TOKEN_CLIENT_INFO.nhanSuId;
export const checkChuTri = (idChuTri, idNhanSu) => {
    if (idChuTri === idNhanSu) {
        return true
    } else {
        return false
    }
}

export const renderTrangThai = (trangThai) => {
    let TrangThai = '';
    switch (trangThai) {
        case _Enum_Trang_Thai_Van_De.Moi:
            TrangThai = <Badge size="sm" color="warning">Chờ</Badge>
            break;
        case _Enum_Trang_Thai_Van_De.HoatDong:
            TrangThai = <Badge size="sm" color="primary">Biểu quyết</Badge>
            break;
        case _Enum_Trang_Thai_Van_De.HoanThanh:
            TrangThai = <Badge size="sm" color="success">Hoàn thành</Badge>
            break;
        default:
            break;
    }
    return TrangThai;
}