import { useState, useEffect, useRef } from "react";
import { AccountsEndpoint } from "../../service/Admin";
// import { logoutAccount } from '../../routes/permission';
import MenuContext from "./MenuContext";


export default function AdminMenuProvider({ children }) {
    // Info User
    // eslint-disable-next-line no-unused-vars
    const [menus, setMenus] = useState([]);
    const _mountedRef = useRef(false);
    // Call API
    useEffect(() => {
        _mountedRef.current = true;
        // Lấy danh sách các Menu
        AccountsEndpoint.getMenuWithAuthority()
            .then(result => {
                if (result.isSuccessed) {
                    _mountedRef.current && setMenus(result.resultObj)
                }
            })
            .catch(() => { });
        // logoutAccount
        return () => {
            _mountedRef.current = false;
        }
    }, []);
    return <MenuContext.Provider value={{ menus }}>{children}</MenuContext.Provider>
}