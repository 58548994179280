/**
 * PDFAnnotate v1.0.1
 * Author: Ravisha Heshan
 */

import { fabric } from "fabric";
import jsPDF from "jspdf";
import { Arrow } from "./arrow.fabric";
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from '//cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.worker.js';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

class PDFAnnotate {
	constructor(container_id, url, options = {}) {
		this.number_of_pages = 0;
		this.pages_rendered = 0;
		this.active_tool = 1; // 0 - Free hand,1 - Pencil, 2 - Text, 3 - Arrow, 4 - Rectangle
		this.fabricObjects = [];
		this.fabricObjectsData = [];
		this.color = '#212121';
		this.borderColor = '#000000';
		this.borderSize = 1;
		this.font_size = 16;
		this.active_canvas = 0;
		this.container_id = container_id;
		this.opacity = 1;
		this.url = url;
		this.pageImageCompression = options.pageImageCompression
			? options.pageImageCompression.toUpperCase()
			: "NONE";
		var inst = this;

		var loadingTask = pdfjsLib.getDocument(this.url);
		if (document.getElementById(inst.container_id)) {
			loadingTask.promise.then(function (pdf) {
				var scale = options.scale ? options.scale : 1.3;
				inst.number_of_pages = pdf.numPages;

				for (var i = 1; i <= pdf.numPages; i++) {
					pdf.getPage(i).then((page) => {
						var viewport = page.getViewport({ scale: scale });
						var canvas = document.createElement('canvas');
						document.getElementById(inst.container_id)?.appendChild(canvas);
						canvas.className = 'pdf-canvas';
						canvas.height = viewport.height;
						canvas.width = viewport.width;
						var context = canvas.getContext('2d');

						var renderContext = {
							canvasContext: context,
							viewport: viewport
						};
						var renderTask = page.render(renderContext);
						renderTask.promise.then(() => {
							const pdfCanvas = document.getElementsByClassName("pdf-canvas");
							for (var i = 0; i < pdfCanvas.length; i++) {
								pdfCanvas[i].setAttribute('id', 'page-' + (i + 1) + '-canvas');
							}
							inst.pages_rendered++;
							if (inst.pages_rendered === inst.number_of_pages)
								inst.initFabric();
						});
					});
				}
				// eslint-disable-next-line no-console
			}, function (reason) { console.log(reason); });
		}

		this.initFabric = function () {
			var inst = this;
			if (document.getElementById(inst.container_id)) {
				let canvases = document.getElementById(inst.container_id).getElementsByTagName("canvas"); //suaaaaaaaaa
				Array.from(canvases).forEach((el, index) => {
					var background = el.toDataURL("image/png");
					var fabricObj = new fabric.Canvas(el.id, { freeDrawingBrush: {} });
					fabricObj.freeDrawingBrush.width = 20;
					fabricObj.freeDrawingBrush.color = inst.color;
					inst.fabricObjects.push(fabricObj);
					if (typeof options.onPageUpdated == 'function') {
						fabricObj.on('object:added', function () {
							var oldValue = Object.assign({}, inst.fabricObjectsData[index]);
							inst.fabricObjectsData[index] = fabricObj.toJSON();
							options.onPageUpdated(index + 1, oldValue, inst.fabricObjectsData[index]);
						});
					}
					fabricObj.setBackgroundImage(background, fabricObj.renderAll.bind(fabricObj));
					fabricObj.upperCanvasEl.addEventListener('click', (event) => {
						inst.active_canvas = index;
						inst.fabricClickHandler(event, fabricObj);
					});
					fabricObj.on('after:render', function () {
						inst.fabricObjectsData[index] = fabricObj.toJSON();
						fabricObj.off('after:render');
					});

					if (index === canvases.length - 1 && typeof options.ready === 'function') {
						options.ready();
					}
				});
			}
		};

		this.fabricClickHandler = function (event, fabricObj) {
			var inst = this;
			if (inst.active_tool === 2) {
				var text = new fabric.IText('Sample text', {
					left: event.clientX - fabricObj.upperCanvasEl.getBoundingClientRect().left,
					top: event.clientY - fabricObj.upperCanvasEl.getBoundingClientRect().top,
					fill: inst.color,
					fontSize: inst.font_size,
					selectable: true
				});
				fabricObj.add(text);
				inst.active_tool = 0;
			}
		};
	}


	enableSelector() {
		var inst = this;
		inst.active_tool = 0;
		if (inst.fabricObjects.length > 0) {
			inst.fabricObjects.forEach((fabricObj, index) => {
				fabricObj.isDrawingMode = false;
			});
		}
	}

	enablePencil() {
		var inst = this;
		inst.active_tool = 1;
		if (inst.fabricObjects.length > 0) {
			inst.fabricObjects.forEach((fabricObj, index) => {
				fabricObj.isDrawingMode = true;
			});
		}
	}
	enableAddText() {
		var inst = this;
		inst.active_tool = 2;
		if (inst.fabricObjects.length > 0) {
			inst.fabricObjects.forEach((fabricObj, index) => {
				fabricObj.isDrawingMode = false;
			});
		}
	}
	enableRectangle() {
		var inst = this;
		var fabricObj = inst.fabricObjects[inst.active_canvas];
		if (fabricObj) {
			inst.active_tool = 4;
			if (inst.fabricObjects.length > 0) {
				inst.fabricObjects.forEach((fabricObj, index) => {
					fabricObj.isDrawingMode = false;
				});
			}

			var rect = new fabric.Rect({
				width: 100,
				height: 100,
				fill: inst.color,
				stroke: inst.borderColor,
				strokeSize: inst.borderSize
			});
			fabricObj?.add(rect);
		}
	}
	enableAddArrow() {
		var inst = this;
		inst.active_tool = 3;
		if (inst.fabricObjects.length > 0) {
			inst.fabricObjects.forEach((fabricObj, index) => {
				fabricObj.isDrawingMode = false;
				new Arrow(fabricObj, inst.color, function () {
					inst.active_tool = 0;
				});
			});
		}
	}
	addImageToCanvas() {
		var inst = this;
		var fabricObj = inst.fabricObjects[inst.active_canvas];

		if (fabricObj) {
			var inputElement = document.createElement("input");
			inputElement.type = 'file'
			inputElement.accept = ".jpg,.jpeg,.png,.PNG,.JPG,.JPEG";
			inputElement.onchange = function () {
				var reader = new FileReader();
				reader.addEventListener("load", function () {
					inputElement.remove()
					var image = new Image();
					image.onload = function () {
						fabricObj.add(new fabric.Image(image))
					}
					image.src = this.result;
				}, false);
				reader.readAsDataURL(inputElement.files[0]);
			}
			document.getElementsByTagName('body')[0].appendChild(inputElement)
			inputElement.click()
		}
	}
	deleteSelectedObject() {
		var inst = this;
		var activeObject = inst.fabricObjects[inst.active_canvas].getActiveObject();
		if (activeObject) {
			inst.fabricObjects[inst.active_canvas].remove(activeObject);
		}
	}
	async savePdf(fileName) {
		var inst = this;
		var doc = new jsPDF();
		if (!fileName || fileName === '') fileName = `${new Date().getTime()}.pdf`;
		inst.fabricObjects.forEach(function (fabricObj, index) {
			if (index !== 0) {
				doc.addPage();
				doc.setPage(index + 1);
			}
			doc.addImage(
				fabricObj.toDataURL({ format: 'png' }),
				inst.pageImageCompression === "NONE" ? "PNG" : "JPEG",
				0,
				0,
				doc.internal.pageSize.getWidth(),
				doc.internal.pageSize.getHeight(),
				`page-${index + 1}`,
				["FAST", "MEDIUM", "SLOW"].indexOf(inst.pageImageCompression) >= 0
					? inst.pageImageCompression
					: undefined
			);
			if (index === inst.fabricObjects.length - 1) {
				// doc.save(fileName);
			}
		})
		var reNameFile = new File([doc.output('blob')], fileName + '.pdf');
		return reNameFile;
	}
	// setAlpha(alpha) {
	// 	var inst = this;
	// 	inst.fabricObjects.forEach((fabricObj, index) => {
	// 		console.log(fabricObj);
	// 	});
	// }
	setBrushSize(size) {
		var inst = this;
		inst.fabricObjects.forEach((fabricObj, index) => {
			fabricObj.freeDrawingBrush.width = parseInt(size);
		});
	}
	setColor(color) {
		var inst = this;
		inst.color = color;
		inst.fabricObjects.forEach((fabricObj, index) => {
			fabricObj.freeDrawingBrush.color = color;
		});
	}
	setBorderColor(color) {
		var inst = this;
		inst.borderColor = color;
	}
	setFontSize(size) { this.font_size = size; }
	setBorderSize(size) { this.borderSize = size; }
	clearActivePage() {
		var inst = this;
		var fabricObj = inst.fabricObjects[inst.active_canvas];
		var bg = fabricObj.backgroundImage;
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Are you sure?')) {
			fabricObj.clear();
			fabricObj.setBackgroundImage(bg, fabricObj.renderAll.bind(fabricObj));
		}
	}
	serializePdf() {
		var inst = this;
		return JSON.stringify(inst.fabricObjects, null, 4);
	}
	loadFromJSON(jsonData) {
		var inst = this;
		inst.fabricObjects.forEach((fabricObj, index) => {
			if (jsonData.length > index) {
				fabricObj.loadFromJSON(jsonData[index], function () {
					inst.fabricObjectsData[index] = fabricObj.toJSON()
				})
			}
		})
	}
}
export { PDFAnnotate };


















