import { Meet } from "./Meet";
class TinhsEndpoint extends Meet {
    constructor() {
        super("/public/Tinhs");
    }
    getAllData() {
        return this.getAll('?select=name%2Cuuid%2Cprefix&count=true');
    }
    getFindById(id) {
        return this.getAll('/findById?id=' + id + '&select=uuid%2Cname%2Cprefix')
    }
}
export default new TinhsEndpoint();