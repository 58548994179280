import { getIPAddress } from './callApi';
import Cookies from 'js-cookie';

// Server URL
export const _URL = `${process.env.REACT_APP_SERVER_WEB || process.env.PUBLIC_URL || window.location.origin}`;
export const _PREFIX = `${process.env.REACT_APP_SERVER_PREFIX || ''}`;
export const _API_URL = `${process.env.REACT_APP_SERVER_API || '/api'}`;
export const _API_URL_GOOGLE_CLOUDE = `${process.env.REACT_APP_GOOGLE_CLOUDE}`;
export const _API_URL_AGENT = `${process.env.REACT_APP_SERVER_AGENT}`;
export const _API_URL_AGENT_STORAGE = `${process.env.REACT_APP_SERVER_AGENT_STORAGE}`;
export const _API_URL_GRPC = `${process.env.REACT_APP_SERVER_GRPC || process.env.PUBLIC_URL || window.location.origin}`;
// eslint-disable-next-line no-unused-vars
const token = Cookies.get('GDTECABINET');

const deadline = new Date();
deadline.setSeconds(deadline.getSeconds() + 5);

export const meta = {};
// Roles
export const _ADMIN = "admin";
export const _PARTNER = "partner";
export const _USER = "user";
export const ID_TOKEN_INFO = 'info';
export const TYPE_SYSTEM = 'typeSystem';
export const TOKEN_CLIENT_INFO = JSON.parse(localStorage.getItem(ID_TOKEN_INFO) || null);
export const TOKEN_CLIENT_PARTNER = JSON.parse(localStorage.getItem(_PARTNER) || null);
export const TOKEN_CLIENT_TYPE_SYSTEM = JSON.parse(localStorage.getItem(TYPE_SYSTEM) || null);
getIPAddress().then(res => Cookies.set('IP_Client', res)).catch(err => { })
export const isShowFeature = (TOKEN_CLIENT_INFO && TOKEN_CLIENT_INFO.isManager) || false;
export const isKhoiDang = process.env.REACT_APP_KHOI_DANG;
export const isSwitchType = process.env.REACT_APP_OPTION_TYPE;
//cookie jwt

// export const PREFIX_API_URL = "/api";
// export const API_URL_MULTI = _API_URL + '/AddMulti';
export const TIME_OUT = 500;
export const PREFIX_API_URL = "";
export const ODATA = '/odata';

export const AUTH_API_URL = PREFIX_API_URL + "/auth";
export const PUBLIC_API_URL = PREFIX_API_URL + "/public";
export const ALL_API_URL = PREFIX_API_URL + "/all";
export const ALLOWS_API_URL = PREFIX_API_URL + "/allow";

export const UPLOAD_API_URL = PUBLIC_API_URL + "/upload";
export const UPLOAD_AUTH_API_URL = AUTH_API_URL;
export const UPLOAD_ALLOW_API_URL = ALLOWS_API_URL + "/upload";

// export const ADMIN_API_URL = `${AUTH_API_URL}/${_ADMIN}`;
export const ADMIN_API_URL = `/admin`;

export const PARTNER_API_URL = `${AUTH_API_URL}/${_PARTNER}`;

export const ONLINE_ADMIN_API_URL = ADMIN_API_URL + "/online";
export const VN_COUNTRY_UUID = "45de171c-12b2-47e1-bb60-58f263d9ffc1";
export const TYPE_ENDPOINT = {
    google: 'google',
    agent: 'agent',
    default: 'default'
}
// deadline grpc

