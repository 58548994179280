import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const ButtonLoad = (props) => {
    const { children, ...attribute } = props;
    return (
        <Button color="primary" {...attribute} >
            <i className="fa-solid fa-eye"></i>
        </Button>
    );
};

ButtonLoad.propTypes = {
    children: PropTypes.any
};

ButtonLoad.defaultProps = {

};

export default ButtonLoad;