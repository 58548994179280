import { Meet } from "./Meet";

class SoDoPhongHopsEndpoint extends Meet {
    constructor() {
        super("/SoDoPhongHops");
    }
    getDataWithIdPhong(id) {
        return this.GetAllow(`?$filter=phongHopId eq ${id}&$expand=soDo($select=id,name,horizontalGuest,horizontalMain,verticalGuest,verticalMain,sizeRoom,typeTable ;$expand=soDoGhes($expand=ghe))`)
    }
    getDataWithId(id) {
        return this.GetAllow(`(${id})?&$expand=soDo($select=id,name,horizontalGuest,horizontalMain,verticalGuest,verticalMain,sizeRoom,typeTable ;$expand=soDoGhes($expand=ghe))`)
    }
    updateSoDoPhong = (id, data) => this.UpdateAUTH(`(${id})`, data);
}
export default new SoDoPhongHopsEndpoint();