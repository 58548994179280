// Enums

const _Enum_Trang_Thai_Phat_Bieu = {
    OFF: 'OFF',
    ON: 'ON',
    DONE: 'DONE',
}
const Enum_Trang_Thai_Phat_Bieu = {
    OFF: 'Chưa phát biểu',
    ON: 'Đang phát biểu',
    DONE: 'Đã phát biểu',
}

const _Enum_Loai_Phat_Bieu = {
    IN: 'IN',
    OUT: 'OUT',
    CHIDINH: 'CHIDINH',
}
const Enum_Loai_Phat_Bieu = {
    IN: 'Đăng ký trong phiên',
    OUT: 'Đăng ký trước phiên',
    CHIDINH: 'Chỉ định',
}

const Enum_Trang_Thai_Noi_Dung = {
    KhoiTao: 'Chưa họp',
    DangHop: 'Đang họp',
    An: 'Ẩn',
    HoanThanh: 'Đã họp',
}

const _Enum_Trang_Thai_Noi_Dung = {
    KhoiTao: 'KhoiTao',
    DangHop: 'DangHop',
    An: 'An',
    HoanThanh: 'HoanThanh',
}

const Enum_Trang_Thai_Tham_Du = [
    { value: 'DuocMoi', label: 'Được mời' },
    { value: 'ThamGia', label: 'Tham gia' },
    { value: 'Vang', label: 'Vắng' },
    { value: 'DiThay', label: 'Đi thay' },
]

const _Enum_Trang_Thai_Tham_Du = {
    DuocMoi: 'DuocMoi',
    ThamGia: 'ThamGia',
    Vang: 'Vang',
    DiThay: 'DiThay',
}

const Enum_Trang_Thai_Di_Thay = {
    Approved: 'Approved',
    Watting: 'Watting',
    Denied: 'Denied',
    Ignored: 'Ignored',
}

const Enum_Trang_Thai_Van_De = [
    { value: 'Moi', label: "Mới" },
    { value: 'HoatDong', label: "Hoạt động" },
    { value: 'HoanThanh', label: "Hoàn thành" },
    { value: 'Huy', label: "Hủy" },
]

const Enum_Option_Meeting_Status = [
    { value: 'DangKy', label: "Đăng ký" },
    { value: 'BoSung', label: "Bổ sung" },
    { value: 'Duyet', label: "Đã duyệt" },
    { value: 'DangHop', label: "Đang họp" },
    { value: 'KetThuc', label: "Kết thúc" },
    { value: 'Huy', label: "Hủy" }
]

const _Enum_Trang_Thai_Van_De = {
    Moi: 'Moi',
    HoatDong: 'HoatDong',
    HoanThanh: 'HoanThanh',
    Huy: 'Huy',
}

const Enum_Meeting_Status_Color = {
    DangKy: '#FFD700',
    Duyet: '#007BF8',
    TuChoi: 'red',
    CongBo: '#7EC0EE',
    DangHop: '#00FF00',
    KetThuc: '#8B864E',
    Hoan: '#8B7E66',
    Huy: 'darkgray',
    BoSung: '#cd201f',
}
const Enum_Meeting_Status_Label = {
    KhoiTao: 'Khởi tạo',
    DangKy: 'Đăng ký',
    TuChoi: 'Từ chối',
    BoSung: 'Bổ sung',
    Duyet: 'Đã duyệt',
    CongBo: 'Công bố',
    DangHop: 'Đang họp',
    KetThuc: 'Đã họp',
    Hoan: 'Hoãn',
    Huy: 'Hủy',
}
const Enum_Meeting_Status = {
    KhoiTao: 'KhoiTao',
    DangKy: 'DangKy',
    TuChoi: 'TuChoi',
    BoSung: 'BoSung',
    Duyet: 'Duyet',
    CongBo: 'CongBo',
    DangHop: 'DangHop',
    KetThuc: 'KetThuc',
    Hoan: 'Hoan',
    Huy: 'Huy',
}
const Enums_Type_Role = [
    { value: 'SA', label: 'Quản trị' },
    { value: 'TGH', label: 'Tham gia họp' },
    { value: 'QLH', label: 'Quản lý họp' },
    { value: 'TGLPYK', label: 'ý Kiến' }
]
const _Enums_Type_Role =
{
    SA: 'ADMIN',
    TGH: 'Tham gia họp',
    QLH: 'Quản lý họp',
    TGLPYK: 'ý Kiến'
}

const Enums_LoaiHeThong = [
    {
        value: 'Dang',
        label: 'Khối đảng',
        class: "justify-content-end pr-6",
        url: 'khoidang.png',
        ticket: "Khối Đảng"
    },
    {
        value: 'DiaPhuong',
        label: 'Chính quyền',
        class: "justify-content-start pl-6",
        url: 'quochuy.jpg',
        ticket: "Chính quyền"
    },
]

const Enums_LoaiPhienHop = [
    { value: 'BCS', label: 'Nhiều phiên', url: 'meet1.jpg' },
    { value: 'HDND', label: 'Một phiên', url: 'meet2.jpg' },
]
const _Enums_LoaiPhienHop = {
    BCS: 'BCS',
    HDND: 'HDND',
}

const Enums_SizeRoom = [
    { value: 'Small', label: 'Nhỏ' },
    { value: 'Medium', label: 'Vừa' },
    { value: 'Big', label: 'To' },
]

const _Enums_TypeDesk = {
    ChuNhat: 'Bàn chữ nhật',
    Tron: 'Bàn tròn',
    HoiDong: 'Bàn hội đồng',
};


const Enums_TypeDesk = [
    { value: 'ChuNhat', label: 'Bàn chữ nhật' },
    { value: 'Tron', label: 'Bàn tròn' },
    { value: 'HoiDong', label: 'Bàn hội đồng' }
]
const Enums_Side = [
    { value: 'Trai', label: 'Trái' },
    { value: 'Phai', label: 'Phải' },
    { value: 'Tren', label: 'Trên' },
    { value: 'Duoi', label: 'Dưới' },
]

const _Enums_Side = { Trai: 'T', Phai: 'P' };

const _Enums_TypeUpload = {
    GiayMoi: 'GiayMoi',
    ChuongTrinh: 'ChuongTrinh',
    DinhKem: 'DinhKem',
    KetLuan: 'KetLuan',
    CaNhan: 'CaNhan',
    DienBien: 'DienBien'
}

const Enums_TypeUpload = [
    { value: 'GiayMoi', label: 'Giấy mời' },
    { value: 'ChuongTrinh', label: 'Chương trình' },
    { value: 'DinhKem', label: 'Đính kèm' },
    { value: 'KetLuan', label: 'Kết luận' },
    { value: 'CaNhan', label: 'Cá nhân' },
]
const _Enums_LoaiBieuQuyet = {
    TrongHeThong: 'IN',
    NgoaiHeThong: 'OUT'
}
const Enums_LoaiBieuQuyet = [
    { value: 'IN', label: 'Trong hệ thống' },
    { value: 'OUT', label: 'Ngoài hệ thống' }
]

const Enums_isDiThay = [
    { value: 'chophep', label: 'Cho phép đi thay' },
    { value: 'khong', label: 'Không cho phép đi thay' }
]

const _Enums_DiThay = {
    'chophep': 'chophep',
    'khong': 'khong'
};

const _Enums_Loai = {
    'Kin': 'Kín',
    'CongKhai': 'Công khai'
};

const _Enum_Loai_Value = {
    'Kin': 'Kin',
    'CongKhai': 'CongKhai'
}

const Enums_Loai = [
    { value: 'Kin', label: 'Kín' },
    { value: 'CongKhai', label: 'Công khai' }
]
const _Is_Enums_DuyetDiThay = [
    { value: 'khong', label: 'Không duyệt' },
    { value: 'duyet', label: 'Cần duyệt' }
]
const Enums_Mau = [
    { value: 'PA', label: 'Phương án' },
    { value: 'BQ', label: 'Biểu quyết' }
]
const _Enums_LoaiPhieuYKien = [
    { value: 'Kin', label: 'Kín' },
    { value: 'CongKhai', label: 'Công khai' },
]

const Enums_GioiTinh = [
    {
        value: 'Nam',
        label: 'Nam'
    },
    {
        value: 'Nu',
        label: 'Nữ'
    },
    {
        value: 'Khong',
        label: 'Chưa có thông tin'
    }
]
const _Enums_GioiTinh = {
    Nam: 'Nam',
    Nu: 'Nữ',
    Khong: 'Chưa có thông tin',
}
const Enums_Color_TinhTrang = {
    New: 'warning',
    TC: 'danger',
    DTN: 'primary',
    DXL: 'success',
}
const _Enums_TinhTrang = {
    New: 'Mới',
    TC: 'Từ chối',
    DTN: 'Đang xử lý',
    DXL: 'Đã xử lý',
}


const _Enums_DoiTuongNumber = {
    CuaHang: 0,
    ThueNgoai: 1,
    KhachHang: 2,
    NhanVien: 3,
    CongTy: 4
}

const _Enums_TypeReloadRGPC = {
    NOIDUNG: 0,
    VANDE: 1,
    KETQUA: 2,
    VANDEHOANTHANH: 3,
    PHATBIEU: 4,
    VANDEFULL: 5,
}
//Enum_BDF
const _Enums_Font_Size = [
    { value: 12, label: 12 },
    { value: 16, label: 16 },
    { value: 18, label: 18 },
    { value: 24, label: 24 },
    { value: 32, label: 32 },
    { value: 48, label: 48 },
    { value: 64, label: 64 },
    { value: 72, label: 72 },
    { value: 108, label: 108 }
];

const _Enums_Template = {
    EmailRegister: 'EmailRegister',
    EmailMeeting: 'EmailMeeting',
    Conclusion: 'Conclusion',
    EmailRemind: 'EmailRemind',
}

export {
    Enum_Trang_Thai_Di_Thay,
    _Enums_DiThay,
    _Is_Enums_DuyetDiThay,
    Enums_isDiThay,
    _Enum_Loai_Value,
    _Enums_Loai,
    _Enums_Template,
    _Enums_Font_Size,
    Enums_GioiTinh,
    _Enums_DoiTuongNumber,
    Enums_Color_TinhTrang,
    _Enums_TinhTrang,
    _Enums_LoaiPhieuYKien,
    _Enums_GioiTinh,
    Enums_SizeRoom,
    Enums_TypeDesk,
    Enums_Side,
    Enums_TypeUpload,
    _Enums_LoaiBieuQuyet,
    Enums_LoaiBieuQuyet,
    Enums_Mau,
    Enums_LoaiPhienHop,
    _Enums_LoaiPhienHop,
    _Enums_Side,
    Enums_Type_Role,
    _Enums_Type_Role,
    Enum_Meeting_Status_Color,
    Enum_Meeting_Status,
    Enum_Meeting_Status_Label,
    _Enums_TypeDesk,
    Enum_Trang_Thai_Tham_Du,
    _Enum_Trang_Thai_Tham_Du,
    _Enum_Trang_Thai_Noi_Dung,
    _Enum_Trang_Thai_Van_De,
    Enum_Trang_Thai_Van_De,
    _Enums_TypeReloadRGPC,
    _Enum_Loai_Phat_Bieu,
    Enum_Loai_Phat_Bieu,
    _Enums_TypeUpload,
    Enum_Trang_Thai_Noi_Dung,
    _Enum_Trang_Thai_Phat_Bieu,
    Enum_Trang_Thai_Phat_Bieu,
    Enum_Option_Meeting_Status,
    Enums_LoaiHeThong,
    Enums_Loai
}