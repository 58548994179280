import React, { Fragment } from "react";
import { Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { Col, Row } from "reactstrap"

function TeamPending({ loading, status, ...props }) {
    return (
        <Fragment>
            {
                loading ? Array.from(Array(8).keys()).map((cur, i) => {
                    return <Row key={'ske4' + i} className="m-1 mb-3">
                        <Col md='3'>
                            <Typography component="div" variant={'body1'}>
                                <Skeleton variant="rect" />
                            </Typography>
                        </Col>
                        <Col md={'9'}>
                            <Typography component="div" variant={'body1'}>
                                <Skeleton variant="rect" />
                            </Typography>
                        </Col>
                    </Row>
                }) : props.children
            }
        </Fragment>

    )
}
export default TeamPending