import { Admin } from './Admin';
// import { _URL_EDIT } from '../../actions/constants';
// import AuthorityEndpoint from './AuthorityEndpoint';

class Endpoints extends Admin {
    constructor() {
        super("/Endpoints");
    }
    // Lấy thông tin tài khoản
    findAllGroupName = () => this.GetAuthen(`/EndpointGroups`);
    // Tìm tất cả các Endpoint với id vai trò tài khoản
    findAllIdEndpoint = (id, groupName) => this.GetAuthenADMIN(`/findAllIdEndpoint?id=${id}&groupName=${groupName}`);
    // Lưu các Endpoints với id vai trò tài khoản và Tên nhóm
    // saveAllWithAuthorityAndGroupName = (id, groupName, body) => this.PostAuthenADMIN(`${AuthorityEndpoint.path}${_URL_EDIT}?id=${id}&groupName=${groupName}`, body);

    // Lấy danh sách Endpoint với tên nhóm
    findAllEndpointByGroupName = (groupName) => this.GetAuthen(`?$filter=controller eq '${groupName}'`, null, true);

    getAll = (param) => this.SearchAllADMIN(param, null, true)
}

export default new Endpoints();