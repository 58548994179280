import { Meet } from "./Meet";
class SoTayPhienHopsEndpoint extends Meet {
  constructor() {
    super("/SoTayPhienHops");
  }

  getAllByThamDu = (isThuKy, id) => {
    const cond = isThuKy ? `phienHopId eq ${id}` : `thanhPhanThamDuId eq ${id}`;
    return this.SearchAllow(`?&$filter=${cond}&$orderby=thuTu`)
  };
  addGhiChu = (data) => this.PostAuthen('', data, { noLoading: true });
  editGhiChu = (id, data) => this.PatchAuthen(`(${id})`, data, { noLoading: true });
  deleteNote = (id) => this.DeleteAUTH(`(${id})`, { noLoading: true });
}
export default new SoTayPhienHopsEndpoint()