import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import FontAwesome from '../../icon/FontAwesome';

const ButtonClose = (props) => {
    const { children, iconSize, ...attribute } = props;
    return (
        <Button color="danger" {...attribute} >
            <FontAwesome fa="times-circle" style={{ ...iconSize }} />{' '}{children}
        </Button>
    );
};

ButtonClose.propTypes = {
    children: PropTypes.any
};

ButtonClose.defaultProps = {
    // size: "sm",
};

export default ButtonClose;