import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import FontAwesome from '../../icon/FontAwesome';

const ButtonEdit = (props) => {
    const { children, ...attribute } = props;
    return (
        <Button color="blue" {...attribute} >
            <FontAwesome fa="pencil-alt" />{' '}{children}
        </Button>
    );
};

ButtonEdit.propTypes = {
    children: PropTypes.any
};

ButtonEdit.defaultProps = {

};

export default ButtonEdit;