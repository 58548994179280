import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from '.';
const Icon = styled.span`
    color: red
`;
const Required = (props) => {
    const { text, children, ...attributes } = props;
    return <Label {...attributes}><b>{children}</b> <Icon>{text}</Icon></Label>;
}
Required.propTypes = { text: PropTypes.string.isRequired };
Required.defaultProps = { text: "*" };
export default Required;