// export interface Action<T = any> {
//     type: string,
//     data: T,
// }

export const ISCHUTRI = 'ISCHUTRI';
export const THANHPHANTHAMDU = 'THANHPHANTHAMDU';
export const PHIENHOP = 'PHIENHOP';
export const CHUCDANH = 'CHUCDANH';
export const UBND = 'UBND';
export const BIEUQUYET = 'BIEUQUYET';
export const RESET = 'RESET';
export const NOIDUNG = 'NOIDUNG';
export const GRPC = 'GRPC';
export const RELOAD = 'RELOAD';
export const CONNECT = 'CONNECT';
export const RELOADFILE = 'RELOADFILE';
export const NOTMODIFY = 'NOTMODIFY';

//Meeting
export const actionGRPC = (value) => {
    return ({ type: GRPC, data: value });
}
export const actionNoiDung = (value) => {
    return ({ type: NOIDUNG, data: value });
}
export const actionIsChuTri = (value) => {
    return ({ type: ISCHUTRI, data: value });
}
export const actionIsUBND = (value) => {
    return ({ type: UBND, data: value });
}
export const actionThanhPhanThamDu = (value) => {
    return ({ type: THANHPHANTHAMDU, data: value });
}
export const actionPhienHop = (value) => {
    return ({ type: PHIENHOP, data: value });
}
export const actionChucDanh = (value) => {
    return ({ type: CHUCDANH, data: value });
}
export const actionBieuQuyet = (value) => {
    return ({ type: BIEUQUYET, data: value });
}
export const actionResetData = () => {
    return ({ type: RESET });
}
//Layout
export const actionReloadLayout = (value) => {
    return ({ type: RELOAD, data: value });
}
export const actionConnect = (value) => {
    return ({ type: CONNECT, data: value });
}
export const actionReloadFile = (value) => {
    return ({ type: RELOADFILE, data: value });
}
export const actionNotModify = (value) => {
    return ({ type: NOTMODIFY, data: value });
}