import { Account } from './Account';
class MenuAdministrationOdataEndpoint extends Account {
    constructor() {
        super("/MenuAdministration");
    }
    // Lưu menu
    saveMenu = (body) => this.InsertODATA(body)
    // xóa menu
    deleteMenu = (id) => this.DeleteADMINODATA(`(${id})`)
    //update menu
    updateMenuAdmin = (menu) => this.UpdateODATA(`(${menu.Uuid})`, menu);
}
export default new MenuAdministrationOdataEndpoint();