import { Admin } from "./Admin";

class MenuRolesEndPoints extends Admin {
    constructor() {
        super("/MenuRoles");
    }
    // Danh sách Menu với Authority
    getMenuWithIDRole = (id) => {
        return this.GetAuthenADMINODATA(`?$filter=AppRoleId eq ${id} &$select=MenuId`);
    }
}

export default new MenuRolesEndPoints()