import { useCallback, useEffect, useState } from "react";
import {
    StreetEndpoint,
} from "../service/category/location";
import HuyensEndpoint from "../service/Meet/HuyensEndpoint";
import TinhsEndpoint from "../service/Meet/TinhsEndpoint";
import XasEndpoint from "../service/Meet/XasEndpoint";

const DEFAULT_OPTIONS = [];
const DEFAULT_DISABLED_STATE = true;
const DEFAULT_LOADING_SELECT = false;
const DEFAULT_SELECT = null;

const CITY_PROVINCE = "cityOrProvince";
const WARD_COMMUNE = "wardOrCommune";
const DISTRICT = "district";
const STREET = "street";

const DEFAULT_SELECTED_VALUE = {
    [CITY_PROVINCE]: DEFAULT_SELECT,
    [DISTRICT]: DEFAULT_SELECT,
    [WARD_COMMUNE]: DEFAULT_SELECT,
    [STREET]: DEFAULT_SELECT,
};

let mDistrictUuid;

function useLocationSelect(disableGetStreet) {
    //options
    const [provinceCityOptions, setProvinceCityOptions] = useState(
        DEFAULT_OPTIONS
    );
    const [districtOptions, setDistrictOptions] = useState(DEFAULT_OPTIONS);
    const [wardCommuneOptions, setWardCommuneOptions] = useState(DEFAULT_OPTIONS);
    const [streetOptions, setStreetOptions] = useState(DEFAULT_OPTIONS);

    //disabled
    const [disabledDistrictSelect, setDisabledDistrictSelect] = useState(
        DEFAULT_DISABLED_STATE
    );
    const [disabledWardCommuneSelect, setDisabledWardCommuneSelect] = useState(
        DEFAULT_DISABLED_STATE
    );
    const [disabledStreetSelect, setDisabledStreetSelect] = useState(
        DEFAULT_DISABLED_STATE
    );

    //selected value
    const [selectedValue, setSelectedValue] = useState(DEFAULT_SELECTED_VALUE);

    //pending state
    const [loadingProvinceCitySelect, setLoadingProvinceCitySelect] = useState(
        DEFAULT_LOADING_SELECT
    );
    const [loadingDistrictSelect, setLoadingDistrictSelect] = useState(
        DEFAULT_LOADING_SELECT
    );
    const [loadingWardCommuneSelect, setLoadingWardCommuneSelect] = useState(
        DEFAULT_LOADING_SELECT
    );
    const [loadingStreetSelect, setLoadingStreetSelect] = useState(
        DEFAULT_LOADING_SELECT
    );

    const formatSelectOptions = useCallback(function (
        response,
        responseProps = `name`
    ) {
        return response.map(function (item) {
            return {
                ...item.prefix ? { prefix: item.prefix } : {},
                label: item[responseProps],
                value: item.uuid
            };
        });
    },
        []);

    const getRequestParams = useCallback(function (id) {
        return `?id=${id}&select=name%2Cuuid%2prefix`;
    }, []);

    const setDistrictUuid = useCallback(function (wardCommuneUuid) {
        mDistrictUuid = wardCommuneUuid;
    }, []);

    const getDistrictUuid = useCallback(function () {
        return mDistrictUuid;
    }, []);

    const getProvinceCityOptions = useCallback(async () => {
        setLoadingProvinceCitySelect(true);
        const params = `?select=uuid%2Cname&count=false`;
        const response = await TinhsEndpoint.getAll(params);
        const options = await formatSelectOptions(response);
        setProvinceCityOptions(options);
        setLoadingProvinceCitySelect(false);
    }, [formatSelectOptions]);

    const getDistrictOptions = useCallback(
        async (uuid) => {
            setLoadingDistrictSelect(true);
            const response = await HuyensEndpoint.getHuyensByProvinceUuid(uuid);
            const options = await formatSelectOptions(response);
            setDistrictOptions(options);
            setDisabledDistrictSelect(false);
            setLoadingDistrictSelect(false);
        },
        [formatSelectOptions]
    );

    const getWardCommuneOptions = useCallback(async () => {
        setLoadingWardCommuneSelect(true);
        const uuid = getDistrictUuid();
        const response = await XasEndpoint.getOptionsByDistrictUuid(uuid);
        const options = await formatSelectOptions(response);
        setWardCommuneOptions(options);
        setDisabledWardCommuneSelect(false);
        setLoadingWardCommuneSelect(false);
    }, [getDistrictUuid, formatSelectOptions]);

    const getStreetOptions = useCallback(
        async function () {
            setLoadingStreetSelect(true);
            const streetEndpoint = StreetEndpoint;
            streetEndpoint.path = `/Street/District`;
            const uuid = getDistrictUuid();
            const params = getRequestParams(uuid);
            const response = await streetEndpoint.getAll(params);
            const options = await formatSelectOptions(response);
            setStreetOptions(options);
            setDisabledStreetSelect(false);
            setLoadingStreetSelect(false);
        },
        [getDistrictUuid, getRequestParams, formatSelectOptions]
    );

    const handleSelect = useCallback(
        function (selected, metadata) {

            const { name } = metadata;
            const { value } = selected || {};

            setSelectedValue((prevState) => ({ ...prevState, [name]: selected }));
            if (!value) return
            switch (name) {
                case CITY_PROVINCE: {
                    if (selectedValue.district !== DEFAULT_SELECT) {
                        setSelectedValue((prevState) => ({
                            ...prevState,
                            [DISTRICT]: DEFAULT_SELECT,
                            [WARD_COMMUNE]: DEFAULT_SELECT,
                            [STREET]: DEFAULT_SELECT,
                        }));
                    }
                    getDistrictOptions(value);
                    return;
                }
                case DISTRICT: {
                    if (selectedValue.wardOrCommune !== DEFAULT_SELECT) {
                        setSelectedValue((prevState) => ({
                            ...prevState,
                            [WARD_COMMUNE]: DEFAULT_SELECT,
                            [STREET]: DEFAULT_SELECT,
                        }));
                    }
                    setDistrictUuid(value);
                    getWardCommuneOptions();
                    return;
                }
                case WARD_COMMUNE: {
                    if (selectedValue.street !== DEFAULT_SELECT)
                        setSelectedValue((prevState) => ({
                            ...prevState,
                            [STREET]: DEFAULT_SELECT,
                        }));
                    if (disableGetStreet) return;
                    getStreetOptions();
                    return;
                }
                case STREET: {
                    return;
                }
                default:
                    return;
            }
        },
        [selectedValue.district, selectedValue.wardOrCommune, selectedValue.street, getDistrictOptions, setDistrictUuid, getWardCommuneOptions, disableGetStreet, getStreetOptions]
    );

    useEffect(
        function () {
            getProvinceCityOptions();
        },
        [getProvinceCityOptions]
    );

    return {
        provinceCityOptions,
        districtOptions,
        wardCommuneOptions,
        streetOptions,
        disabledDistrictSelect,
        disabledWardCommuneSelect,
        disabledStreetSelect,
        setDisabledDistrictSelect,
        setDisabledWardCommuneSelect,
        loadingProvinceCitySelect,
        loadingDistrictSelect,
        loadingWardCommuneSelect,
        loadingStreetSelect,
        handleSelect,
        selectedValue,
        formatSelectOptions,
        setSelectedValue,
    };
}

export default useLocationSelect;
