import React, { Component } from 'react';
import Select from './Select';

class SelectSingle extends Component {
  render() {
    const {
      name, disabled, options, placeholder, onChange, valueRef, labelRef, value,
      onMenuScrollToBottom, isLoading, onInputChange, onMenuOpen, onMenuClose, isClearable,
      menuPlacement
    } = this.props;

    const filterOption = (e) => e && e.map(k => {
      k.value = k[valueRef];
      k.label = k[labelRef];
      return k;
    });

    const values = filterOption(options);

    const select = value === null || value === '' ? null : values.filter(e => e.value === value)
    return (
      <Select
        menuPlacement={menuPlacement}
        isDisabled={disabled}
        className="basic-single"
        name={name}
        value={select}
        defaultValue={select}
        isClearable={isClearable}
        isSearchable
        options={values}
        placeholder={placeholder}
        onChange={(e, meta) => onChange(e, meta)}
        onMenuScrollToBottom={onMenuScrollToBottom}
        isLoading={isLoading}
        onInputChange={onInputChange}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
      // menuScrollIntoViewElement={menuScrollIntoViewElement}
      />
    );
  }
};

SelectSingle.propTypes = {

};

SelectSingle.defaultProps = {
  placeholder: 'Chọn...'
};

export default SelectSingle;
