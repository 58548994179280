import { Meet } from "./Meet";


class KetQuaYKienEndpoint extends Meet {
  constructor() {
    super("/KetQuaYKiens");
  }

  getAllKetQua = (param) => this.SearchAUTH(param)
  getNhanSuById = (id) => this.SearchAUTH(`?$expand=nhanSu($expand=phieuLayYKiens,organization,chucDanh,thongTinCaNhan,nhomThanhVienNhanSus)&$select=id`)
  deleteKetQua(id) { return this.DeleteAUTH(`(${id})`) }


  getKetQuaByIdPhieu = (Id, expand = []) => this.SearchAUTH(`?$filter=phieuLayYKienId eq ${Id}&$expand=${expand}`);
  getKetQuaByIdNoiDungAndIdNhanSu = (Id, nhanSuId) => this.SearchAUTH(`?$filter=boSungVanDeId eq ${Id} and nhanSuId eq ${nhanSuId}`);
  updateKetQuaYKien = (Id, data) => this.UpdateAUTH(`(${Id})`, data);
}
export default new KetQuaYKienEndpoint()