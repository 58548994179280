import FullScreenDialog from "../../../components/dialog/FullScreenDialog";
import React, { useMemo, useEffect, useCallback } from "react";
import PDFViewer from "../../../components/pdf/PDF.Viewer";
import Main from "../../../service/GateWay/Main";
import { Modal, ModalBody } from "reactstrap";
import { useState } from "react";
import AnnotationPDF from "../../../components/other/AnnotationPDF"
import { _Enums_TypeUpload } from "../../../views/action/defaultEnums";
// eslint-disable-next-line no-unused-vars
import { StorageEndpoint } from "../../../service/GateWay";

// const MS_OFFICE_VIEWER_URL = "https://view.officeapps.live.com/op/embed.aspx?src="
const GG_DRIVE_VIEWER_URL = "https://docs.google.com/gview?url="
const supportedDocumentExtensions = ["pdf", "doc", "docx"];
const enum_type_file = { pdf: ['pdf'], word: ['doc', 'docx'] }

// https://stackoverflow.com/questions/27957766/how-do-i-render-a-word-document-doc-docx-in-the-browser-using-javascript

function DocumentViewer({ activeDoc, onClose, isModal, isEdit, phienHopId }) {
    const [iframeUrl, setIframeUrl] = useState(GG_DRIVE_VIEWER_URL);
    const switchIframeUrl = () => { setIframeUrl(GG_DRIVE_VIEWER_URL) }
    const [data, setData] = useState(null);


    const prepareFileData = useCallback(async (doc) => {
        let temp = doc && doc?.name.split('.').length - 1
        let extension = doc?.name.split('.')[temp];
        // if (enum_type_file.pdf.includes(extension)) {
        const uri = Main.getDocUrlByPath(doc.path);
        setData({ uri, extension })
        // } 
        // else if (enum_type_file.word.includes(extension)) {
        //     const file = await StorageEndpoint.getImgByPath(doc.path);
        //     const reader = new FileReader();
        //     reader.readAsDataURL(file);
        //     reader.onload = () => {
        //         setData({ uri: reader.result, extension })
        //     };
        //     reader.onerror = () => {
        //         throw new Error('Fail to load the file');
        //     };
        // }
    }, [])

    useEffect(() => {
        if (activeDoc) {
            prepareFileData(activeDoc)
        }
    }, [activeDoc, prepareFileData])

    const renderDocViewer = useMemo(() => {
        if (!data) return null
        if (!supportedDocumentExtensions.includes(data.extension)) return (
            <div className={"w-100 h-100 d-flex flex-column align-items-center justify-content-center"}>
                <i className={"fa-exclamation-circle text-danger fa-3x"} />
                <h3 className={"mt-3"}>Định dạng văn bản không được hỗ trợ!</h3>
                <h3>Bạn chỉ có thể xem được các văn bản có định
                    dạng {supportedDocumentExtensions.join(", ")}</h3>
            </div>
        )
        const { uri } = data;
        const docSrc = `${GG_DRIVE_VIEWER_URL}${encodeURIComponent('https://ecabinet.gdtsolutions.vn' + uri)}&ui=en-US`
        if (enum_type_file.pdf.includes(data.extension)) {
            return uri ? <PDFViewer fileUrl={uri} height={'100%'} /> : <></>
        }
        // else if (enum_type_file.word.includes(data.extension)) {
        //     return uri ? <DocViewer pluginRenderers={DocViewerRenderers} documents={[{ uri }]} /> : <></>
        // }
        return (
            <iframe
                onError={switchIframeUrl}
                id={"doc-iframe"}
                title={"Xem văn bản"}
                src={docSrc}
                width='100%'
                height='100%'
                frameBorder='0'>
                {iframeUrl === GG_DRIVE_VIEWER_URL
                    ? <>This is an embedded
                        <a target='_blank'
                            rel="noreferrer"
                            href='http://office.com'>Microsoft
                            Office</a> document, powered by <a rel="noreferrer" target='_blank'
                                href='http://office.com/webapps'>Office
                            Online</a>.
                    </>
                    : null}
            </iframe>
        )
    }, [data, iframeUrl])

    return isModal ?
        <Modal size="xl" isOpen={activeDoc ? true : false} toggle={onClose} zIndex='1500'>
            <ModalBody style={{ height: '35rem', overflowY: 'hidden' }}>
                {
                    isEdit && activeDoc ? <AnnotationPDF
                        type={_Enums_TypeUpload.CaNhan}
                        phienHopId={phienHopId}
                        url={activeDoc && Main.getDocUrlByPath(activeDoc.path)}
                        style={{ height: '30.5rem' }} />
                        : renderDocViewer
                }
            </ModalBody>
        </Modal >
        : <FullScreenDialog
            open={activeDoc !== undefined}
            onClose={onClose}>
            {renderDocViewer}
        </FullScreenDialog>

}

export default React.memo(DocumentViewer)
