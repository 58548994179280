import React from 'react';
// import PropTypes from 'prop-types';
import { ModalBody } from "reactstrap";

const IModalBody = (props) => {
    const { children, ...attribute } = props;
    return (
        <ModalBody {...attribute}>
            {children}
        </ModalBody>
    );
};

IModalBody.propTypes = {

};

IModalBody.defaultProps = {

};

export default IModalBody;