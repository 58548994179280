import { Admin } from "./Admin";
class MenusEndpoint extends Admin {
    constructor() {
        super("/Menus");
    }
    // Lấy dữ liệu theo cây
    getMenuTree = () => this.GetAllow(`?$select=id,label,icon,link,parentId,ordinal&$orderby=ordinal asc`);
    // Thêm mới menu
    saveMenu = (body) => this.InsertODATA(body)
    // Xóa menu
    deleteMenu = (id) => this.DeleteAUTH(`(${id})`)
    // Cập nhật menu
    updateMenuAdmin = (id, menu) => {
        return this.UpdateAUTH(`(${id})`, menu)
    };
}
export default new MenusEndpoint();