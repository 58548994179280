import React, { useState, useEffect, useImperativeHandle } from "react";
import { CHECK_CONFIRM, CHECK_ERROR, CHECK_SUCCESS } from "../../components/alert/Alert";
import { ButtonDelete } from "../../components/base/Button";
import { LoadingSpinner } from "../../components/spinners";
import { TreeView, TreeItem } from "../../components/tree";

const settingData = (data) => {
  const arrData = [];
  data.forEach((curr, index, arr) => {
    if (!curr.parentId) {
      const element = { ...curr }
      const children = arr.filter(x => x.parentId === curr.id)
      if (children.length !== 0) {
        const dataChild = getNestedChildren(data, curr.id)
        element.children = dataChild;
      }
      arrData.push(element);
    }
  })
  return arrData
}

function getNestedChildren(arr, idParent) {
  var children = [];
  arr.forEach((curr, i) => {
    if (curr.parentId === idParent) {
      var grandChildren = getNestedChildren(arr, curr.id)
      if (grandChildren.length) {
        curr.children = grandChildren;
      }
      children.push(curr);
    }
  })
  return children;
}

/**
 * @author Hồ Văn Tuấn
 * @description Hiển thị cây Menu
 */
const Menus = React.forwardRef(
  (
    {
      // selectedKeys,
      manipulation,
      onSelect,
      viewDeleted,
      reloadTable
    },
    ref
  ) => {
    // states
    const [loading, setLoading] = useState(true);
    const [menus, setMenus] = useState([]);
    const [expanded, setExpanded] = useState(['null'])
    // Call API

    useEffect(() => {
      ref.current.queryData();
    }, [ref]);
    // Call API by Ref
    useImperativeHandle(ref, () => ({
      queryData: () => {
        setLoading(true);
        manipulation
          .view()
          .then(({ value }) => {
            const menus = settingData(value);
            setMenus(menus)
          })
          .catch(err => CHECK_ERROR("Không thể load dữ liệu !"))
          .finally(() => setLoading(false));
      }
    }));
    //

    const handleToggle = (event, nodeIds) => {
      event.persist()
      let iconClicked = event.target.closest(".MuiTreeItem-iconContainer")
      if (iconClicked) {
        setExpanded(nodeIds);
      }
    };

    return (
      <LoadingSpinner show={loading}>
        {menus && menus.length !== 0 && (
          <TreeView
            nameId="id"
            expandAll
            expanded={expanded}
            data={[{ id: "null", children: menus }]}
            onNodeToggle={handleToggle}
          >
            <TreeItem nodeId="null" icon="fa-home" title="Web" path="/">
              {viewNodes("null", menus, viewDeleted, onSelect, manipulation, reloadTable)}
            </TreeItem>
          </TreeView>
        )}
      </LoadingSpinner>
    );
  }
);

const viewNodes = (keyParent, nodes, viewDeleted, onSelect, manipulation, reloadTable) => {
  return (
    nodes &&
    nodes.map((node, i) => (
      <TreeItem
        key={`${i}${node.id}`}
        nodeId={node.id}
        icon={node.Icon}
        title={node.label}
        data={{
          Parent: keyParent,
          NodeId: node.id,
          icon: node.icon.split(',').join(' '),
          title: node.label,
          label: node.label,
          link: node.link,
          ordinal: node.ordinal
        }}
        onSelection={(e, infos) => {
          onSelect(e, infos)
        }}
        renderAfter={
          viewDeleted && (
            <ButtonDelete
              style={{ height: 24 }}
              onClick={() =>
                CHECK_CONFIRM(
                  `Bạn thật sự muốn xóa menu với tên '${node.label}' ?`,
                  "Xóa Menu"
                ).then((res) => {
                  if (res && res.isConfirmed) {
                    manipulation
                      .delete(node.id)
                      .then(result => {
                        reloadTable();
                        CHECK_SUCCESS('Xóa thành công');
                      })
                      .catch(err => CHECK_ERROR("Xóa thất bại !"));
                  }

                })
              }
            >
              Xóa
            </ButtonDelete>
          )
        }
      >
        {node.children &&
          viewNodes(
            node.id,
            node.children,
            viewDeleted,
            onSelect,
            manipulation,
            reloadTable
          )}
      </TreeItem>
    ))
  );
};

export default Menus;
