import { lazy } from "react";

const adminRoutes = [
  // {
  //   path: "/admin/view/base/profile",
  //   component: lazy(() => import("../../views/base/Profile/Profile"))
  // },
  {
    path: "/admin", component: lazy(() => import("../../views/dashboard"))
  },
  {
    path: "/admin/quan-tri/menu-khach-hang",
    component: lazy(() => import("../../views/quan-tri/MenuKhachHang"))
  },
  {
    path: "/admin/quan-tri/menu-he-thong",
    component: lazy(() => import("../../views/quan-tri/MenuHeThong"))
  },
  {
    path: "/admin/quan-tri/cau-hinh-vai-tro",
    component: lazy(() => import("../../views/quan-tri/CauHinhVaiTro/CauHinhVaiTro"))
  },
  {
    path: "/admin/quan-tri/danh-sach-quyen",
    component: lazy(() => import("../../views/quan-tri/DanhSachQuyen/DanhSachQuyen"))
  },
  {
    path: "/admin/quan-tri/quan-li-tai-khoan",
    component: lazy(() => import("../../views/quan-tri/DanhSachTaiKhoan/DanhSachTaiKhoan"))
  },
  {
    path: "/admin/quan-tri/lich-su-thao-tac",
    component: lazy(() => import("../../views/quan-tri/LichSuThaoTac"))
  },
  {
    path: "/admin/template",
    component: lazy(() => import("../../views/quan-tri/CauHinhTemplate"))
  },
  //Trang chủ
  // { path: '/admin/trang-chu', component: lazy(() => import('../../views/dashboard')) },
  { path: '/don-vi/trang-chu', component: lazy(() => import('../../views/dashboard')) },

];

export default adminRoutes
