import { lazy } from "react";

const categoryRoutes = [
    // Khác
    { path: '/admin/email', component: lazy(() => import('../../views/quan-tri/DanhMuc/danh-muc-khac/Email')) },
    { path: '/admin/chuyen-muc', component: lazy(() => import('../../views/quan-tri/DanhMuc/danh-muc-khac/ChuyenMuc')) },
    { path: '/admin/nhom-thanh-vien', component: lazy(() => import('../../views/quan-tri/DanhMuc/danh-muc-khac/NhomThanhVien')) },
    { path: '/admin/don-vi', component: lazy(() => import('../../views/quan-tri/DanhMuc/DonVi/Index')) },

    // dùng chung
    { path: '/admin/danh-muc/cau-hinh-chung', component: lazy(() => import('../../views/quan-tri/DanhMuc/cau-hinh-chung')) },

    // Vị trí
    { path: '/admin/view/danh-muc/danh-muc-quoc-gia', component: lazy(() => import('../../views/quan-tri/DanhMuc/danh-muc-vi-tri/DanhMucQuocGia')) },
    { path: '/admin/danh-muc/danh-muc-tinh-thanh-pho', component: lazy(() => import('../../views/quan-tri/DanhMuc/danh-muc-vi-tri/DanhMucTinhThanhPho')) },
    { path: '/admin/danh-muc/danh-muc-quan-huyen', component: lazy(() => import('../../views/quan-tri/DanhMuc/danh-muc-vi-tri/DanhMucQuanHuyen')) },
    { path: '/admin/danh-muc/danh-muc-phuong-xa', component: lazy(() => import('../../views/quan-tri/DanhMuc/danh-muc-vi-tri/DanhMucPhuongXa')) },
    { path: '/admin/danh-muc/danh-muc-duong-pho', component: lazy(() => import('../../views/quan-tri/DanhMuc/danh-muc-vi-tri/DanhMucDuongPho')) },

    // Đơn vị
    { path: '/don-vi/chuc-danh', component: lazy(() => import('../../views/quan-tri/DanhMuc/DonVi/ChucDanh')) },
    { path: '/don-vi/so-do', component: lazy(() => import('../../views/don-vi/DanhMuc/SoDo')) },
    { path: '/don-vi/khach-moi', component: lazy(() => import('../../views/quan-tri/DanhMuc/DonVi/KhachMoi')) },
];

export default categoryRoutes
