import { Admin } from "./Admin";
class AccountsEndpoint extends Admin {
    constructor() {
        super("/Accounts");
    }



    // lấy danh sách tài khoản
    getAllAccount = () => this.SearchAUTH('?$expand=accountRoles&$select=userName,displayName,activated,changed,id&$orderby=userName', null, null, true);

    // xóa tài khoản
    deleteUser = (id) => this.DeleteAUTH(`(${id})`)
    // Reset mật khẩu
    resetPassword = (userName) => this.GetAuthen(`/ResetPassword?userName=${userName}`)
    // lấy tài khoản theo Id
    getInfoByIdAccount = (id) => this.GetAuthen(`(${id})?$select=displayName, userName, email, phoneNumber`);

    // login Form
    loginAccount = (data) => this.PostAuthenPUBLIC("/Authenticate/Login", data)

    // login Account
    logoutAccount = () => this.DeleteAllow('/Logout')

    // Lấy danh sách menu theo tài khoản admin/public/Menus/GetMenu
    getMenuWithAuthority = () => this.GetAllow(`/Menus`);

    // lấy danh sách vai trò theo tài khoản
    getAuthorities = () => this.GetAllow(`/DanhSachVaiTro`)

    //lấy thông tin theo tài khoản
    getInfo = () => this.GetAuthen('/ChiTietTaiKhoan', { noLoading: true })

    // cập nhật thông tin chi tiết tài khoản
    UpdateChiTietTaiKhoan = (body) => this.PostAuthen('/UpdateChiTietTaiKhoan', body)

    // get ID upload
    getDefault = () => this.GetAuthen('/GetIdUpload', { responseType: 'json' })

    // reset cho tài khoản
    changePassword = ({ userName, password }) => this.GetAuthen(`/ChangePass?userName=${userName}&password=${password}`)

    // đổi mật khẩu cho tài khoản
    accChangePassword = (body) => this.PostALLow(`/ChangePassword`, body)

    //upload
    uploadFile(file) {
        return this.UploadAllow('/UploadAvatar', { file })
    }

    // Kiểm tra user đã thay đổi password lần đầu hay chưa
    isChangePass = () => this.GetAllow(`/IsChangePass`)

    // Thêm mới tài khoản
    addNewUsers = (body) => this.PostAuthen('/ThemTaiKhoan', body)

    update = (id, body) => this.PatchAuthen(`(${id})`, body)

    // Thêm vai trò
    addAuthorities = (body) => this.PostAuthen('/ThemVaiTro', body)

    // Lấy danh sách url Avatar
    getUrlAvatars = (idUsers, config) => this.PostALLow(`/GetAvatar`, idUsers, config);

    // Update organization
    updateOrgan = (data) => this.GetAllow(`/UpdateOrgan?userId=${data.userId}&organId=${data.organId}`);

}
export default new AccountsEndpoint();