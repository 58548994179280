import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, memo, useState, forwardRef } from 'react'
import { Card, CardBody, CardHeader, Col, Modal, ModalBody, Table } from 'reactstrap';
import { BoSungVanDesEndpoint } from '../../../../../service/Meet';
import './style.scss'
import RenderTbodyCanBo from './RenderTbodyCanBo';
import { idNhanSu } from './util';
//grpc
import { _Enums_LoaiBieuQuyet, _Enum_Trang_Thai_Van_De } from '../../../../action/defaultEnums';
import { CHECK_SUCCESS } from '../../../../../components/alert/Alert';
import { TOKEN_CLIENT_INFO } from '../../../../../actions/types';
import RenderTbodyChuyenVien from './RenderTbodyChuyenVien';
import { ButtonAdd, ButtonIcon } from '../../../../../components/base/Button';
import ModalVanDe from './Modal/ModalVanDe';
import { cumulativeOffset } from '../Main';
import { useSelector, useDispatch } from 'react-redux';
import { actionBieuQuyet } from '../../Redux/ActionsMeeting'
import { TypeEnums } from '../../../../../proto/reloadroute_pb';
import PDFViewer from '../../../../../components/pdf/PDF.Viewer';
import { useAsync } from '../../../../../hooks'

export const RenderPDF = memo(({ url, onClose }) => {
    return <Modal size="xl" isOpen={url ? true : false} toggle={onClose} zIndex='1500'>
        <ModalBody style={{ height: '35rem', overflowY: 'hidden' }}>
            <PDFViewer fileUrl={url} height={'100%'} />
        </ModalBody>
    </Modal >
});

const NoiDungVanDe = forwardRef((props, ref) => {
    const { phienHopId, isDienBien } = props;
    const [vanDe, setVanDe] = useState([]);
    const [idThanhPhanThamDu, setIdThanhPhanThamDu] = useState(null);
    const refTbodyCanBo = useRef();
    const refTbodyChuyenVien = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const { isChuTri, thamDus, isUBND, noiDung } = useSelector(state => state.meetingRProcess);
    const [openReportPDF, setOpenReportPDF] = useState(null);
    const dispatch = useDispatch();

    const getDSBoSungVanDes = useCallback(async (noiDungId, idType, isSameFloor = true, vanDeBieuQuyet) => {
        if (noiDungId) {
            //Lấy danh sách vấn đề mới
            let { value: data } = await BoSungVanDesEndpoint.getDataByIDNoiDung(noiDungId);
            if (idType === TypeEnums.VANDEFULL) {
                setVanDe(data);
                return;
            }
            if (TOKEN_CLIENT_INFO.khachMoiId) {
                data = data.filter(f => f.loaiBieuQuyet === _Enums_LoaiBieuQuyet.NgoaiHeThong);
            }
            setVanDe(data);
            //Check biểu quyết đang hoạt động
            let bieuQuyetHoatDong = isSameFloor ?
                data.find(cur => cur.trangThai === _Enum_Trang_Thai_Van_De.HoatDong)
                : vanDeBieuQuyet

            if ([TypeEnums.VANDEHOANTHANH, TypeEnums.VANDE].includes(idType)) {
                if (idType === 3) bieuQuyetHoatDong = null;
                dispatch(actionBieuQuyet(bieuQuyetHoatDong));
            }


        }
    }, [dispatch])

    const getThanhPhanThamDu = useCallback(() => {
        if (thamDus) {
            if (idNhanSu) {
                const data = thamDus.thamGia.filter(f => f.trangThaiThamDu === 'ThamGia').find(find => find.nhanSuId === idNhanSu);
                if (data) setIdThanhPhanThamDu(data.id)
            } else if (TOKEN_CLIENT_INFO.khachMoiId) {
                const data = thamDus.thamGia.filter(f => f.trangThaiThamDu === 'ThamGia').find(find => find.khachMoiId === TOKEN_CLIENT_INFO.khachMoiId);
                if (data) setIdThanhPhanThamDu(data.id)
            }
        }
    }, [thamDus])

    useEffect(() => { getThanhPhanThamDu() }, [getThanhPhanThamDu])
    useEffect(() => { noiDung && getDSBoSungVanDes(noiDung.id, 2) }, [getDSBoSungVanDes, noiDung])

    /**
     * 1. Khi patch bổ sung vấn đề từ trạng thái mới thành hoạt động
     * 2. Khi cán bộ tham gia phiên họp patch phương án bổ sung thì bên chủ trì load lại
     * 3. Khi chủ trì patch bố sung vấn đề thành hoàn thành
     * 5. Khi người chuyên viên tạo vấn đề mới
     */
    const updateReload = useCallback(async (idVanDe, idType) => {
        const { id: khungNoiDungId } = noiDung;
        let vanDeBieuQuyet = {};
        if (idType !== TypeEnums.XOAVANDE) vanDeBieuQuyet = await BoSungVanDesEndpoint.SearchAllow(`(${idVanDe})`);
        //kiểm tra có đứng tại nội dung đang họp hay không
        const isSameContent = vanDeBieuQuyet.khungNoiDungId === khungNoiDungId;
        getDSBoSungVanDes(khungNoiDungId, idType, isSameContent, vanDeBieuQuyet);
        switch (idType) {
            case TypeEnums.VANDE:// 1 bắt đầu
                TOKEN_CLIENT_INFO?.role !== 'QLH' && refTbodyCanBo.current.setDanhGia(vanDeBieuQuyet.id); // bật lên popup
                TOKEN_CLIENT_INFO?.mainRole === 'TK' && refTbodyCanBo.current.setKetQua({ ...vanDeBieuQuyet, trangThai: _Enum_Trang_Thai_Van_De.HoatDong });
                break;
            case TypeEnums.KETQUA://2 biểu quyết
                break;
            case TypeEnums.VANDEHOANTHANH://3 hoàn thành
                refTbodyCanBo.current && refTbodyCanBo.current.setCloseDanhGia();
                CHECK_SUCCESS(`Đã hoàn thành vấn đề ${vanDeBieuQuyet.name}`);
                break;
            case TypeEnums.VANDEFULL://Tạo vấn đề mới
                CHECK_SUCCESS(`Vấn đề biểu quyết có thay đổi mới`);
                break;
            case TypeEnums.XOAVANDE://Xóa vấn đề
                CHECK_SUCCESS(`Vấn đề biểu quyết đã được xóa`);
                break;
            case TypeEnums.LAMMOI://Edit vấn đề
                break;
            default:
                break;
        }
        return true
    }, [noiDung, getDSBoSungVanDes]);

    useImperativeHandle(ref, () => ({ updateReload }), [updateReload]);

    const modalVanDe = useMemo(() => {
        return isOpen && (
            <ModalVanDe
                isOpen={isOpen}
                isClose={() => setIsOpen(false)}
                khungNoiDungId={noiDung?.id}
            />
        )
    }, [isOpen, noiDung])

    useEffect(() => {
        const a = document.getElementById('content-problem');
        if (a) { a.style.height = window.screen.availHeight - cumulativeOffset(a) - 120; }
    });

    const renderThead = useMemo(() => {
        return <thead>
            <tr>
                <td>STT</td>
                <td>Vấn đề</td>
                <td>Mẫu</td>
                <td>Trạng thái</td>
                {isUBND && <td>Thao tác</td>}
            </tr>
        </thead>
    }, [isUBND])

    const isChuyenVien = TOKEN_CLIENT_INFO.mainRole === 'CV';

    const openReport = useCallback(async (data) => {
        try {
            const file = await BoSungVanDesEndpoint.reportPDF(data);
            const url = window.URL.createObjectURL(file);
            setOpenReportPDF(url);
            return true
        } catch (error) { throw new Error(error) }

    }, []);
    const { status, execute: exportReport } = useAsync(openReport, false);
    return (
        <Card className="mb-0">
            <CardHeader className="p-2" >
                <Col md='12' className="text-white d-flex justify-content-between">
                    <span>Danh sách vấn đề biểu quyết ({vanDe.length}) </span>
                    {
                        isChuTri && <ButtonIcon
                            color='red'
                            icon='file-pdf'
                            onClick={() => exportReport({ khungNoiDungId: noiDung.id })}>
                            {status === 'pending' ? <span className='d-flex'>
                                Đang tải file <div className="spinner-notify" />
                            </span >
                                : <span>Xuất báo cáo</span>}
                        </ButtonIcon>
                    }
                    {
                        (isChuyenVien || isChuTri) && !isDienBien && <ButtonAdd onClick={() => setIsOpen(true)}>Thêm</ButtonAdd>
                    }
                </Col>
            </CardHeader>
            <CardBody className="p-0 table-sticky-vd" id='content-problem' style={{ height: '14rem' }} >
                <Table size="sm" bordered striped>
                    {renderThead}
                    {
                        isChuyenVien ?
                            <RenderTbodyChuyenVien
                                ref={refTbodyChuyenVien}
                                vanDe={vanDe}
                                isDienBien={isDienBien}
                            /> : <RenderTbodyCanBo
                                openReport={openReport}
                                thamDus={thamDus}
                                ref={refTbodyCanBo}
                                idThanhPhanThamDu={idThanhPhanThamDu}
                                isChuTri={isChuTri}
                                vanDe={vanDe}
                                phienHopId={phienHopId}
                                isDienBien={isDienBien}
                            />
                    }
                </Table>
            </CardBody>
            <RenderPDF url={openReportPDF} onClose={() => setOpenReportPDF(null)} />
            {modalVanDe}
        </Card>
    )
})
export default NoiDungVanDe;
