import { Meet } from "./Meet";

class PhuongAnBoSungsEndpoint extends Meet {
    constructor() {
        super("/PhuongAnBoSungs");
    }

    getDataByIdVanDe = (id) => this.SearchAllow(`?&$filter=boSungVanDeId eq ${id}&$orderby=thuTu`);

    addPhuongAns(data) { return this.PostAuthen('', data); }
}
export default new PhuongAnBoSungsEndpoint();