import { lazy } from "react";

const categoryRoutes = [
    // Nhân sự
    { path: '/don-vi/nhan-su/danh-sach-nhan-su', component: lazy(() => import('../../views/don-vi/NhanSu')) },
    { path: '/don-vi/lich-lam-viec', component: lazy(() => import('../../views/don-vi/LichLamViec')) },
    { path: '/don-vi/van-ban', component: lazy(() => import('../../views/thu-vien-van-ban/VanBan')) },
    // { path: '/don-vi/phieu-y-kien', component: lazy(() => import('../../views/don-vi/phieu-lay-y-kien/PhieuYKien')) },
    { path: '/don-vi/phieu-y-kien', component: lazy(() => import('../../views/don-vi/PhieuLayYKien')) },
    //Phòng họp
    { path: '/don-vi/quan-ly-phong-hop', component: lazy(() => import('../../views/don-vi/PhongHop')) },
    //Phiên họp
    { path: '/don-vi/quan-ly-phien-hop', component: lazy(() => import('../../views/don-vi/PhienHop')) },
    { path: '/don-vi/loai-phien-mau', component: lazy(() => import('../../views/don-vi/LoaiPhienHop')) },
    { path: '/don-vi/mau-phuong-an', component: lazy(() => import('../../views/don-vi/MauPhuongAn')) },

];

export default categoryRoutes
