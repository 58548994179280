import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';

const IRow = (props) => {
    const { children, ...attribute } = props;
    return (
        <Row {...attribute}>
            {children}
        </Row>
    );
}

IRow.propTypes = {
    noGutters: PropTypes.bool,
    form: PropTypes.bool,
    xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

IRow.defaultProps = {

};

export default IRow;