
export default function DuAnReducer(state, action) {
    switch (action.type) {
        //Can bo
        case "RESET_INI_STATE":
            return null
        case "SET_ID_PHIEN_HOP":
            return ({ ...state, 'id': action.payload });
        case "SET_CODE":
            return ({ ...state, 'code': action.payload });
        case "SET_NAME":
            return ({ ...state, 'name': action.payload });
        default:
            break;
    }
}