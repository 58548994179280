import { Admin } from "./Admin";

class AuthorizedRolesEndpoint extends Admin {
    constructor() {
        super("/AuthorizedRoles");
    }
    getEndpointWithIDRole = (id, groupName) => {
        return this.GetAuthen(`?$expand=endpoint&$filter=roleId eq ${id} and endpoint/controller eq '${groupName}'`);
    }
}

export default new AuthorizedRolesEndpoint()