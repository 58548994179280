import * as OdataUtils from '../table/OdataUtils';
// import { CHECK_WARNING } from "../alert/Alert";

export const checkDataRef = (url, column, dataRef, where) => {
    let odataURL = `${url}`;
    if (dataRef) {
        let arrColumns = [...dataRef];
        arrColumns.push(column.field);
        arrColumns.push(column.label);
        odataURL += `${OdataUtils.generatedExpandSelectURL(arrColumns, where, false)}`;
    } else {
        odataURL += `${OdataUtils.generatedExpandSelectURL([column.field, column.label], where, false)}`;
    }
    return odataURL;
}

export const checkDataRefPaginate = (url, column, dataRef, where, page, pageSize) => {
    return checkDataRef(`${url}?${OdataUtils.generatePaginate(page, pageSize)}&`, column, dataRef, where);
}