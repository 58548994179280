
import { TYPE_ENDPOINT } from '../../actions/constants';
import { Service } from '../Service';
// import { PUBLIC_API_URL, _API_URL } from '../../actions/constants';

class AgentEndpoint extends Service {
    constructor() {
        super('');
        this.isAgent = { typeEndpoint:TYPE_ENDPOINT.agent, noLoading: true }
    }
    // login
    login = (data) => this.PostPartner('/authen/api/v1/auth/login', data, this.isAgent);

    getAgents = () => this.GetPartner('/on-premise/v1/live/agent', this.isAgent)

    setMicAgent = ({ id_agent, mic_name }) => this.PostPartner(`/on-premise/v1/live/agent/${id_agent}/set-mic`, { mic_name }, this.isAgent)

    detailChannel = (data) => this.PostPartner('/on-premise/v1/live-meetings', data, this.isAgent)

    createChannel = (data) => this.PostPartner('/on-premise/v1/live-meetings', data, this.isAgent)

    startChannel = (agentID) => this.GetPartner(`/on-premise/v1/live/agent/${agentID}/start`, this.isAgent)

    stopChannel = (channelID) => this.GetPartner(`/on-premise/v1/live/agent/${channelID}/stop`, this.isAgent)
    // get source audio
    getAudioSources = () => this.GetPartner('/analytic/v1/audio-sources', { ...this.isAgent, noLoading: true })

    getSignature = (type) => this.PostPartner('/analytic/v1/digitalization/upload-audio', { "file_extension": '.mp3' }, { ...this.isAgent, noLoading: true })

    sendFileToStorage = (data) => this.UploadPartner('/UploadAudio/PostAudio', data, { ...this.isAgent, noLoading: true })

    getChannelAudio = (key) => this.GetPartner(`/audio-info/v1/audio-channels?bucket=vais-audio&key=${key}`, { ...this.isAgent, noLoading: true })

    creatAnalyticAudio = (data) => this.PostPartner('/analytic/v2/audios', data, { ...this.isAgent, noLoading: true })

    getTextFromAudio = (audio_id) => this.GetPartner(`/analytic/v1/texts?audio_id=${audio_id}&order_by=stime&order_direction=1`, { ...this.isAgent, noLoading: true })
}

export default new AgentEndpoint();