import React, { Fragment, useCallback } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import styled from "styled-components";
import { TOKEN_CLIENT_INFO } from "../../../../../../actions/constants";
import { CHECK_CONFIRM, CHECK_SUCCESS, CHECK_WARNING } from "../../../../../../components/alert/Alert";
import { ButtonCustom } from "../../../../../../components/base/Button";
import DangKyPhatBieusEndpoint from "../../../../../../service/Meet/DangKyPhatBieusEndpoint";
import { _Enum_Loai_Phat_Bieu } from "../../../../../action/defaultEnums";
import './style.scss';

const Action = styled.td`
vertical-align: middle !important;
    text-align: center;
`
const ActionText = styled.td`
vertical-align: middle !important;
    text-align: left;
`
function Index({ close, isOpen }) {
    const { thamDus, phienHop } = useSelector(state => state.meetingRProcess);

    const handleChiDinh = useCallback((thamDu) => {
        const name = thamDu.nhanSu ? thamDu.nhanSu.thongTinCaNhan.hoVaTenDem + ` ` + thamDu.nhanSu.thongTinCaNhan.ten
            : thamDu.khachMoi.name;
        CHECK_CONFIRM(`Chỉ định cán bộ ${name}`)
            .then(res => {
                if (res && res.isConfirmed) {
                    DangKyPhatBieusEndpoint.dangKyPhatBieu({
                        khungNoiDungId: isOpen,
                        type: _Enum_Loai_Phat_Bieu.CHIDINH,
                        thanhPhanThamDuId: thamDu.id,
                        noiDung: ''
                    }).then(res => {
                        CHECK_SUCCESS('Đăng ký thành công');
                    }).catch(err => {
                        CHECK_WARNING(err.message)
                    })
                }
            });
    }, [isOpen]);

    const renderThanhVien = useMemo(() => {
        const data = thamDus.thamGia.filter(cur =>
            (cur.nhanSuId !== TOKEN_CLIENT_INFO.nhanSuId)
            // && (cur.khachMoiId !== TOKEN_CLIENT_INFO.khachMoiId)
            && cur.nhanSuId !== phienHop.chuTriId
        )
        return <Fragment>
            {data.map((cd, i) => {
                return <tr key={i + 'nhansu'}>
                    <Action>{i + 1}</Action>
                    <ActionText>{
                        cd.nhanSu ?
                            cd.nhanSu.thongTinCaNhan.hoVaTenDem + ` ` + cd.nhanSu.thongTinCaNhan.ten :
                            cd.khachMoi.name
                    }</ActionText>
                    <ActionText>{cd.nhanSu ? cd.nhanSu.chucDanh.name : 'Khách mời'}</ActionText>
                    <Action>
                        <ButtonCustom color="primary" onClick={() => handleChiDinh(cd)}>Chỉ định</ButtonCustom>
                    </Action>
                </tr>
            })}
        </Fragment>
    }, [handleChiDinh, phienHop, thamDus])
    return (
        <Modal isOpen={isOpen} toggle={close} zIndex='1500'>
            <ModalHeader toggle={close}>Chọn nhân sự</ModalHeader>
            <ModalBody>
                <div className="p-0 table-sticky-choose-person" style={{ height: '21rem' }}>
                    <Table size="sm" bordered striped>
                        <thead>
                            <tr >
                                <td className="text-center">STT</td>
                                <td className="text-center">Họ và tên</td>
                                <td className="text-center">Chức vụ</td>
                                <td className="text-center">Chọn</td>
                            </tr>
                        </thead>
                        <tbody className="position-sticky overflow-auto">
                            {renderThanhVien}
                        </tbody>
                    </Table>
                </div>
            </ModalBody>
        </Modal >
    )
}
export default Index
