import {
    ISCHUTRI, THANHPHANTHAMDU, PHIENHOP, CHUCDANH, UBND,
    BIEUQUYET, RESET, NOIDUNG, GRPC, RELOAD, CONNECT, RELOADFILE, NOTMODIFY
} from "./ActionsMeeting";

// export interface StateMeetingProcess {
//     isChuTri: boolean
// }

const defaultState = {
    noiDung: null,
    isChuTri: false,
    thamDus: null,
    phienHop: null,
    chucDanhs: [],
    isUBND: true,
    vanDeBieuQuyet: null,
    grpc: null,
    reload: false,
    connect: true,
    isReloadFile: null,
    isNotModify: false
}

const meetingReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GRPC:
            return ({ ...state, grpc: action.data })
        case NOIDUNG:
            return ({ ...state, noiDung: action.data })
        case ISCHUTRI:
            return ({ ...state, isChuTri: action.data })
        case THANHPHANTHAMDU:
            return ({ ...state, thamDus: action.data })
        case PHIENHOP:
            return ({ ...state, phienHop: action.data })
        case CHUCDANH:
            return ({ ...state, chucDanhs: action.data })
        case UBND:
            return ({ ...state, isUBND: action.data })
        case BIEUQUYET:
            return ({ ...state, vanDeBieuQuyet: action.data })
        case RESET:
            return defaultState;
        case RELOADFILE:
            return ({ ...state, isReloadFile: action.data })
        //Layout
        case RELOAD:
            return ({ ...state, reload: action.data })
        case CONNECT:
            return ({ ...state, connect: action.data })
        case NOTMODIFY:
            return ({ ...state, notModify: action.data })
        default:
            return state;
    }
}
export default meetingReducer;