import { Meet } from "./Meet";

class PhienHopsEndpoint extends Meet {
    constructor() {
        super("/PhienHops");
    }

    getAllPhienHopById = (IdPhienHop) => this.GetAllow(`(${IdPhienHop})?$expand=soDoPhongPhien`)

    getAllLichHopCaNhan = (body) => this.PostALLow(`/LichHopCaNhan`, body)

    updateData(id, data) {
        return this.PatchAuthen(`(${id})`, data)
    }

    getDataByID(id) {
        return this.SearchAllow(`(${id})?$expand=thuKy($expand=thongTinCaNhan($select=ten,hoVaTenDem))&$select=id,name,code,deNghi,batDau,ketThuc,loaiPhienHop,organId,chuTriId,phongHopId,trangThai,thuKyId,fileId,isDiThay,isDuyetDiThay`)
    }
    InfoPhienHopPublicById(id) {
        return this.SearchAllPUBLIC(`/InfoPhienHopPublicById?phienHopId=${id}`)
    }
    getDataParentByID(id) {
        return this.SearchAllow(`(${id})?$select=id,name,code,deNghi,batDau,ketThuc,loaiPhienHop,organId,chuTriId,phongHopId,trangThai,thuKyId`)
    }
    addPhienHop(data) {
        return this.PostAuthen('/ThemPhienHop', data)
    }
    addPhienHopOdata(data) {
        return this.PostAuthen('', data)
    }
    UploadFile(key, data, config) {
        return this.UploadAuth('/Upload', { ...data, key: key, isDelPhienHop: false }, config)
    }
    deletePhienHop(id) { return this.DeleteAUTH(`(${id})`) }

    updateDanhSachThamDu(data) { return this.PostAuthen('/ThemThanhPhanThamDu', data) }

    sendMail(idPhien, idMail, password) { return this.GetAuthen(`/GuiMailLichHop?phienHopId=${idPhien}&email=${idMail}&secretKey=${password}`, { noLoading: false }) }

    getPhienHopFromParent(id) { return this.SearchAllow(`?$filter=parentId eq ${id}&$expand=khungNoiDungs&orderBy=batDau`) }

    getAllPhienHop = (param) => this.SearchAllow(param);

    getPhienHopByIdAndParam = (id, param) => this.SearchAllow(`(${id})${param}`)

    getAllPhienHopByFilter = (TuNgay, DenNgay) => this.SearchAllow(`?TuNgay=${TuNgay}&DenNgay=${DenNgay}`)

    getLichHopDonVi = (body) => this.PostALLow(`/LichHopDonVi`, body)

    getAllFile = (id) => {
        return this.GetAuthen(`/GetFileInfos?key=${id}`)
    }

    addKhachMoi = (data) => { return this.PostAuthen('', data) }

    getDetailByID(id) {
        return this.SearchAllow(`(${id})?$expand=phongHop,soDoPhongPhien,thanhPhanThamDus&$select=fileId,id,name,code,deNghi,batDau,ketThuc,loaiPhienHop,organId,chuTriId,phongHopId,thuKyId,trangThai`)
    }
    getAllData(id) {
        return this.SearchAllow(`(${id})?$expand=phongHop,khungNoiDungs,chuTri($expand=thongTinCaNhan)&$select=id,name,code,deNghi,batDau,ketThuc,loaiPhienHop,organId,chuTriId,phongHopId,thuKyId,trangThai`)
    }
    start(id) { return this.GetAuthen(`/BatDauHop?phienHopId=${id}`) }

    diThay(data) { return this.PostAuthen('/DiThay', data) }

    getDuocDiThay(id) { return this.GetAuthen(`/DanhSachDiThay?phienHopId=${id}`, { noLoading: true }) }

    CheckUBND(idPhien) { return this.GetAllow(`/ThanhVienUBND?phienHopId=${idPhien}`) }

    DuyetNhieuPhien(arrIdPhien) { return this.PostAuthen(`/DuyetPhien`, arrIdPhien, { noAlert: true }) }

    getTemplateKetLuan(id) { return this.SearchAllow(`(${id})?$expand=ketLuans&$select=id,name`) }

    exportPDFByHtml(html) { return this.PostAuthenPUBLIC(`/RenderPdfFromHtml`, html, { responseType: 'blob' }) }

    DeleteFileEmpty(fileId) { return this.DeleteAllow(`/XoaFilePhienHopEmpty?fileId=${fileId}`, { noLoading: true }) }

    checkAccessMeet(idPhien) { return this.GetAllow(`/CheckAccessPhienHop?phienHopId=${idPhien}`) }

    getDataTranfer(id) {
        return this.SearchAllow(`(${id})?$expand=thanhPhanThamDus($expand=nhanSu($expand=thongTinCaNhan))&$select=id`)
    }
}
export default new PhienHopsEndpoint();