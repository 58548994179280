
import { TYPE_ENDPOINT } from '../../actions/constants';
import { Service } from '../Service';
// import { PUBLIC_API_URL, _API_URL } from '../../actions/constants';
const apiKey = 'AIzaSyAewqM6NruzLCqGJ3u0vaBo4-b1aGsNtfA';

class AgentEndpoint extends Service {
    constructor() {
        super('');
        this.isGoogle = { typeEndpoint: TYPE_ENDPOINT.google, noLoading: true }
    }
    // login
    recognize = (data) => this.PostGoogle(`/v1p1beta1/speech:recognize?key=${apiKey}`, data, this.isGoogle);

    getAgents = () => this.GetPartner('/on-premise/v1/live/agent', this.isGoogle)

 
}

export default new AgentEndpoint();