import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Col } from "reactstrap";
import Label from '../Label/Label';
import { Password, InputBoolean } from '../Input';
import { formatDatetime, formatDatetime2, formatNumber } from '../../../actions/util';
import { ButtonDownload } from '../Button';
import styled from 'styled-components';

const size_ini = { 'sm': 'mb-0' }
const Link = styled.span`
  &:hover{
    cursor:pointer;
    scale:2
  }
`;

const renderControl = (props) => {
  let { data, onChange, value, isDetail, download, id, icon, className } = props;
  const setPlaceholder = (name) => `Nhập ${name.toLowerCase()}`;
  let type = isDetail && data.type !== 'data-select' ? 'label' : data.type;
  // eslint-disable-next-line default-case
  switch (type) {
    case "password":
      return (
        <Password
          id={id}
          name={data.field_name} value={value == null ? "" : value}
          onChange={e => onChange && onChange(e.target.name, e.target.value)}
          placeholder={setPlaceholder(data.title)}
        />
      )
    case "checkbox":
      return (
        <InputBoolean
          id={id}
          disabled={data.disabled ? data.disabled : false}
          name={data.field_name}
          checked={value ? value : false}
          onChange={checked => onChange && onChange(data.field_name, checked)}
          placeholder={setPlaceholder(data.title)}
        />
      )
    case "currency":
      return (
        <span className="font-weight-bold">{formatNumber(value)}</span>
      )
    case "file":
      return (
        <ButtonDownload onClick={() => download(value)}>Tải về</ButtonDownload>
      )
    case "link":
      return (
        <>
          <Link id={id} className={`link font-weight-bold badge bg-blue text-white + ${className}`} >
            {value}
            {icon && <i className={`ml-2 ` + icon} />}
          </Link>
        </>
      )
    case "async-select-multi":
      return <Fragment>
        {value && value.length !== 0 && value.map(cur => <span className="font-weight-bold">
          {cur[data.fieldLabel]}
        </span>)}
      </Fragment>
    default:
      return (
        <span className="font-weight-bold">
          {data.type === 'datetime' ? data.notSecond ? formatDatetime2(value) : formatDatetime(value) : value}
        </span>
      )
  }
}
const FormControl = props => {
  const { data, inline, colLabel, colValue, size } = props;
  return (
    data && data.type !== "hidden" &&
    <FormGroup row className={`form-control-${data.field_name} ${size_ini[size]}`} >
      {
        data.hidden_label !== true &&
        <Col md={inline === false ? "12" : colLabel ? colLabel : "5"}>
          <Label>{data.title} :</Label>
        </Col>
      }
      <Col md={inline === false || data.hidden_label === true ? "12" : colValue ? colValue : "7"}>
        <div className="form-control-value" style={data.width && { maxWidth: data.width }}>
          {renderControl(props)}
        </div>
      </Col>
    </FormGroup>
  );
}

FormControl.propTypes = {
  data: PropTypes.object.isRequired
};

FormControl.defaultProps = {
  size: 'md'
}

export default FormControl;
