import React, { Component } from "react";
import PropTypes from "prop-types";
import SelectSingle from "./SelectSingle";
import * as SelectUtils from "./SelectUtils";
import * as OdataUtils from "../table/OdataUtils";
// import Spinners from "../spinners/Spinners";

export default class DataSelect extends Component {
  _isFirstCalled = true;

  constructor(props) {
    super(props);
    this.state = {
      route: [],
      totalRow: -1,
      page: 0,
      querySearch: null,
      dialogIsOpen: false,
      objSelect: null,
      valueSelect: null,
      nameRef: [],
      loading: false
    };
  }

  generateDataRef = (where, isPaginate = true) => {
    const { url, column, dataRef } = this.props;
    if (isPaginate) {
      return SelectUtils.checkDataRefPaginate(
        url,
        column,
        dataRef,
        where,
        this.state.page,
        this.props.pageSize
      );
    }
    return SelectUtils.checkDataRef(`${url}?`, column, dataRef, where);
  };

  checkCondition = () => {
    const { column, where } = this.props;
    if (where && this.state.valueSelect) {
      return undefined;
    }
    let conditions = [];
    if (where) {
      conditions.push(where);
    }
    if (this.state.querySearch) {
      conditions.push(
        OdataUtils.filterField(
          column.label,
          "string",
          this.state.querySearch,
          true
        )
      );
    } else {
      if (this.state.valueSelect) {
        conditions.push(
          `${column.field} ne ${OdataUtils.filterTypeField(
            column.type,
            this.state.valueSelect
          )}`
        );
      }
    }
    return conditions.join(" and ");
  };

  componentDidMount() {
    const { column, value, initValue } = this.props;
    if (initValue) {
      this.setState({
        nameRef: [initValue],
        valueSelect: initValue[column.field]
      });
    } else {
      if (value) {
        this.setState({ loading: true });
        // let editURL = this.generateDataRef(OdataUtils.filterField(column.field, column.type, value), false);
        // callApiNotLoading(editURL)
        //   .then(result => {
        //     if (result.value) {
        //       const val = result.value[0];
        //       this.setState({ nameRef: [val], valueSelect: val[column.field] });
        //     }
        //   })
        //   .catch(err => this.setState({ loading: false }))
        //   .finally(() => this.setState({ loading: false }));
      }
    }
  }

  handleMenuOpen = () => {
    if (this._isFirstCalled) {
      this.setState({ loading: true });
    } else {
      this.setState({
        nameRef: this.state.objSelect ? [this.state.objSelect] : [],
        loading: true
      });
    }
    // callApiNotLoading(this.generateDataRef(this.checkCondition()))
    //   .then(result => {
    //     if (result.value) {
    //       let datas = [...this.state.nameRef];
    //       result.value.forEach(element => datas.push(element));
    //       this.setState({ nameRef: datas, totalRow: Number(result['odata.count']), loading: false });
    //     }
    //     this._isFirstCalled = false;
    //   })
    //   .catch(err => this.setState({ loading: false }));
  };

  handleInputChange = (value, { action }) => {
    if (action === "input-change") {
      // const { column, where } = this.props;
      // this.setState({
      //   totalRow: -1,
      //   page: 0,
      //   nameRef: [],
      //   loading: true
      // });
      // let condition = OdataUtils.filterField(column.label, 'string', value, true) + (where ? (' and ' + where) : '');
      // callApiNotLoading(this.generateDataRef(condition))
      //   .then(result => this.setState({
      //     nameRef: result.value,
      //     totalRow: Number(result['odata.count']),
      //     loading: false,
      //     querySearch: value
      //   }))
      //   .catch(err => this.setState({ loading: false }));
    }
  };

  handleScrolledToBottom = async () => {
    if (this.state.valueSelect) {
      if (this.state.nameRef.length - 1 >= this.state.totalRow) {
        return;
      }
    } else {
      if (this.state.nameRef.length >= this.state.totalRow) {
        return;
      }
    }
    this.setState({ page: this.state.page + 1, loading: true });
    // callApiNotLoading(this.generateDataRef(this.checkCondition()))
    //   .then(result => {
    //     let datas = [...this.state.nameRef];
    //     result.value.forEach(element => datas.push(element));
    //     this.setState({ nameRef: datas, loading: false });
    //   })
    //   .catch(err => this.setState({ loading: false }));
  };

  handleMenuClose = () => {
    this.setState({
      totalRow: -1,
      page: 0,
      querySearch: null
    });
  };

  onOpenDialog = () => this.setState({ loading: true, dialogIsOpen: true });
  onCloseDialog = () => this.setState({ loading: false, dialogIsOpen: false });

  render() {
    const {
      disabled,
      // dialogOdata,
      placeholder,
      name,
      onChange,
      column,
      renderRow
      // moudle
    } = this.props;
    // const { route } = this.state;
    return (
      <>
        <div style={{ display: "flex" }}>
          <SelectSingle
            disabled={disabled}
            value={this.state.valueSelect}
            name={name}
            valueRef={column.field}
            labelRef={column.label}
            placeholder={placeholder}
            options={this.state.nameRef}
            onChange={(e, meta) =>
              this.setState(
                { objSelect: e, valueSelect: e ? e.value : null },
                onChange(e, meta)
              )
            }
            renderOption={(row, i) => renderRow(row, i)}
            onMenuScrollToBottom={this.handleScrolledToBottom}
            isLoading={this.state.loading}
            onInputChange={this.handleInputChange}
            onMenuOpen={this.handleMenuOpen}
            onMenuClose={this.handleMenuClose}
          />
        </div>
      </>
    );
  }
}

DataSelect.propTypes = {
  url: PropTypes.string.isRequired,
  column: PropTypes.object.isRequired
};

DataSelect.defaultProps = {
  pageSize: 30,
  placeholder: "Chọn...",
  dialogOdata: true
};
