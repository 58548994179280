import React from "react";
import { Chip } from "@material-ui/core";

const MethodHttp = ({ method }) => {
  if (method === "GET") {
    return (
      <Chip
        label={method}
        size="small"
        style={{ backgroundColor: "#61affe", color: "white", width: 70 }}
      />
    );
  }
  if (method === "POST") {
    return (
      <Chip
        label={method}
        size="small"
        style={{ backgroundColor: "#49cc90", color: "white", width: 70 }}
      />
    );
  }
  if (method === "PUT") {
    return (
      <Chip
        label={method}
        size="small"
        style={{ backgroundColor: "#fca130", color: "white", width: 70 }}
      />
    );
  }
  if (method === "DELETE") {
    return (
      <Chip
        label={method}
        size="small"
        style={{ backgroundColor: "#f93e3e", color: "white", width: 70 }}
      />
    );
  }
  if (method === "PATCH") {
    return (
      <Chip
        label={method}
        size="small"
        style={{ backgroundColor: "#fab005", color: "white", width: 70 }}
      />
    );
  }
};

export default MethodHttp;
