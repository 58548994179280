import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { Enums_LoaiBieuQuyet, Enums_Mau, _Enum_Trang_Thai_Van_De } from '../../../../action/defaultEnums';
import { Badge, Button, UncontrolledTooltip } from 'reactstrap'
import { FontAwesome } from '../../../../../components/icon';
import ModalVanDe from './Modal/ModalVanDe';
import { renderTrangThai } from './util';
import { CHECK_CONFIRM } from '../../../../../components/alert/Alert';
import { BoSungVanDesEndpoint } from '../../../../../service/Meet';
import ModalKetQua from './Modal/ModalKetQua';
import { TOKEN_CLIENT_INFO } from '../../../../../actions/constants';
import { useSelector } from 'react-redux';

const dataInit = {
    isOpen: false,
    data: {}
}
const dataKQBQ = {
    isOpen: false
}

const RenderTbodyChuyenVien = React.forwardRef((props, ref) => {
    const { vanDe, isDienBien } = props;
    const [dataEdit, setDataEdit] = useState(dataInit);
    const [_dataKQBQ, _setDataKQBQ] = useState(dataKQBQ);
    const [ketQuaBieuQuyet, setKetQuaBieuQuyet] = useState([]);
    const { noiDung: dataND } = useSelector(state => state.meetingRProcess);

    const hanldeDelete = useCallback((item) => {
        CHECK_CONFIRM('Bạn chắc chắn muốn xóa').then(res => {
            if (res && res.isConfirmed) { BoSungVanDesEndpoint.deleteVD(item.id) }
        })
    }, []);

    const ketQuaBQ = useCallback((item) => {
        BoSungVanDesEndpoint.getKetQuaBieuQuyet(item.id)
            .then(res => setKetQuaBieuQuyet(res.resultObj?.ketQuas || []))
    }, [])

    const ketQua = useCallback(async (item) => {
        _setDataKQBQ(pre => ({ ...pre, isOpen: true, ...item }))
        ketQuaBQ(item)
    }, [ketQuaBQ]);

    useImperativeHandle(ref, () => ({
        setKetQua: (item) => ketQua(item),
        setKetQuaBQ: (item) => ketQuaBQ(item),
        setCloseKetQua: () => _setDataKQBQ(pre => ({ ...pre, isOpen: false, }))
    }), [ketQua, ketQuaBQ]);

    const modalVanDe = useMemo(() => {
        return dataEdit.isOpen && (
            <ModalVanDe
                isOpen={dataEdit.isOpen}
                isClose={() => setDataEdit(pre => ({ ...pre, isOpen: false }))}
                khungNoiDungId={dataND?.id}
                data={dataEdit.data}
            />
        )
    }, [dataEdit, dataND]);

    const renderModalKetQua = useMemo(() => {
        return _dataKQBQ.isOpen && <ModalKetQua
            isOpen={_dataKQBQ.isOpen}
            isClose={() => _setDataKQBQ(pre => ({ ...pre, isOpen: false }))}
            _dataKQBQ={_dataKQBQ}
            ketQuaBieuQuyet={ketQuaBieuQuyet}
            isDienBien={isDienBien}
        />
    }, [_dataKQBQ, ketQuaBieuQuyet, isDienBien])

    return (
        <React.Fragment>
            <tbody>
                {
                    vanDe.length !== 0 ? vanDe.map((item, index) => {
                        const mau = Enums_Mau.find(f => f.value === item.mau);
                        return <tr key={index + item.mau} style={{ backgroundColor: (index === 0 && item.trangThai === 'HoatDong' && dataND?.trangThai === "DangHop") ? '#88ec88' : '' }}>
                            <td>{index + 1}</td>
                            <td className="over-text">{item.name}</td>
                            <td>
                                <span>{mau?.label}</span>
                                <Badge className="d-block">{Enums_LoaiBieuQuyet.find(f => f.value === item.loaiBieuQuyet).label}</Badge>
                            </td>
                            <td>{renderTrangThai(item.trangThai)}</td>
                            <td>
                                {
                                    (item.trangThai === _Enum_Trang_Thai_Van_De.Moi && !isDienBien && TOKEN_CLIENT_INFO?.mainRole !== 'TK') ?
                                        <React.Fragment>
                                            <Button size="sm" color="primary" onClick={() => setDataEdit(pre => ({ ...pre, isOpen: true, data: item }))}>
                                                <FontAwesome fa='edit' />
                                                <span className="ml-1">Sửa</span>
                                            </Button>
                                            <Button size="sm" color="danger" className="ml-1" onClick={() => hanldeDelete(item)}>
                                                <FontAwesome fa='times' />
                                                <span className="ml-1">Xóa</span>
                                            </Button>
                                        </React.Fragment> :
                                        <>
                                            <Button size="sm" color="success" id="result" onClick={() => ketQua(item)}>
                                                <FontAwesome fa="clipboard-list" />
                                            </Button>
                                            <UncontrolledTooltip placement="right" target="result">Kết quả</UncontrolledTooltip>
                                        </>
                                }
                            </td>
                        </tr>
                    }) : <tr><td colSpan="5" className="text-center">Chưa có dữ liệu</td></tr>
                }
            </tbody>
            {modalVanDe}
            {renderModalKetQua}
        </React.Fragment>
    )
})
export default RenderTbodyChuyenVien;
