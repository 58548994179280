import { Meet } from "./Meet";
class OrganizationsEndpoint extends Meet {
    constructor() {
        super("/Organizations");
    }
    getAllDonVi = (IdDonVi) => this.GetAllow(`(${IdDonVi})`)

    getOrganizations = (str) => this.SearchAllow(`?${str}`, null, null, true);

    getAllOrganizations = () => this.SearchAllow('?$select=OrganName,Id', null, null, true);

    getAllOrganizationsByFilter = (filter = []) => this.SearchAllow(`?$select=OrganName,OrganAddress,Id&$filter=${filter.join(' and ')}&$orderby=DoUuTien asc`, null, null, true)

    getAllOrganizationsParam = (param) => this.getAll(param)

    // Thêm mới đơn vị
    addOrganizations = (body) => this.InsertAUTH(body);
    // Cập nhật đơn vị
    updateOrganizations = (IdDonVi, data) => this.UpdateAUTH(`(${IdDonVi})`, data);
    // Xóa đơn vị
    deleteOrganizations = (id) => this.DeleteAUTH(`(${id})`);
    getDataByID(ID) {
        return this.SearchAllow(`(${ID})`)
    }
    seachFullField(value) {
        return this.SearchAllow('?search=' + encodeURIComponent(value));
    }
}
export default new OrganizationsEndpoint();