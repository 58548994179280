import { Meet } from "./Meet";

class NhanSusEndpoint extends Meet {
    constructor() {
        super("/NhanSus");
    }
    getDataByID(ID) {
        return this.SearchAUTH(`(${ID})?$expand=thongTinCaNhan,nhomThanhVienNhanSus&$select=id,email,isThuTruong,soDienThoai,organId,chucDanhId,userId`)
    }
    getDataWithOrganId(ids, cvs) {
        const formatString = (arr) => {
            let str = ''
            arr.map((cur, i) => i === arr.length - 1 ? str += `'${cur}'` : str += `'${cur}',`)
            return str;
        }
        var cond = formatString(ids);
        if (cvs) {
            var condcv = formatString(cvs);
            return condcv !== '' ? this.SearchAUTH(`?$expand=organization($select=organName),thongTinCaNhan($select=ten,hoVaTenDem),chucDanh&$filter=organId in [${cond}] and not(chucDanhId in [${condcv}])&$select=id`) : [];
        }
        return cond !== '' ? this.SearchAUTH(`?$expand=organization($select=organName),thongTinCaNhan($select=ten,hoVaTenDem),chucDanh&$filter=organId in [${cond}] and isCurrent eq true &$select=id`) : [];
    }
    getFilterByDuAnId = (filter = []) => this.SearchAllOdata(`?$select=DonThuId,VanBanId&$filter=${filter.join(' and ')}`, null, null, true);

    seachFullChucDanh(value, idChucDanh) {
        return this.GetAuthen(`${value ? '?search=' + encodeURIComponent(value) + '&' : '?'}$expand=thongTinCaNhan&$filter=chucDanhId eq ${idChucDanh}`)
    }
    seachFullCanBo(value, { idThuKy, idChuyenVien }) {
        return this.GetAuthen(`${value ? '?search=' + encodeURIComponent(value) + '&' : '?'}$expand=thongTinCaNhan&$filter= not(chucDanhId in ['${idThuKy}','${idChuyenVien}'])`)
    }
    seachChuyenVien(value) {
        return this.GetAuthen(`${value ? '?search=' + encodeURIComponent(value) + '&' : '?'}$expand=thongTinCaNhan`)
    }
    getFullNhanSuByFilter(filter) {
        return this.SearchAUTH(`?$expand=thongTinCaNhan,chucDanh&$filter=${filter.join(' and ')} and isCurrent eq true`)
    }
    getFullNhanSuById(Id) {
        return this.SearchAUTH(`(${Id})?$expand=thongTinCaNhan,chucDanh`)
    }
    getNhanSuByPram(param) {
        return this.SearchAUTH(`?${param}`)
    }
    updateNhomThanhVienNhanSu(data) {
        return this.PatchAuthen(`/UpdateNhomNhanSu`, data)
    }
    addNhanSuInTypeMeet(data) { return this.PatchAuthen(`/ThemNhanSuLoaiPhienHop`, data) }
    deleteNhanSuInTypeMeet(data) { return this.DeleteAUTHPATH(`/DeleteNhanSuLoaiPhien`, data) }

    getByTypeMeet(id) {
        return this.GetAuthen(`?$filter=isCurrent eq true and loaiPhiens/any(d:d/id eq ${id})`)
    }

    getThanhVienIni(type) {
        return this.GetAuthen(`?$expand=thongTinCaNhan($select=idCard,hoVaTenDem,ten,gender),chucDanh($select=name),organization($select=organName)&$select=id&$filter=isCurrent eq true and loaiPhiens/any(d:d/id eq ${type})`)
    }
}
export default new NhanSusEndpoint();