import { Meet } from "./Meet";
class ThanhPhanThamDusEndpoint extends Meet {
    constructor() {
        super("/ThanhPhanThamDus");
    }

    getFullWithPhienHopId(id) {
        return this.SearchAllow(`?$expand=nhanSuGhe,khachMoi,nhanSu($expand=organization,chucDanh,thongTinCaNhan,nhomThanhVienNhanSus)&$filter=phienHopId eq ${id}`, { noLoading: true })
    }
    getDataWithPhienHopId(id) {
        return this.SearchAllow(`?$expand=nhanSuGhe,nhanSu($expand=organization,chucDanh,thongTinCaNhan,nhomThanhVienNhanSus)&$filter=phienHopId eq ${id} and khachMoiId eq null`)
    }
    getThamDusPhienHopId(id) {
        return this.SearchAllow(`?$expand=khachMoi,nhanSuGhe,nhanSu($expand=organization,chucDanh,thongTinCaNhan,nhomThanhVienNhanSus)&$filter=phienHopId eq ${id}`)
    }
    getGuestWithPhienHopId(id) {
        return this.SearchAllow(`?$expand=khachMoi&$filter=phienHopId eq ${id} and nhanSuId eq null`)
    }
    deleteThanhVien(id) { return this.DeleteAUTH(`(${id})`) }

    addKhachMoi(data) { return this.PostAuthen('', data) }

    updateThamDus(id, data) { return this.PatchAuthen(`(${id})`, data, { noLoading: true }) }

    getDataByID(id) { return this.GetAllow(`(${id})?$expand=khachMoi,nhanSu($expand=thongTinCaNhan,organization)`) }

    getByCanBoPhienHop(phienHopId, nhanSuId, khachMoiId) {
        const fieldFilter = nhanSuId ? `nhanSuId eq ${nhanSuId}` : `khachMoiId eq ${khachMoiId}`;
        return this.SearchAllow(`?$filter=phienHopId eq ${phienHopId} and ${fieldFilter}`)
    }
    getListThamDuByGroup(idPhien) {
        return this.SearchAllow(`$select=id,nhomThamGia&$compute=nhanSu/nhomThanhVienNhanSus/$count eq 0 as nhomThamGia&$filter=phienHopId eq 8ed64adc-fb1f-49e4-a6c7-744f38df804e and khachMoiId eq null&$count=true&$expand=nhanSu($expand=nhomThanhVienNhanSus($select=nhomThanhVienId))`)
    }
    getFullThamDuWithPhienHopId(id) {
        return this.SearchAllow(`?$expand=khachMoi,nhanSu($expand=organization,chucDanh,thongTinCaNhan,nhomThanhVienNhanSus)&$filter=phienHopId eq ${id}`)
    }
    getThamDuTranferWithPhienHopId(id) {
        return this.SearchAllow(`?$expand=nhanSu($expand=organization,chucDanh,thongTinCaNhan,nhomThanhVienNhanSus)&$select=id,parentId,trangThaiThamDu,trangThaiDiThay,ghiChu&$filter=phienHopId eq ${id}`)
    }

    duyetDiThay(data) {
        return this.PostAuthen('/DuyetDiThay', data)
    }

    getDanhSachDiThay(phienHopId) {
        return this.GetAllow(`/DanhSachNguoiDiThay?phienHopId=${phienHopId}`)
    }
}
export default new ThanhPhanThamDusEndpoint();