import React from 'react';
// import PropTypes from 'prop-types';
import { ModalHeader } from "reactstrap";

const IModalHeader = (props) => {
    const { children, ...attribute } = props;
    return (
        <ModalHeader {...attribute}>
            {children}
        </ModalHeader>
    );
};

IModalHeader.propTypes = {

};

IModalHeader.defaultProps = {

};

export default IModalHeader;