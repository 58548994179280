import { GDTCores } from "./GDTCores";
import saveAs from "../../actions/file-saver"
import { _API_URL, AUTH_API_URL } from "../../actions/constants";
class VanBansEndpoint extends GDTCores {
  constructor() {
    super("/VanBans");
  }
  downloadDoc = ({ Id, Subject }) => this.GetAuthen(`/GetFile?id=${Id}`,
    { responseType: "blob", noAlert: true, noLoading: true })
    .then(res => saveAs(res, Subject))
  getAllVanBans = (param, cof) => this.SearchAllOdata(param, cof);

  getVanBans = () => this.SearchAllOdata(`?$select=Id,Name,LoaiVanBanId,OrganId&$filter=Status eq true`);

  downloadVanBan = ({ Id, Name }) => this.GetAuthen(`/GetFile?id=${Id}`,
    { responseType: "blob", noAlert: true, noLoading: true })
    .then(res => saveAs(res, Name))

  getDocUrlByUuid = (id) => _API_URL + AUTH_API_URL + this.prefix + this.path + `/GetFile?id=${id}`
}
export default new VanBansEndpoint();