import React, { Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { memo } from "react";
import styled from "styled-components";
import { RenderDownload } from "../../../../../others/DocViewer";
import { Enums_TypeUpload } from "../../../../action/defaultEnums";
import Pending from '../Component/Pending';
import TreeFile from "./TreeFile";

const Action = styled.div`
    cursor: pointer;
    :hover{
        background-color: rgba(0, 0, 0, 0.04)
    }
`
const ActionText = styled.div`
vertical-align: middle !important;
    text-align: left;
`

const Index = React.forwardRef(({ showDocDraw, id, TEndpoint, isEdit, isTree, notPopup, onClickPDF, stylePDF, ...props }, ref) => {
    const [files, setFiles] = useState(null);
    const refPDF = useRef();
    const refTreeFile = useRef();
    const getVanBan = useCallback(async (id, isReturn) => {
        const { resultObj } = await TEndpoint.getAllFile(id);
        let files = [];
        resultObj.forEach((f) => {
            files.push({
                name: f['fileName'], moTa: f['moTa'], label: f['fileName'],
                path: f.filePath, id: f['etag'], parentId: f['tags']?.type
            })
        })
        if (isReturn) {
            Enums_TypeUpload.forEach((cur, i) => cur['id'] = cur.value)
            return [...files, ...Enums_TypeUpload];
        }
        else { setFiles(files); }
    }, [TEndpoint])
    useEffect(() => {
        if (showDocDraw !== undefined) {
            if (id && !isTree && showDocDraw) getVanBan(id);
        } else {
            if (id && !isTree) getVanBan(id);
        }
    }, [getVanBan, id, isTree, showDocDraw])
    useImperativeHandle(ref, () => ({
        getData: () => files
    }));

    const handleOpenPDF = (file) => {
        refPDF.current && refPDF.current._openDocument(file)
    }

    const renderFile = useMemo(() => {
        return isTree ? <TreeFile
            ref={refTreeFile}
            onClickPDF={onClickPDF}
            manipulation={{ view: getVanBan }}
            id={id}
            notPopup={notPopup}
            {...props} /> : <Pending stylePDF={stylePDF} loading={files === null}>
            {
                files && files.length !== 0 ? files.map((f, i) => (<Action onClick={() => handleOpenPDF(f)} key={i + 'DL'} className="d-flex justify-content-start p-1">
                    <ActionText>
                        <RenderDownload stylePDF={stylePDF} ref={refPDF} onClickPDF={onClickPDF} file={f} isEdit={isEdit} {...props}></RenderDownload>
                    </ActionText>
                </Action>)) : <div>Không có dữ liệu</div>
            }
        </Pending>
    }, [files, getVanBan, id, isEdit, isTree, props, notPopup, onClickPDF, stylePDF])
    return (<Fragment>{renderFile}</Fragment>
    )
})
export default memo(Index)