import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from './Input';
import InputText from './InputText';
import Button from '../Button/Button';
import FontAwesome from '../../icon/FontAwesome';

const Text = styled(InputText)`
    left: 0;
    top: 0;
    // flex: 0 0 86%;
    display: block;
    position: relative;
`;

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    display: inline-block;
`;

const File = styled(Input)`
    padding: 0;
    border-radius: 0;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
`;

const ButtonWrap = styled(Button)`
    margin-right: 0px !important;
    margin-left: 0px !important;
    border-radius: 0.2rem 0.5rem 0.5rem 0.2rem;
`;
const Ini_Validate = [{
  value: 'size',
  condition: 'compareMore',
  message: 'Vui lòng chọn file dưới'
}, {
  value: 'type',
  condition: 'same',
  message: 'Vui lòng chọn file loại'
}];

const InputFile = (props) => {
  const { name, accept, placeholder, onChange, required, value, validation } = props;
  const [isShowError, setIsShowError] = useState(null);

  useEffect(() => {
    if (isShowError) setTimeout(() => { setIsShowError(null); }, 2000);
  }, [isShowError])
  // nếu có props value thì chạy theo props valule
  // nếu không có thì mặc định tạo state
  const [valueState, setValueState] = React.useState('');
  const checkValidate = useCallback((data) => {
    let allow = true; let error = '';
    Ini_Validate.forEach(cur => {
      if (allow && validation && validation[cur.value]) {
        switch (cur.condition) {
          case 'same':
            if (validation[cur.value] === data[cur.value]) {
              allow = false;
              error = cur.message + ` ` + validation[cur.value];
            };
            break;
          default:
            if (validation[cur.value] < data[cur.value] / 1000000) {
              allow = false;
              error = cur.message + ` ` + validation[cur.value] + 'Mb';
            }
            break;
        }
      }
    });
    !allow && setIsShowError(error);
    return allow;
  }, [validation])
  return (<div>
    <div style={{ display: 'flex' }}>
      <Text value={valueState ? valueState : value} placeholder={placeholder} disabled="disabled" />
      <Wrapper>
        <ButtonWrap color="blue" style={{ height: '100%', width: '100%' }}>
          <FontAwesome fa="folder-open" />
        </ButtonWrap>
        <File
          name={name} accept={accept} required={required} type="file"
          onChange={e => {
            let file = e.target.files[0];
            if (checkValidate(file)) {
              setValueState(file ? file.name : '');
              if (onChange) onChange(file);
            }
          }}
        />
      </Wrapper>
    </div>
    <div style={{ color: 'red' }}>{isShowError}</div>
  </div>

  );
};

InputFile.defaultProps = {
  value: '',
};

export default InputFile;
