import { useEffect, useCallback, useState, useMemo, forwardRef, useRef, useImperativeHandle, memo } from 'react'
import { Checkbox } from "@material-ui/core";
import { FontAwesome } from "../../../../../../components/icon";
import { useSelector } from 'react-redux';
import DeniedBoSungVanDesEndpoint from '../../../../../../service/Meet/DeniedBoSungVanDesEndpoint';
import { TOKEN_CLIENT_INFO } from '../../../../../../actions/constants';
import { ThanhPhanThamDusEndpoint } from '../../../../../../service/Meet';

const ThamDus = forwardRef(({ dataVD }, ref) => {
    const [listDeny, setListDeny] = useState([]);
    const { thamDus, phienHop } = useSelector(state => state.meetingRProcess);
    const isDetail = useRef(false);
    const [canBos, setCanBos] = useState(null);

    useImperativeHandle(ref, () => ({ getDeny: () => listDeny || [] }), [listDeny]);

    const getThamDus = useCallback(async (data) => {
        try {
            if (data?.deniedBoSungVanDes || data?.denieds) {
                setListDeny(data?.deniedBoSungVanDes || data?.denieds);
                isDetail.current = true;
            }
        } catch (error) { }
    }, [])

    const getLisCanBos = useCallback(async (phienHop) => {
        try {
            const { value } = await ThanhPhanThamDusEndpoint.getDataWithPhienHopId(phienHop.id);
            setCanBos({ thamGia: value })
        } catch (error) { console.log(error) }
    }, [])

    useEffect(() => {
        if (thamDus) {
            setCanBos(thamDus)
        } else {
            phienHop && getLisCanBos(phienHop)
        }
    }, [getLisCanBos, phienHop, thamDus])

    useEffect(() => {
        dataVD && getThamDus(dataVD);
    }, [dataVD, getThamDus])

    const handleDeny = useCallback((idThamDu, checked) => {
        setListDeny(pre => {
            const data = [...pre];
            if (checked) {
                dataVD.id && DeniedBoSungVanDesEndpoint.deleteDeny({ boSungVanDeId: dataVD.id, thanhPhanThamDuId: idThamDu })
                const index = data.findIndex(cur => cur === idThamDu);
                if (index > -1) data.splice(index, 1);
            } else {
                dataVD.id && DeniedBoSungVanDesEndpoint.addDeny({ boSungVanDeId: dataVD.id, thanhPhanThamDuId: idThamDu })
                data.push(idThamDu);
            }
            return data;
        })
    }, [dataVD])

    const renderThamDus = useMemo(() => {
        const cb = canBos ? canBos?.thamGia : []
        return cb && cb.map((cur, i) => {
            const { nhanSu, khachMoi } = cur
            const name = nhanSu ? nhanSu.thongTinCaNhan.hoVaTenDem + ` ` + nhanSu.thongTinCaNhan.ten
                : khachMoi.name;
            return <div className='d-flex align-items-center justify-content-between thamDu border rounded p-1 mb-1 bg-white '>
                <div className='ml-2'>
                    <span>{name}</span>
                    <br></br>
                    <span style={{ fontSize: 'small', color: 'darkgrey' }}>{nhanSu?.chucDanh?.name || 'Khách mời'}</span>
                </div>
                <Checkbox
                    disabled={TOKEN_CLIENT_INFO.role !== 'QLH'}
                    checked={!listDeny.includes(cur.id)}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onChange={(e, check) => { handleDeny(cur.id, check) }}
                />
            </div>
        })
    }, [canBos, handleDeny, listDeny])

    return (<>
        <div>
            <FontAwesome className="mr-1" fa='users-between-lines'></FontAwesome>
            Danh sách tham dự
        </div>
        <div className='list-thamDus p-1'>
            {renderThamDus}
        </div>
    </>);
})

export default memo(ThamDus);