import { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import viVN from '@react-pdf-viewer/locales/lib/vi_VN.json';

/** @type {import('react').FC<import('@react-pdf-viewer/core').ViewerProps>} */
const PDFViewer = ({ height = '100vh', fileUrl, ...attrs }) => {
  const [file, setFile] = useState('');

  const defaultLayoutInstance = defaultLayoutPlugin();
  useEffect(() => {
    fileUrl && setFile(fileUrl)
  }, [fileUrl])
  // attrs['httpHeaders'] = { "X-GDT-Dev-Token": process.env.REACT_APP_DEV_TOKEN };
  const renderError = (error) => {
    let message = 'Bạn không có quyền truy cập văn bản !';
    return (
      <div className="d-flex justify-content-center h-100 align-items-center">
        <div>
          <div className="text-center" >
            <h3><i className="fa-solid fa-ban" style={{ color: '#e53e3e', fontSize: 'xx-large' }}></i></h3>
          </div>
          <div style={{ borderRadius: '0.25rem', fontSize: 'x-large', padding: '0.5rem', color: '#e53e3e', background: 'rgb(229, 62, 62,0.1)' }} >
            {message}
          </div>
        </div>
      </div>
    );
  };
  return file && (
    <div style={{ height }}>
      <Worker workerUrl={pdfjsWorker}>
        <Viewer
          renderError={renderError}
          {...attrs}
          fileUrl={file}
          plugins={[defaultLayoutInstance]}
        />
      </Worker>
    </div>
  );
};

PDFViewer.defaultProps = {
  localization: viVN
}

export default PDFViewer;
