import _quantri from './menus/quantri';
import _danhmuc from './menus/danhmuc';
import _donvi from './menus/donvi';
import _chat from './menus/chat';

const appRoutes = [
    ..._quantri,
    ..._danhmuc,
    ..._donvi,
    ..._chat,
];

export default appRoutes
