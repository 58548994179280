import { Meet } from "./Meet";
class NhomThanhVienNhanSusEndpoint extends Meet {
    constructor() {
        super("/NhomThanhVienNhanSus");
    }
    getDataWithGroups(nhoms) {
        let cond = ''; nhoms.map((cur, i) => i === nhoms.length - 1 ? cond += `'${cur}'` : cond += `'${cur}',`)
        return nhoms ? this.SearchAllow(`?$expand=nhanSu($select=id;$expand=chucDanh,thongTinCaNhan($select=ten,hoVaTenDem),organization($select=organName))&$filter=nhomThanhVienId in [${cond}] and nhanSu/chucDanh/code in ['TK','CV'] eq false&$select=nhanSuId`) : [];
    }

}
export default new NhomThanhVienNhanSusEndpoint();