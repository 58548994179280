import { Account } from "./Account";

class AuthorityEndpoint extends Account {
  constructor() {
    super("/Authorities");
    // super("");
  }
  // Lấy danh sách
  getAuthorities = () => this.GetAuthen(`/getAuthorities`);
  // Lấy danh sách
  getAuthoritiesByIdAccount = (id) => this.GetAuthenPUBLIC(`/getAuthoritiesByIdAccount?id=${id}`, null, true);

  getAllAuthorities = () => this.GetAuthenADMINODATA(`?$filter=Status eq true&page=0&size=10&count=true and Status eq true`, null, true);


}

export default new AuthorityEndpoint();
