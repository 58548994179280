import React, { Component } from 'react';
import InputDate from './InputDate';

class InputDateTime extends Component {
    render() {
        const { ...attribute } = this.props;
        return (
            <InputDate {...attribute} />
        )
    }
}

InputDateTime.defaultProps = {
    enableTime: true
}

export default InputDateTime