import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalHeader, Label, Card, CardBody, Badge, Alert } from 'reactstrap';
import { formatISODate } from '../../../../../../actions/util';
import { CHECK_CONFIRM, CHECK_SUCCESS, CHECK_WARNING } from '../../../../../../components/alert/Alert';
import { FontAwesome } from '../../../../../../components/icon';
import { BoSungVanDesEndpoint, KetQuaBieuQuyetEndpoint } from '../../../../../../service/Meet';
import { Enums_Mau, _Enum_Trang_Thai_Van_De } from '../../../../../action/defaultEnums';
import { useAsync } from '../../../../../../hooks';
import TeamPending from './Pending';
import './style.scss';
import { isBefore } from 'date-fns';
import { TypeEnums } from '../../../../../../proto/reloadroute_pb';
import { actionGRPC } from '../../../Redux/ActionsMeeting';

const INIT_OTHER = 'YKIENKHAC';
const INI_STATUS = {
    [_Enum_Trang_Thai_Van_De.HoanThanh]: { color: "success", text: 'Hoàn thành' },
    [_Enum_Trang_Thai_Van_De.HoatDong]: { color: "primary", label: 'Thảo luận' },
    [_Enum_Trang_Thai_Van_De.Moi]: { color: "warning", label: 'Chờ' },
}
export default function ModalDanhGia(props) {
    const { boSungVanDeId, isOpen, isClose, isDienBien, idThanhPhanThamDu } = props;
    const [textYKien, setTextYKien] = useState('');
    const [dataTraLoi, setDataTraLoi] = useState({});
    const { noiDung } = useSelector(state => state.meetingRProcess);
    const [dataPhuongAns, setDataPhuongAns] = useState([]);
    const idKetQuaBieuQuyet = useRef(null);
    const [vanDeBieuQuyet, setVanDeBieuQuyet] = useState(null);
    const { grpc } = useSelector(state => state.meetingRProcess);
    const dispatch = useDispatch();

    const getTraLoi = useCallback(async (boSungVanDeId, isReloadAnswer = true) => {
        try {
            const boSungVanDe = await BoSungVanDesEndpoint.getVanDesById(boSungVanDeId);
            const { value: [answer] } = await KetQuaBieuQuyetEndpoint.getKetQuaById(boSungVanDeId, idThanhPhanThamDu);
            if (answer) {
                idKetQuaBieuQuyet.current = answer?.id;
                isReloadAnswer && setDataTraLoi({ ...answer.ngayTraLoi ? { ...answer.phuongAnBoSung, answered: true } : {} });
                setDataPhuongAns(boSungVanDe.phuongAnBoSungs);
                setVanDeBieuQuyet(boSungVanDe);
            } else {
                idKetQuaBieuQuyet.current = 'notAllow';
            }
            return true;
        } catch (error) { CHECK_WARNING(error); }
    }, [idThanhPhanThamDu])

    const { execute: reloadVanDe, status } = useAsync(getTraLoi, false);

    useEffect(() => {
        if (boSungVanDeId) {
            reloadVanDe(boSungVanDeId);
        }
    }, [boSungVanDeId, reloadVanDe])

    const reloadData = useCallback((_grpc) => {
        if (!isOpen) return;
        const { data } = _grpc;
        if (data.type === TypeEnums.KETQUA) {
            getTraLoi(data.key, false);
            dispatch(actionGRPC({ ..._grpc, isViewed: true }));
        }
    }, [dispatch, getTraLoi, isOpen])

    useEffect(() => {
        if (grpc && !grpc.isViewed) { reloadData(grpc) }
    }, [grpc, reloadData])

    const sendKQ = useCallback(() => {
        if (!dataTraLoi?.id) { CHECK_WARNING('Vui lòng chọn phương án'); return false }
        else {
            if (dataTraLoi.id === INIT_OTHER && !textYKien) return CHECK_WARNING('Vui lòng nhập ý kiến');
            CHECK_CONFIRM('Bạn có chắc chắn chọn kết quả này').then((res) => {
                if (res) {
                    if (dataTraLoi.id === INIT_OTHER) {
                        const modelPhuongAnBoSung = {
                            thuTu: dataPhuongAns.length + 1,
                            noiDung: textYKien,
                            boSungVanDeId: boSungVanDeId,
                            isBieuQuyet: true
                        }
                        BoSungVanDesEndpoint.createOtherOption(modelPhuongAnBoSung)
                            .then((_res) => {
                                isClose();
                                CHECK_SUCCESS('Gửi kết quả thành công');
                            }).catch(err => console.log('lỗi gửi kết quả'))
                    } else {
                        const model = { phuongAnBoSungId: dataTraLoi.id, ngayTraLoi: formatISODate(new Date()) };
                        KetQuaBieuQuyetEndpoint.updateKetQuaBieuQuyet(idKetQuaBieuQuyet.current, model)
                            .then((rest) => { isClose(); CHECK_SUCCESS('Gửi kết quả thành công') })
                            .catch(err => console.log('lỗi gửi kết quả'))
                    }
                }
            })
        }
    }, [dataTraLoi, textYKien, dataPhuongAns, boSungVanDeId, isClose]);

    const handleChange = (value) => {
        if (value === INIT_OTHER) return setDataTraLoi({ id: value, value });
        setDataTraLoi(value);
    };

    const renderTrangThai = useMemo(() => {
        const { trangThai } = vanDeBieuQuyet || {};
        return <Badge size="sm" color={INI_STATUS[trangThai]?.color}>{INI_STATUS[trangThai]?.label}</Badge>
    }, [vanDeBieuQuyet])

    const { trangThai: trangThaiNoiDung } = noiDung || {};

    function checkExpired(vanDeBieuQuyet) {
        const { ngayKetThuc, ngayBatDau } = vanDeBieuQuyet || {};
        if (!ngayBatDau) return false;
        return isBefore(new Date(ngayKetThuc), new Date());
    }
    const mau = vanDeBieuQuyet ? Enums_Mau.find(f => f.value === vanDeBieuQuyet.mau) : {};
    return (
        <Modal size="lg" isOpen={isOpen ? true : false}>
            <ModalHeader toggle={isClose}>Vấn đề biểu quyết</ModalHeader>
            <ModalBody>
                {
                    idKetQuaBieuQuyet.current && <>{
                        vanDeBieuQuyet && <TeamPending loading={status === 'pending'}>
                            <Form>
                                <FormGroup row>
                                    <Col xs="3">Nội dung:</Col>
                                    <Col xs="9">
                                        <Input
                                            type="textarea"
                                            disabled={true}
                                            value={vanDeBieuQuyet.name}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs="3">Mẫu: </Col>
                                    <Col xs="4">{mau?.label}</Col>
                                    <Col xs="2" className="text-right">Trạng thái: </Col>
                                    <Col xs="3" className="text-right"> {renderTrangThai}</Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs="3">Phương án:</Col>
                                    <Col xs="9" style={{ paddingLeft: 20 }}>
                                        {
                                            dataPhuongAns.map((item, index) => {
                                                const isDisable = checkExpired(vanDeBieuQuyet) || vanDeBieuQuyet?.trangThai === 'HoanThanh' || isDienBien || dataTraLoi?.answered || trangThaiNoiDung === 'HoanThanh';
                                                return <Label key={index} className="ml-2 d-block" >
                                                    <Input
                                                        disabled={isDisable}
                                                        name="radio1"
                                                        value={item.id}
                                                        style={{ width: 15, height: 15, marginTop: '0.8rem' }}
                                                        type="radio"
                                                        onChange={(e) => handleChange(item)}
                                                        checked={dataTraLoi.id === item.id}
                                                    />
                                                    <Card className={`ml-2 mb-2 ${dataTraLoi.id === item.id ? "active-request" : ""} 
                                                ${isDisable ? "card-request-disable" : "card-request-hover"}`}
                                                        key={index}>
                                                        <CardBody className="p-2 d-flex align-items-center">
                                                            <span className="ml-1">{item.noiDung}</span>
                                                        </CardBody>
                                                    </Card>
                                                </Label>
                                            })
                                        }
                                        {
                                            dataTraLoi.answered || checkExpired(vanDeBieuQuyet) ? <Label className="ml-2 d-block" >
                                                {/* <Input
                                                disabled={true}
                                                style={{ width: 15, height: 15, marginTop: '0.8rem' }}
                                                type="radio"
                                            /> */}
                                                {/* <Card className={`ml-2 mb-2 card-request-disable`}>
                                                <CardBody className="p-2 d-flex align-items-center justify-content-between">
                                                    <span style={{ width: '30%' }} className="ml-1">Ý kiến khác</span>
                                                    <span style={{ color: 'red' }}>Có {dataPhuongAns.filter(f => f.yKienKhac).length || 0} ý kiến khác</span>
                                                </CardBody>
                                            </Card> */}
                                            </Label> : <Label className="ml-2 d-block" >
                                                <Input
                                                    disabled={(vanDeBieuQuyet.trangThai === 'HoanThanh' || isDienBien || trangThaiNoiDung === 'HoanThanh')}
                                                    value={INIT_OTHER}
                                                    style={{ width: 15, height: 15, marginTop: '0.8rem' }}
                                                    type="radio"
                                                    onChange={(e) => handleChange(e.target.value)}
                                                    checked={dataTraLoi.id === INIT_OTHER}
                                                />
                                                <Card className={`ml-2 mb-2 ${dataTraLoi.id === INIT_OTHER ? "active-request" : ""} ${(vanDeBieuQuyet.trangThaiNoiDung === 'HoanThanh' || isDienBien || dataTraLoi?.answered || trangThaiNoiDung === 'HoanThanh') ? "card-request-disable" : "card-request-hover"}`}>
                                                    <CardBody className="p-2 d-flex align-items-center">
                                                        <span style={{ width: '30%' }} className="ml-1">Ý kiến khác</span>
                                                        {dataTraLoi.id === INIT_OTHER && <Input autoFocus type="textarea" placeholder="Vui lòng nhập ý kiến" onChange={(e) => setTextYKien(e.target.value)} />}
                                                    </CardBody>
                                                </Card>
                                            </Label>
                                        }

                                    </Col>
                                </FormGroup>
                            </Form>
                            {(isDienBien || vanDeBieuQuyet.trangThai === 'HoanThanh' || dataTraLoi?.answered || trangThaiNoiDung === 'HoanThanh') && <div className="con-dau-info">{dataTraLoi.answered ? 'ĐÃ TRẢ LỜI' : 'CHƯA TRẢ LỜI'}</div>}
                            <Form className="mb-0">
                                <FormGroup row className="mb-0">
                                    <Col className="text-right">
                                        <Button onClick={isClose} color="danger" size="sm"><FontAwesome fa="times" /><span className="ml-1">Đóng</span></Button>
                                        {
                                            (vanDeBieuQuyet.trangThai !== 'HoanThanh' && !isDienBien && !dataTraLoi.answered)
                                            && <Button className="ml-1" color="primary" size="sm" onClick={sendKQ} >
                                                <FontAwesome fa="paper-plane" />
                                                <span className="ml-1">Gửi kết quả</span>
                                            </Button>
                                        }
                                    </Col>
                                </FormGroup>
                            </Form>
                        </TeamPending>
                    }</>
                }
                {
                    idKetQuaBieuQuyet.current === 'notAllow' && <Alert color="warning">
                        Cán bộ được quyền tham gia biểu quyết vấn đề này
                    </Alert>
                }
            </ModalBody>
        </Modal>
    )
}