import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from "reactstrap";

const IFormGroup = (props) => {
    const { children, ...attribute } = props;
    return (
        <FormGroup {...attribute}>
            {children}
        </FormGroup>
    );
};

IFormGroup.propTypes = {
    children: PropTypes.node,
    row: PropTypes.bool,
    check: PropTypes.bool,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    tag: PropTypes.string,
    className: PropTypes.string,
    cssModule: PropTypes.object
};

IFormGroup.defaultProps = {

};

export default IFormGroup;