import React from "react";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
// import "./material_blue.css";
import {Vietnamese} from "./flatpickr.vn";
import {formatDate, formatDatetime, formatTime, formatISODate} from "../../actions/util";

const DatePicker = ({
                        value,
                        onChange,
                        placeholder,
                        enableTime,
                        noCalendar,
                        isRequired,
                        disabled,
                        inputClassName
                    }) => {
    const val = value ? new Date(value) : null;
    return (
        <Flatpickr
            value={val}
            onChange={(date) => onChange && onChange(formatISODate(date[0]))}
            disabled={disabled}
            options={{
                enableTime,
                noCalendar,
                mode: "single",
                formatDate: (date) => {
                    if (enableTime === true && noCalendar === true) {
                        return formatTime(date);
                    }
                    if (enableTime) {
                        return formatDatetime(date);
                    }
                    return formatDate(date);
                },
                locale: Vietnamese,
            }}
            render={({defaultValue, value, ...props}, ref) => {
                return (
                    <input
                        {...props}
                        required={isRequired}
                        type="text"
                        ref={ref}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        className={"form-control " + inputClassName}
                        style={{
                            background: "white",
                            color: "black",
                        }}
                    />
                );
            }}
        />
    );
};

DatePicker.propTypes = {
    enableTime: PropTypes.bool.isRequired,
    noCalendar: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
};

DatePicker.defaultProps = {
    disabled: false,
    enableTime: false,
    noCalendar: false,
    placeholder: "Vui lòng nhập ngày tháng",
};

export default DatePicker;
