
//config host
export const _API_URL = '/api'; //'http://localhost:57874';
//export const _API_URL = 'http://171.244.38.75:8083';
//port fake
// export const _API_URL = 'http://171.244.38.75:8085';
// export const API_URL
// export const API_URL = _API_URL + '/odata';
export const API_URL = '/odata';
// export const API_URL_MULTI
export const API_URL_MULTI = _API_URL + '/AddMulti';
// *************************************************************************
// Biến token client
export const ID_TOKEN_CLIENT = 'GDTNID';
export const ID_TOKEN_INFO = 'info';
export const ID_TOKEN_MENU = 'menu';
export const ID_TOKEN_PERMISSION = 'permission';
export const SETTINGS = 'settings';
// GET token info client
export const TOKEN_CLIENT_INFO = JSON.parse(localStorage.getItem(ID_TOKEN_INFO) || null);
export const PERMISSION = JSON.parse(localStorage.getItem(ID_TOKEN_PERMISSION) || null);
