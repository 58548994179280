import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col } from "../../components/gird";
import { ButtonAdd } from "../../components/base/Button";
import ToastDetail from "../../components/base/Toast/ToastDetail";
import AddMenu from "./AddMenu";
import ViewMenu from "./ViewMenu";
import EditMenu from "./EditMenu";
import { MenusEndpoint } from "../../service/Admin";

export default function Advanced(props) {
  const [arrMenu, setArrMenu] = useState([]);
  // Refs
  const viewMenuRef = useRef(null);
  const editMenuRef = useRef(null);

  // Props
  const { name, manipulation } = props;

  // States
  const [modal, setModal] = useState(false);

  // Set Value
  const menuDetailSetValue = (e, info) => {
    editMenuRef.current && editMenuRef.current.setValuesDetail(e, info);
  }

  const getMenuIni = useCallback(async () => {
    const { value } = await MenusEndpoint.getMenuTree();
    setArrMenu(value);
  }, [])
  useEffect(() => {getMenuIni()}, [getMenuIni])

  // Refesh
  const refeshMenu = async () => {
    viewMenuRef.current && viewMenuRef.current.refeshMenu();
    editMenuRef.current && editMenuRef.current.refeshMenu();
    getMenuIni();
  };

  /**
   * @description
   * @return ButtonAdd
   */
  const viewHeader = (
    <div>
      {name} <ButtonAdd onClick={() => setModal(!modal)}>Thêm</ButtonAdd>
    </div>
  );

  /**
   * @return Render Advanced
   */
  return (
    <>
      <AddMenu
        isOpen={modal}
        toggle={() => setModal(!modal)}
        refeshMenu={refeshMenu}
        manipulation={manipulation}
        iniMenus={arrMenu}
      />
      <Row>
        <Col md={6}>
          <ToastDetail renderTitle={viewHeader}>
            <ViewMenu
              refeshMenu={refeshMenu}
              ref={viewMenuRef}
              menuDetailSetValue={menuDetailSetValue}
              manipulation={manipulation}
            />
          </ToastDetail>
        </Col>
        <Col md={6} style={{ paddingLeft: 0 }}>
          <ToastDetail title="Chi tiết">
            <EditMenu
              iniMenus={arrMenu}
              ref={editMenuRef}
              refeshMenu={refeshMenu}
              manipulation={manipulation}
            />
          </ToastDetail>
        </Col>
      </Row>
    </>
  );
}
