import React, { useState } from 'react';
import styled from 'styled-components';
import _classnames from 'classnames';
import TreeItem from '@material-ui/lab/TreeItem';
import { alpha, withStyles } from '@material-ui/core/styles';
// import { FontAwesome } from '../icon';
import { Checkbox } from '../base/Input';
import { useEffect } from 'react';

const Wrapper = styled.div`
    margin-right: 5px;
    display: flex;
    padding: 4px 0px;
    align-items: center;
`;

const Title = styled.span`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
`;

const StyledTreeItem = withStyles(theme => ({
    iconContainer: {
        '& .tree-close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 12,
        paddingLeft: 12,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}))(props => <TreeItem {...props} />);

export default function CustomTreeView(props) {
    const {
        children,
        nodeId, title, icon, data,
        renderAfter, checkable, checked, onCheck,
        onSelection, disabled, classIcon
    } = props;
    const [check, setCheck] = useState(false);
    useEffect(() => {
        if (checkable) {
            setCheck(checked);
        }
    }, [checkable, checked]);
    return (
        <StyledTreeItem
            nodeId={nodeId}
            label={
                <Wrapper onClick={e => onSelection && onSelection(e, data)}>
                    {checkable &&
                        <Checkbox
                            disabled={disabled}
                            checked={check} color="primary"
                            onChange={e => {
                                let valueChecked = !check;
                                setCheck(valueChecked);
                                if (onCheck) { onCheck(e, valueChecked, data); }
                            }}
                        />}
                    <Wrapper>
                        <i className={_classnames("mr-2", icon, classIcon)}></i>
                        {/* <FontAwesome style={{ marginRight: 8 }} fa={icon} /> */}
                        <Title>{title}</Title>
                    </Wrapper>
                    {renderAfter}
                </Wrapper>
            }
        >
            {children}
        </StyledTreeItem>
    );
}