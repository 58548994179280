import * as Config from '../../actions/constants';
import { logoutAccount } from '../../routes/permission';
import {
  OPEN_LOADING_DATA_SERVER_API,
  CLOSE_LOADING_DATA_SERVER_API
} from "../../actions/util";
import { saveAs } from '../../actions/file-saver';
import { CHECK_ERROR } from '../../components/alert/Alert';
import { exportConfigsFetch } from '../../actions/callApi'

const headers = new Headers();
headers.append('Accept', 'application/json');
headers.append('Content-Type', 'application/json');

// todo: check valid truoc khi goi res.json()
// function isValidJson(str) {
//     try {
//         JSON.parse(str);
//     } catch (_) {
//         return false;
//     }
//     return true;
// }

/**
 * fetch trả về data kèm status
 * Thành công 200 - 300
 */
export function gdtFetch(url, options) {
  return fetch(url, options)
    .then(res =>
      res.json()
        .then(data => ({
          status: res.status,
          data
        })))
    .then(res => {
      if (res.status >= 200 && res.status < 300) {
        return Promise.resolve(res);
      } else {
        return Promise.reject(res);
      }
    });
}

//call API
export async function callApi(endpoint, method, body) {
  let url = `${Config.ODATA}/${endpoint}`;
  const result = await fetch(url, {
    method: method,
    mode: "cors",
    headers,
    body: body
  });
  if (!result.ok) throw (await result.json())['error'];
  return result.status === 204 ? result : await result.json();
};

// export async function callApiMulti(endpoint, method, body) {
//   let url = `${Config.API_URL_MULTI}/${endpoint}`;
//   const result = await fetch(url, {
//     method: method,
//     mode: "cors",
//     headers,
//     body: body
//   });

//   return result;
// };

export async function _callApi(endpoint, method, body) {
  let url = `${Config._API_URL}/${endpoint}`;
  const result = await fetch(url, {
    method: method,
    mode: "cors",
    headers,
    body: body
  });
  // if (!result.ok) throw await result.text(); // tuấn sửa
  if (!result.ok) {
    throw await result.json().then(res => res).catch(err => err);
  }
  return result;
};

//call API
export function callApi2(endpoint, method, body, odata = true,) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    var api = odata ? Config._API_URL + Config.ODATA : Config._API_URL;
    const auth = endpoint.indexOf('/public') === -1;
    fetch(`${api}/${endpoint}`, exportConfigsFetch(method, body, auth))
      .then(async res => {
        if (res.status === 200 && method === "GET") {
          resolve(await res.json());
        } else if (res.status === 201 && ["POST", "PUT"].indexOf(method) > -1) {
          resolve(res);
        } else if (res.status === 204 && ["PUT", "PATCH", "DELETE"].indexOf(method) > -1) {
          resolve(res);
        } else if (res.status === 401) {
          logoutAccount()
        } else if (!res.ok) {
          throw await res.json().then(result => reject(result)).catch(err => reject(err));
        } else {
          resolve(await res.json());
        }
      })
      .catch(err => { reject(err) })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API());
  });
};
//call API
export function callApi2NotLoading(endpoint, method, body, odata = true,) {
  return new Promise((resolve, reject) => {
    var api = odata ? Config._API_URL + Config.ODATA : Config._API_URL;
    const auth = endpoint.indexOf('/public') === -1;
    fetch(`${api}/${endpoint}`, exportConfigsFetch(method, body, auth))
      .then(async res => {
        if (res.status === 200 && method === "GET") {
          resolve(await res.json());
        } else if (res.status === 201 && ["POST", "PUT"].indexOf(method) > -1) {
          resolve(res);
        } else if (res.status === 204 && ["PUT", "PATCH", "DELETE"].indexOf(method) > -1) {
          resolve(res);
        } else if (res.status === 401) {
          logoutAccount()
        } else if (!res.ok) {
          throw await res.json().then(result => reject(result)).catch(err => reject(err));
        } else {
          resolve(await res.json());
        }
      })
      .catch(err => { reject(err) })
      .finally(() => { });
  });
};

export function callApi3(endpoint, method, body, odata = true) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    var api = odata ? Config.ODATA : Config._API_URL;
    fetch(`${api}/${endpoint}`, { method: method, headers, mode: "cors", body: body })
      .then(async res => {
        if (res.status === 200 && method === "GET") {
          resolve(await res.json());
        } else if (res.status === 201 && ["POST", "PUT"].indexOf(method) > -1) {
          resolve(await res.json());
        } else if (res.status === 204 && ["PUT", "PATCH", "DELETE"].indexOf(method) > -1) {
          resolve(res);
        } else if (res.status === 401) {
          logoutAccount()
        } else if (!res.ok) {
          throw await res.json().then(result => reject(result)).catch(err => reject(err));
        } else {
          resolve(await res.json());
        }
      })
      .catch(err => { reject(err) })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API());
  });
};
//call API
export function callApiNotLoading(endpoint, method = "GET", body, noOdata = false) {
  return new Promise((resolve, reject) => {
    // var api = !noOdata ? Config.ODATA : '';
    const auth = endpoint.indexOf('/public') === -1;
    fetch(`${Config._API_URL}/${endpoint}`, exportConfigsFetch(method, body, auth))
      .then(res => {
        if (res.status === 200 && method === "GET") {
          resolve(res.json());
        } else if (res.status === 201 && (method === "POST" || method === "PUT")) {
          resolve(res.json());
        } else if (res.status === 204 && (method === "PUT" || method === "PATCH" || method === "DELETE")) {
          resolve(res);
        } else if (res.status === 401) {
          // logoutAccount()
        } else if (!res.ok) {
          res.json().then(result => reject(result)).catch(err => reject(err));
        } else {
          resolve(res);
        }
      })
      .catch(err => { CHECK_ERROR("Không thành công !"); reject(err); });
  });
};

export function callApiFormData(endpoint, method, body) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    fetch(`${Config._API_URL}/${endpoint}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: 'same-origin', // include, *same-origin, omit
      // headers: {
      //   //'Content-Type': 'application/json',
      //   //'Content-Type': 'application/x-www-form-urlencoded',
      //   'Authorization': 'Bearer ' + Config.TOKEN_CLIENT
      // },
      body: body, // body data type must match "Content-Type" header
    })
      .then(async (res) => {
        if (res.status >= 200 && res.status <= 300) {
          resolve(res.json());
        } else if (res.status === 401) {
          logoutAccount()
        } else {
          reject(await res.json());
        }
      })
      .catch(err => {
        CHECK_ERROR("Không thể lấy dữ liệu !");
        reject(err);
      })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API());
  });
};
/**
 * Xử lý api tải tập tin về theo định dạng base64
 * @param {string} endpoint Địa chỉ đường dẫn api tải tập tin
 * @param {string} filename Tên tập tin cần lưu
 * @param {string} method Phương thức gọi api
 * @param {any} body Dữ liệu truyền lên nếu dùng phương thức POST
 * @param {boolean} download Mặc định tự động tải về, false nếu không muốn tự động tải
 * @returns {Promise<ArrayBuffer>} Trả về ArrayBuffer chứa nội dung tập tin
 */
export function callApiDownload(endpoint, filename, method = 'GET', body, download = true) {
  filename = filename || "download.pdf";
  let type = '';
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    fetch(`${Config._API_URL}/${endpoint}`, {
      method: method,
      mode: 'cors',
      headers: headers,
      body: body
    }).then(async res => {
      if (!res.ok) throw new Error('Lỗi tải file: ' + await res.text())
      let contentDisposition = res.headers.get("content-disposition")
      type = res.headers.get("content-type");
      filename = contentDisposition ? contentDisposition.match(/filename=([^;]+)/)[1] : filename;
      return res.arrayBuffer();
    }).then(buffer => {
      if (download)
        saveAs(new Blob([buffer], { type }), filename);
      resolve(buffer);
    })
      .catch(err => reject(err))
      .finally(() => CLOSE_LOADING_DATA_SERVER_API())
  })
}

export function callApiPublic(endpoint, method = 'GET', body) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    fetch(`${Config._API_URL}/${endpoint}`, {
      method: method,
      mode: 'cors',
      body: body,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => CLOSE_LOADING_DATA_SERVER_API())
  })
}




export function callApi2FakeVGCA(endpoint) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    fetch(endpoint, { method: 'GET', headers, mode: "cors", body: null })
      .then(res => resolve(res))
      .catch(err => { CHECK_ERROR("Không thể lấy dữ liệu !"); reject(err); })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API());
  });
};

export function callApiFormDataFakeVGCA(endpoint, formData) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    fetch(`${endpoint}`, {
      method: 'POST',
      mode: 'cors',
      body: formData
    })
      .then(res => resolve(res.json()))
      .catch(err => {
        CHECK_ERROR("Không thể giả lập !");
        reject(err);
      })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API());
  });
  //
};
