import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ButtonClose from '../base/Button/ButtonClose';

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      backgroundColor: '#006fca'
    },
    toolbar: {
      minHeight: 40
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

export default function HeaderDialog(props) {
  const classes = useStyles();
  const { renderCustom, renderButton, title, onClose } = props;
  return (
    <AppBar style={{ position: 'relative' }}>
      <Toolbar className={classes.toolbar} variant="dense">
        {renderCustom}
        <Typography variant="h6" className={classes.title}>{title}</Typography>
        {renderButton}
        <ButtonClose style={{ width: '1.5rem', height: '1.5rem', backgroundColor: 'none' }}
          className="rounded-circle" onClick={onClose} iconSize={{ fontSize: '1.2rem' }} />
      </Toolbar>
    </AppBar >
  );
}
