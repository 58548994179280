import React, { Fragment, useImperativeHandle } from "react";
import { useCallback, useState } from "react";
import { Col, FormGroup } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap/lib";
import Swal from "sweetalert2";
import { ButtonDownload } from "../../components/base/Button";
import { Label } from "../../components/base/Label";
import { FontAwesome } from "../../components/icon";
import DocumentViewer from "./components/DocumentViewer";

const getEmptyDocSwal = (title,) => ({
    title,
    icon: "error",
    confirmButtonText: "Thử lại",
    cancelButtonText: "Đóng",
    cancelButtonColor: "#d33",
})
const handleEmptyDocSwal = (callback) => ({ isConfirmed }) => {
    if (!isConfirmed) return
    callback()
}
// eslint-disable-next-line no-unused-vars
const onEmptyDoc = (callback, title = "Không tìm thấy văn bản. Vui lòng thử lại") => Swal
    .fire(getEmptyDocSwal(title))
    .then(handleEmptyDocSwal(callback))

export const RenderDownload = React.forwardRef(({ file, keySet, className, isLabel, name, isEdit, onClickPDF, stylePDF, ...props }, ref) => {

    const [activeDoc, setActiveDoc] = useState(undefined);
    const handleFileViewerClose = useCallback(() => setActiveDoc(undefined), [])
    const openDocument = useCallback((file) => {
        if (file) {
            // let vb = null;
            // if (!vb) return onEmptyDoc(() => openDocument(vb))
            !onClickPDF && setActiveDoc(file);
            onClickPDF && onClickPDF(file);
        }
    }, [onClickPDF])

    useImperativeHandle(ref, () => ({
        _openDocument: (file) => openDocument(file)
    }))

    if (!file) return <ButtonDownload className={className} id={"dowloadVB-LB" + keySet}>{'Tải về'} </ButtonDownload>;
    return (
        <Fragment>
            {
                isLabel ? <FormGroup row className={`form-control-${name}`} >
                    <Col md='5'><Label>{name}</Label></Col>
                    <Col md='7'>
                        {
                            stylePDF === "PDF" ? <div className="d-flex">
                                <span style={{ color: '#cd201f' }}><FontAwesome fa="file-pdf" /></span>
                                <span className="ml-1" style={{ fontSize: '0.875rem' }}>{file.name || file.file.name}</span>
                            </div> : <>
                                {/* <Button onClick={() => openDocument(file)} className="p-0" color="link"><span style={{ color: '#cd201f' }} id={"dowloadVB-LB" + file.type}><FontAwesome fa="file-pdf" /></span><span>{file.name}</span></Button> */}
                                <ButtonDownload className={className} onClick={() => openDocument(file)} id={"dowloadVB-LB" + file.type}>{file.name || file?.file?.name} </ButtonDownload>
                                <UncontrolledTooltip placement="right" target={"dowloadVB-LB" + file.type}>{file.name || file.file.name}</UncontrolledTooltip>
                            </>
                        }
                        {activeDoc && <DocumentViewer {...props} isEdit={isEdit} isModal={true} activeDoc={activeDoc} onClose={handleFileViewerClose} />}
                    </Col>
                </FormGroup> : <>
                    {
                        stylePDF === "PDF" ? <div className="d-flex">
                            <span style={{ color: '#cd201f' }}><FontAwesome fa="file-pdf" /></span>
                            <span className="ml-1" style={{ fontSize: '0.875rem' }}>{file.name}</span>
                        </div> : <>
                            {/* <Button onClick={() => openDocument(file)} className="p-0" color="link"><span style={{ color: '#cd201f' }} id={"dowloadVB" + key}><FontAwesome fa="file-pdf" /></span><span>{file.name}</span></Button> */}
                            <ButtonDownload className={className} onClick={() => openDocument(file)} id={"dowloadVB" + keySet}>{file?.name || file?.file?.name} </ButtonDownload>
                            <UncontrolledTooltip placement="right" target={"dowloadVB" + keySet}>Tải Xuống</UncontrolledTooltip>
                        </>
                    }
                    {activeDoc && <DocumentViewer {...props} isEdit={isEdit} isModal={true} activeDoc={activeDoc} onClose={handleFileViewerClose} />}
                </>
            }
        </Fragment>
    )
})