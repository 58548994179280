import { useCallback } from "react";

function useBatch() {
    const updateBatchData = useCallback((dataBatch, method, data, index) => {
        const batchs = [...dataBatch];
        const indexExist = data && batchs.findIndex(cur => cur.data.id === data.id);
        switch (method) {
            case 'POST':
                return [...dataBatch, { method, data }];
            case 'PATCH':
                if (data && data.id) {
                    batchs[indexExist]['data'] = data;
                    batchs[indexExist]['method'] = method;
                } else { batchs[index]['data'] = data; }
                return batchs;
            case 'DELETE':
                if (data && data.id) {
                    batchs[indexExist].method = method
                } else {
                    batchs.splice(index, 1);
                }
                return batchs;
            default:
                break;
        }
    }, [])
    return { updateBatchData }
}

export default useBatch