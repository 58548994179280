import React, { Component } from 'react';
import SelectSingle from './SelectSingle';


const getData = () => {
  const year = (new Date()).getFullYear();
  let option = [];
  for (var i = year - 3; i <= year + 3; i++) {
      option.push({ value: i, label: `Năm ${i}` })
  }
  return option;
}

class SelectYear extends Component {
    render() {
        const { value, onChange } = this.props;

        const data = getData();
        const val = value ? value : '';
        // var select = data.filter(item => item.value === val)
        // console.log(select[0])
        return (
            <SelectSingle
                value={val}
                onChange={onChange}
                options={data}
                valueRef="value"
                labelRef="label"
                placeholder="Chọn năm"
            />
        );
    }
};

SelectYear.propTypes = {
};

SelectYear.defaultProps = {
    placeholder: 'Chọn...'
};

export default SelectYear;
