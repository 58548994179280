/**
 * @fileoverview gRPC-Web generated client stub for reloadroute
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.reloadroute = require('./reloadroute_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reloadroute.ReloadRoutesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reloadroute.ReloadRoutesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reloadroute.UnReloadReq,
 *   !proto.reloadroute.StreamResponse>}
 */
const methodDescriptor_ReloadRoutes_GetUnReload = new grpc.web.MethodDescriptor(
  '/reloadroute.ReloadRoutes/GetUnReload',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.reloadroute.UnReloadReq,
  proto.reloadroute.StreamResponse,
  /**
   * @param {!proto.reloadroute.UnReloadReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reloadroute.StreamResponse.deserializeBinary
);


/**
 * @param {!proto.reloadroute.UnReloadReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.reloadroute.StreamResponse>}
 *     The XHR Node Readable Stream
 */
proto.reloadroute.ReloadRoutesClient.prototype.getUnReload =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/reloadroute.ReloadRoutes/GetUnReload',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_GetUnReload);
};


/**
 * @param {!proto.reloadroute.UnReloadReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.reloadroute.StreamResponse>}
 *     The XHR Node Readable Stream
 */
proto.reloadroute.ReloadRoutesPromiseClient.prototype.getUnReload =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/reloadroute.ReloadRoutes/GetUnReload',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_GetUnReload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reloadroute.UnReloadReq,
 *   !proto.reloadroute.ReloadResponse>}
 */
const methodDescriptor_ReloadRoutes_ViewAll = new grpc.web.MethodDescriptor(
  '/reloadroute.ReloadRoutes/ViewAll',
  grpc.web.MethodType.UNARY,
  proto.reloadroute.UnReloadReq,
  proto.reloadroute.ReloadResponse,
  /**
   * @param {!proto.reloadroute.UnReloadReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reloadroute.ReloadResponse.deserializeBinary
);


/**
 * @param {!proto.reloadroute.UnReloadReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reloadroute.ReloadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reloadroute.ReloadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reloadroute.ReloadRoutesClient.prototype.viewAll =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/ViewAll',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_ViewAll,
      callback);
};


/**
 * @param {!proto.reloadroute.UnReloadReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reloadroute.ReloadResponse>}
 *     Promise that resolves to the response
 */
proto.reloadroute.ReloadRoutesPromiseClient.prototype.viewAll =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/ViewAll',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_ViewAll);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reloadroute.AddReloadModel,
 *   !proto.reloadroute.ReloadResponse>}
 */
const methodDescriptor_ReloadRoutes_AddReload = new grpc.web.MethodDescriptor(
  '/reloadroute.ReloadRoutes/AddReload',
  grpc.web.MethodType.UNARY,
  proto.reloadroute.AddReloadModel,
  proto.reloadroute.ReloadResponse,
  /**
   * @param {!proto.reloadroute.AddReloadModel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reloadroute.ReloadResponse.deserializeBinary
);


/**
 * @param {!proto.reloadroute.AddReloadModel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reloadroute.ReloadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reloadroute.ReloadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reloadroute.ReloadRoutesClient.prototype.addReload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/AddReload',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_AddReload,
      callback);
};


/**
 * @param {!proto.reloadroute.AddReloadModel} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reloadroute.ReloadResponse>}
 *     Promise that resolves to the response
 */
proto.reloadroute.ReloadRoutesPromiseClient.prototype.addReload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/AddReload',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_AddReload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reloadroute.AddReloadModels,
 *   !proto.reloadroute.ReloadResponse>}
 */
const methodDescriptor_ReloadRoutes_AddReloads = new grpc.web.MethodDescriptor(
  '/reloadroute.ReloadRoutes/AddReloads',
  grpc.web.MethodType.UNARY,
  proto.reloadroute.AddReloadModels,
  proto.reloadroute.ReloadResponse,
  /**
   * @param {!proto.reloadroute.AddReloadModels} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reloadroute.ReloadResponse.deserializeBinary
);


/**
 * @param {!proto.reloadroute.AddReloadModels} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reloadroute.ReloadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reloadroute.ReloadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reloadroute.ReloadRoutesClient.prototype.addReloads =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/AddReloads',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_AddReloads,
      callback);
};


/**
 * @param {!proto.reloadroute.AddReloadModels} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reloadroute.ReloadResponse>}
 *     Promise that resolves to the response
 */
proto.reloadroute.ReloadRoutesPromiseClient.prototype.addReloads =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/AddReloads',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_AddReloads);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reloadroute.AddReloadModel,
 *   !proto.reloadroute.ReloadResponse>}
 */
const methodDescriptor_ReloadRoutes_ViewReload = new grpc.web.MethodDescriptor(
  '/reloadroute.ReloadRoutes/ViewReload',
  grpc.web.MethodType.UNARY,
  proto.reloadroute.AddReloadModel,
  proto.reloadroute.ReloadResponse,
  /**
   * @param {!proto.reloadroute.AddReloadModel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reloadroute.ReloadResponse.deserializeBinary
);


/**
 * @param {!proto.reloadroute.AddReloadModel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reloadroute.ReloadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reloadroute.ReloadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reloadroute.ReloadRoutesClient.prototype.viewReload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/ViewReload',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_ViewReload,
      callback);
};


/**
 * @param {!proto.reloadroute.AddReloadModel} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reloadroute.ReloadResponse>}
 *     Promise that resolves to the response
 */
proto.reloadroute.ReloadRoutesPromiseClient.prototype.viewReload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/ViewReload',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_ViewReload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.reloadroute.ReloadResponse>}
 */
const methodDescriptor_ReloadRoutes_DeleteAll = new grpc.web.MethodDescriptor(
  '/reloadroute.ReloadRoutes/DeleteAll',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.reloadroute.ReloadResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reloadroute.ReloadResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reloadroute.ReloadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reloadroute.ReloadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reloadroute.ReloadRoutesClient.prototype.deleteAll =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/DeleteAll',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_DeleteAll,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reloadroute.ReloadResponse>}
 *     Promise that resolves to the response
 */
proto.reloadroute.ReloadRoutesPromiseClient.prototype.deleteAll =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/DeleteAll',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_DeleteAll);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reloadroute.MeetingReq,
 *   !proto.reloadroute.ReloadResponse>}
 */
const methodDescriptor_ReloadRoutes_DeleteWithMeeting = new grpc.web.MethodDescriptor(
  '/reloadroute.ReloadRoutes/DeleteWithMeeting',
  grpc.web.MethodType.UNARY,
  proto.reloadroute.MeetingReq,
  proto.reloadroute.ReloadResponse,
  /**
   * @param {!proto.reloadroute.MeetingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reloadroute.ReloadResponse.deserializeBinary
);


/**
 * @param {!proto.reloadroute.MeetingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reloadroute.ReloadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reloadroute.ReloadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reloadroute.ReloadRoutesClient.prototype.deleteWithMeeting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/DeleteWithMeeting',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_DeleteWithMeeting,
      callback);
};


/**
 * @param {!proto.reloadroute.MeetingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reloadroute.ReloadResponse>}
 *     Promise that resolves to the response
 */
proto.reloadroute.ReloadRoutesPromiseClient.prototype.deleteWithMeeting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/DeleteWithMeeting',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_DeleteWithMeeting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.reloadroute.ReloadResponse>}
 */
const methodDescriptor_ReloadRoutes_DeleteOldReloads = new grpc.web.MethodDescriptor(
  '/reloadroute.ReloadRoutes/DeleteOldReloads',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.reloadroute.ReloadResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reloadroute.ReloadResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reloadroute.ReloadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reloadroute.ReloadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reloadroute.ReloadRoutesClient.prototype.deleteOldReloads =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/DeleteOldReloads',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_DeleteOldReloads,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reloadroute.ReloadResponse>}
 *     Promise that resolves to the response
 */
proto.reloadroute.ReloadRoutesPromiseClient.prototype.deleteOldReloads =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reloadroute.ReloadRoutes/DeleteOldReloads',
      request,
      metadata || {},
      methodDescriptor_ReloadRoutes_DeleteOldReloads);
};


module.exports = proto.reloadroute;

