import React from 'react';
import IInput from "./Input";

const InputSelect = (props) => {
    const { children, ...attribute } = props;
    return (
        <IInput {...attribute} type="select">
            {children}
        </IInput>
    );
};

InputSelect.propTypes = {
};

InputSelect.defaultProps = {
    placeholder: "Chọn giá trị",
};

export default InputSelect;