import * as React from 'react';
import PropTypes from 'prop-types';
import { Input } from "reactstrap";
// import Input from '../base/Input/Input';
import Number from './number-format';

class NumberFormat extends React.Component {
    render() {
        const { name, value, onValueChange, thousandSeparator, decimalSeparator, invalid, ...props } = this.props;
        return (
            <Number
                {...props}
                style={{ textAlign: 'right' }}
                value={value}
                thousandSeparator={thousandSeparator || ","}
                decimalSeparator={decimalSeparator || "."}
                decimalScale={2}
                onValueChange={values => onValueChange({ values, name })}
                invalid={invalid}
                customInput={Input}
            //suffix=" đ"
            />
        );
    }
}

NumberFormat.defaultProps = {
    placeholder: 'Nhâp...'
};

NumberFormat.propTypes = {
    // size: PropTypes.string
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

export default NumberFormat;