import { lazy, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { RenderSuspense } from "./components/other";
import TabsRegister from "./views/register/tabsRegiter";
import { MenuContext, AdminMenuProvider } from './context';
import { checkLinkURL, getAllRoute } from "./routes/permission";//, getHomeRoute
import { Provider } from 'react-redux';
import { PhienHopProvider } from './views/don-vi/PhienHop/Context';

// import Breadcrumb from "./layout/components/Breadcrumb";
import NProgress from "./layout/Nprogress";
import _mobile from './routes/menus/mobile';
import store from './redux/rootStore';
import { isSwitchType } from "./actions/constants";

const Layout = lazy(() => import("./layout/Layout"));
const Login = lazy(() => import("./views/login/index"));
const Thief = lazy(() => import("./views/login/thief"));
// const Page404 = lazy(() => import("./views/base/page404/Page404"));
const ChonHeThong = lazy(() => import("./views/base/switchType"));
// const CreateMenu = lazy(() => import("./views/quan-tri/DanhSachTaiKhoan/DanhSachTaiKhoan"))
// const CreateMenu = lazy(() => import("./views/quan-tri/CauHinhVaiTro/CauHinhVaiTro"))
// const CreateMenu = lazy(() => import("./views/quan-tri/MenuHeThong"))

export default function App() {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => setLoading(false), [])
  // useEffect(() => {
  //   if (!("Notification" in window)) {
  //     alert("Trình duyệt không hỗ trợ gửi thông báo!");
  //   }
  //   if (Notification.permission !== "denied") {
  //     Notification.requestPermission().then(permission => { });
  //   }
  // }, [])
  return (
    <Provider store={store}>
      <BrowserRouter>
        <RenderSuspense>
          <NProgress isAnimating={isLoading} />
          <Switch>
            <Route exact path="/dang-ky" name="Đăng ký" render={props => <TabsRegister {...props}></TabsRegister>} />
            <Route exact path="/dang-nhap" name="Đăng nhập" render={props => <Login {...props} />} />
            <Route exact path="/loai-he-thong" name="Chọn loại hệ thống" render={props => <ChonHeThong {...props} />} />
            <Route exact path="/thief" render={props => <Thief {...props} />} />

            {_mobile.map((route, i) => <Route key={i + 'mb'}
              exact
              path={checkLinkURL(route.path)}
              name={route.viewName}
              component={props => (<route.component {...props} />)}
            />)}
            <AdminMenuProvider>
              <PhienHopProvider>
                <Layout>
                  <MenuContext.Consumer>
                    {({ menus }) => menus && (
                      <Switch>
                        {getAllRoute(menus).map((route, i) => {
                          return <Route key={i} exact path={checkLinkURL(route.path)} name={route.viewName}
                            component={props => (<route.component {...{ ...props }} />)}
                          />
                        })}
                        {/* <Route path="/admin/*" name="admin" component={Page404} /> */}
                        {/* <Route path="/don-vi/*" name="donvi" component={Page404} /> */}
                      </Switch>
                    )}
                  </MenuContext.Consumer>
                </Layout>
              </PhienHopProvider>
            </AdminMenuProvider>
            <Route path="/admin" component={Layout}></Route>
            <Route path="/don-vi" component={Layout}></Route>
            <Route path="/" name="Home">
              <Layout> {isSwitchType ? <Redirect to="/loai-he-thong" /> : <Redirect to="/dang-nhap" />} </Layout>
            </Route>
          </Switch>
        </RenderSuspense>
      </BrowserRouter>
    </Provider>
  );
}
