import React, { useCallback, useImperativeHandle, useRef } from "react";
import Menus from "./Menus";

const ViewMenu = React.forwardRef(
  ({ manipulation, menuDetailSetValue, refeshMenu }, ref) => {
    // refs
    const menuRef = useRef(null);
    // Select Node
    const onSelect = (e, info) => {
      menuDetailSetValue(e, info);
    }
    //reload menu
    const reloadTable = useCallback(() => menuRef.current && menuRef.current.queryData(), [])

    useImperativeHandle(ref, () => ({
      onSelect: onSelect,
      refeshMenu: () => reloadTable()
    }));
    return (
      <Menus
        reloadTable={refeshMenu}
        ref={menuRef}
        manipulation={manipulation}
        viewDeleted
        onSelect={(e, info) => onSelect(e, info)}
      />
    );
  }
);

export default ViewMenu;
