import { add } from 'date-fns';
import React, { Fragment, memo, useCallback, useImperativeHandle, useMemo, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Badge, Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { TOKEN_CLIENT_INFO } from '../../../../../actions/constants';
import { CHECK_CONFIRM, CHECK_CONFIRM_TIME } from '../../../../../components/alert/Alert';
import { FontAwesome } from '../../../../../components/icon';
import { BoSungVanDesEndpoint } from '../../../../../service/Meet';
import { Enums_Mau, _Enum_Trang_Thai_Noi_Dung, _Enum_Trang_Thai_Van_De } from '../../../../action/defaultEnums';
import ModalDanhGia from './Modal/ModalDanhGia'
import ModalKetQua from './Modal/ModalKetQua';
import { renderTrangThai } from './util';
import { useAsync } from '../../../../../hooks';
import { randomCode } from '../../../../../actions/util';
import ModalVanDe from './Modal/ModalVanDe';

const dataInit = {
    isOpen: false,
    data: {}
}
const RenderButton = memo((props) => {
    const { item, dataND, index, isChuTri, isDienBien, _setIdVanDe, _setDataKQBQ, openReport } = props;
    const [dataEdit, setDataEdit] = useState(dataInit);
    const { status, execute: exportReport } = useAsync(openReport, false);

    const thaoLuan = useCallback(async (item) => {
        const data = { trangThai: _Enum_Trang_Thai_Van_De.HoatDong }
        CHECK_CONFIRM_TIME({ titles: 'Bắt đầu thảo luận vấn đề này?' }).then(res => {
            if (res) {
                if (!res?.value.noneTime) {
                    const { value: { times } } = res;
                    const ngayBatDau = new Date();
                    const ngayKetThuc = add(new Date(), times);
                    data.ngayBatDau = ngayBatDau;
                    data.ngayKetThuc = ngayKetThuc;
                }
                BoSungVanDesEndpoint.editVanDes(item.id, data);
            }
        })
    }, []);

    const ButtonResult = memo(({ id, onClick }) => {
        const a = Date.now();
        return <Button className="ml-1" id={`${a}`} size="sm" color="success" onClick={onClick}>
            Kết quả<FontAwesome fa="clipboard-list" />
        </Button>
    });

    // const buttonPDF = useCallback((item) => {
    //     return <ButtonIcon
    //         color='red'
    //         icon='file-pdf'
    //         onClick={() => exportReport({ khungNoiDungId: dataND?.id, boSungVanDeId: item?.id })}>
    //         {status === 'pending' ? <span className='d-flex'>Đang tải<div className="spinner-notify" /></span >
    //             : <span>pdf</span>}
    //     </ButtonIcon>
    // }, [dataND, exportReport, status])

    const modalVanDe = useMemo(() => {
        return dataEdit?.isOpen && (
            <ModalVanDe
                isOpen={dataEdit.isOpen}
                isClose={() => setDataEdit(pre => ({ data: null, isOpen: false }))}
                khungNoiDungId={dataND?.id}
                data={dataEdit.data}
            />
        )
    }, [dataEdit, dataND]);

    const hanldeDelete = useCallback((item) => {
        CHECK_CONFIRM('Bạn chắc chắn muốn xóa').then(res => {
            if (res && res.isConfirmed) { BoSungVanDesEndpoint.deleteVD(item.id) }
        })
    }, []);

    const renderButtonChuTri = useMemo(() => {
        if ((item.trangThai === _Enum_Trang_Thai_Van_De.HoatDong)) {
            return <Fragment>
                {isChuTri && dataND?.trangThai === "DangHop" && TOKEN_CLIENT_INFO?.mainRole !== 'TK' &&
                    <Button size="sm" color="primary" onClick={() => _setIdVanDe(item.id)}>
                        <FontAwesome fa="paper-plane" />
                        <span className="ml-1">Trả lời</span>
                    </Button>
                }
                <ButtonResult id={index + 'hd'} onClick={() => _setDataKQBQ(item, true)} />
            </Fragment>
        } else if ((item.trangThai === _Enum_Trang_Thai_Van_De.HoanThanh || isDienBien)) {
            return <Fragment>
                <UncontrolledButtonDropdown>
                    <DropdownToggle color="info"> {status === 'pending' ? <span className='d-flex'>
                        <div className="spinner-notify" />
                    </span > : <span>...</span>}
                    </DropdownToggle>
                    <DropdownMenu className='p-1'>
                        {TOKEN_CLIENT_INFO.mainRole !== 'TK' && <DropdownItem className='bg-red-lt' key='pdf' onClick={() => exportReport({ khungNoiDungId: dataND?.id, boSungVanDeId: item?.id })}>
                            <FontAwesome style={{ marginRight: '0.1rem' }} fa="file-pdf" /> Xuất file PDF
                        </DropdownItem>}
                        {TOKEN_CLIENT_INFO.mainRole !== 'TK' && <DropdownItem divider className='m-1' />}
                        <DropdownItem className='bg-green-lt' key='result' onClick={() => _setDataKQBQ(item, true)}>
                            <FontAwesome style={{ marginRight: '0.1rem' }} fa="clipboard-list" />  Kết quả
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </Fragment>
        } else if (item.trangThai === _Enum_Trang_Thai_Van_De.Moi && dataND && dataND.trangThai === _Enum_Trang_Thai_Noi_Dung.DangHop && !isDienBien) {
            return <Fragment>
                <UncontrolledButtonDropdown>
                    <DropdownToggle color="info"><span>...</span> </DropdownToggle>
                    <DropdownMenu className='p-1'>
                        <DropdownItem className='action-pro' key='thaoluan' onClick={() => thaoLuan(item)}>
                            <FontAwesome style={{ marginRight: '0.1rem' }} fa="comments" /> Thảo luận
                        </DropdownItem>
                        <DropdownItem divider className='m-1' />
                        <DropdownItem className='action-pro' key='result' onClick={() => setDataEdit(pre => ({ ...pre, isOpen: true, data: item }))}>
                            <FontAwesome style={{ marginRight: '0.1rem' }} fa="edit" />  Sửa
                        </DropdownItem>
                        <DropdownItem className='action-pro' key='result' onClick={() => hanldeDelete(item)}>
                            <FontAwesome style={{ marginRight: '0.1rem' }} fa="trash" />  Xóa
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>

                {/* <UncontrolledPopover
                    placement="left"
                    target={'action' + index}
                    trigger="legacy"
                >
                    <PopoverBody className='p-0 rounded shadow' style={{ background: 'whitesmoke' }}>
                        <List component="nav" className='w-100 p-0' aria-label="mailbox folders">
                             <ListItem button onClick={() => thaoLuan(item)}>
                                <ListItemAvatar style={{ minWidth: '20px' }}><i className="fa-comments" /></ListItemAvatar>
                                <ListItemText primary="Thảo luận" />
                            </ListItem>
                            <Divider />
                            <ListItem button divider onClick={() => setDataEdit(pre => ({ ...pre, isOpen: true, data: item }))}>
                                <ListItemAvatar style={{ minWidth: '20px' }}><i className="fa-edit" /></ListItemAvatar>
                                <ListItemText primary="Sửa" />
                            </ListItem>
                            <ListItem button onClick={() => hanldeDelete(item)}>
                                <ListItemAvatar style={{ minWidth: '20px' }}><i className="fa-trash" /></ListItemAvatar>
                                <ListItemText primary="Xóa" />
                            </ListItem>
                        </List>
                    </PopoverBody>
                </UncontrolledPopover> */}
            </Fragment>
        } else {
            return <div></div>
        }
    }, [_setDataKQBQ, _setIdVanDe, dataND, exportReport, hanldeDelete, index, isChuTri, isDienBien, item, status, thaoLuan])

    const renderButtonCanBo = useMemo(() => {
        if (item.trangThai === _Enum_Trang_Thai_Van_De.HoatDong && !isDienBien && dataND && dataND.trangThai === "DangHop") {
            return <Button size="sm" color="primary" onClick={() => _setIdVanDe(item.id)}>
                <FontAwesome fa="paper-plane" /><span className="ml-1">Trả lời</span>
            </Button>
        } else if ((item.trangThai === _Enum_Trang_Thai_Van_De.HoanThanh) || isDienBien || dataND?.trangThai === "HoanThanh") {
            return <Fragment>
                <UncontrolledButtonDropdown direction="up">
                    <DropdownToggle color="info">
                        {status === 'pending' ? <span className='d-flex'>
                            <div className="spinner-notify" />
                        </span > : <span>...</span>}
                    </DropdownToggle>
                    <DropdownMenu className='p-1'>
                        <DropdownItem className='bg-red-lt' key='pdf' onClick={() => exportReport({ khungNoiDungId: dataND?.id, boSungVanDeId: item?.id })}>
                            <FontAwesome style={{ marginRight: '0.1rem' }} fa="file-pdf" /> Xuất file PDF
                        </DropdownItem>
                        <DropdownItem divider className='m-1' />
                        <DropdownItem className='bg-green-lt' key='result' onClick={() => _setIdVanDe(item.id)}>
                            <FontAwesome style={{ marginRight: '0.1rem' }} fa="clipboard-list" />  Kết quả
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </Fragment>
        }
        return <Fragment />
    }, [_setIdVanDe, dataND, exportReport, isDienBien, item, status])

    return <Fragment>
        {isChuTri ? renderButtonChuTri : renderButtonCanBo}
        {modalVanDe}
    </Fragment>

});

const RenderTbodyCanBo = React.forwardRef((props, ref) => {
    const { isChuTri, idThanhPhanThamDu, vanDe, isDienBien } = props;
    const [_idVanDe, _setIdVanDe] = useState(null);
    const [_dataKQBQ, _setDataKQBQ] = useState(null);
    const { isUBND, noiDung: dataND } = useSelector(state => state.meetingRProcess);
    const refKetQua = useRef();

    useImperativeHandle(ref, () => ({
        setDanhGia: (data) => _setIdVanDe(data),
        setKetQua: (data) => _setDataKQBQ(data),
        setCloseDanhGia: () => _setIdVanDe(null),
        setCloseKetQua: () => _setDataKQBQ(null)
    }), [])
    const renderModalDanhGia = useMemo(() => {
        return _idVanDe && <ModalDanhGia
            isOpen={_idVanDe ? true : false}
            isClose={() => _setIdVanDe(null)}
            boSungVanDeId={_idVanDe}
            isDienBien={isDienBien}
            idThanhPhanThamDu={idThanhPhanThamDu}
        />
    }, [_idVanDe, idThanhPhanThamDu, isDienBien])

    const renderModalKetQua = useMemo(() => {
        return isChuTri && <ModalKetQua
            ref={refKetQua}
            isOpen={_dataKQBQ}
            isClose={() => _setDataKQBQ(null)}
            _dataKQBQ={_dataKQBQ}
            isDienBien={isDienBien}
        />
    }, [_dataKQBQ, isChuTri, isDienBien])

    return (
        <Fragment>
            <tbody>
                {
                    vanDe.length !== 0 ? vanDe.map((item, index) => {
                        const code = randomCode();
                        return <tr key={index + 'VĐ'} style={{ backgroundColor: (index === 0 && item.trangThai === 'HoatDong' && dataND?.trangThai === "DangHop") ? '#88ec88' : '' }}>
                            <td>{index + 1}</td>
                            <td className='over-text' id={`${code}`}>
                                {item.name}
                            </td>
                            {/* <UncontrolledTooltip placement="top" target={`${code}`}>
                                {item.name}
                            </UncontrolledTooltip> */}
                            <td>
                                {/* <span>{Enums_Mau.find(f => f.value === item.mau).label}</span> */}
                                {/* <Badge className="d-block">{Enums_LoaiBieuQuyet.find(f => f.value === item.loaiBieuQuyet).label}</Badge> */}
                                <Badge className="d-block">{Enums_Mau.find(f => f.value === item.mau).label}</Badge>
                            </td>
                            <td>{renderTrangThai(item.trangThai)}</td>
                            {isUBND && <td>
                                <RenderButton
                                    key={'repect' + index}
                                    {...{ ...props, dataND, item, index: index + 1, _setIdVanDe, _setDataKQBQ }}
                                />
                            </td>}
                        </tr>
                    }) : <tr>
                        <td colSpan="5" className="text-center">Chưa có dữ liệu</td>
                    </tr>
                }
            </tbody>
            {renderModalDanhGia}
            {renderModalKetQua}
        </Fragment>
    )
})
export default RenderTbodyCanBo