import Cookies from 'js-cookie';
import _danhmuc from './menus/danhmuc';
// Routes
import _routes from "./routes";
import _includes from "./includes";
import _trangTin from "./menus/trangtin";
import {
  PERMISSION, ID_TOKEN_PERMISSION,// SETTINGS,
  ID_TOKEN_MENU
} from '../actions/types';
import { TOKEN_CLIENT_TYPE_SYSTEM } from '../actions/constants';
import { callApi2 } from '../service/api/index';
import { ID_TOKEN_INFO, _PARTNER } from "../actions/constants";

export function clearTokens() {
  Cookies.remove('GDTECABINET');
  Cookies.remove('PARTNER_ACCESS_TOKEN');
  Cookies.remove('PARTNER_REFRESH_TOKEN');
  localStorage.removeItem(ID_TOKEN_INFO);
  localStorage.removeItem(_PARTNER);
};

export function logoutAccount() {
  setTimeout(
    () => {
      clearTokens();
      TOKEN_CLIENT_TYPE_SYSTEM ? window.location.href = `/dang-nhap`
        : window.location.href = `/loai-he-thong`
    }, 500);
}
/**
 * @author Hồ Văn Tuấn
 * @description Kiểm tra đường link cho Routers
 */
export const checkLinkURL = path =>
  path === undefined || path === null ? "#" : `${path}`;

/**
 * @author Hồ Văn Tuấn
 * @description Hàm lấy danh sách Routes
 */
export function getAllRoute(menus) {
  return (
    menus && [
      ..._includes,
      ..._routes.filter(route => {
        const obj = menus.find(menu => route.path === menu.path);
        if (obj) {
          route.viewName = obj.Name;
          route.viewIcon = obj.Icon;
          return true;
        }
        return false;
      })
    ]
  );
}
/**
 * @author Hồ Văn Tuấn
 * @description Hàm lấy danh sách Routes
 */
export function getHomeRoute(menus) {
  return (
    menus && [
      ..._includes,
      ..._trangTin.filter(route => {
        const obj = menus.find(menu => route.path === menu.Path);
        if (obj) {
          route.viewName = obj.Name;
          route.viewIcon = obj.Icon;
          return true;
        }
        return false;
      })
    ]
  );
}

/**
 *
 *@author Hồ văn tuấn
 */
const checkCondition = (route, permission) => route.path === permission.url;
const checkHasPermission = (route, permissions) => permissions.some(permission => checkCondition(route, permission));

//check permission
export async function getRouteDanhMuc(url) {
  const route = _danhmuc.filter(route => route.odata && route.odata.url && route.odata.url === url);
  if (route && route.length > 0) {
    const danhmuc = route[0];
    // lấy danh sách quyền được cấp phép
    if (checkHasPermission(danhmuc, await getPermissions())) {
      return danhmuc;
    }
  }
  return null;
};

//check permission
export async function getPermissions() {
  let permissions = [];
  //console.log(PERMISSION);
  if (PERMISSION !== null) {
    permissions = PERMISSION;
  } else {
    const res = await callApi2('Login/GetMenu', 'GET', null, false);
    if (res.menu && res.danh_sach_chuc_nang) {
      localStorage.setItem(ID_TOKEN_MENU, JSON.stringify(res.menu));
      localStorage.setItem(ID_TOKEN_PERMISSION, JSON.stringify(res.danh_sach_chuc_nang));
      // window.location.replace(TOKEN_CLIENT_INFO.TrangChu ? TOKEN_CLIENT_INFO.TrangChu : '/');
      // window.location.replace('/dashboard');
      permissions = res.danh_sach_chuc_nang;
    }
  }

  return permissions;
};