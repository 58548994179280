import { useEffect, useCallback, useState, memo, useMemo } from 'react'
import { Modal, ModalBody } from "reactstrap";
import { useAsync } from "../../../../../hooks";
import { Accordion, AccordionDetails, AccordionSummary, Icon, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import './style.scss'
import { PhienLogsEndpoint } from '../../../../../service/Meet';
import { formatDatetime } from '../../../../../actions/util';
import DangKyPhatBieusEndpoint from '../../../../../service/Meet/DangKyPhatBieusEndpoint';
import { CHECK_WARNING } from '../../../../../components/alert/Alert';
import { StorageEndpoint } from '../../../../../service/GateWay';


const Enum_Label_Log = {
    'PHATBIEU': 'phát biểu',
    'BATDAU': 'Bắt đầu phiên họp',
    'VANDE': {
        'Moi': 'Thêm mới biểu quyết',
        'HoanThanh': 'Đã hoàn thành biểu quyết'
    },
    'KHUNGNOIDUNG': 'hoàn thành'
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        magrin: '0px',
        flexBasis: '90%%',
        fontFamily: '"Mulish", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color emoji'
    },
}));


const DetailLog = memo(({ log }) => {
    const classes = useStyles();
    const json = JSON.parse(log.json);
    const [expanded, setExpanded] = useState(false);
    const [path, setPath] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (log && log.code === 'PHATBIEU' && expanded) {
            DangKyPhatBieusEndpoint.getDataFullByID(log.key)
                .then(res => {
                    setPath(res.path)
                })
                .catch(err => CHECK_WARNING(err))
        }
    }, [expanded, log])

    const renderLabel = ({ code, status, json }) => {
        let label = ''
        switch (code) {
            case 'PHATBIEU':
                label = <p className='mb-1'>{json?.TenChucDanh} <b>{json?.Name}</b> {Enum_Label_Log[code]}</p>
                break;
            case 'BATDAU':
                label = <p className='mb-1'>{Enum_Label_Log[code]} <b>{json?.Name}</b></p>
                break;
            case 'VANDE':
                label = <p className='mb-1'>{Enum_Label_Log[code][status]} <b>{json?.Name}</b></p>
                break;
            case 'KHUNGNOIDUNG':
                label = <p className='mb-1'>Nội dung <b>{json?.Name}</b> {Enum_Label_Log[code]} </p>
                break;
            default:
                break;
        }
        return label
    }

    const renderExpandLog = useMemo(() => {
        return path ? <audio
            className='w-100'
            controls
            src={path && StorageEndpoint.getDocUrlByPath(path)} /> : <></>
    }, [path])

    return <Accordion expanded={expanded} onChange={handleChange('panel')}>
        <AccordionSummary
            expandIcon={<Icon fontSize="small">arrow_upward</Icon>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography className={classes.heading}>
                <p className='mb-1'>{formatDatetime(log.thoiGian)}</p>
                {renderLabel({ ...log, json })}
            </Typography>
        </AccordionSummary>
        <AccordionDetails className='d-block'>
            <Typography>
                {renderExpandLog}
            </Typography>
        </AccordionDetails>
    </Accordion>
})

function LogActivity({ close, isOpen }) {
    const [log, setLogs] = useState([]);

    const getDataTranfer = useCallback(async (phienHopId) => {
        try {
            const { value } = await PhienLogsEndpoint.getData(phienHopId);
            setLogs(value)
            return true;
        } catch (error) {
            console.log(error)
            throw new Error(error)
        }
    }, [])

    const { execute, status } = useAsync(getDataTranfer, false);

    useEffect(() => { isOpen && execute(isOpen) }, [execute, isOpen]);

    return (<Modal size="lg" isOpen={isOpen ? true : false} toggle={close} zIndex='1500'>
        <ModalBody>
            <div className="mb-1">
                <b>{isOpen?.phienHop?.name}</b>
            </div>
            <div className='log-activity'>
                {
                    status === 'pending' ? <LinearProgress />
                        : <div className="container">
                            <section><h1>DIỄN BIẾN PHIÊN HỌP</h1></section>
                            <div style={{ color: 'cadetblue' }} className='text-center'>Bắt đầu</div>
                            <div className="timeline">
                                {
                                    log.map((cur, index) => {
                                        return <article key={'logP' + index} className="row">
                                            <div className={`block slide-in-blurred-${index % 2 === 0 ? 'left' : 'right'}`}>
                                                <DetailLog {...{ log: cur }}></DetailLog>
                                            </div>
                                        </article>
                                    })
                                }
                            </div>
                            <div style={{ color: 'cadetblue' }} className='text-center'>Kết thúc</div>
                        </div >
                }
            </div>
        </ModalBody>
    </Modal >);
}

export default LogActivity;