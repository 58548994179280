import React from 'react';
import Dialog from './Dialog';
import HeaderDialog from './HeaderDialog';
import BodyDialog from './BodyDialog';

export default function FullScreenDialog(props) {
    const { renderCustom, renderButton, children, title,
        onClose, open, style, className } = props;

    return (
        <Dialog open={open} onClose={onClose} style={style} className={className}>
            <HeaderDialog renderCustom={renderCustom} renderButton={renderButton} title={title} onClose={onClose} />
            <BodyDialog className="position-relative">{children}</BodyDialog>
        </Dialog>
    );
}
