import { useCallback, useEffect, useRef, useState } from "react";

const useAsync = (asyncFunction, immediate = true) => {
    const [status, setStatus] = useState('idle');
    const [value, setValue] = useState(undefined);
    const [error, setError] = useState(undefined);
    const isMounted = useRef(false)
    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback((param) => {
        setStatus('pending');
        setError(null);

        return asyncFunction(param)
            .then(response => {
                if (!isMounted.current) return
                setValue(response);
                setStatus('success');
            })
            .catch(error => {

                // console.log('error', error)

                if (!isMounted.current) return
                setError(error);
                setStatus('error');
            });
    }, [asyncFunction]);

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);

    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    return { execute, status, value, error, setValue, setStatus };
};

export default useAsync
