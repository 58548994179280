import React, { createContext, useReducer } from "react";
import { initialState, init } from '../Action/OtherConstant'
import PhienHopReducer from "../Reducer/PhienHopReducer";

export const PhienHopContext = createContext();
const { Provider } = PhienHopContext;

const PhienHopProvider = ({ children }) => {
    const [state, dispatch] = useReducer(PhienHopReducer, initialState, init);
    return (
        <Provider value={[state, dispatch]}>
            {children}
        </Provider >
    )
}
export default PhienHopProvider