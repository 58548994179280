import React, { useRef } from "react";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import '../../other/Picker/material_blue.css';
import { Vietnamese } from "../../other/Picker/flatpickr.vn";
import { formatDate, formatDatetime, formatDatetime2, formatISODate, formatTime } from "../../../actions/util";
import styled from 'styled-components';
import { FontAwesome } from "../../icon";

const Icon = styled.div`
    top: 0.6rem;
    right: 0.3rem;
    color: rgb(204, 204, 204);
    :hover{
	color: red;
	cursor: pointer;
    }
`;
const DatePicker = ({
	value,
	onChange,
	placeholder,
	enableTime,
	minDate,
	maxDate,
	noCalendar,
	isRequired,
	disabled,
	name, style, className,
	isClearable,
	type,
	enableTimeNotSecond
}) => {
	const val = value ? new Date(value) : undefined;
	const refFlat = useRef();
	return (
		<>
			<Flatpickr
				id={name + type}
				ref={refFlat}
				className={className}
				value={val}
				style={style}
				onChange={(date) => onChange && onChange(formatISODate(date[0]))}
				disabled={disabled}
				name={name}
				options={{
					enableTime,
					noCalendar,
					maxDate,
					minDate,
					time_24hr: true,
					// plugins: [new rangePlugin({ input: '#secondRangeInput' + name })],
					formatDate: (date) => {
						if (enableTime === true) {
							if (noCalendar === true) return formatTime(date);
							if (enableTimeNotSecond) return formatDatetime2(date);
							return formatDatetime(date)
						}
						return formatDate(date);
					},
					locale: Vietnamese,
				}}
				render={({ defaultValue, value, ...props }, ref) => {
					return (
						<div className="position-relative">
							<input
								{...props}
								style={{ flexGrow: 2 }}
								ref={ref}
								className={`form-control form-control-md ${className}`}
								required={isRequired}
								defaultValue={defaultValue}
								placeholder={placeholder}
							/>
							{/* {isClearable && <Icon className="fa-times fa-fw icon position-absolute"
								onClick={() => refFlat.current.flatpickr.clear()}>
							</Icon>} */}
							{
								isClearable && <Icon className="position-absolute" onClick={() => refFlat.current.flatpickr.clear()}><FontAwesome fa="times-circle" /></Icon>
							}

						</div>
					);
				}}
			/>
			{/* <input
                id={'secondRangeInput' + name}
                style={{ flexGrow: 2 }}
                className={`form-control form-control-md ${className}`}
                required={isRequired}
                placeholder={placeholder}
            /> */}
		</>
	);
};

DatePicker.propTypes = {
	enableTime: PropTypes.bool.isRequired,
	noCalendar: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
};

DatePicker.defaultProps = {
	disabled: false,
	enableTime: false,
	noCalendar: false,
	placeholder: "Chọn ngày tháng",
	minDate: new Date("1900-01-01")
};

export default DatePicker;
