import ReactDOMServer from 'react-dom/server'
import { removeVietnameseTones } from '../../actions/util';
/**
 * @typedef {Object} AvatarProps
 * @property {string} name
 * @property {'circle' | 'rounded'} shape
 * @property {number} size
 * 
 * @param {AvatarProps & import('react').SVGAttributes<SVGSVGElement>} props
 */
function Avatar({ name, shape, size = 60, ...svgProps }) {
  const id = window.crypto.randomUUID();
  const color1 = '#' + Math.floor(Math.random() * 16777215).toString(16);
  const color2 = '#' + Math.floor(Math.random() * 16777215).toString(16);
  const text = removeVietnameseTones(name || '').substring(0, 2) || 'A';
  const borderRadius = shape === 'circle' ? '50%'
    : shape === 'rounded' ? '10px' : undefined;
  const fontsize = (size * 0.9) / text.length;
  return (
    <svg {...svgProps} style={{ borderRadius }} width={size} height={size}
      viewBox={`0 0 ${size} ${size}`} version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <defs>
          <linearGradient id={id} x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor={color1} />
            <stop offset="100%" stopColor={color2} />
          </linearGradient>
        </defs>
        <rect fill={`url(#${id})`} x="0" y="0" width={size} height={size} />
        <text x="50%" y="50%" alignmentBaseline="central" dominantBaseline="central"
          textAnchor="middle" fill="#fff" fontFamily="sans-serif" fontSize={fontsize}>{text}</text>
      </g>
    </svg>
  )
}

/** @param {AvatarProps} avatarProps */
export function toDataUrlAvatar(avatarProps) {
  const svgString = ReactDOMServer.renderToString(<Avatar {...avatarProps} />);
  return `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`
}