import { AUTH_API_URL, _API_URL } from "../actions/constants";
import Cookies from 'js-cookie';
import { useCallback } from "react";

const PATCH = '/Accounts';

const useConvertAvatar = () => {

    const convert = useCallback(async (idUpload, uuid) => {
        const headers = {};
        headers["Accept"] = "application/json";
        const token = Cookies.get('GDTECABINET');
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }
        const url_Upload = uuid && 'HinhCanBoDownload';
        const url = _API_URL + AUTH_API_URL + PATCH + `/DownloadAuth/${url_Upload}?id=${uuid}&idUpload=${idUpload}`;
        // const encodedUrl = url.replace(/(?<=search=)[^&]+/g, m => encodeURIComponent(m));
        const res = await fetch(url, {
            method: "GET",
            credentials: "same-origin",
            headers: headers
        });
        if (res.ok)
            return res;
        else
            throw res;

    }, [])
    return { convert }
}
export default useConvertAvatar