import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";

function Index({ children, close }) {
    const [container] = useState(document.createElement('div'));
    const secondWindow = useRef(null);
    useEffect(() => {
        secondWindow.current = window.open("", "_blank", "width=auto,height=auto,status=1");
        secondWindow.current.document.body.appendChild(container);
        secondWindow.current.document.title = 'Màn hình chính phiên họp';
        secondWindow.current.addEventListener("beforeunload", () => {
            secondWindow.current.close();
            close();
        });
        copyStyles(document, secondWindow.current.document);
    })

    return createPortal(children, container);
}
export default Index
/**
 * Chép toàn bộ style sang cửa sổ mới
 * @param {Document} srcDoc
 * @param {Document} destDoc
 */
function copyStyles(srcDoc, destDoc) {
    Array.from(srcDoc.styleSheets).forEach(styleSheet => {
        if (styleSheet.href) { // true for stylesheets loaded from a URL
            const newLinkEl = srcDoc.createElement('link');

            newLinkEl.rel = 'stylesheet';
            newLinkEl.href = styleSheet.href;
            destDoc.head.appendChild(newLinkEl);
        } else if (styleSheet.cssRules) { // true for inline styles
            const newStyleEl = srcDoc.createElement('style');

            Array.from(styleSheet.cssRules).forEach(cssRule => {
                if (cssRule.cssText !== 'body { display: none; }')
                    newStyleEl.appendChild(srcDoc.createTextNode(cssRule.cssText));
            });

            destDoc.head.appendChild(newStyleEl);
        }
    });
}