import React, { useState, useImperativeHandle, useRef } from "react";
import {
  CHECK_SUCCESS,
  CHECK_ERROR,
  CHECK_WARNING
} from "../../components/alert/Alert";
import { Col } from "../../components/gird";
import { Form, FormGroup, FormFooter } from "../../components/base/Form";
import { InputText } from "../../components/base/Input";
import { Label } from "../../components/base/Label";
import { ButtonEdit } from "../../components/base/Button";
import FormInputMenu from "./FormInputMenu";

const EditMenu = React.forwardRef(({ manipulation, refeshMenu, iniMenus }, ref) => {
  // refs
  const formInputRef = useRef(null);
  // states
  const [Id, setId] = useState("");
  const [label, setLabel] = useState("");
  const [link, setLink] = useState("");
  const [icon, setIcon] = useState("");
  const [Description, setDescription] = useState("");
  const [ordinal, setOrdinal] = useState("");
  const [parentId, setParentId] = useState("null");
  //
  useImperativeHandle(ref, () => ({
    refeshMenu: () => formInputRef.current && formInputRef.current.refeshMenu(),
    setValuesDetail: (e, info) => {
      if (info) {
        setId(info.NodeId);
        setLabel(info.label);
        setLink(info.link);
        setIcon(info.icon);
        setDescription(info.Description);
        setOrdinal(info.ordinal);
        setParentId(info.Parent);
      }
    }
  }));
  //
  const callApiEdit = () => {
    if (Id === "" || label === "" || icon === "" || ordinal === "" || link === "") {
      CHECK_ERROR("Vui lòng nhập đầy đủ thông tin !");
      return;
    }
    const menu = { Id, label, icon, Description, ordinal, link };
    if (parentId !== "null") {
      if (Id === parentId) {
        CHECK_WARNING("Menu bạn sửa không thể là con của chính nó !");
        return;
      }
      menu.parentId = parentId;
    }
    manipulation
      .edit(menu)
      .then(result => {
        refeshMenu();
        CHECK_SUCCESS("Sửa menu thành công !");
      })
      .catch(() => CHECK_ERROR("Sửa không thành công !"));
  };
  return (
    <>
      <Form>
        <FormGroup row>
          <Label sm={2}>Mã</Label>
          <Col sm={10}>
            <InputText value={Id} disabled />
          </Col>
        </FormGroup>
        <FormInputMenu
          refeshMenu={refeshMenu}
          ref={formInputRef}
          manipulation={manipulation}
          id={Id}
          name={label}
          setLabel={value => setLabel(value)}
          path={link}
          setLink={value => setLink(value)}
          icon={icon}
          setIcon={value => setIcon(value)}
          description={Description}
          setDescription={value => setDescription(value)}
          ordinal={ordinal}
          setOrdinal={value => setOrdinal(value)}
          parent={parentId}
          setParent={value => setParentId(value)}
          iniMenus={iniMenus}
        />
        <FormFooter>
          <ButtonEdit disabled={Id === ""} onClick={callApiEdit}>Sửa</ButtonEdit>
        </FormFooter>
      </Form>
    </>
  );
});

export default EditMenu;
