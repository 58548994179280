import { Admin } from "./Admin";

class RoleHttpEndPoints extends Admin {
    constructor() {
        super("/RoleHttpEndPoints");
    }
    // Lấy thông tin tài khoản
    findAllGroupName = () => this.GetAuthenADMIN(`/findAllGroupName`);
    // Lấy danh sách Endpoint với tên nhóm
    findAllEndpointByGroupName = (groupName) => this.GetAuthenADMIN(`/findAllEndpointByGroupName?groupName=${groupName}`);
    // Lưu các Endpoints với id vai trò tài khoản và Tên nhóm
    // saveAllWithAuthorityAndGroupName = (id, groupName, body) => this.PostAuthenADMIN(`${AuthorityEndpoint.path}${_URL_EDIT}?id=${id}&groupName=${groupName}`, body);
    // Tìm tất cả các Endpoint với id vai trò tài khoản
    findAllIdEndpoint = (id, groupName) => this.GetAuthenADMINODATA(`?$expand=HttpEndpoint($select=Id,GroupName)&$filter=AppRoleId eq ${id} and HttpEndpoint/GroupName eq '${groupName}'`);
}

export default new RoleHttpEndPoints()