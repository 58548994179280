
import React, { Fragment, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import styled from 'styled-components';
import { TextArea } from '../../../../../../components/base/Input';
import { useBatch } from '../../../../../../hooks';
import { PhuongAnBoSungsEndpoint } from '../../../../../../service/Meet';


const SpanRadius = styled.div`
    .cd-timeline-img {
        text-align: center;
        top: 0;
        border-radius: 50%;
        &:hover {
            box-shadow: 0 0 0 4px #d1ff03, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
        }
        box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
        background: #69e378;
        width: 25px;
        height: 25px;
        left: 50%;
    }
    :hover {
        color: green;
    }
`;
const INI_DATA = [{ noiDung: '', thuTu: 1 }]
const PhuongAns = React.forwardRef(({ vanDeId, data, ...props }, ref) => {
    const [dataPA, setDataPA] = useState(JSON.parse(JSON.stringify(INI_DATA)));
    const [batchData, setBatchData] = useState(null);
    const { updateBatchData } = useBatch();

    const checkBatchData = useCallback((method, data, index) => {
        setBatchData(pre => updateBatchData(pre, method, data, index))
    }, [updateBatchData])

    const getDataPA = useCallback(async (id) => {
        const { value } = await PhuongAnBoSungsEndpoint.getDataByIdVanDe(id);
        setDataPA(value);
        setBatchData(value.map(cur => ({ method: 'PATCH', data: cur })));
    }, [])

    useEffect(() => {
        vanDeId && getDataPA(vanDeId)
        data && setDataPA(data);
    }, [vanDeId, getDataPA, data])

    useImperativeHandle(ref, () => ({
        getData: () => dataPA,
        getBatchData: () => batchData,
        getDataPA
    }), [batchData, getDataPA, dataPA]);

    const onAddItemPhuCap = useCallback(() => {
        setDataPA(pre => {
            const dataPA = [...pre];
            const newData = { thuTu: dataPA.length + 1, noiDung: '', boSungVanDeId: vanDeId };
            dataPA.push(newData);
            vanDeId && checkBatchData('POST', newData);
            return dataPA;
        })
    }, [checkBatchData, vanDeId])
    const handleChange = useCallback((key, value) => {
        setDataPA(pre => {
            const phuongAns = [...pre];
            phuongAns[key].noiDung = value;
            vanDeId && checkBatchData('PATCH', phuongAns[key], key);
            return phuongAns;
        })
    }, [checkBatchData, vanDeId])
    const onRemove = useCallback((index, dataDelete) => {
        setDataPA(pre => {
            const dataPA = [...pre];
            if (dataPA.length - 1 !== 0) {
                const i = index || dataPA.length - 1;
                vanDeId && checkBatchData('DELETE', dataPA[i], i);
                dataPA.splice(i, 1);
            }
            return dataPA;
        })
    }, [checkBatchData, vanDeId])
    return dataPA && (
        <Fragment>
            {
                dataPA.map((pa, i) => {
                    return <div key={'phuongan' + i}>
                        <FormGroup row >
                            <Col xs='3'>
                                <Label >Phương án {i + 1} </Label>
                            </Col>
                            <Col xs='7' style={{ paddingLeft: 10, paddingRight: 27 }} className="d-flex justify-content-start">
                                <TextArea name="pa" value={pa.noiDung} onChange={e => handleChange(i, e.target.value)}></TextArea>
                            </Col>
                            {i === 0 && <Col xs='2' style={{ paddingLeft: 0, paddingRight: 0, height: 20 }}>
                                <Row>
                                    <SpanRadius className='mr-3'>
                                        <div className="cd-timeline-img" onClick={onAddItemPhuCap}>
                                            <i className="fa-solid fa-plus" style={{ paddingTop: '4px', fontSize: "large", color: "white", cursor: "pointer" }} />
                                        </div>
                                    </SpanRadius>
                                    <SpanRadius>
                                        <div className="cd-timeline-img" onClick={e => onRemove(i, pa)}>
                                            <i className="fa-solid fa-minus" style={{ paddingTop: '4px', fontSize: "large", color: "white", cursor: "pointer" }} />
                                        </div>
                                    </SpanRadius>
                                </Row>
                            </Col>
                            }
                        </FormGroup>
                    </div>
                })
            }
        </Fragment>
    )
})
export default PhuongAns