import { lazy } from "react";

const includeRoutes = [
    {
        path: "/admin/base/profile",
        viewName: "Thông tin người sử dụng",
        viewIcon: "fa-id-badge",
        component: lazy(() => import("../views/base/user-profile"))
    },
    {
        path: "/loai-he-thong",
        viewName: "Loại hệ thống",
        viewIcon: "fa-id-badge",
        component: lazy(() => import("../views/base/switchType"))
    }
];

export default includeRoutes
