import React, { useCallback, useState } from "react";
import { CHECK_SUCCESS, CHECK_ERROR } from "../../components/alert/Alert";
// import { CHECK_ERROR } from "../../components/alert/Alert";
import { Form } from "../../components/base/Form";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "../../components/modal";
import { ButtonAdd, ButtonClose } from "../../components/base/Button";
import FormInputMenu from "./FormInputMenu";

const AddMenu = ({ manipulation, isOpen, toggle, refeshMenu, iniMenus }) => {
  // states
  const [label, setLabel] = useState("");
  const [link, setLink] = useState("");
  const [icon, setIcon] = useState("");
  // const [Description, setDescription] = useState("");
  const [ordinal, setOrdinal] = useState("");
  const [parentId, setParenId] = useState(null);

  //refresh DataForm
  const refreshData = useCallback(() => {
    setLabel("");
    setLink("");
    setIcon("");
    // setDescription("");
    setOrdinal("");
    setParenId(null);
  }, [])
  //
  const callApiAdd = () => {
    if (label === "" || icon === "" || ordinal === "" || link === "") {
      CHECK_ERROR("Vui lòng nhập đầy đủ thông tin !");
      return;
    }
    const menu = { label, icon, ordinal, link };
    if (parentId !== null) menu.parentId = parentId;
    // refreshData()
    manipulation
      .add({ ...menu, isPublic: true })
      .then(result => {
        toggle();
        result && refeshMenu();
        refreshData();
        CHECK_SUCCESS("Thêm menu thành công !");
      })
      .catch(() => CHECK_ERROR("Thêm 1 Menu thất bại !"));
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Thêm Menu</ModalHeader>
      <ModalBody>
        <Form>
          <FormInputMenu
            manipulation={manipulation}
            name={label}
            setLabel={value => setLabel(value)}
            path={link}
            setLink={value => setLink(value)}
            icon={icon}
            setIcon={value => setIcon(value)}
            // description={Description}
            // setDescription={value => setDescription(value)}
            ordinal={ordinal}
            setOrdinal={value => setOrdinal(parseInt(value))}
            parent={parentId}
            setParent={value => setParenId(value)}
            iniMenus={iniMenus}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <ButtonAdd onClick={callApiAdd}>Thêm</ButtonAdd>{" "}
        <ButtonClose onClick={toggle}>Đóng</ButtonClose>
      </ModalFooter>
    </Modal>
  );
};

export default AddMenu;
