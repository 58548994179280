/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Card } from '@material-ui/core';
import { CardBody, Form, Col, FormGroup, CardFooter, Row, Input, Label } from 'reactstrap';
import { Required } from '../../components/base/Label';
import { ButtonSave } from '../../components/base/Button';
import { DatePicker } from '../../components/picker';
import Swal from 'sweetalert2';
// import Select from "react-select";
// import useLocationSelect from '../../shared/profile/address_book/useLocationSelect';
// import { PictureEndpoint } from '../../service/upload';


// const FIELD_FORM = ["birthday","dateCMND"]

const INITIAL_CONFIG_DATA = {
    username: "", password: "", rePassword: "", nameCompany: "",
    codeCompany: "", dateCompany: "", placeMSDN: "", Headquarter: "",
    legalRepresentative: "", CMND: "", dateCMND: "", placeCMND: "", phoneNumber: "", email: "",
}
const INITIAL_ERROR = {
    username: "", password: "", rePassword: "", name: "",
    birthday: "", phoneNumber: "", CMND: "", dateCMND: "",
    placeCMND: "", address: ""
}

export default function CaNhan() {

    const [submitData, setSubmitData] = useState(INITIAL_CONFIG_DATA);
    const [errorData, setError] = useState(INITIAL_ERROR);
    const [refFileF, setSubmitDataF] = useState(null);
    const [acceptInfo,setAcceptInfo] = useState(false);
    const [acceptWander,setAcceptWander] = useState(false);

    const handleChange = useCallback((event) => {
        const { target: { name, value }, } = event;
        setSubmitData((pre) => ({ ...pre, [name]: value }))
    }, []);

    const setbirthday = useCallback((value, name) => {
        setSubmitData((pre) => ({ ...pre, [name]: value }))
    }, [])

    const isValidatePassword = useMemo(() => {
        return submitData.password === submitData.rePassword
    }, [submitData.password, submitData.rePassword])

    const alertError = useCallback(() => {
        const alert = {
            title: "Lỗi",
            text: "Mật khẩu khôn khớp,vui lòng kiểm tra lại",
            icon: "error",
        };
        Swal.fire(alert)
    }, [])

    const hanldeSubmit = async (e) => {
        e.preventDefault();
        if (!isValidatePassword) {
            alertError()
            return
        } else {
            // const idCMNDFront = await PictureEndpoint.uploadFile(refFileF);
        }
    }
    const onchangeIMG = useCallback((event) => {
        const { target: { name }, } = event;
        if (event.target.files && event.target.files[0]) {
            setSubmitDataF(event.target.files[0]);
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (event) => {
                setSubmitData((pre) => ({ ...pre, [name]: event.target.result }))
            }
        }
    }, [])
    // const {
    //     provinceCityOptions,
    //     districtOptions,
    //     wardCommuneOptions,
    //     streetOptions,
    //     disabledDistrictSelect,
    //     disabledWardCommuneSelect,
    //     disabledStreetSelect,
    //     loadingProvinceCitySelect,
    //     loadingDistrictSelect,
    //     loadingWardCommuneSelect,
    //     loadingStreetSelect,
    //     handleSelect,
    //     selectedValue: { cityOrProvince, district, wardOrCommune, street },
    // } = useLocationSelect();
    return (
        <Form onSubmit={hanldeSubmit} method="POST" id="register-form">
            <Card>
                <CardBody>
                    <Col xs="12" sm="12" lg="12">
                        <Row>
                            <Col xs="12" sm="6" lg="6">
                                <FormGroup row>
                                    <Col xs='4'><Required>Tên tài khoản</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            value={submitData.username}
                                            onChange={handleChange}
                                            name="username"
                                            required
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Mật khẩu</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            type='password'
                                            value={submitData.password}
                                            onChange={handleChange}
                                            name="password"
                                            minLength={6}
                                            required
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                        <code className="code" >Mật khẩu phải chứa ít nhất 1 chữ hoa, 1 chữ thường,1 ký tự đặc biệt, 1 chữ số và có độ dài trên 6 ký tự</code>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Xác nhận mật khẩu</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            type='password'
                                            value={submitData.rePassword}
                                            onChange={handleChange}
                                            name="rePassword"
                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            required
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Tên doanh nghiệp/Tổ chức</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            value={submitData.nameCompany}
                                            onChange={handleChange}
                                            name="nameCompany"
                                            required
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Mã số doanh nghiệp/Mã số thuế</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            value={submitData.codeCompany}
                                            onChange={handleChange}
                                            name="codeCompany"
                                            required
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Ngày cấp số doanh nghiệp/Mã số thuế</Required></Col>
                                    <Col xs='8'>
                                        <DatePicker
                                            value={submitData.dateCompany}
                                            onChange={(e) => setbirthday(e, 'birthday')}
                                            placeholder="Chọn ngày cấp"
                                            name="dateCompany"
                                            isRequired={true}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Nơi cấp MSDN/MST*</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            value={submitData.placeMSDN}
                                            onChange={handleChange}
                                            name="placeMSDN"
                                            required
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Trụ sở</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            value={submitData.Headquarter}
                                            onChange={handleChange}
                                            name="Headquarter"
                                            required
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col xs='12'>
                                        <Input
                                            checked={acceptInfo}
                                            type="checkbox"
                                            name='acceptInfo'
                                            className="mr-12"
                                            onChange={({ target: { checked } }) => {
                                                setAcceptInfo(checked)
                                            }} />
                                        <Label>Tôi cam kết tự chịu trách nhiệm với các thông tin nhập đăng ký tài khoản</Label>
                                    </Col>
                                    <Col xs='12'>
                                        <Input
                                            checked={acceptWander}
                                            type="checkbox"
                                            name='acceptWander'
                                            className="mr-12"
                                            onChange={({ target: { checked } }) => {
                                                setAcceptWander(checked)
                                            }} />
                                        <Label>Tôi cam kết tuân thủ <a href="#"> ĐIỀU KHOẢN VÀ CHÍNH SÁCH</a> tại website đấu giá trực tuyến</Label>
                                    </Col>
                                </FormGroup>
                                
                            </Col>
                            <Col xs="12" sm="6" lg="6">
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Người đại diện pháp luật</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            value={submitData.legalRepresentative}
                                            onChange={handleChange}
                                            name="legalRepresentative"
                                            required
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Số CMND/CCCD người đại diện</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            type="number"
                                            value={submitData.CMND}
                                            onChange={handleChange}
                                            name="CMND"
                                            required
                                            minLength='9'
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                        {errorData.CMND && errorData.CMND !== "" && (
                                            <p className="help is-danger">{errorData.CMND}</p>
                                        )}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Ngày cấp CMND/CCCD người đại diện</Required></Col>
                                    <Col xs='8'>
                                        <DatePicker
                                            value={submitData.dateCMND}
                                            onChange={(e) => setbirthday(e, 'dateCMDN')}
                                            placeholder="Chọn ngày"
                                            isRequired={true}
                                        />
                                        {errorData.placeCMND && errorData.placeCMND !== "" && (
                                            <p className="help is-danger">{errorData.placeCMND}</p>
                                        )}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Nơi cấp CMND/CCCD người đại diện</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            value={submitData.placeCMND}
                                            onChange={handleChange}
                                            name="placeCMND"
                                            required
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                        {errorData.placeCMND && errorData.placeCMND !== "" && (
                                            <p className="help is-danger">{errorData.placeCMND}</p>
                                        )}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Số điện thoại</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            value={submitData.phoneNumber}
                                            onChange={handleChange}
                                            name="phoneNumber"
                                            type="text"
                                            pattern="^0?([0-9]{9,10})"
                                            required
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                        {errorData.phoneNumber && errorData.phoneNumber !== "" && (
                                            <p className="help is-danger">{errorData.phoneNumber}</p>
                                        )}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Email</Required></Col>
                                    <Col xs='8'>
                                        <input
                                            value={submitData.email}
                                            onChange={handleChange}
                                            name="email"
                                            required
                                            type="email"
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                            className="form-control ng-untouched ng-pristine ng-invalid"
                                        />
                                        {errorData.email && errorData.email !== "" && (
                                            <p className="help is-danger">{errorData.email}</p>
                                        )}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='6' className='img-pd'>
                                        <Col xs=''><Required text={"*"}>Ảnh giấy chứng nhận đăng ký doanh nghiệp/quyết định thành lập*</Required></Col>
                                        <Col xs='8'>
                                            <input ref={refFileF} type="file" name='imageFront' required onChange={e => onchangeIMG(e)} className="btn btn-primary img" accept="image/png, image/jpeg" />
                                            {submitData.imageFront && <img src={submitData.imageFront} alt="description of image" width="250" ></img>}
                                        </Col>
                                    </Col>
                                </FormGroup>
                                {/* <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Tỉnh thành</Required></Col>
                                    <Col xs='8'>
                                        <Select
                                            required
                                            name={"cityOrProvince"}
                                            onChange={handleSelect}
                                            options={provinceCityOptions}
                                            value={cityOrProvince}
                                            isLoading={loadingProvinceCitySelect}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Quận/huyện</Required></Col>
                                    <Col xs='8'>
                                        <Select
                                            required
                                            name={"district"}
                                            isDisabled={disabledDistrictSelect}
                                            options={districtOptions}
                                            value={district}
                                            isLoading={loadingDistrictSelect}
                                            onChange={handleSelect}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Phường/xã</Required></Col>
                                    <Col xs='8'>
                                        <Select
                                            required
                                            name={"wardOrCommune"}
                                            isDisabled={disabledWardCommuneSelect}
                                            options={wardCommuneOptions}
                                            value={wardOrCommune}
                                            isLoading={loadingWardCommuneSelect}
                                            onChange={handleSelect}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Col xs='4'><Required text={"*"}>Đường phố</Required></Col>
                                    <Col xs='8'>
                                        <Select
                                            required
                                            name={"street"}
                                            isDisabled={disabledStreetSelect}
                                            options={streetOptions}
                                            value={street}
                                            isLoading={loadingStreetSelect}
                                            onChange={handleSelect}
                                        />
                                    </Col>
                                </FormGroup> */}

                                

                            </Col>
                        </Row>
                    </Col>
                </CardBody>
                <CardFooter>
                    <div className="d-flex"><ButtonSave className="btn btn-primary" >Lưu</ButtonSave></div>
                </CardFooter>
            </Card>
        </Form>
    )
}