import { Meet } from "./Meet";

class DeniedDocumentationsEndpoint extends Meet {
    constructor() {
        super("/DeniedDocumentations");
    }

    getDataByPath = (path) => this.SearchAllow(`?&$filter=path eq '${path}'`);

    addDenied(data) { return this.PostAuthen('', data); }

    updateListDenied = (data) => this.PatchAuthen('/UpdateDeniedDocument', data)

}
export default new DeniedDocumentationsEndpoint();