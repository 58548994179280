import { Meet } from "./Meet";
class NhanSuGhesEndpoint extends Meet {
    constructor() {
        super("/NhanSuGhes");
    }
    getDataWithPhienHopId(id) { return this.SearchAllow(`?$filter=phongHopId eq ${id}`) }
    deleteThanhVien(id) { return this.DeleteAUTH(`(${id})`, { noLoading: true }) };
    //update
    updateNhanSuGhe(id, data) { return this.PatchAuthen(`(${id})`, data, { noLoading: true }) };
    addNhanSuGhe(data) { return this.PostAuthen('', data, { noLoading: true }) };
    findNhanSu(thanhPhanThamDuId, phongHopId, soDoId) {
        return this.SearchAllow(`?$filter=thanhPhanThamDuId eq ${thanhPhanThamDuId} and phongHopId eq ${phongHopId} and soDoId eq ${soDoId}`)
    };
}
export default new NhanSuGhesEndpoint();