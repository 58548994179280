import React, { useEffect, forwardRef, useImperativeHandle, useRef, useCallback, useState } from 'react'
import JoditEditor from 'jodit-react';

const Index = forwardRef(({ isOpen }, ref) => {
    const refData = useRef(null);
    const [dataTemplate, setDataTemplate] = useState(null);

    const prepareTemplate = useCallback(async (dataTemp) => {
        setDataTemplate(dataTemp || '');
    }, [])

    useEffect(() => {
        isOpen && prepareTemplate(isOpen);
        return () => {
            setDataTemplate(null);
            refData.current = null;
        }
    }, [isOpen, prepareTemplate])

    const config = {
        removeButtons: ['font', 'spellcheck', 'copyformat',
            'superscript', 'subscript', 'file', 'image', 'video', 'about'],
        height: 400,
        allowResizeY: false,
    };

    const handleModelChange = useCallback((model) => {
        console.log(model)
        refData.current = model
    }, [])

    useImperativeHandle(ref, () => ({
        getValue: () => refData.current
    }), []);

    return (
        <JoditEditor
            config={config}
            value={dataTemplate || ''}
            onChange={(a) => handleModelChange(a)}
        />
    )
})

export default Index
