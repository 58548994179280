import { Account } from './Account';
import AuthorityEndpoint from './AuthorityEndpoint';
// import { _URL_EDIT } from '../../actions/constants';

class MenuPartnerEndpoint extends Account {
    constructor() {
        super("/MenuPartner");
    }
    // Lấy dữ liệu theo cây
    getMenuTree = () => this.GetAuthenPUBLIC(`/getMenusTree`);
    // Danh sách Menu với Authority
    getWithAuthorityWithID = (id) => this.GetAuthenPUBLIC(`${AuthorityEndpoint.path}?id=${id}&select=uuid`);
    // Lấy dữ liệu theo cây với Authority
    getMenuTreeWithAuthority = () => this.GetAuthenPARTNER(`${AuthorityEndpoint.path}/getMenusTree`);
    // Lấy dữ liệu theo cây với Authority
    // setAllMenuAdministrationWithAuthority = (id, body) => this.PostAuthenADMIN(`${AuthorityEndpoint.path}${_URL_EDIT}?id=${id}`, body);
}

export default new MenuPartnerEndpoint();