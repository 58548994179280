import React, { Component } from 'react';
import makeAnimated from 'react-select/animated';
import Select from './Select';

class SelectMulti extends Component {
    render() {
        const { options, placeholder, defaultValue, onChange } = this.props;
        return (
            <Select
                isMulti
                closeMenuOnSelect={false}
                components={makeAnimated()}
                className="basic-multi-select"
                defaultValue={defaultValue}
                options={options}
                placeholder={placeholder}
                onChange={onChange}
                onMenuScrollToBottom={() => {
                }}
            />
        );
    }
};

SelectMulti.propTypes = {
};

SelectMulti.defaultProps = {
    placeholder: 'Chọn...'
};

export default SelectMulti;