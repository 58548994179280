import { Meet } from "./Meet";


class KetQuaBieuQuyetEndpoint extends Meet {
    constructor() {
        super("/KetQuaBieuQuyets");
    }

    // getAllKetQua = (param) => this.SearchAUTH(param)
    // getNhanSuById = (id) => this.SearchAUTH(`?$expand=nhanSu($expand=phieuLayYKiens,organization,chucDanh,thongTinCaNhan,nhomThanhVienNhanSus)&$select=id`)
    // deleteKetQua(id) { return this.DeleteAUTH(`(${id})`) }


    // getKetQuaByIdPhieu = (Id, expand = []) => this.SearchAUTH(`?$filter=phieuLayYKienId eq ${Id}&$expand=${expand}`);
    getKetQuaById = (IdVanDe, IdThanhPhanThamDu) => this.SearchAUTH(`?$expand=phuongAnBoSung&$filter=boSungVanDeId eq ${IdVanDe} and thanhPhanThamDuId eq ${IdThanhPhanThamDu}`);
    updateKetQuaBieuQuyet = (Id, data) => this.UpdateAUTH(`(${Id})`, data);
}
export default new KetQuaBieuQuyetEndpoint()