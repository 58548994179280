import { Meet } from "./Meet";

class EmailSettingsEndpoint extends Meet {
  constructor() {
    super("/EmailSettings");
  }
  getEmails = () => this.GetAllow('')
  getDataEmail = (id) => this.GetAllow(`(${id})`)
  addEmail = (body) => this.InsertAUTH(body);
  deleteEmail = (id) => this.DeleteAUTH(`(${id})`);
  editEmail = (id, body) => this.UpdateAUTH(`(${id})`, body);

}
export default new EmailSettingsEndpoint()