import { Account } from "./Account";
// import { _URL_ADD } from "../../actions/constants";

class AvatarEndpoint extends Account {
    constructor() {
        super("/Avatar");
    }

    // Get avatar
    getDefault = () => this.GetAuthen(`/getDefault`);
    // Get avatar Public
    getDefaultPublic = id => this.GetAuthenPUBLIC(`/getDefault?id=${id}`);
    // Add avatar
    // addAvatar = id => this.PostAuthenALLOWS(`${_URL_ADD}`, { id });
    // Set avatar
    setAvatar = uuid => this.PutAuthenALLOWS(`/setDefault`, { uuid }, { responseType: 'text' });

    deleteAvatar = uuid => this.DeleteAuthenALLOWS(`/delete?id=${uuid}`)
}

export default new AvatarEndpoint();
