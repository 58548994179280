import { useState, useRef } from 'react'
import { RECORD_STATUS } from './status'
import useTimer from './useTimer'

export const useAudioRecorder = () => {
  const dataArray = useRef([])
  const mediaRecorder = useRef();
  const localStream = useRef();

  const [status, setStatus] = useState(RECORD_STATUS.IDLE)
  // eslint-disable-next-line no-unused-vars
  const [audioResult, setAudioResult] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    timer,
    handleStartTimer,
    handlePauseTimer,
    handleResumeTimer,
    handleResetTimer
  } = useTimer()

  const startRecording = () => {
    if (status === RECORD_STATUS.IDLE) {
      try {
        setErrorMessage('')
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((mediaStreamObj) => {
            localStream.current = mediaStreamObj
            mediaRecorder.current = new MediaRecorder(mediaStreamObj)
            mediaRecorder.current.start()
            mediaRecorder.current.onstart = () => {
              handleStartTimer()
              setStatus(RECORD_STATUS.RECORDING)
            }
            mediaRecorder.current.ondataavailable = (event) => {
              dataArray.current.push(event.data)
            }
          })
          .catch((error) => {
            setErrorMessage(error?.message)
          })
      } catch (error) {
        setErrorMessage(error?.message)
      }
    } else {
      return
    }
  }

  const resumeRecording = () => {
    if (status === RECORD_STATUS.PAUSED) {
      mediaRecorder.current.resume()
      mediaRecorder.current.onresume = () => {
        handleResumeTimer()
        setStatus(RECORD_STATUS.RECORDING)
      }
    } else {
      return
    }
  }

  const pauseRecording = () => {
    if (status === RECORD_STATUS.RECORDING) {
      mediaRecorder.current.pause()
      mediaRecorder.current.onpause = () => {
        handlePauseTimer()
        setStatus(RECORD_STATUS.PAUSED)
      }
    } else {
      return
    }
  }

  const resetAudio = () => {
    setAudioResult(null)
  }

  const stopRecording = () => {
    if (status !== RECORD_STATUS.IDLE) {
      mediaRecorder.current.stop()
      return mediaRecorder.current.onstop = () => {
        handleResetTimer()
        let audioData = new Blob(dataArray.current, { type: 'audio/mp3'})
        dataArray.current = []
        setAudioResult(audioData)
        setStatus(RECORD_STATUS.IDLE)
        localStream.current.getAudioTracks().forEach((track) => {
          track.stop()
        })
      }
    } else {
      return
    }
  }

  return {
    resetAudio,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    audioResult,
    errorMessage,
    timer
  }
}
