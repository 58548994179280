import { Categories } from "../Categories";


class ChuyenMucEndpoint extends Categories {
  constructor() {
    super("/ChuyenMucs");
  }

  getAllChuyenMuc = (IdChuyenMuc) => this.GetAllow(`(${IdChuyenMuc})`)
  addChuyenMuc = (body) => this.InsertAUTH(body);
  deleteChuyenMuc = (id) => this.DeleteAUTH(`(${id})`);
  editChuyenMuc = (IdChuyenMuc, body) => this.UpdateAUTH(`(${IdChuyenMuc})`, body);

}
export default new ChuyenMucEndpoint()