import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function FullScreenDialog(props) {
    const { children, onClose, open, style, className } = props;
    return (
        <Dialog className={className} style={style} disableEnforceFocus fullScreen open={open} onClose={onClose}
            TransitionComponent={Transition}>
            {children}
        </Dialog>
    );
}
