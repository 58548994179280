import { Meet } from "./Meet";
class PhongHopsEndpoint extends Meet {
  constructor() {
    super("/PhongHops");
  }

  getAllPhongHop = (IdChucDanh) => this.GetAllow(`(${IdChucDanh})`)
  addPhongHop = (body) => this.InsertAUTH(body);
  deletePhongHop = (id) => this.DeleteAUTH(`(${id})`);
  editPhongHop = (IdChucDanh, body) => this.UpdateAUTH(`(${IdChucDanh})`, body);

}
export default new PhongHopsEndpoint()