import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import "./polyfill";
import React from 'react'
import ReactDOM from "react-dom/client";
import App from "./app";
import "./scss/style.scss";
import reportWebVitals from './reportWebVitals';


const rootElement = document.getElementById(`${process.env.REACT_APP_ROOT || 'root'}`);

if (!rootElement) throw new Error('Failed to find the root element');

ReactDOM.createRoot(rootElement).render(<App />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// eslint-disable-next-line no-console
reportWebVitals()