import { GateWay } from "./GateWay";

class NhanSusGWndpoint extends GateWay {
    constructor() {
        super("/NhanSus");
    }
    addNhanSu = (data) => this.PostALLow('/Register', data, { isDataError: true });
    updateNhanSu(data) {
        return this.PatchAllow(`/UpdateNhanSu`, data)
    }
    updateThongTinTaiKhoan(data) {
        return this.PostALLow(`/UpdateThongTinTaiKhoan`, data)
    }
    deleteNhanSu(id,isKhachMoi=false) {
        return this.DeleteAllow(`/Delete?accountId=${id}${isKhachMoi?`&IsKhachMoi=true`:''}`)
    }
}
export default new NhanSusGWndpoint();