import { useRef } from 'react'
import { FormGroup } from '@material-ui/core';
import React, { memo, forwardRef, useCallback, useState, useEffect } from 'react';
import AsyncSelect from "react-select";
import { Col, Row } from 'reactstrap';
import { ButtonAdd } from '../base/Button';
import useAsync from '../../hooks/useAsync';
import _ from "lodash";

const selectProps = {
    noOptionsMessage: () => "Không có lựa chọn",
    placeholder: "Nhấp để chọn",
    loadingMessage: () => "Đang tìm kiếm",
    menuPosition: 'fixed'
}

const INI_TOP = 10;

const Index = forwardRef(({ onChange, id, name, placeholder, getOptionLabel,
    getOptionValue, onAdd, value, disabled, endPoint, fieldLabel, fieldValue, isClearable, ...props }, ref) => {

    const [selected, setSelected] = useState(null);
    const [options, setOptions] = useState([]);
    const [optionSearch, setOptionSearch] = useState([]);
    const [search, setSearch] = useState(null);

    const refTopSkip = useRef();
    const refTopSkipSearch = useRef();

    if (placeholder) selectProps['placeholder'] = placeholder;

    useEffect(() => {
        refTopSkip.current = { top: INI_TOP, skip: 0 };
        refTopSkipSearch.current = { top: INI_TOP, skip: 0 }
    }, [])

    const convertStrQueryOdata = (field, valSearch, isScroll) => {
        let query = valSearch ? 'search=' + valSearch : '';
        if (valSearch) {
            const { top, skip } = refTopSkipSearch.current;
            if (!isScroll) {
                refTopSkipSearch.current.skip = 0;
                query += `&$top=${top}&$skip=0&$count=true`;
            } else {
                query += `&$top=${top}&$skip=${skip}&$count=true`;
            }
        } else {
            const { top, skip } = refTopSkip.current;
            query += `&$top=${top}&$skip=${skip}&$count=true`;
        }
        if (field.length > 0) {
            query += field.reduce((strQuery, cur, index) => {
                const syn = (index === field.length - 1) ? '' : ',';
                strQuery += `${cur}${syn}`;
                return strQuery
            }, '&$select=')
        }
        return query;
    }

    const getDataByUrl = useCallback(async (strSearch, isScroll) => {
        if (endPoint) {
            const query = convertStrQueryOdata([fieldLabel, fieldValue], strSearch, isScroll)
            const { value, ...res } = await endPoint(query);

            const saveOption = pre => {
                let optionPre = [...pre];
                if (isScroll) { optionPre = [...optionPre, ...value] }
                else { optionPre = value; }
                return optionPre;
            }
            if (strSearch) {
                refTopSkipSearch.current.count = res['@odata.count'];
                setOptionSearch(saveOption);
            }
            else {
                refTopSkip.current.count = res['@odata.count'];
                setOptions(saveOption);
            }
            return value;
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endPoint, fieldLabel, fieldValue])

    const promiseOptions = useCallback(({ curSearch, preSearch, isScroll }) => {
        if (curSearch) {
            if (curSearch !== '') {
                setSearch(curSearch);
                return new Promise(resolve => {
                    setTimeout(() => resolve(getDataByUrl(curSearch, isScroll)), 1000);
                })
            }
        } else if (curSearch !== '') {
            setSearch(null); setOptionSearch([]);
            refTopSkipSearch.current.count = 0;
            return new Promise(resolve => {
                setTimeout(() => resolve(getDataByUrl(null, isScroll)), 1000);
            })
        } else {
            refTopSkipSearch.current.count = 0;
            return Promise.resolve([]);
        }
    }, [getDataByUrl])

    const { execute, status } = useAsync(promiseOptions, false);

    useEffect(() => { value && setSelected(value); }, [value])

    // eslint-disable-next-line no-unused-vars
    const getDefaultValue = useCallback(async (value) => {
        const options = await promiseOptions();
        const [op] = options.filter(option => option[getOptionValue || 'value'] === value)
        return op ? op : null
    }, [getOptionValue, promiseOptions])

    const handleScrollToBottom = useCallback((search, skip) => {
        const { count } = refTopSkip.current;
        refTopSkip.current.skip += skip;
        if (refTopSkip.current.skip <= count)
            execute({ curSearch: search, preSearch: search, isScroll: true })
    }, [execute])

    const hanldeChange = useCallback((e) => { onChange(e) }, [onChange])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchDebounce = useCallback(_.debounce(execute, 1000), []);

    const hanldeSearch = useCallback((curSearch, preSearch) => {
        searchDebounce({ curSearch, preSearch })
    }, [searchDebounce])
    return (
        <FormGroup>
            <Row className={onAdd && "d-flex justify-content-between"} >
                <Col className="pr-0">
                    <AsyncSelect
                        {...props}

                        onMenuScrollToBottom={() => handleScrollToBottom(search, 10)}
                        options={search ? optionSearch : options}
                        isLoading={status === 'pending'}
                        isDisabled={disabled}
                        defaultOptions
                        value={selected}
                        name={name}
                        onChange={hanldeChange}
                        onInputChange={(str) => hanldeSearch(str, search)}
                        getOptionValue={getOptionValue}
                        getOptionLabel={getOptionLabel}
                        inputId={name}
                        id={id}
                        instanceId={name}
                        onMenuClose={() => { setSearch(null); setOptionSearch([]); }}
                        onMenuOpen={() => options.length === 0 && execute({ curSearch: null, preSearch: null, isScroll: false })}
                        {...selectProps}
                        isClearable={isClearable} />
                </Col>
                {onAdd && <Col md='1' className="pl-0">
                    <ButtonAdd className="h-100" onClick={onAdd}>
                    </ButtonAdd>
                </Col>}
            </Row>
        </FormGroup>

    )
})
export default memo(Index)