// eslint-disable-next-line no-unused-vars
import {useImperativeHandle,forwardRef,useEffect,useMemo} from 'react'
import { Button, Col, Modal, ModalBody, Row } from "reactstrap"
import { FontAwesome } from '../../../../../components/icon'
import { useAsync } from '../../../../../hooks'
import { RECORD_STATUS } from './react-audio-recorder/status'
import { useAudioRecorder } from "./react-audio-recorder/useAudioRecorder"
import { Tooltip } from '@material-ui/core'
import './style.scss'


const RecordAudio = forwardRef(({stateMedia,handleStop},ref) =>{
    const {
      audioResult,
      timer,
      startRecording,
      stopRecording,
      pauseRecording,
      resumeRecording,
      resetAudio,
      status
    } = useAudioRecorder()
  
    useImperativeHandle(ref, () => ({ stopRecording }), [stopRecording]);

    useEffect(()=>{
        stateMedia?.isOpen && startRecording();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[stateMedia?.isOpen])

    const { execute: stopSaveRecord, status:statusSave } = useAsync(handleStop, false);

    useEffect(()=>{
        const dataSave = {audio:audioResult,data:stateMedia?.data}
        audioResult && stopSaveRecord(dataSave)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[audioResult, stateMedia])

    useEffect(()=>{
        statusSave === 'success' && resetAudio()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[statusSave])

    const pending = useMemo(() => {
        return statusSave === 'pending' && <div style={{ display: "inline-block" }}>
                Đang lưu dữ liệu<div className="progress-7"></div>
            </div>
    }, [statusSave])

    return (
      <Modal backdrop='static' size="sm" isOpen={stateMedia ? true : false} zIndex='1500'>
          <ModalBody className="pb-1">
                <Row>
                    <Col md="6">
                        <Button id='recording'
                            style={{ backgroundColor: 'antiquewhite', border: 'none', color: 'black' }}
                            disabled={statusSave === 'pending'}
                            onClick={()=>{stopRecording()}} >
                            <div className='d-flex'>
                                <div className="mic-animation-container">
                                    <div className="animation-outer"></div>
                                    <img style={{ zIndex: 2 }} src={`/assets/img/recording.${status === RECORD_STATUS.RECORDING ? 'gif' : 'png'}`} alt="Thu âm" />
                                </div>
                            </div>
                        </Button>
                        
                    </Col>
                    <Col md="6" className='d-flex flex-column align-items-center justify-content-center'>
                        <p className='time-record'>{new Date(timer * 1000).toISOString().substr(11, 8)}</p>
                        <span className='w-100 d-flex justify-content-center p-1'>
                            <Tooltip title="Tiếp tục">
                                <button className='mr-1 btn-play' 
                                onClick={()=>resumeRecording()}>
                                    <FontAwesome fa="play"></FontAwesome>
                                </button>
                            </Tooltip>
                            <Tooltip title="Tạm dừng">
                                <button className='mr-1 btn-resume' style={{background:'linear-gradient(#7450ff,#9076ff)'}} 
                                onClick={()=>pauseRecording()}>
                                    <FontAwesome fa="stop"></FontAwesome>
                                </button>
                            </Tooltip>
                        </span>
                        <div className="text-blue">{stateMedia?.data?.name || "Cán bộ text"}</div>
                        {pending}
                    </Col>
                    <h5 style={{ color: 'darkgrey' }} className='mb-0'>Click vào micro để kết thúc ghi âm</h5>
                </Row>
          </ModalBody>
      </Modal >
    )
  }) 
export default RecordAudio