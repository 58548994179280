import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const colors = scaleOrdinal(schemeCategory10).range();

const getPath = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} 
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width
        }, ${y + height}
  Z`;
};

const TriangleBar = ({ fill, x, y, width, height }) => {
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const Chart = forwardRef(({ phatBieus }, ref) => {
    const [data, setData] = useState([]);
    const [openDash, setOpenDash] = useState(null);

    useImperativeHandle(ref, () => ({ setOpenDash }));

    const distintData = (phatBieus) => {
        const data = [];
        phatBieus.forEach((pb, i) => {
            const indexExist = data.findIndex(cur => cur.thanhPhanThamDuId === pb.thanhPhanThamDuId);
            if (indexExist > -1) {
                data[indexExist]['soPhatBieu'] += 1
            } else {
                data.push({ ...pb, soPhatBieu: 1 });
            }
        });
        return data;
    }
    useEffect(() => {
        const dataChart = distintData(phatBieus);
        setData(dataChart);
    }, [phatBieus])
    return (
        <Modal isOpen={openDash} toggle={() => setOpenDash(false)} zIndex='1500'>
            <ModalHeader toggle={() => setOpenDash(false)}>Lượt phát biểu</ModalHeader>
            <ModalBody>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false} />
                    <Bar
                        dataKey="soPhatBieu"
                        fill="#8884d8"
                        shape={<TriangleBar />}
                        label={{ position: "top" }}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                        ))}
                    </Bar>
                </BarChart>
            </ModalBody>
        </Modal >

    );
})
export default Chart