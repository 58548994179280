import React, { useState, useEffect, useImperativeHandle, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { CHECK_ERROR } from "../../../../../components/alert/Alert";
import { AnnotationPDF } from "../../../../../components/other";
// import { LoadingSpinner } from "../../../../../components/spinners";
import { TreeView, TreeItem } from "../../../../../components/tree";
import { Main } from "../../../../../service/GateWay";
import { _Enums_TypeUpload } from "../../../../action/defaultEnums";
import { actionReloadFile } from "../../Redux/ActionsMeeting";
import TeamPending from './Pending'

const settingData = (data) => {
  const arrData = [];
  data.forEach((curr, index, arr) => {
    if (!curr.parentId) {
      const element = { ...curr }
      const children = arr.filter(x => x.parentId === curr.id)
      if (children.length !== 0) {
        const dataChild = getNestedChildren(data, curr.id)
        element.children = dataChild;
      }
      arrData.push(element);
    }
  })
  return arrData
}

function getNestedChildren(arr, idParent) {
  var children = [];
  arr.forEach((curr, i) => {
    if (curr.parentId === idParent) {
      var grandChildren = getNestedChildren(arr, curr.id)
      if (grandChildren.length) {
        curr.children = grandChildren;
      }
      children.push(curr);
    }
  })
  return children;
}

/**
 * @author Hồ Văn Tuấn
 * @description Hiển thị cây
 */
const TreeFile = React.forwardRef(({ manipulation, viewDeleted, reloadTable, id, phienHopId, notPopup, onClickPDF }, ref) => {
  // states
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const { isReloadFile } = useSelector(state => state.meetingRProcess);
  const dispatch = useDispatch();

  const queryData = useCallback((id) => {
    setLoading(true);
    manipulation.view(id, true).then((value) => {
      const files = settingData(value);
      onClickPDF && setFiles(files.filter(f => f.children));
      !onClickPDF && setFiles(files);
    }).catch(err => CHECK_ERROR("Không thể load dữ liệu !"))
      .finally(() => {
        dispatch(actionReloadFile(null));
        setLoading(false)
      });
  }, [dispatch, manipulation, onClickPDF])
  // Call API
  useEffect(() => { if (id) queryData(id); }, [id, queryData]);



  // Call API by Ref
  useImperativeHandle(ref, () => ({ queryData }));

  const handleToggle = (event, nodeIds) => {
    event.persist()
    let iconClicked = event.target.closest(".MuiTreeItem-iconContainer")
    if (iconClicked) { setExpanded(nodeIds); }
  };
  const onSelect = useCallback((info) => { onClickPDF && onClickPDF(info); setSelected(info) }, [onClickPDF]);
  const handleReload = useCallback((id) => { queryData(id) }, [queryData]);

  useEffect(() => {
    isReloadFile && queryData(isReloadFile);
  }, [isReloadFile, queryData])

  return (
    <div className="p-2">
      <TeamPending loading={loading}>
        {files && files.length !== 0 && (
          <TreeView
            nameId="id"
            expandAll={true}
            expanded={expanded}
            data={[{ id: "null", children: files }]}
            onNodeToggle={handleToggle}
          >
            <TreeItem nodeId="null" classIcon="text-blue" icon="fa-folder-open" title="Tài liệu phiên họp" path="/">
              {viewNodes("null", files, viewDeleted, onSelect, manipulation, reloadTable)}
            </TreeItem>
          </TreeView>
        )}
      </TeamPending>
      {!notPopup && <Modal size="xl" isOpen={selected ? true : false} toggle={() => setSelected(null)} zIndex='1500'>
        <ModalBody style={{ height: '35rem', overflowY: 'hidden' }}>
          {selected && <AnnotationPDF onReloadFile={handleReload} type={_Enums_TypeUpload.CaNhan} phienHopId={phienHopId} url={Main.getDocUrlByPath(selected.path)} style={{ height: '30.5rem' }} />}</ModalBody>
      </Modal>}
    </div>
  );
}
);

const viewNodes = (keyParent, nodes, viewDeleted, onSelect, manipulation, reloadTable) => {
  return (
    nodes && nodes.map((node, i) => (
      <TreeItem
        icon={node.children ? "fa-file-alt" : "fa-file-pdf"}
        classIcon={node.children ? "text-blue" : "text-red"}
        key={`${i}${node.id}`}
        nodeId={node.id}
        title={node.label}
        data={{
          Parent: keyParent,
          NodeId: node.id,
          title: node.label,
          label: node.label,
          path: node.path
        }}
        onSelection={(e, infos) => infos.Parent !== 'null' && onSelect(infos)}
      >
        {node.children &&
          viewNodes(
            node.id,
            node.children,
            viewDeleted,
            onSelect,
            manipulation,
            reloadTable
          )}
      </TreeItem>
    ))
  );
};

export default TreeFile;
