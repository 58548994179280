
import { Fragment } from 'react'
import { FontAwesome } from '../../../../../../../components/icon'
import './Toolbar.scss'

function Toolbar({ editor, isFullExtension, isAnimate, actions }) {
    return (
        <div className='ToolbarContainer d-flex justify-content-between'>
            <div className="toolbar-note">
                <div className="icon" onClick={() => editor.chain().focus().toggleBold().run()}>
                    <FontAwesome fa="bold"/>
                 </div>
                <div className="icon" onClick={() => editor.chain().focus().toggleItalic().run()}>
                    <FontAwesome fa="italic"/>
                </div>
                <div className="icon" onClick={() => editor.chain().focus().toggleStrike().run()}>
                    <FontAwesome fa="strikethrough"/>
                </div>
                <div className="divider"></div>
                <div
                    className="icon"
                    onClick={() => editor.chain().focus().toggleBulletList().run()}>
                    <FontAwesome fa="list"/>
                </div>
                <div
                    className="icon"
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}>
                    <FontAwesome fa="list-ol"/>
                 </div>
                <div className="divider"></div>
                <div
                    className="icon"
                    onClick={() => editor.chain().focus().toggleBlockquote().run()}>
                    <FontAwesome fa="list-quote-left"/>
                </div>
                <div className="divider"></div>
                <div className="icon" onClick={() => editor.chain().focus().undo().run()}>
                    <FontAwesome fa="long-arrow-alt-left"/>
                </div>
                <div className="icon" onClick={() => editor.chain().focus().redo().run()}>
                    <FontAwesome fa="long-arrow-alt-right"/>
                </div>
                <div className="divider"></div>
                {
                    isFullExtension && <Fragment>
                        <div className="icon" onClick={() => editor.chain().focus().setTextAlign('left').run()}>
                            <FontAwesome fa="align-left"/>
                        </div>
                        <div className="icon" onClick={() => editor.chain().focus().setTextAlign('center').run()}>
                            <FontAwesome fa="align-center"/>
                        </div>
                        <div className="icon" onClick={() => editor.chain().focus().setTextAlign('right').run()}>
                            <FontAwesome fa="align-right"/>
                        </div>
                    </Fragment>
                }
            </div>
            <div className='d-flex'>
                {isAnimate && <div className="ping-animation circle-animation" />}
                {actions && actions.map(({ render, title, onClick, hidden }) => !hidden ? render(title, onClick) : <></>)}
            </div>

        </div>
    )
}

export { Toolbar }
