import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import FontAwesome from "../../icon/FontAwesome";

const ButtonCustom = props => {
  const { children, color, fa, outline, faSolid, ...attribute } = props;
  return (
    <Button outline={outline} color={color || "primary"}  {...attribute}>
      {fa && <FontAwesome
        style={{ marginRight: children ? '0.2rem' : '0px' }}
        faSolid={faSolid}
        fa={fa} />}
      {children}
    </Button>
  );
};

ButtonCustom.propTypes = {
  children: PropTypes.any
};

ButtonCustom.defaultProps = {};

export default ButtonCustom;
