import { Fragment, useCallback, useEffect } from "react";
import './styles.scss';
import './pdfannotate.scss';
import { PDFAnnotate } from "./pdfannotate";
import { useState } from "react";
import { Col } from "reactstrap";
import { SelectSingle } from "../../select";
import { ButtonSave } from "../../base/Button";
import { _Enums_Font_Size } from "../../../views/action/defaultEnums";
import { CHECK_ERROR, CHECK_SUCCESS } from "../../alert/Alert";
import { PhienHopsEndpoint } from "../../../service/Meet";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { actionReloadFile } from "../../../views/don-vi/PhienHop/Redux/ActionsMeeting";
import { FontAwesome } from "../../icon";
// import { CHECK_ERROR } from "../../alert/Alert";
// import { PhienHopsEndpoint } from "../../../service/Meet";

const INI_COLOR = [
	{ bg: 'black', fill: 'rgba(0, 0, 0,0.3)' },
	{ bg: 'red', fill: 'rgba(255, 0, 0,0.3)' },
	{ bg: 'blue', fill: 'rgba(0, 0, 255,0.3)' },
	{ bg: 'green', fill: 'rgba(0, 255, 0,0.3)' },
	{ bg: 'yellow', fill: 'rgba(255, 255, 0,0.4)' }
];
const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
		position: "absolute"
	},
}));

export default function Index({ style, url, type, phienHopId, onReloadFile }) {
	const [pdf, setPdf] = useState(null);
	const [dataSetting, setDataSetting] = useState({ fontSize: 12, brushSize: 20, color: INI_COLOR[0].bg })
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		const urlPdf = url ? url : `${process.env.PUBLIC_URL}/plugins/pdfjs-dist/2.pdf`;
		const pdfAnnotate = new PDFAnnotate("pdf-container", urlPdf, {
			onPageUpdated(page, oldData, newData) { },
			ready() {
				// eslint-disable-next-line no-console
				console.log("Plugin initialized successfully");
			},
			scale: 1.5,
			pageImageCompression: "MEDIUM", // FAST, MEDIUM, SLOW(Helps to control the new PDF file size)
		})
		setPdf(pdfAnnotate)
	}, [url])

	const changeActiveTool = (event) => {
		const a = document.getElementsByClassName("tool-button active")[0];
		a && a.classList.remove("active");
		event.currentTarget.classList.add("active");
	}
	const enablePencil = useCallback((event) => {
		event.preventDefault();
		changeActiveTool(event);
		pdf.enablePencil();
		// pdf.setAlpha(0.3);
	}, [pdf])
	const enableSelector = useCallback((event) => {
		event.preventDefault();
		changeActiveTool(event);
		pdf.enableSelector();
	}, [pdf]);

	const enableAddText = useCallback((event) => {
		event.preventDefault();
		changeActiveTool(event);
		pdf.enableAddText();
	}, [pdf])

	const enableAddArrow = useCallback((event) => {
		event.preventDefault();
		changeActiveTool(event);
		pdf.enableAddArrow();
	}, [pdf])

	const addImage = useCallback((event) => {
		event.preventDefault();
		pdf.addImageToCanvas()
	}, [pdf])

	const enableRectangle = useCallback((event) => {
		event.preventDefault();
		changeActiveTool(event);
		pdf.setColor('rgba(255, 0, 0, 0.3)');
		pdf.setBorderColor('blue');
		pdf.enableRectangle();
	}, [pdf])

	const deleteSelectedObject = useCallback((event) => {
		event.preventDefault();
		pdf.deleteSelectedObject();
	}, [pdf])
	function clearPage() { pdf.clearActivePage(); }
	const showPdfData = useCallback(async () => {
		const string = pdf.serializePdf();
		// eslint-disable-next-line no-console
		console.log(string);
	}, [pdf]);

	const savePDF = useCallback(() => {
		const editNameFile = () => Swal.fire({
			title: 'Nhập tên file',
			input: 'text',
			showCancelButton: true,
			cancelButtonText: 'Hủy',
			confirmButtonText: 'Lưu',
			inputValidator: (value) => !value && 'Vui lòng nhập tên file trước khi lưu'
		})
		setOpen(true);
		const save = (name) => {
			return pdf.savePdf(name).then(file => {
				return PhienHopsEndpoint.UploadFile(phienHopId, { file, type })
			}).then(c => {
				CHECK_SUCCESS('Thành công');
				dispatch(actionReloadFile(phienHopId));
				// onReloadFile && onReloadFile(phienHopId);
			})
				.catch(err => CHECK_ERROR('Thất bại'))
		}
		editNameFile().then(({ isConfirmed, value: name }) => (isConfirmed && name) && save(name))
		setOpen(false);

	}, [dispatch, pdf, phienHopId, type]); // save with given file name
	const INI_TOOLS = [
		{
			className: "tool-button",
			onClick: (e) => enablePencil(e),
			icon: { fa: "pencil-alt", title: "Đánh dấu" }
		},
		{
			className: "tool-button",
			onClick: (e) => enableAddText(e),
			icon: { fa: "font", title: "Thêm ghi chú" }
		},
		{
			className: "tool-button",
			onClick: (e) => enableAddArrow(e),
			icon: { fa: "long-arrow-alt-right", title: "Thêm mũi tên" }
		},
		{
			className: "tool-button",
			onClick: (e) => enableRectangle(e),
			icon: { fa: "square", title: "Thêm vùng màu" }
		},
		{
			className: "tool-button",
			onClick: (e) => addImage(e),
			icon: { fa: "image", title: "Thêm ảnh" }
		}
	]
	const onChangeSetting = useCallback((value, name) => {
		setDataSetting(pre => {
			let data = { ...pre };
			data[name] = value.bg || value;
			switch (name) {
				case 'fontSize':
					pdf.setFontSize(value);
					break;
				case 'brushSize':
					pdf.setBrushSize(value);
					break;
				case 'color':
					pdf.setColor(value.fill);
					break;
				default:
					break;
			}
			return data
		})
	}, [pdf])

	return (
		<Fragment>
			{/* <button id="clickMe" onClick={() => { setOpen(pre => !pre) }}>aaaaa</button> */}
			<div className="toolbar">
				<div className="tool">
					<label htmlFor="">Cỡ bút</label>
					<input type="number" onChange={e => onChangeSetting(e.target.value, e.target.name)}
						className="form-control text-center" value={dataSetting.brushSize} name="brushSize" id="brush-size" max="50" />
				</div>
				<div className="row " style={{ width: '8rem' }}>
					<Col md='6' className="text-white align-self-center p-0">Cỡ phông</Col>
					<Col md='6' className="text-white align-self-center p-0">
						<SelectSingle
							id="font-size"
							valueRef='value'
							labelRef='label'
							placeholder=""
							options={_Enums_Font_Size}
							value={dataSetting.fontSize}
							onChange={e => onChangeSetting(e.value, 'fontSize')}
						/>
					</Col>
				</div>
				<div className="tool">
					{INI_COLOR.map((color, i) => (<button key={'color' + i}
						className={`color-tool ${color.bg === dataSetting.color ? 'active' : ''}`}
						onClick={() => onChangeSetting(color, 'color')}
						style={{ backgroundColor: color.bg }}></button>))}
				</div>
				<div className="align-self-center">
					<div className="tool">
						<button className="tool-button active" onClick={(e) => enableSelector(e)}>
							<i className="far fa-hand-paper" title="Tùy chọn"></i></button>
					</div>
					{INI_TOOLS.map(tool => (<div className="tool">
						<button className={tool.className} onClick={(e) => tool.onClick(e)}>
							<FontAwesome fa={tool.icon.fa} title={tool.icon.title} />
						</button></div>))}
					<div className="tool">
						<button className="btn btn-danger btn-sm" onClick={(e) => deleteSelectedObject(e)} ><i className="fa fa-trash"></i></button>
					</div>
					<div className="tool">
						<button className="btn btn-danger btn-sm" onClick={clearPage} >Xóa ghi chú</button>
					</div>
					<div className="tool">
						<button className="btn btn-info btn-sm" onClick={showPdfData} >Dữ liệu ghi chú</button>
					</div>
					<div className="tool">
						<ButtonSave onClick={savePDF} >Lưu</ButtonSave>
					</div>
				</div>
				<div className="modal fade" id="dataModal" tabIndex="-1" role="dialog" aria-labelledby="dataModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="dataModalLabel">PDF annotation data</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<pre className="prettyprint lang-json linenums"></pre>
							</div>
						</div>
					</div>
				</div>
			</div >
			<div id="pdf-container" className="position-relative" style={style}>
				<Backdrop className={classes.backdrop} open={open}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</div>
		</Fragment >
	)
}
