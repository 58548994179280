import { Service } from '../Service';
// import { PUBLIC_API_URL, ADMIN_API_URL } from '../../actions/constants';

class Categories extends Service {
    constructor(path) {
        // super('/stpbd-rest-api-category', path);
        super('/Categories', path);
    }
    // Tìm kiếm
    // getAll = (param) => this.SearchAll(PUBLIC_API_URL + "/category", param, null, true);
    getAll = (param) => this.SearchAllOdata("", param, null, true);
    // Thêm
    add = (data) => this.Insert("", data);
    // Sửa
    edit = (data) => this.Update("", data);
    // Xóa
    delete = (id) => this.Delete("", id);
}

export { Categories };