import { Service } from '../Service';
// import { PUBLIC_API_URL, ADMIN_API_URL } from '../../actions/constants';

class GDTCores extends Service {
    constructor(path) {
        super('/GDTCores', path);
    }
    // Tìm kiếm
    // getAll = (param) => this.SearchAll(PUBLIC_API_URL + "/category", param, null, true);
    getAll = (param) => this.SearchAllOdata(param, null, null, true);
    // Thêm
    add = (data) => this.InsertODATA(data);
    // Sửa
    edit = (Id, data) => this.UpdateODATA(`(${Id})`, data);
    // Xóa
    delete = (id) => this.DeleteADMINODATA(id);

    Upload = (url, file) => this.UploadAuth(url, file)

    addWithBatch = (data) => this.InsertBatch(``, data)

    update(ID, body) {
        return this.edit(ID, body);
    }
}

export { GDTCores };