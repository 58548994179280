import {
  PUBLIC_API_URL,
  // ALLOWS_API_URL,
  ADMIN_API_URL,
  // PARTNER_API_URL,
  ODATA,
  // CRUD
  // _URL_ADD,
  _API_URL,
  // _URL_EDIT,
  // _URL_DELETE,
  UPLOAD_AUTH_API_URL,
  // UPLOAD_ALLOW_API_URL,
  UPLOAD_API_URL,
  AUTH_API_URL,
  ALL_API_URL,
  ALLOWS_API_URL,
} from "../actions/constants";
import {
  GetMappingAuthorization,
  PostMappingAuthorization,
  PutMappingAuthorization,
  DeleteMappingAuthorization,
  PatchMappingAuthorization,
  BatchAuthorization
} from "../actions/callApi";
import Cookies from "js-cookie";
import { parseJwt } from "../actions/util";
import { closeLoading, openLoading } from "../actions/loading";

/**
 * @author Hồ Văn Tuấn
  */
class Service {
  constructor(prefix, path) {
    this.prefix = prefix;
    this.path = path;
  }
  /**
 * Kiểm tra hạn token
 * Nếu hết hạn thì refresh lấy token mới lưu lại vào cookie
 */
  checkDurationToken = async () => {
    const token = Cookies.get('GDTECABINET');
    if (token) {
      var decoded = parseJwt(token);
      var n = Date.now();
      if (n > decoded.exp) {
        const newToken = await this.GetToken(`/Account/Login/RefreshToken`);
        Cookies.set('GDTECABINET', newToken);
      }
    }
    return token;
  }
  // ================================[REQUEST]================================
  GetMapping(url) {
    return this.PostAuthenIniNotCheckToken(url);
  }
  PostAuthenIniNotCheckToken(url, data, conf) {
    return PostMappingAuthorization(`${url}`, JSON.stringify(data), conf, true);
  }
  GetAuthenIni(type, url, conf) {
    return GetMappingAuthorization(`${this.prefix}${type}${url}`, conf);
  }

  PostAuthenIni(type, url, data, conf) {
    // await this.checkDurationToken().then(res => {
    //   console.log(res)
    // });
    return PostMappingAuthorization(`${this.prefix}${type}${url}`, JSON.stringify(data), conf);
  }
  PatchAuthenIni(type, url, data, conf) {
    // this.checkDurationToken();
    return PatchMappingAuthorization(`${this.prefix}${type}${url}`, JSON.stringify(data), conf)
  }
  PutAuthenIni(url, data, conf) {
    // this.checkDurationToken();
    return PutMappingAuthorization(`${this.prefix}${url}`, JSON.stringify(data), conf)
  }
  DeleteAuthenIni(type, url, conf, isFile, data) {
    if (isFile) return DeleteMappingAuthorization(`${this.prefix}${type}${url}`, conf, JSON.stringify(data))
    return DeleteMappingAuthorization(`${this.prefix}${type}${url}`, conf)
  }

  BatchIni(type, url, data) {
    return BatchAuthorization(`${this.prefix}${type}`, data, url);
  }
  // ================================[UPLOAD]================================
  UploadIni(type, url, file, config) {
    const { notLoading, isAgent } = config || {};
    !notLoading && openLoading();
    const formData = new FormData();
    // formData.append("temp", temp);
    // formData.append("allowed", allowed);
    Object.keys(file).forEach(f => {
      formData.append(f, file[f]);
    })

    const headers = {};
    headers["Accept"] = "application/json";
    headers["X-GDT-Dev-Token"] = process.env.REACT_APP_DEV_TOKEN
    let token = '';
    if (!isAgent) {
      token = Cookies.get('GDTECABINET');
    }
    if (token) headers["Authorization"] = `Bearer ${token}`;
    const host = `${_API_URL}${this.prefix}${type}${url}`
    // const encodedUrl = url.replace(/(?<=search=)[^&]+/g, m => encodeURIComponent(m));
    return fetch(host, {
      method: "POST",
      credentials: "same-origin", // <-- includes cookies in the request
      headers: headers,
      body: formData
    }).then(res => {
      !notLoading && closeLoading();
      if (res.ok) {
        if (isAgent) return res;
        return res.json();
      }
      else throw res;
    });
  }

  UploadPublic = (temp, allowed, file) => this.UploadIni(UPLOAD_API_URL, `${this.path}/upload`, temp, allowed, file);
  Upload = (temp, allowed, file) => this.UploadIni('', `${this.path}/upload`, temp, allowed, file);
  UploadAuth = (url, file, config) => this.UploadIni(UPLOAD_AUTH_API_URL, `${this.path}${url}`, file, config);
  UploadAllow = (url, file, config) => this.UploadIni(ALLOWS_API_URL, `${this.path}${url}`, file, config);
  UploadAuthImg = (url, file) => this.UploadIni(UPLOAD_AUTH_API_URL, `${this.path}${url}`, file);
  UploadODataSdoc = (url, temp, allowed, file, uuid) => this.UploadIni(ODATA, `${this.path}${url}`, temp, allowed, file, uuid);
  UploadPartner = (url, file, config) => this.UploadIni(PUBLIC_API_URL, `${url}`, file, config);
  // ================================[REQUEST]================================

  // =========== [GET] ===========
  GetToken = (url) => this.GetMapping(`${PUBLIC_API_URL}${url}`);

  GetAuthenPUBLIC = (url, conf) => this.GetAuthenIni(PUBLIC_API_URL, `${this.path}${url}`, conf);
  GetAuthen = (url, conf) => this.GetAuthenIni(AUTH_API_URL, `${this.path}${url}`, conf);
  GetAuthenADMIN = (url, conf) => this.GetAuthenIni(ADMIN_API_URL, `${this.path}${url}`, conf);
  GetAuthenADMINODATA = (url, conf) => this.GetAuthenIni(ODATA, `${this.path}${url}`, conf);
  GetAllow = (url, conf) => this.GetAuthenIni(ALLOWS_API_URL, `${this.path}${url}`, conf);
  GetPartner = (url, conf) => this.GetAuthenIni('', `${url}`, conf);


  // GetAuthenPARTNER = (url, conf, odata) => this.GetAuthen(`${PARTNER_API_URL}${this.path}${url}`, conf, odata);
  // =========== [POST] ===========
  PostAuthenPUBLIC = (url, data, conf) => this.PostAuthenIni(PUBLIC_API_URL, `${this.path}${url}`, data, conf);
  PostAuthen = (url, data, conf) => this.PostAuthenIni(AUTH_API_URL, `${this.path}${url}`, data, conf);
  PostAuthenADMIN = (url, data, conf) => this.PostAuthenIni(ADMIN_API_URL, `${this.path}${url}`, data, conf);
  PostALLow = (url, data, conf) => this.PostAuthenIni(ALLOWS_API_URL, `${this.path}${url}`, data, conf);
  PostAuthenADMINODATA = (url, data, conf) => this.PostAuthenIni(ODATA, `${this.path}${url}`, data, conf);
  PostPartner = (url, data, conf) => this.PostAuthenIni('', `${url}`, data, conf);
  PostGoogle = (url, data, conf) => this.PostAuthenIni('', `${url}`, data, conf);

  // PostAuthenPARTNER = (url, data, conf) => this.PostAuthen(`${PARTNER_API_URL}${this.path}${url}`, data, conf);
  // =========== [PUT] ===========
  PutAuthenPUBLIC = (url, data, conf) => this.PutAuthenIni(`${PUBLIC_API_URL}${this.path}${url}`, data, conf);
  PutAuthen = (url, data, conf) => this.PutAuthenIni(`${this.path}${url}`, data, conf);
  PutAuthenADMIN = (url, data, conf) => this.PutAuthenIni(`${ADMIN_API_URL}${this.path}${url}`, data, conf);
  // PutAuthenPARTNER = (url, data, conf) => this.PutAuthen(`${PARTNER_API_URL}${this.path}${url}`, data, conf);
  // =========== [PATCH] ===========
  PatchAuthenPUBLIC = (url, data, conf) => this.PatchAuthenIni(`${PUBLIC_API_URL}${this.path}${url}`, data, conf);
  PatchAuthen = (url, data, conf) => this.PatchAuthenIni(AUTH_API_URL, `${this.path}${url}`, data, conf);
  PatchAllow = (url, data, conf) => this.PatchAuthenIni(ALLOWS_API_URL, `${this.path}${url}`, data, conf);
  PatchAuthenADMIN = (url, data, conf) => this.PatchAuthenIni(ADMIN_API_URL, `${this.path}${url}`, data, conf);
  // PutAuthenPARTNER = (url, data, conf) => this.PutAuthen(`${PARTNER_API_URL}${this.path}${url}`, data, conf);
  // =========== [DELETE] ===========
  DeleteAuthenPUBLIC = (url, conf) => this.DeleteAuthenIni(`${PUBLIC_API_URL}${this.path}${url}`, conf);
  DeleteAuthen = (url, conf) => this.DeleteAuthenIni(`${this.path}${url}`, conf);
  DeleteAuthenADMIN = (url, conf) => this.DeleteAuthenIni(`${ADMIN_API_URL}${this.path}${url}`, conf);
  // DeleteAuthenPARTNER = (url, conf) => this.DeleteAuthen(`${PARTNER_API_URL}${this.path}${url}`, conf);

  // ================================[ADD - EDIT - DELETE]================================
  // =========== [ADD] ===========
  Insert = (pre, data) => this.PostAuthenIni(pre, `${this.path}`, data);
  InsertPUBLIC = (data) => this.Insert(`${PUBLIC_API_URL}`, data);
  InsertAUTH = (data) => this.Insert(`${AUTH_API_URL}`, data);
  InsertAllow = (data) => this.Insert(`${ALLOWS_API_URL}`, data);
  InsertODATA = (data) => this.Insert(ODATA, data);
  InsertBatchAUTH = (url, data) => this.BatchIni(AUTH_API_URL, url, data);

  // InsertPARTNER = (data, conf) => this.Insert(`${ PARTNER_API_URL }`, data, conf);
  // =========== [EDIT] ===========
  Update = (pre, data, Urlcondition, conf) => this.PatchAuthenIni(pre, `${this.path}${Urlcondition ? Urlcondition : ''}`, data, conf);
  UpdatePUBLIC = (Urlcondition, data, conf) => this.Update(`${PUBLIC_API_URL}`, data, Urlcondition, conf);
  UpdateAUTH = (Urlcondition, data, conf) => this.Update(`${AUTH_API_URL}`, data, Urlcondition, conf);
  UpdateODATA = (Urlcondition, data, conf) => this.Update(ODATA, data, Urlcondition, conf);
  // UpdatePARTNER = (data, conf) => this.Update(`${ PARTNER_API_URL }`, data, conf);
  // =========== [DELETE] ===========
  Delete = (pre, where, conf, isFile, data) => this.DeleteAuthenIni(pre, `${this.path}${where}`, conf, isFile, data);
  DeletePUBLIC = (id, conf) => this.Delete(`${PUBLIC_API_URL}`, id, conf);
  DeleteAUTH = (id, conf) => this.Delete(`${AUTH_API_URL}`, id, conf);
  DeleteAUTHPATH = (pre, data) => this.Delete(`${AUTH_API_URL}`, pre, null, true, data);
  DeleteAuthenALL = (id, conf) => this.Delete(`${ALL_API_URL}`, id, conf);
  DeleteAllow = (id, conf) => this.Delete(`${ALLOWS_API_URL}`, id, conf);
  DeleteADMINODATA = (where, conf) => this.Delete(ODATA, where, conf, true);

  // ================================[OTHER - REQUEST - BACKEND]================================

  // =========== [SEARCH] ===========
  SearchAll = (type, param, conf, odata) => this.GetAuthenIni(type, `${this.path}${param || ""}`, conf)
  SearchAllPUBLIC = (param, conf, odata) => this.SearchAll(PUBLIC_API_URL, param, conf, odata);
  SearchAUTH = (param, conf, odata) => this.SearchAll(AUTH_API_URL, param, conf, odata);
  SearchAllow = (param, conf) => this.SearchAll(ALLOWS_API_URL, param, conf, true);
  // SearchAllPARTNER = (param, conf) => this.SearchAll(`${ PARTNER_API_URL } `, param, conf);

  // =========== [FIND BY ID] ===========
  // Public
  FindById = (pre, id, params, conf) => this.GetAuthenIni(`${pre} ${this.path} (${id}) ${params || ""} `, conf);
  FindByIdPUBLIC = (id, params, conf) => this.FindById(`${PUBLIC_API_URL} `, id, params, conf);
  FindByIdADMIN = (id, params, conf) => this.FindById(`${ADMIN_API_URL} `, id, params, conf);
  // FindByIdPARTNER = (id, select, conf) => this.FindById(`${ PARTNER_API_URL } `, id, select, conf);

}

export { Service };
