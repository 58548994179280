import React from 'react';
import styled from 'styled-components';

export default function Checkbox(props) {
  return (
    <Styled
      onClick={() => !props.disabled && props.onChange(!props.checked)}
    >
      <input
        type="checkbox"
        checked={props.checked}
        disabled={props.disabled}
        onChange={() => { }}
      />
      <label>{props.label}</label>
    </Styled>
  );
}

const Styled = styled.div`
  display: inline-block;
  margin-right:0.5rem;
  > input {
    display: none
  }
  > input + label {
    position: relative; 
    padding-left: 25px; 
    // cursor: pointer;    
    &:before {
      content: '';
      position: absolute;
      left:0; top: 0px;
      width: 17px; height: 17px; 
      border: 1px solid #aaa;
      background: #f8f8f8;
      border-radius: 3px; 
      box-shadow: inset 0 1px 3px rgba(0,0,0,.3)
    }
    &:after {
      // 🆗✅☑️🗹
      content: '☑️'; 
      position: absolute;
      top: -4px; left: -3px;
      font-size: 16px;
      color: red;
      // transition: all .1s; 
    }
  }
  &:hover::before {
    background: green
  }
  > input:not(:checked) + label {
      &:after {
        opacity: 0; 
        transform: scale(0);
      }
  }
  > input:disabled:not(:checked) + label {
      &:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
  }
  > input:checked + label {
    &:after {
      opacity: 1;
      transform: scale(1); 
    }
    &:before {
      opacity: 0
    }
  }
  > input:disabled:checked + label {
    &:after {
      color: #999;
    }
  }
  > input:disabled + label {
    color: #aaa;
  }
  > input:checked:focus + label, input:not(:checked):focus + label {
    &:before {
      border: 1px dotted blue;
    }
  }
`;