import React, { Component } from 'react';
import _propTypes from 'prop-types';
import InputText from './InputText';
import ReactMonthPicker from '../../other/Picker/month-picker';
import '../../other/Picker/month-picker.css';
const pickerLang = {
    months: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12']
}

class MonthPicker extends Component {
    constructor(props) {
        super(props);
        this.pickerRef = React.createRef();
        this.state = {
            value: null,
            years: 5
        }
    }
    componentDidMount() {
        this.setState({ years: { min: this.props.minYear, max: this.props.maxYear } });
        if (typeof this.props.value === 'string') {
            let val = this.props.value.split('/'),
                month = parseInt(val[0]),
                year = parseInt(val[1]);
            month && year && this.setState({ textValue: this.props.value, value: { month, year } })
        } else if (typeof this.props.value === 'object') {
            this.setState({ value: this.props.value })
        }
    }
    pickHandler = (year, month) => {
        let value = year && month ? { year, month } : null;
        this.setState({ value }, () => {
            this.pickerRef.current && this.pickerRef.current.dismiss();
            this.onChange();
        });
    }
    onChange = () => {
        if (this.props.onChange) {
            this.props.valueType === 'string' && this.props.onChange(this.makeText);
            this.props.valueType === 'object' && this.props.onChange(this.state.value);
        }
    }
    monthMask = rawValue => {
        let mNo = rawValue.charAt(0);
        if (parseInt(mNo) > 1) return [/[2-9]/, '/', /[1-2]/, /\d/, /\d/, /\d/]
        if (parseInt(mNo) === 1) return ['1', /[0-2]/, '/', /[1-2]/, /\d/, /\d/, /\d/]
        return ['0', /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/]
    }
    makeText = () => {
        const { value } = this.state;
        return value ? `${value.month.toString().padStart(2, '0')}/${value.year.toString().padStart(4, '0')}` : ''
    }
    render() {
        return (
            <ReactMonthPicker ref={this.pickerRef} lang={pickerLang} years={this.state.years} value={this.state.value} onChange={this.pickHandler}>
                <InputText value={this.makeText()} onFocus={() => this.pickerRef.current.show()} readOnly />
            </ReactMonthPicker>
        )
    }
    validYear = year => {
        if (typeof this.state.years === 'object')
            return (year >= (this.state.years.min || new Date().getFullYear() - 5) && year <= (this.state.years.max || new Date().getFullYear()));
        if (Array.isArray(this.state.years)) return this.state.years.find(x => x === year) > -1;
        return new Date().getFullYear() - year >= this.state.years;
    }
}
MonthPicker.propTypes = {
    name: _propTypes.string,
    minYear: _propTypes.number,
    maxYear: _propTypes.number,
    value: _propTypes.oneOfType([_propTypes.string, _propTypes.object]),
    onChange: _propTypes.func,
    valueType: _propTypes.oneOf(['string', 'object'])
}
MonthPicker.defaultProps = {
    name: null,
    value: { month: new Date().getMonth() + 1, year: new Date().getFullYear() },
    minYear: 2000,
    maxYear: 2030,
    valueType: 'string'
}

export default MonthPicker;