import { Meet } from "./Meet";
class ChucDanhsEndpoint extends Meet {
  constructor() {
    super("/ChucDanhs");
  }

  getAllChucDanh = (IdChucDanh) => this.GetAllow(`(${IdChucDanh})`)
  addChucDanh = (body) => this.InsertAUTH(body);
  deleteChucDanh = (id) => this.DeleteAUTH(`(${id})`);
  editChucDanh = (IdChucDanh, body) => this.UpdateAUTH(`(${IdChucDanh})`, body);

}
export default new ChucDanhsEndpoint()