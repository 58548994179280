import { TOKEN_CLIENT_INFO } from "../../actions/constants";
import { Meet } from "./Meet";


class PhieuYKienEndpoint extends Meet {
    constructor() {
        super("/PhieuLayYKiens");
    }
    getIsComplete = (IdPhieuYKien) => this.GetAuthen(`(${IdPhieuYKien})`);
    getAllPhieuYKienById = (IdPhieuYKien) => this.GetAuthen(`(${IdPhieuYKien})?$expand=boSungVanDes($expand=ketQuaYKiens($filter=nhanSuId eq ${TOKEN_CLIENT_INFO.nhanSuId}),phuongAnBoSungs($orderby=thuTu asc)),nhanSu($expand=thongTinCaNhan($select=ten,hoVaTenDem))`)
    getAllPhieuYKien = (param) => this.SearchAUTH(`?$expand=boSungVanDes($expand=phuongAnBoSungs($select=thuTu,noiDung))`, param)
    addPhieuYKien(data) { return this.PostAuthen('/ThemPhieuYKien', data) }
    updateThamGiaYKien(data) { return this.PostAuthen('/ThamGiaYKien', data) }
    editPhieuYKien = (IdPhieuYKien, body) => this.UpdateAUTH(`(${IdPhieuYKien})`, body);
    deletePhieuYKien = (id) => this.DeleteAUTH(`(${id})`);
    /////
    getNhanSuById = (id) => this.GetAuthen(`(${id})?$expand=ketQuaYKiens($expand=nhanSu)&$select=id`)

    getAllPhieu = (Id, expand = []) => this.GetAuthen(`(${Id})?$expand=${expand}`)

    getDanhSachThamGia = (key) => this.GetAuthen(`/DanhSachThamGiaYKien?key=${key}`)

    UploadFile(key, data) {
        return this.UploadAuth('/Upload', { ...data, key: key }, null)
    }

    getAllFile = (id) => {
        return this.GetAuthen(`/GetAttachments?key=${id}`)
    }

    chonPhuongAn = (data) => this.PostAuthen('/chonPhuongAn', data);

    getPhieuYKienByPram = (param) => this.SearchAUTH(param);
    getPhieuByIdAndParam = (id, param) => this.GetAuthen(`(${id})?${param}`);

    reportPDF = (id, isMau) => {
        const mau = isMau ? `&isMau=true` : '';
        return this.GetAllow(`/PDFKetQuaPhieuYKien?key=${id + mau}`, { responseType: 'blob' });
        // return _API_URL + `/meet${ALLOWS_API_URL}/BoSungVanDes/PDFBieuQuyet?${khungNoiDung}${boSungVanDe}`
    }
}
export default new PhieuYKienEndpoint()