import React, { useCallback, useEffect, useState } from 'react';
import Select from './Select';
import { callApi2 } from '../../service/api';

const styles = {
    multiValueLabel: styles => ({ ...styles, padding: '0px 0px 0px 6px', fontSize: 13 }),
    control: styles => ({
        ...styles,
        backgroundColor: 'white',
        height: '28.38px',
        minHeight: '28.38px',
        fontSize: '12px',
        lineHeight: '1.5',
        cursor: 'pointer',
        borderRadius: '4px'
    }),
    option: (styles) => ({ ...styles, fontSize: '12px', cursor: 'pointer' }),
    input: styles => ({ ...styles, position: 'unset' }),
    placeholder: styles => ({ ...styles, marginLeft: 5, marginRight: 5 }),
    singleValue: (styles, { data }) => ({ ...styles, transform: 'inherit', top: 'unset' }),
    container: styles => ({ ...styles, width: '100%' }),
    valueContainer: styles => ({ ...styles, padding: '0px 5px' }),
    indicatorsContainer: styles => ({ ...styles, padding: '0px 5px' }),
    indicatorContainer: styles => ({ ...styles, padding: '0px 5px !important', fontSize: '12px' }),
};

function DropdownListForm2({ options, ...props }) {
    const [data, setData] = useState([]);
    const [valueSnap, setValueSnap] = useState(null);
    const [selected, setSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getOptionsMulti = useCallback((value) => {
        let selected = [];
        if (data && data.length > 0 && value) {
            for (let i = 0; i < data.length; i++) {
                let current = data[i];
                for (let j = 0; j < value.length; j++) {
                    if (current.value && current.value === value[j]) {
                        selected.push(current);
                    }
                }
            }
        }
        return selected;
    }, [data])

    const getOptionsSingle = useCallback((value) => {
        let selected = null;
        if (data && data.length > 0 && value) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].value && data[i].value === value) {
                    selected = data[i];
                    break;
                } else if (data[i].options) {
                    for (let j = 0; j < data[i].options.length; j++) {
                        if (data[i].options[j].value && data[i].options[j].value === value) {
                            selected = data[i].options[j];
                            break;
                        }
                    }
                }
            }
        }
        return selected;
    }, [data])

    const setStateSelected = useCallback((value) => {
        if (props.isMulti) {
            setSelected(getOptionsMulti(value));
        } else {
            setSelected(getOptionsSingle(value));
        }
    }, [getOptionsMulti, getOptionsSingle, props.isMulti])

    const loadDataFromApi = useCallback(() => {
        let { columns, odata, url, where, valueField, textField } = props;
        let data = [];
        let _url = "";
        if (odata !== false) {
            _url = `${url}`;

            if (where) {
                _url += `/?$filter=${where}&`;
            } else {
                _url += `/?`;
            }

            if (columns && columns.length > 0) {
                _url += `$select=${props.columns.join()}`;
            }
        } else {
            _url = props.url;
        }

        callApi2(_url, "GET", null, odata)
            .then((res) => {
                if (odata !== false) {
                    res.value.forEach(x => {
                        data.push({
                            value: x[valueField],
                            label: x[textField],
                            obj: x
                        })
                    });
                } else {
                    data = res;
                }
                setData(data); setIsLoading(false);
                setStateSelected(valueSnap);
            })
            .catch((error) => { })
    }, [props, setStateSelected, valueSnap])

    // eslint-disable-next-line no-unused-vars
    const getSnapshotBeforeUpdate = useCallback((prevProps, prevState) => {
        if (prevProps.value !== props.value) {
            setValueSnap(props.value)
            setStateSelected(props.value);
            return props.value || null;
        } else
            return null;
    }, [props.value, setStateSelected])

    useEffect(() => {
        setIsLoading(true)
        let { options, valueField, textField } = props;
        if (options) {
            let data = options.map(x => ({
                value: x[valueField],
                label: x[textField],
                obj: x
            })
            );
            setData(data); setIsLoading(false);
            setStateSelected(valueSnap);
        } else {
            loadDataFromApi();
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsLoading(true);
        setData(options);
        // if (props.value) {
        //     let isSelected = isMulti ? getOptionsMulti(value) : getOptionsSingle(value);
        //     setSelected(isSelected)
        // }
        setIsLoading(false);
    }, [options])

    useEffect(() => {
        if (props.where) loadDataFromApi();
    }, [loadDataFromApi, props.where])

    const onChange = useCallback((e, meta) => {
        setSelected(e);
        if (props.isMulti) {
            var list = [];
            for (let i = 0; i < e.length; i++) {
                list.push(e[i].value);
            }
            props.onChange(list, null);
        } else {
            if (props.getFullValue) {
                props.onChange(e ? e : null, e ? e.obj : null);
            } else {
                props.onChange(e ? e.value : null, e ? e.obj : null);
            }
        }
    }, [props])

    let { placeholder, isMulti, isDetail, value, disabled } = props;
    let isSelected = isMulti ? getOptionsMulti(value) : getOptionsSingle(value);
    return (
        data.length > 0 && isDetail ?
            <span>{selected && selected.label}</span>
            :
            <Select
                isMulti={isMulti}
                isLoading={isLoading}
                isDisabled={disabled}
                isClearable={true}
                className="dropdownlist"
                value={isSelected}
                styles={styles}
                options={data}
                onChange={onChange}
                placeholder={placeholder}
            />
    )
}

export default DropdownListForm2
