import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Rounded = styled.div`
    border-radius: 0.25rem !important;
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
`;

const Toast = styled.div`
    display: block;
    opacity: 1;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
`;

const ToastHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #fff;
    background-color: #006fca;
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    strong {
        margin-right: auto !important;
        font-weight: bolder;
    }
`;

const ToastBody = styled.div`
    display: block;
    padding: 0.75rem;
    overflow: hidden;
`;

const ToastDetail = (props) => {
  const { renderTitle, title, variant, children } = props;
  const viewHeader = renderTitle ? renderTitle : title;
  return (
    <Rounded>
      <Toast>
        <ToastHeader>
          {variant === 'h3' && <h3><strong>{viewHeader}</strong></h3>}
          {variant === 'h4' && <h4><strong>{viewHeader}</strong></h4>}
          {variant === 'h5' && <h5><strong>{viewHeader}</strong></h5>}
          {variant === 'h6' && <strong>{viewHeader}</strong>}
        </ToastHeader>
        <ToastBody>{children}</ToastBody>
      </Toast>
    </Rounded>
  );
};

ToastDetail.propTypes = {
  title: PropTypes.string.isRequired
};

ToastDetail.defaultProps = {
  title: 'Chi tiết',
  variant: 'h6'
};

export default ToastDetail;
