import { Meet } from "./Meet";

class NhomThanhViensEndpoint extends Meet {
    constructor() {
        super("/NhomThanhViens");
    }
    seachFullField(value) {
        return value ? this.SearchAllow(`?$filter=name le '${value}' and code ne 'KM'`) : this.SearchAllow(`?$filter=code ne 'KM'`);
    }
    getAllNhomThanhVien = (IdNhomThanhVien) => this.GetAllow(`(${IdNhomThanhVien})`)
    addNhomThanhVien = (body) => this.InsertAUTH(body);
    deleteNhomThanhVien = (id) => this.DeleteAUTH(`(${id})`);
    editNhomThanhVien = (IdNhomThanhVien, body) => this.UpdateAUTH(`(${IdNhomThanhVien})`, body);
}
export default new NhomThanhViensEndpoint();