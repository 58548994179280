import { Meet } from "./Meet";

class SoDoesEndpoint extends Meet {
    constructor() {
        super("/SoDoes");
    }
    addSoDo(data) { return this.PostAuthen(`/ThemSoDo`, data) }
    addGhe(data) { return this.PostAuthen(`/ThemGhe`, data, { noLoading: true }) }
    getDataWithIdPhong(id) {
        return this.GetAllow(`?$filter=phongHopId eq ${id}&$expand=soDoGhe($expand=ghe) `)
    }
    getDataWithId(id) {
        return this.GetAllow(`(${id})?$select=id,pathPic,name,horizontalGuest,horizontalMain,verticalGuest,verticalMain,sizeRoom,typeTable 
        &$expand=soDoGhes($expand=ghe)`)
    }
    deleteSoDo = (id) => this.DeleteAUTH(`(${id})`)
}
export default new SoDoesEndpoint();