import Cookies from "js-cookie";
import { forwardRef, memo, useCallback, useEffect, useRef, useState } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { TOKEN_CLIENT_PARTNER, _PARTNER } from "../../../../../actions/constants";
import { randomCode } from "../../../../../actions/util";
import { CHECK_WARNING } from "../../../../../components/alert/Alert";
import { ButtonCustom, ButtonIcon } from "../../../../../components/base/Button";
import useSpeechToText, { Action } from "../../../../../hooks/useSpeechToText";
import { ConfigMeetsEndpoint, KetLuansEndpoint, PhienHopsEndpoint } from "../../../../../service/Meet";
import { AgentEndpoint } from "../../../../../service/SpeechToText";
import { _Enums_Template } from "../../../../action/defaultEnums";
import Tiptap from "../Note/Tiptap/Component/TipTap";
import Tooltip from '@material-ui/core/Tooltip';
import Jordit from '../../../../../components/other/Jodit';

const index = forwardRef(({ isOpen, onClose, nameAgent, phienHopId }, ref) => {
    const [dataTemplate, setDataTemplate] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const { stopConnect, startConnect, data, status } = useSpeechToText(phienHopId, Action.SPEECH_TO_TEXT);
    const refIdKetLuan = useRef(null);
    const refContent = useRef()

    const prepareTemplate = useCallback(async () => {
        const { ketLuans } = await PhienHopsEndpoint.getTemplateKetLuan(phienHopId);
        if (ketLuans.length > 0) {
            setDataTemplate(ketLuans[0].htmlData);
            refIdKetLuan.current = ketLuans[0].id;
            return;
        }
        const { value } = await ConfigMeetsEndpoint.getTemplateByCode(_Enums_Template.Conclusion);
        const [content] = value;
        setDataTemplate(content?.name || '');
    }, [phienHopId])

    useEffect(() => {
        if (data) { refNote.current && refNote.current.insertData(` ` + data); }
    }, [data])

    const refNote = useRef();

    // eslint-disable-next-line no-unused-vars
    const checkAccessSpeechToText = async () => {
        if (!TOKEN_CLIENT_PARTNER) {
            try {
                const res = await AgentEndpoint.login({ email: 'toan2@aimed.edu.vn', password: 'toan2@2022' })
                const { access_token, refresh_token, expire_after, expire_at, user_id } = res;
                if (access_token && refresh_token) {
                    Cookies.set('PARTNER_ACCESS_TOKEN', access_token);
                    Cookies.set('PARTNER_REFRESH_TOKEN', refresh_token);
                }
                localStorage.setItem(_PARTNER, JSON.stringify({ expire_after, expire_at, agent_id: user_id }));
                return true;
            } catch (error) { return false }
        } else { return true }
    }

    const getAgentAndSetMic = useCallback(async () => {
        try {
            // const access = await checkAccessSpeechToText();
            // if (access) {
            //     const dataChannel = await PhienHopsEndpoint.SearchAllow(`(${phienHopId})?$select=channelStatus,channelId,agentId`)
            //     const { data } = await AgentEndpoint.getAgents();
            //     const curAgent = data.find(cur => cur.name === nameAgent)
            //     startConnect(Action.SPEECH_TO_TEXT, curAgent?.agent_id, dataChannel);
            // }
        } catch (error) { }
    }, [])

    useEffect(() => {
        if (isOpen) { prepareTemplate(); }
        return () => { setDataTemplate(null) }
    }, [prepareTemplate, isOpen])

    const handleSaveAndExportPDF = useCallback(async () => {
        try {
            const html = refContent.current.getValue() || refNote.current.getValue();
            if (refIdKetLuan.current) { await KetLuansEndpoint.editKetLuan(refIdKetLuan.current, { htmlData: html, phienHopId }) }
            else { await KetLuansEndpoint.addKetLuan({ htmlData: html, phienHopId }) }
            /** @type {Blob} */
            const file = await PhienHopsEndpoint.exportPDFByHtml(html);
            file.lastModifiedDate = new Date();
            const name = randomCode() + '.pdf';
            await PhienHopsEndpoint.UploadFile(phienHopId, { Type: 'KetLuan', file: new File([file], name) })
            stopConnect()
            onClose();
        } catch (error) {
            CHECK_WARNING('Thao tác không thành công')
        }
    }, [onClose, phienHopId, stopConnect])

    return <Modal size="xl" isOpen={isOpen}>
        <ModalHeader toggle={onClose} >
            <div className="d-flex">
                <img src="/assets/icon/other/speechtotext.svg" alt='icon-table' />
                <div>Chuyển đổi giọng nói thành văn bản</div>
            </div>
        </ModalHeader>
        <ModalBody style={{ padding: '1rem' }}>
            <div className="speech-text-container" style={{ height: '25rem' }}>
                {dataTemplate ? <Jordit
                    ref={refContent}
                    isOpen={dataTemplate || ''}
                /> : <Tiptap
                    withPlaceholderExtension={true}
                    ref={refNote}
                    content={dataTemplate}
                    withToolbar={true}
                    withTaskListExtension={true}
                    withLinkExtension={true}
                    isFullExtension={true}
                    isAnimate={status}
                    actions={[
                        {
                            title: 'Chuyển đổi giọng nói thành văn bản',
                            render: (title, onClick) => <Tooltip title={title} arrow>
                                <ButtonIcon color="success" faSolid={true} fa='magnifying-glass-chart' onClick={onClick}></ButtonIcon>
                            </Tooltip>,
                            onClick: getAgentAndSetMic,
                            hidden: status
                        },
                        {
                            title: 'Dừng chuyển đổi',
                            render: (title, onClick) => <Tooltip title={title} arrow>
                                <ButtonIcon color="danger" faSolid={true} fa='stop' onClick={onClick} ></ButtonIcon>
                            </Tooltip>,
                            onClick: stopConnect,
                            hidden: !status
                        }
                    ]}
                />}
            </div>
        </ModalBody>
        <ModalFooter>
            <ButtonCustom onClick={handleSaveAndExportPDF}>
                <i className="fa-solid fa-file-export mr-1" />
                Xuất PDF - Lưu
            </ButtonCustom>
            <ButtonCustom color="danger" onClick={() => { stopConnect(); onClose(); }}>
                <i className="fa-solid fa-circle-xmark mr-1" />
                Hủy
            </ButtonCustom>
        </ModalFooter>
    </Modal >
})
export default memo(index) 