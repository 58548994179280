import { Meet } from "./Meet";

class XasEndpoint extends Meet {
  constructor() {
    super("/public/Xas");
  }

  getFindById(id) {
    return this.getAll('/findById?id=' + id + '&select=name%2Cuuid%2Cprefix')
  }

  findByUuid(uuid) {
    return this.getAll(`/findById?id=${uuid}&select=name,uuid,prefix`)
  }

  getOptionsByHuyenUuid(uuid) {
    return this.getAll(`/District?id=${uuid}&select=name,uuid,prefix`);
  }
  getAllData() {
    return this.getAll('?select=name%2Cuuid%2Cprefix&count=true');
  }
}

export default new XasEndpoint();
