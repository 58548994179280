import { Meet } from "./Meet";
class KhachMoisEndpoint extends Meet {
  constructor() {
    super("/KhachMois");
  }
  getWithName(data) {
    return this.getAll(`?search=${encodeURIComponent(data)}`)
  }
  updateWithId(id, data) { return this.PatchAuthen(`(${id})`, data) }
}
export default new KhachMoisEndpoint()