import React, { Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import { FormControlLabel } from "../../../../../../components/base/Form";
import { useAsync } from "../../../../../../hooks";
import { KhungNoiDungsEndpoint } from "../../../../../../service/Meet";
import NoiDungVanDe from "../../NoiDung";
import { formDataTTPH } from './data';
import DangKyPhatBieu from '../../DangKyPhatBieu';
import FormAttachFile from "../../FormAttachFile";
import Pending from '../../Component/Pending';
import { AddReloadModel, UnReloadReq, TypeEnums } from '../../../../../../proto/reloadroute_pb';
import { ReloadRoutesPromiseClient } from '../../../../../../proto/reloadroute_grpc_web_pb';
import { CHECK_CONFIRM, CHECK_ERROR, CHECK_SUCCESS, CHECK_TIME, CHECK_WARNING } from "../../../../../../components/alert/Alert";
import { ButtonCustom } from "../../../../../../components/base/Button";
import { _Enum_Trang_Thai_Noi_Dung } from "../../../../../action/defaultEnums";
import { meta, TOKEN_CLIENT_INFO, _API_URL_GRPC } from "../../../../../../actions/constants";
import grpcDevTool from "../../../../../../actions/grpcDevTool";
// import { getTimeDeadLine } from "../../../../../../actions/util";
import { forwardRef } from "react";
import useUnload from "../../../../../../hooks/useUnload";
import '../style.scss';
import { useSelector, useDispatch } from 'react-redux';
import { actionGRPC, actionNoiDung } from '../../../Redux/ActionsMeeting';

const Fieldset = styled.fieldset`
    border: outset; 
    border-radius: 0.5rem;
    height:9rem;
    overflowX:none;
    padding: 0.5rem
    border: 1px groove #ddd !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
    box-shadow:  0px 0px 0px 0px #000;
    &>legend{
        margin:0px;
        font-size: 1rem !important;
        color:blue
    }
    &>div{
        padding: 0px 10px
    }
`
//stream notify
const reloadService = new ReloadRoutesPromiseClient(_API_URL_GRPC);
const newModel = new UnReloadReq();
grpcDevTool(reloadService)

const Enum_Proto_VanDe = [TypeEnums.VANDE, TypeEnums.KETQUA, TypeEnums.VANDEHOANTHANH, TypeEnums.VANDEFULL, TypeEnums.XOAVANDE, TypeEnums.LAMMOI];
const Enum_Proto_PhatBieu = [TypeEnums.DUNGPHATBIEU, TypeEnums.PHATBIEU, TypeEnums.CHIDINHPHATBIEU, TypeEnums.GHIAMPHATBIEU, TypeEnums.XOAPHATBIEU];
/**
 * 0. Chuyển sang nội dung mới
 * 1. Khi patch bổ sung vấn đề từ trạng thái mới thành hoạt động
 * 2. Khi cán bộ tham gia phiên họp patch phương án bổ sung thì bên chủ trì load lại
 * 3. Khi chủ trì patch bố sung vấn đề thành hoàn thành
 * 4. Có cán bộ đăng ký phát biểu
 * 5. Khi người chuyên viên tạo vấn đề mới
 * 6. Kết thúc phiên họp
 * 7. Có cán bộ thoát hoặc tham gia phiên họp
 * 8. Cán bộ đươc chỉ định phát biểu
 * 9. Thư ký bật ghi âm phát biểu của 1 cán bộ
 * 10.Ông chủ trì/Thư ký xóa phát biểu mà họ chỉ định hoặc cán bộ tự xóa phát biểu đã đăng ký
 * 11.
 * 12.Xóa vấn đề biểu quyết(thư ký hoặc chuyên viên mới có thể xóa)
 */

const Info = React.memo(({ data, title, statusNoiDung, isChuTri, isDienBien, hanldePerfect }) => {
  return <Fieldset className="overflow-auto">
    <legend className="w-auto">{title}</legend>
    <div className="start-meet">
      <Pending loading={statusNoiDung === 'pending' || !data} numberRow={4}>
        <FormControlLabel
          colLabel="4"
          data={formDataTTPH.organizations}
          value={(data && data.organizations) || null}
          size='sm' />
        <div className="d-flex justify-content-between">
          <div className="w-100">
            <FormControlLabel colLabel="5" data={formDataTTPH.batDau} value={(data && data.batDau) || null} size='sm' />
            <FormControlLabel colLabel="5" data={formDataTTPH.ketThuc} value={(data && data.ketThuc) || null} size='sm' />
          </div>
          <div style={{ width: '8rem', alignSelf: 'flex-end', textAlign: 'center', marginBottom: '0.5rem' }}>
            {data?.trangThai === _Enum_Trang_Thai_Noi_Dung.DangHop && !isDienBien && <>
              {isChuTri && <ButtonCustom color="success" onClick={hanldePerfect}>
                <i className="fa-solid fa-clipboard-check"></i> Hoàn thành
              </ButtonCustom>}
            </>}
            {/* <div className={(data?.trangThai === _Enum_Trang_Thai_Noi_Dung.DangHop && !isDienBien) ? "pulsate-bck" : 'static-state'}>
              <Label id={data?.trangThai}>{Enum_Trang_Thai_Noi_Dung[data?.trangThai]}</Label>
            </div> */}
          </div>
        </div>
      </Pending>
    </div>
  </Fieldset>
})

const Index = forwardRef((props, ref) => {
  const { khungNoiDungId, phienHopId, title, isView, setActiveNextNoiDung,
    handleClose, isDienBien, updateDataThamDu } = props;

  // const [dataND, setDataND] = useState(null);
  const refPhatBieu = useRef();
  const refVanDe = useRef();
  const refStream = useRef();
  const dispatch = useDispatch();
  const { isChuTri, grpc, connect, noiDung } = useSelector(state => state.meetingRProcess);
  // const state = useNetworkState();

  const getKhungNoiDung = useCallback(async (idNoiDung) => {
    if (idNoiDung) {
      const data = await KhungNoiDungsEndpoint.getDataDetailByID(idNoiDung);
      const { nhanSu, thanhPhanThamDus } = data;
      const existInContent = thanhPhanThamDus.find(cur => cur.nhanSuId === TOKEN_CLIENT_INFO.nhanSuId)
      const accessLayout = document.getElementById('overlay-access');
      if (!isChuTri && TOKEN_CLIENT_INFO?.mainRole !== 'CV') {
        existInContent ? accessLayout.style.display = "none" : accessLayout.style.display = "flex";
      }
      if (nhanSu) {
        const { thongTinCaNhan: { hoVaTenDem, ten }, id } = nhanSu;
        data['nhanSuId'] = { value: id, hoVaTen: hoVaTenDem + ` ` + ten }; delete data['nhanSu'];
      }
      dispatch(actionNoiDung(data));
      return true;
    }
  }, [dispatch, isChuTri])

  const { status: statusNoiDung, execute: getNoiDung } = useAsync(getKhungNoiDung);

  useEffect(() => { khungNoiDungId && getNoiDung(khungNoiDungId); }, [getNoiDung, khungNoiDungId])

  const endStream = useCallback(() => {
    refStream && refStream.current?.cancel();
  }, [])
  const createViewed = ({ key, key2, type }) => {
    /**
     * key: idPhienHop
     * key2: Tùy theo type trả xuống. Nếu type = [1, 2, 3, 5] thì idBoSungVanDe, 
     * type = [4] thì idDangKyPhatBieu
     * còn ngoài thì idKhungNoiDung
     */
    const viewed = new AddReloadModel();
    viewed.setKey(key); viewed.setKey2(key2);
    viewed.setType(type); viewed.setUserid(null);
    return viewed;
  }

  const handleViewReload = useCallback((grpc) => {
    const { viewed } = grpc;
    viewed && reloadService.viewReload(viewed, meta);
    dispatch(actionGRPC(null));
  }, [dispatch])

  useEffect(() => {
    grpc?.isViewed && handleViewReload(grpc);
  }, [grpc, handleViewReload])

  const viewedLoader = useCallback((object) => {
    const { key2, type, isnew } = object;
    const viewed = createViewed(object);
    if (Enum_Proto_VanDe.includes(type)) {//Vấn đề biểu quyết
      //------ push value to redux--------
      dispatch(actionGRPC({ viewed, data: { key: key2, type } }));
      //----------------------------------
      refVanDe.current?.updateReload(key2, type)
        .then(res => {
          reloadService.viewReload(viewed, meta)
        }).catch(err => CHECK_WARNING(err))

    } else if (type === TypeEnums.NOIDUNG) { //Khung nội dung
      KhungNoiDungsEndpoint.getDataByIdPhien(phienHopId).then(({ value }) => {
        const activeNoiDung = value.find(cur => cur.trangThai === _Enum_Trang_Thai_Noi_Dung.DangHop);
        activeNoiDung && setActiveNextNoiDung(activeNoiDung.id, false, value);
        reloadService.viewReload(viewed, meta);
      });
    } else if (Enum_Proto_PhatBieu.includes(type)) { // Phát biểu
      const formPhatBieu = refPhatBieu.current;
      formPhatBieu && formPhatBieu.updateReload({ isnew, isChuTri, idDangKyPhatBieu: key2, type })
        .then(res => { reloadService.viewReload(viewed, meta) })
        .catch(err => CHECK_WARNING(err))
    } else if (type === TypeEnums.THAMDU) {
      updateDataThamDu(phienHopId).then(res => { reloadService.viewReload(viewed, meta) })
        .catch(err => CHECK_WARNING('Mất kết nối stream'))
    } else if (type === TypeEnums.LAMMOI) {
      reloadService.viewReload(viewed, meta)
    } else if (type === TypeEnums.SHARESCREEN) {
      CHECK_SUCCESS('1 cán bộ đang chia sẽ màn hình')
      reloadService.viewReload(viewed, meta)
    } else {//kết thúc phiên họp
      reloadService.viewReload(viewed, meta);
      if (!isDienBien) { CHECK_TIME('Phiên họp đã kết thúc', 'Chuyển về sảnh chờ', handleClose) };
    }
  }, [dispatch, handleClose, isChuTri, isDienBien, phienHopId, setActiveNextNoiDung, updateDataThamDu]);

  const startStreamData = useCallback((_stream, viewedLoader, phienHopId) => {
    _stream.on('data', function (response) {
      const { dataList } = response.toObject();
      dataList.forEach(viewedLoader);
    });
    refStream.current = _stream;
    const statusHandler = ({ code, message }) => {
      if ([4, 1, 16].includes(code)) {
        refStream.current.cancel();
        const newStream = reloadService.getUnReload(newModel.setKey(phienHopId), { ...meta, /* deadline: getTimeDeadLine() */ });
        startStreamData(newStream, viewedLoader, phienHopId);
      }
      // eslint-disable-next-line no-console
      console.log('Lỗi grpc');
    }
    _stream.on('error', statusHandler);
  }, [])

  useImperativeHandle(ref, () => ({ endStream }));

  useEffect(() => {
    const connectLayout = document.getElementById('overlay-connect');
    if (phienHopId && connect) {
      const stream = reloadService.getUnReload(newModel.setKey(phienHopId), { ...meta, /* deadline: getTimeDeadLine() */ });
      startStreamData(stream, viewedLoader, phienHopId);
      connectLayout.style.display = "none";
    } else {
      connectLayout.style.display = "flex";
      endStream();
    }
  }, [connect, endStream, phienHopId, startStreamData, viewedLoader])

  const hanldePerfect = useCallback(() => {
    CHECK_CONFIRM(`Hoàn thành nội dung hiện tại`, 'Chuyển sang nội dung tiếp theo ?')
      .then(async res => {
        if (res && res.isConfirmed) {
          await KhungNoiDungsEndpoint.update(khungNoiDungId, { trangThai: _Enum_Trang_Thai_Noi_Dung.HoanThanh })
          setActiveNextNoiDung(khungNoiDungId);
        }
      }).catch(err => CHECK_ERROR('Thao tác thất bại!'))
  }, [khungNoiDungId, setActiveNextNoiDung])

  useUnload(e => {
    e.preventDefault();
    if (refStream.current) refStream.current.cancel();
    e.returnValue = '';
  });

  const Document = useMemo(() => {
    return <Fieldset className="overflow-auto">
      <legend className="w-auto">Văn bản đính kèm</legend>
      <div>
        <FormAttachFile
          id={noiDung?.fileId}
          phienHopId={phienHopId}
          TEndpoint={KhungNoiDungsEndpoint}
          isEdit={true}
          stylePDF="PDF" />
      </div>
    </Fieldset>
  }, [noiDung, phienHopId])
  const renderDangKy = useMemo(() => (
    <DangKyPhatBieu
      dataND={noiDung}
      isDienBien={isDienBien}
      ref={refPhatBieu}
      isChuTri={isChuTri}
      isView={isView}
      khungNoiDungId={khungNoiDungId}
    />
  ), [isChuTri, isDienBien, isView, khungNoiDungId, noiDung])

  const renderVanDe = useMemo(() => (
    <NoiDungVanDe
      isDienBien={isDienBien}
      ref={refVanDe}
      isChuTri={isChuTri}
      phienHopId={phienHopId} />
  ), [isChuTri, isDienBien, phienHopId])

  return (
    <Fragment>
      <div id="overlay-connect" className="overlay-connecting justify-content-center align-items-center text-white h2">
        <div className="text-center">
          <i className="fa-exclamation-circle"></i> Mất kết nối
          <br />Đang kết nối trở lại<span className="progress-connect" />
        </div>
      </div>
      <div className="w-100 position-relative" >
        <div id="overlay-access" className="overlay-accessing position-absolute justify-content-center align-items-center text-white h2">
          <div className="text-center">
            <i className="fa-exclamation-circle" />Bạn không có quyền truy cập nội dung này
          </div>
        </div>
        <label className="text-center font-weight-bold mb-0 text-white w-100"
          style={{ borderBottom: 'inset', backgroundColor: 'lightslategray', borderRadius: "0.3rem 0.3rem 0px 0px" }}>{title.toUpperCase()}</label>
        <Row className="mr-0 ml-0">
          <Col md='6' className="p-1">
            <Info
              {...{ title, statusNoiDung, isChuTri, isDienBien, hanldePerfect }}
              data={noiDung}
              title="Thông tin chung" />
          </Col>
          <Col md='6' className="p-1 pr-0">{Document}</Col>
        </Row>
        <Row className="mr-0 ml-0">
          <Col md='6' className="p-1">{renderDangKy}</Col>
          <Col md='6' className="p-1">{renderVanDe}</Col>
        </Row>
      </div>
    </Fragment>
  )
})
export default Index
