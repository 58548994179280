/* eslint-disable no-useless-escape */
import { format, formatISO } from "date-fns";
import flatten from './flatten';
import dateVi from 'date-fns/locale/vi';
import isValid from "date-fns/isValid"
import { RolesEndpoint } from "../service/Admin";
import { compare } from "../views/don-vi/PhienHop/StartMeeting/DangKyPhatBieu";

/**
 * Lấy quyền cao nhất của tài khoản
 */
export const getMainRole = async (arrRoleUser) => {
    const sortGroup = { CB: 1, CV: 2, TK: 3 };
    const { value: roles } = await RolesEndpoint.getAllRoles();
    const groupRole = { CV: [], TK: [], CB: [] };
    roles.forEach(cur => {
        switch (cur.normalizedName) {
            case 'CV': groupRole['CV'].push(cur.normalizedName); break;
            case 'TKTH': groupRole['TK'].push(cur.normalizedName); break;
            default: groupRole['CB'].push(cur.normalizedName); break;
        }
    })
    const mainRole = [];
    Object.keys(groupRole).forEach(group => {
        arrRoleUser.forEach(roleUser => {
            if (!mainRole.find(cur => cur.group === group)) {
                const r = groupRole[group].find(cur => cur === roleUser);
                if (r) mainRole.push({ group, thuTu: sortGroup[group] });
            }
        })
    });
    mainRole.sort(compare);
    return mainRole[0].group;
}

export const regex_phoneNumber = new RegExp(/((09|03|07|08|05)+([0-9]{8})\b)/g);
export const regex_email = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

/**
 * Lấy thời gian cho deadline
 */
const timeOut = 60 * 60 * 12;
export const getTimeDeadLine = (time) => {
    const deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + time || timeOut);
    return deadline.getTime();
}

/**
 * Lấy mảng alphabet
 */
export function getAlphabet() {
    const alp = 'abcdefghijklmnopqrstuvwxyz';
    return alp.split('');
}

/**
 * Lấy giá trị từ thẻ Meta tag theo Key truyền vào
 * @param {String} key meta cần lấy
 */
export function getContentMetaTag(key) {
    return `${document
        .querySelector(`meta[name="${key}"]`)
        .getAttribute("content")}`;
}
/**
 * Định dạng ngày thành yyyy-MM-dd
 * @param {String} value giá trị cần định dạng
 */
export function formatDateNormal(value) {
    return value ? format(new Date(value), "yyyy-MM-dd", { locale: dateVi }) : "";
}
/**
 * Thẩm định Email
 * @param {String} email cần thẩm định
 */
export function validateEmail(email) {
    return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
}

/**
 * Định dạng ngày thành dd/MM/yyyy
 * @param {String} value giá trị cần định dạng
 */
export function formatDate(value) {
    return value ? format(new Date(value), "dd/MM/yyyy", { locale: dateVi }) : "";
}
export function formatDate2(value) {
    return value ? format(new Date(value), "yyyy-MM-dd", { locale: dateVi }) : "";
}

/**
 * Định dạng thời gian HH:mm:ss
 * @param {String} value giá trị cần định dạng
 */
export function formatTime(value) {
    return value ? format(new Date(value), "HH:mm:ss", { locale: dateVi }) : "";
}

export function formatTime2(value) {
    return value ? format(new Date(value), "HH:mm", { locale: dateVi }) : "";
}

// Loading
export function OPEN_LOADING_DATA_SERVER_API(msg, s) { // modal.style.display = "none";
    if (document.getElementById('Modal-show-loading-data-api-call')) document.getElementById('Modal-show-loading-data-api-call').classList.add("Modal-show-loading-data-show-modal");

}
/**
 * Random một chuỗi ký tự với độ dài bất kỳ
 * @param {Int} length Độ dài chuỗi ký tự
 * @param {String} iniCode ký tự mặc định
*/
export function randomCode(length = 6, iniCode = '') {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return iniCode + result
}

export function CLOSE_LOADING_DATA_SERVER_API(msg, s) { // modal.style.display = "none";
    if (document.getElementById('Modal-show-loading-data-api-call')) document.getElementById('Modal-show-loading-data-api-call').classList.remove("Modal-show-loading-data-show-modal");
}

/**
 * Định dạng thời gian dd/MM/yyyy HH:mm:ss
 * @param {String} value giá trị cần định dạng
 */
export function formatDatetime(value) {
    return value ? format(new Date(value), "dd/MM/yyyy HH:mm:ss", { locale: dateVi }) : "";
}

export function formatDatetime2(value) {
    return value ? format(new Date(value), "dd/MM/yyyy HH:mm", { locale: dateVi }) : "";
}


/**
 * Định dạng thời gian yyyy-MM-dd
 * @param {String} value giá trị cần định dạng
 */
export function formatISODate(value) {
    return value ? formatISO(new Date(value)) : null;
}
/**
 * Định dạng thời gian dd/MM/yyyy HH:mm
 * @param {String} value giá trị cần định dạng
 */
export function formatISODateTime(value) {
    return value ? format(new Date(value), "yyyy-MM-dd'T'HH:mm", { locale: dateVi }) : "";
}

/**
 * Reset indexValue
 * @param {String} value giá trị cần định dạng
 */
export function formatObject(object, start) {
    var items = {};
    var i = start || 0;
    for (var index in object.items) {
        items[i] = object[index];
        i++;
    }
    object = items;
    return object
}

/**
 * Định dạng tiền tệ VND
 * @param {Intl} value
 */
export function formatCurrency(value) {
    return new Intl.NumberFormat("vi", {
        style: "currency",
        currency: "VND"
    }).format(value !== undefined ? value : 0);
}

export function formatTienTe(value) {
    if (!value) return '';
    let nguyen = 0;
    let du = 0;
    let thua = 0;
    if (value >= 1000000000) {
        // tslint:disable-next-line: radix
        nguyen = parseInt((value / 1000000000).toLocaleString("vi-VN"));
        du = value - nguyen * 1000000000;
        thua = Math.floor(du / 1000000);
        return nguyen + ' tỷ' + (thua ? ' ' + thua : '');
    }
    if (value >= 1000000) {
        // tslint:disable-next-line: radix
        nguyen = parseInt((value / 1000000).toLocaleString("vi-VN"));
        du = value - nguyen * 1000000;
        thua = Math.floor(du / 1000);
        return nguyen + ' triệu' + (thua ? ' ' + thua : '');
    }
    return value.toLocaleString("vi-VN")
}

/**
 * Xóa phần tử trong mảng
 * @param {*} arr Mảng được truyền vào
 * @param {*} value giá trị cần xóa
 */
export function arrayRemove(arr, value) {
    return arr.filter(ele => ele !== value);
}

/**
 * Sinh chuỗi ngẫu nhiên theo độ dài
 * @param {int} length độ dài của chuỗi cần sinh
 */
export function makeRandomId(length) {
    var result = "";
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/**
 *
 * @param object
 * @constructor
 */
export function ObjectUtils(object) {
    this.obj = object

    /**
     * @description so sánh 2 obj
     * @param objToCompare obj cần so sánh
     * @return {boolean}
     */
    this.isEqual = function (objToCompare = {}) {
        if (typeof this.obj !== "object" || typeof objToCompare !== "object") return false
        for (const p in this.obj) {
            if (this.obj.hasOwnProperty(p)) {
                if (this.obj[p] !== objToCompare[p]) {
                    return false;
                }
            }
        }
        for (const p in objToCompare) {
            if (objToCompare.hasOwnProperty(p)) {
                if (this.obj[p] !== objToCompare[p]) {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * @description kiểm tra có phải là obj hay không và có thuộc tính hay không
     * @param prop
     * @return {boolean|boolean}
     */
    this.hasOwnProp = function (prop) {

        if (this.obj === null) return false
        if (typeof this.obj !== "object") return false
        if (typeof prop === "string") {
            return typeof this.obj === "object"
                && this.obj.hasOwnProperty.call(this.obj, prop)
        }
        if (Array.isArray(prop)) {
            prop.forEach(p => {
                if (!this.obj.hasOwnProperty.call(this.obj, p)) {
                    return false
                }
            })
            return true
        }
    }

    const parseDotNotation = (str, val, obj) => {
        let currentObj = obj,
            keys = str.split("."),
            i, l = Math.max(1, keys.length - 1),
            key;

        for (i = 0; i < l; ++i) {
            key = keys[i];
            currentObj[key] = currentObj[key] || {};
            currentObj = currentObj[key];
        }

        currentObj[keys[i]] = val;
        delete obj[str];
    }

    this.expand = function () {
        for (let key in this.obj) {
            if (this.obj.hasOwnProperty.call(this.obj, key))
                if (key.indexOf(".") !== -1) {
                    parseDotNotation(key, this.obj[key], this.obj);
                }
        }
        return this.obj;
    };

    /**
     * @description chuyển các thuộc tính bên trong của obj thành dấu chấm như trong param select trong swagger
     * @return {string}
     */
    this.getDotParam = function () {
        const flatObj = flatten(this.obj);
        return Array.from(Object.keys(flatObj)).join(",")
    }

    this.deepen = function () {
        const result = {};
        // For each object path (property key) in the object
        for (const objectPath in this.obj) {
            if (this.obj.hasOwnProperty.call(this.obj, objectPath)) {
                // Split path into component parts
                const parts = objectPath.split('.');

                // Create sub-objects along path as needed
                let target = result;
                while (parts.length > 1) {
                    const part = parts.shift();
                    target = target[part] = target[part] || {};
                }
                // Set value at end of path
                target[parts[0]] = this.obj[objectPath]
            }
        }
        return result;
    }

    const inProps = (key, props) => {
        return props.some((omitKey) => {
            return omitKey === key;
        });
    };

    this.omit = (props) => {
        let newObj = {};
        Object.keys(this.obj).forEach((key) => {
            if (!inProps(key, props)) {
                newObj[key] = this.obj[key];
            }
        });
        return newObj
    }

    this.omitNullValue = () => Object
        .entries(this.obj)
        .reduce((acc, [k, v]) => {
            if (v !== "") acc[k] = v
            return acc
        }, {})

    this.hasProps = (props) => props.every(prop => this.obj.hasOwnProperty.call(this.obj, prop))

}

/**
 * @see https://anonystick.com/blog-developer/25-truong-hop-su-dung-reduce-javascript-2020062089156060
 * @param arr
 * @constructor
 */
export function ArrayUtils(arr) {
    this.arr = arr
    this.mapByProps = function (prop) {
        if (typeof prop === "string") return this.arr.map(e => e[prop])
        if (Array.isArray(prop)) {
            const set = new Set(prop)
            return this.arr.map((obj) => Object.keys(obj).reduce((acc, key) => {
                if (set.has(key))
                    acc[key] = obj[key];
                return acc;
            }, {}));
        }
        return this.arr
    }
    this.groupByProp = function (prop) {
        return this.arr.reduce(function (groups, item) {
            const val = item[prop]
            groups[val] = groups[val] || []
            groups[val].push(item)
            return groups
        }, {})
    }
    this.filterUniqueElement = function () {
        return this.arr.filter((price, index, arr) => arr.indexOf(price) === index)
    }
    this.getSum = function () {
        return this.arr.reduce(function (total, currentValue) {
            return total + currentValue.x;
        }, 0);
    }
    this.flat = function () {
        return this.arr.reduce((t, v) => t.concat(Array.isArray(v) ? this.flat(v) : v), [])
    }
    // this.groupByType = function () {
    //     return this.arr.reduce(
    //         (t, v) => (v.forEach((w, i) => t[i].push(w)), t),
    //         Array.from({ length: Math.max(...arr.map(v => v.length)) }).map(() => [])
    //     );
    // }
    this.toObject = function () {
        return this.arr.reduce((t, v) => {
            const { name, ...rest } = v;
            t[name] = rest;
            return t;
        }, {})
    }
    this.insert = function (index, item) {
        return [
            ...this.arr.slice(0, index),
            item,
            ...this.arr.slice(index)
        ]
    }
}

// Format Number
export function formatNumber(amount, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
        if (amount !== 0 && amount !== 0.0000) {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
            let valueDecimal = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")
            let arrValueNotDecimal = valueDecimal.split(',');
            let arrValue = arrValueNotDecimal[1].split('');
            let arrValueFake = arrValue;
            for (let index = arrValueFake.length - 1; index >= 0; index--) {
                if (arrValueFake[index] === "0" || arrValueFake[index] === 0) {
                    arrValue.splice(index, 1)
                } else {
                    break;
                }
            }
            if (arrValue.length === 0) {
                return arrValueNotDecimal[0];
            } else {
                const decimal = arrValue.join("");
                const realValue = [arrValueNotDecimal[0], decimal];
                return realValue.join(",");
            }
        } else {
            return '-';
        }
    } catch {
    }
};

//Decode JWT
export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function removeVietnameseTones(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    return str;
}


// Tìm child của các phần tử con trong 1 mảng
// function getNestedChildren(arr, idParent) {
//     var children = [];
//     arr.forEach((curr, i) => {
//         if (curr.uuidParent === idParent) {
//             var grandChildren = getNestedChildren(arr, curr.uuid)
//             if (grandChildren.length) {
//                 curr.children = grandChildren;
//             }
//             children.push(curr);
//         }
//     })
//     return children;
// }

// export function settingTreeData(data) {
//     const arrData = [];
//     // eslint-disable-next-line no-console
//     data.forEach((curr, index, arr) => {
//         if (!curr.uuidParent) {
//             const element = { ...curr }
//             const children = arr.filter(x => x.uuidParent === curr.uuid)
//             if (children.length !== 0) {
//                 const dataChild = getNestedChildren(data, curr.uuid)
//                 element.children = dataChild;
//             }
//             arrData.push(element);
//         }
//     })
//     return arrData
// }

const inProps = (key, props) => {
    return props.some((omitKey) => {
        return omitKey === key;
    });
};

/**
 * @see https://lodash.com/docs/4.17.15#omit
 */
export const omit = (obj, props) => {
    let newObj = {};
    Object.keys(obj).forEach((key) => {
        if (!inProps(key, props)) {
            newObj[key] = obj[key];
        }
    });
    return newObj
}

export const dateUtils = {
    format(date, fallback = "Không có thông tin", isAlreadyValid) {
        if (!date) return fallback
        const d = new Date(date)
        if (isAlreadyValid) return formatDate(date)
        if (!isValid(d)) return fallback
        return formatDate(date)
    },
    getTimeDuration(f, t) {
        if (!f && !t) return ""
        const fd = new Date(f)
        const td = new Date(t)
        if (!isValid(fd) && !isValid(td)) return ""
        if (isValid(fd) && isValid(td)) return `từ ${formatDate(f)} đến ${formatDate(t)}`
        if (isValid(fd) && !isValid(td)) return `kể từ ngày ${formatDate(f)}`
        if (!isValid(fd) && isValid(td)) return `đến ngày ${formatDate(t)}`
        return ""
    }
}

export const settingData = (data) => {
    const arrData = [];
    data.forEach((curr, index, arr) => {
        if (!curr.ParentId) {
            const element = { ...curr }
            const children = arr.filter(x => x.ParentId === curr.Id);
            if (children.length !== 0) {
                const dataChild = getNestedChildren(data, curr.Id)
                element.children = dataChild;
            }
            arrData.push(element);
        }
    })
    return arrData.filter(f => f.children)
}

function getNestedChildren(arr, idParent) {
    var children = [];
    arr.forEach((curr, i) => {
        if (curr.ParentId === idParent) {
            var grandChildren = getNestedChildren(arr, curr.Id)
            if (grandChildren.length) {
                curr.children = grandChildren;
            }
            children.push(curr);
        }
    })
    return children;
}