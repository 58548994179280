import React from 'react';
import PropTypes from 'prop-types';
import { Label } from "reactstrap";

const ILabel = (props) => {
    const { children, ...attribute } = props;
    return (
        <Label {...attribute} >
            {children}
        </Label>
    );
};

ILabel.propTypes = {
    size: PropTypes.string.isRequired
};

ILabel.defaultProps = {
    size: "sm",
};

export default ILabel;