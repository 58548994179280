import Cookies from 'js-cookie';
import { _API_URL, _API_URL_AGENT, _API_URL_GOOGLE_CLOUDE } from "./constants";
import { openLoading, closeLoading } from "./loading";
import { CHECK_ERROR } from "../components/alert/Alert";
import { logoutAccount } from '../routes/permission';

/**
 * @module src/actions/callApi
 * @typedef {Object} ExportFetchType
 * @property {string} prefix // Địa chỉ REST API server, để trống nếu `url` chứa đường dẫn đầy đủ
 * @property {string} url // Đường dẫn chính xác của API
 * @property {string} [method='GET'] // Phương thức gọi API
 * @property {BodyInit} [body=null] // Dữ liệu gửi lên API
 * @property {boolean} [noAlert=false] // Tắt bật alert nếu gọi API thất bại
 * @property {boolean} [noLoading=false]
 * @property {'json' | 'text' | 'blob' | 'arrayBuffer'} [responseType='blob'] // Loại dữ liệu trả về [json, text, arrayBuffer, blob]
 * @property {string=} refreshToken
 */

/**
 * Gọi Fetch API
 *  Lấy địa chỉ IP
 */
export const getIPAddress = async () => {
    const publicIp = require('public-ip');
    const data = await publicIp.v4();
    return data
}
/**
 * Cấu hình fetch API
 * @param {string} method
 * @param {BodyInit | null} body
 * @param {boolean} auth
 * @param {string} refreshToken
 */
export const exportConfigsFetch = (method, body, auth, refreshToken, typeEndpoint) => {
    /** @type {RequestInit} */
    const configs = {
        method,
        credentials: "same-origin", // <-- includes cookies in the request
        headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: "application/json,*/*",
            'X-GDT-Dev-Token': process.env.REACT_APP_DEV_TOKEN
        },
    };
    let token = auth ? Cookies.get('GDTECABINET') : '';
    const IPADDRESS = Cookies.get('IP_Client');

    switch (typeEndpoint) {
        case 'google':
            token = Cookies.get('PARTNER_ACCESS_TOKEN');
            delete configs.headers['X-GDT-Dev-Token']
            break;
        case 'agent':
            token = Cookies.get('PARTNER_ACCESS_TOKEN');
            delete configs.headers['X-GDT-Dev-Token']
            break;
        default:
            configs.headers["X-Forwarded-For"] = IPADDRESS;
            break;
    }

    if (refreshToken) configs["cookies"] = Cookies.get('refreshToken');


    if (token) {
        configs.headers["Authorization"] = `Bearer ${token}`;
    }
    if (method !== "GET") {
        if (method === "POST" || method === "PUT" || method === "PATCH" || method === "DELETE") {
            if (body) {
                configs["body"] = body;
                configs.headers["Content-Type"] = "application/json";
            }
        }
    }
    return configs;
};

/**
 * Cấu hình header in batch Api
 * @param {string} method
 * @param {BodyInit | null} body
 * @param {boolean} auth
 */
export const exportConfigHeader = (method, auth) => {
    /** @type {RequestInit} */
    const headers = {};
    if (method !== "GET") {
        headers["Content-Type"] = "application/json";
    }
    return headers;
};


/**
 * Tạo body cho request with batch Odata
 * @param {string} urlBatch 
 * @param {string} method
 * @param {array} data 
*/
export function CreateListFetch(urlEndpoint, dataRequest) {
    if (Array.isArray(dataRequest)) {
        let rq = [];
        dataRequest.forEach((b, i) => {
            const { data: body, method } = b;
            let url = body.id ? urlEndpoint + `(${body.id})` : urlEndpoint;
            if (method === 'PATCH') {
                url = urlEndpoint + `(${body.id})`;
                delete body['id'];
            };
            rq.push({ id: `${i + 1}`, method, url, body, headers: exportConfigHeader(method, true) })
        })
        return JSON.stringify({ requests: rq });
    }
    return [];
}

/**
 * Gọi Fetch API
 * @param {ExportFetchType} conf
 */
const exportFetch = ({
    prefix = "",
    url,
    method = "GET",
    body = null,
    noAlert,
    noLoading,
    responseType = "json",
    refreshToken,
    endpoint,
    isBatch,
    isDataError,
    deep = 0,
    typeEndpoint = 'default'
}) => {
    if (method !== "GET" && !noLoading)
        openLoading();
    const auth = prefix && url.indexOf('/public') === -1;
    // const encodedUrl = url.replace(/(?<=search=)[^&]+/g, m => {
    //     if (decodeURIComponent(m) === m) return encodeURIComponent(m);
    //     return m;
    // });

    let bodyBatch = null
    if (isBatch) bodyBatch = CreateListFetch(endpoint, body);

    let host = null;

    switch (typeEndpoint) {
        case 'google':
            host = `${_API_URL_GOOGLE_CLOUDE}${url}`
            break;
        case 'agent':
            host = `${_API_URL_AGENT}${url}`;
            break;
        default:
            host = `${prefix}${url}`
            break;
    }
    return fetch(host, exportConfigsFetch(method, (bodyBatch || body), auth, refreshToken, typeEndpoint))
        .then(async (response) => {
            if (response.status >= 200 && response.status < 300) {
                if (response.status === 204) return response;
                if (response.status === 205) return await Promise.resolve(response[responseType]());
                if (method === "DELETE") {
                    return await Promise.resolve(response);
                } else if (url.includes("Upload/Download")) {
                    return await Promise.resolve(response.blob());
                } else {
                    return await Promise.resolve(response[responseType]())
                }
            } else if (response.status === 500) {
                // if (deep < 1) {
                //     deep++;
                //     return exportFetch({ prefix, url, method, body, noAlert, noLoading, responseType, refreshToken, endpoint, isBatch, deep })
                // }
                // CHECK_ERROR('Liên hệ GDT!')
                return Promise.reject(false)
            }
            else if (response.status === 401) { logoutAccount(); }
            else {
                const mes = await Promise.reject(response["json"]())
                return mes;
            }
        })
        .catch(async (error) => {
            // if (error) CHECK_ERROR(error.message)
            return Promise.resolve(error).then((err) => {
                !noAlert && CHECK_ERROR(err.message);
                if (isDataError) err = { ...err, dataError: JSON.parse(body) }
                return Promise.reject(err)
            });
        }).finally(() => method !== "GET" && !noLoading && closeLoading());
};



/**
 * Gọi Fetch API
 * @param {string} url // Đường dẫn chính xác của API
 * @param {ExportFetchType} conf // Tùy chỉnh fetch API
 * @param {BodyInit | null} body // Dữ liệu gửi lên API
 */
// Make a request using the Fetch API
export function FetchMapping(url, conf, body) {
    return exportFetch({ ...conf, url, body });
}

/**
 * Gọi Fetch API phương thức GET không cần đăng nhập
 * @param {string} url // Đường dẫn chính xác của API
 * @param {Pick<ExportFetchType, 'noAlert' | 'responseType'>} conf Tùy chỉnh fetch API
 */
export function GetMappingPublic(url, conf) {
    return exportFetch({ ...conf, prefix: _API_URL, method: "GET", url, body: null });
}

/**
 * Gọi Fetch API phương thức GET
 * @param {string} url // Đường dẫn chính xác của API
 * @param {Pick<ExportFetchType, 'noAlert' | 'responseType'>} conf Tùy chỉnh fetch API
 */
export function GetMappingAuthorization(url, conf) {
    return exportFetch({ ...conf, prefix: _API_URL, method: "GET", url, body: null });
}

/**
 * Gọi Fetch API phương thức POST không cần đăng nhập
 * @param {String} url // Đường dẫn chính xác của API
 * @param {BodyInit | null} body // Dữ liệu gửi lên API
 * @param {Pick<ExportFetchType, 'noAlert' | 'responseType'>} conf Tùy chỉnh fetch API
 */
export function PostMappingPublic(url, body, conf) {
    return exportFetch({ ...conf, prefix: _API_URL, method: "POST", url, body });
}

/**
 * Gọi Fetch API phương thức POST BATCH
 * @param {String} url // Đường dẫn chính xác của API
 * @param {BodyInit | null} body // Dữ liệu gửi lên API
 * @param {Pick<ExportFetchType, 'noAlert' | 'responseType'>} conf Tùy chỉnh fetch API
 */

export function BatchAuthorization(urlBatch, data, endpoint) {
    // const body = CreateListFetch(urlBatch, url, data);
    return exportFetch({ prefix: _API_URL, method: 'POST', url: urlBatch + '/$batch', body: data, endpoint, isBatch: true });
}


/**
 * Gọi Fetch API phương thức POST
 * @param {String} url // Đường dẫn chính xác của API
 * @param {BodyInit | null} body // Dữ liệu gửi lên API
 * @param {Pick<ExportFetchType, 'noAlert' | 'responseType'>} conf Tùy chỉnh fetch API
 * @param {string} refreshToken refresh token
 */
export function PostMappingAuthorization(url, body, conf, refreshToken) {
    return exportFetch({ ...conf, prefix: _API_URL, method: "POST", url, body, refreshToken });
}

/**
 * Gọi Fetch API phương thức PUT
 * @param {String} url // Đường dẫn chính xác của API
 * @param {BodyInit | null} body // Dữ liệu gửi lên API
 * @param {Pick<ExportFetchType, 'noAlert' | 'responseType'>} conf Tùy chỉnh fetch API
 */
export function PutMappingAuthorization(url, body, conf) {
    return exportFetch({ ...conf, prefix: _API_URL, method: "PUT", url, body });
}

/**
 * Gọi Fetch API phương thức PATCH
 * @param {String} conf.url // Đường dẫn chính xác của API
 * @param {BodyInit | null} body // Dữ liệu gửi lên API
 * @param {Pick<ExportFetchType, 'noAlert' | 'responseType'>} conf Tùy chỉnh fetch API
 */
export function PatchMappingAuthorization(url, body, conf) {
    return exportFetch({ ...conf, prefix: _API_URL, method: "PATCH", url, body });
}

/**
 * Gọi Fetch API phương thức DELETE
 *
 * @param {String} url // Đường dẫn chính xác của API
 * @param {Pick<ExportFetchType, 'noAlert' | 'responseType'>} conf Tùy chỉnh fetch API
 */
export function DeleteMappingAuthorization(url, conf, body) {
    return exportFetch({ ...conf, prefix: _API_URL, method: "DELETE", url, body });
}
