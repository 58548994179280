import React from 'react';
// import PropTypes from 'prop-types';
import { ModalFooter } from "reactstrap";

const IModalFooter = (props) => {
    const { children, ...attribute } = props;
    return (
        <ModalFooter {...attribute}>
            {children}
        </ModalFooter>
    );
};

IModalFooter.propTypes = {

};

IModalFooter.defaultProps = {

};

export default IModalFooter;