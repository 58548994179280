import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const IInput = (props) => {
    const { children, ...attribute } = props;
    return <Input {...attribute}>
        {children}
    </Input>
};

IInput.propTypes = {
    validation: PropTypes.object,
    children: PropTypes.node,
    type: PropTypes.string,
    size: PropTypes.string,
    bsSize: PropTypes.string,
    valid: PropTypes.bool,
    invalid: PropTypes.bool,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    plaintext: PropTypes.bool,
    addon: PropTypes.bool,
    className: PropTypes.string,
    cssModule: PropTypes.object
};

IInput.defaultProps = {
    value: '',
    onChange: () => undefined
};

export default IInput;
