import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import SelectSingle2 from './SelectSingle2';
import DialogCustomize from '../dialog/DialogCustomize';
import * as OdataUtils from '../tableOdata/OdataUtils';
import * as permission from '../../routes/permission';
import Spinners from '../spinners/Spinners';
import { ButtonIcon } from '../base/Button';
import { callApiNotLoading } from '../../service/api';
import debounce from 'lodash.debounce';

const loading = () => <Spinners />;

export default class DataSelect2 extends Component {

  _isFirstCalled = true;
  constructor(props) {
    super(props);
    this.state = {
      route: null,
      totalRow: -1,
      page: 0,
      querySearch: null,
      dialogIsOpen: false,
      valueSelect: null,
      sources: [],
      loading: false,
      //expect: this.props,
    };
    this.handleInputThrottled = debounce(this.handleInputChange, 1000)
  }
  /** @returns {string} */
  get normalizedUrl() {
    return this.props.url;//.replace('meet/', m => window.location.host.includes('ademax') ? 'ecabinet/' : m)
  }
  async UNSAFE_componentWillMount() {
    const route = await permission.getRouteDanhMuc(this.normalizedUrl);
    this.setState({ route });
  }

  checkDataRef = (where, isPaginate = true, expect = undefined, isFullSearch = false) => {
    const { column, dataRef, originfield, multiLabel, typeFieldKey, value, fields } = this.props;
    let odataURL = '';
    if (typeFieldKey && typeFieldKey === 'guid' && isPaginate === false) {
      odataURL += `${this.normalizedUrl}(${value})?${isPaginate ? `${OdataUtils.generatePaginate(this.state.page, this.props.pageSize)}&` : ''}`;
    } else {
      odataURL += `${this.normalizedUrl}${isFullSearch ? where + '&' : '?'}${isPaginate ? `${OdataUtils.generatePaginate(this.state.page, this.props.pageSize)}&` : ''}`;
    }
    if (dataRef) {
      let arrColumns = [...dataRef];
      arrColumns.push(column.field);
      arrColumns.push(column.label);
      odataURL += `${OdataUtils.generatedExpandSelectURL(arrColumns, where, false, 'withColumns', (expect === undefined) ? null : expect)}`;
    } else {
      if (typeFieldKey && typeFieldKey === 'guid' && isPaginate === false) {
        where = null
        odataURL += `${OdataUtils.generatedExpandSelectURL([column.field, column.label], where, false, 'withColumns', (expect === undefined) ? null : expect, originfield, multiLabel)}`;
      } else {
        odataURL += isFullSearch ? '' : `${OdataUtils.generatedExpandSelectURL(fields ? [column.field, column.label, ...fields] : [column.field, column.label], where, false, 'withColumns', (expect === undefined) ? null : expect, originfield, multiLabel)}`;
      }

      // console.log(odataURL);
    }

    return odataURL;
  }

  checkCondition = () => {
    const { column, where, whereOne } = this.props;
    // console.log(whereOne);
    if (where && this.state.valueSelect) {
      return undefined;
    }
    let conditions = [];
    if (whereOne) {
      whereOne.forEach((curr, i) => {
        conditions.push(`${curr.field} ${curr.expression} ${curr.value}`);
      });
    }
    if (where) {
      conditions.push(where);
    }
    if (this.state.querySearch) {
      conditions.push(OdataUtils.filterField(column.label, 'string', this.state.querySearch, true));
    } else {
      // if (this.state.valueSelect) {
      //   conditions.push(`${column.field} ne ${OdataUtils.filterTypeField(column.type, this.state.valueSelect)}`);
      // }
    }
    return conditions.join(' and ');
  }
  componentDidMount() {
    const { column, value, eleUrl, multiLabel, isFullSearch } = this.props;
    if (value !== null && value !== undefined) {
      if (eleUrl !== "") {
        this.setState({
          route: permission.getRouteDanhMuc(this.normalizedUrl, eleUrl)
        });
      }
      this.loadDefaultSource(column, value, multiLabel, isFullSearch);
    }
  }

  handleMenuOpen = () => {
    const { originfield, parentchange } = this.props;
    const { valueSelect, sources } = this.state;
    this.loadSources(valueSelect, sources, originfield, parentchange);
  }

  getConditionInputSeach = (value) => {
    const { column, where, whereOne, multiLabel } = this.props;
    var real_Where = '';
    if (whereOne && whereOne.length !== 0) {
      let string_Filter = '';
      whereOne.forEach((curr, index) => {
        if (index === 0) {
          string_Filter = `${curr.field} ${curr.expression} ${curr.value}`
        } else {
          string_Filter += ` and ${curr.field} ${curr.expression} ${curr.value}`
        }
      })
      real_Where = string_Filter;
    } else {
      real_Where = where;
    }
    let condition = OdataUtils.filterField(column.label, 'string', value, true, real_Where ? (' and ' + real_Where) : null, multiLabel);
    return condition
  }

  handleInputChange = (value, { action }) => {
    if (action === 'input-change' && value) {
      const { column, where, whereOne, multiLabel, isFullSearch } = this.props;
      this.setState({
        totalRow: -1,
        page: 0,
        sources: [],
        loading: true,
      });
      var real_Where = '';
      if (whereOne && whereOne.length !== 0) {
        let string_Filter = '';
        whereOne.forEach((curr, index) => {
          if (index === 0) {
            string_Filter = `${curr.field} ${curr.expression} ${curr.value}`
          } else {
            string_Filter += ` and ${curr.field} ${curr.expression} ${curr.value}`
          }
        })
        real_Where = string_Filter;
      } else {
        real_Where = where;
      }
      let condition = isFullSearch ? `&search=${value}` :
        OdataUtils.filterField(column.label, 'string', value, true, real_Where ? (' and ' + real_Where) : null, multiLabel);
      callApiNotLoading(this.checkDataRef(condition, true, this.props.expect, isFullSearch))
        .then(result => this.setState({
          sources: result.value,
          totalRow: Number(result['@odata.count']),
          loading: false,
          querySearch: value
        }))
        .catch(err => this.setState({ loading: false }));
    }
  }

  handleScrolledToBottom = async () => {
    const { querySearch } = this.state;
    const { sort, isFullSearch } = this.props;
    if (this.state.valueSelect) {
      if ((this.state.sources.length - 1) >= this.state.totalRow) {
        return;
      }
    } else {
      if (this.state.sources.length >= this.state.totalRow) {
        return;
      }
    }
    this.setState({ page: this.state.page + 1, loading: true });

    let check = null;
    if (querySearch) {
      check = this.checkDataRef(this.getConditionInputSeach(querySearch), true, this.props.expect, isFullSearch);
    } else {
      check = this.checkDataRef(this.checkCondition(), true, this.props.expect, isFullSearch);
      if (sort) check += sort;
    }
    callApiNotLoading(check)
      .then(result => {
        let datas = [...this.state.sources];
        result.value.forEach(element => datas.push(element));
        this.setState({ sources: datas, loading: false });
      })
      .catch(err => this.setState({ loading: false }));
  }

  async loadSources(valueSelect, sources, originfield, parentchange) {
    const { sort, isFullSearch } = this.props;
    const { querySearch } = this.state;
    const {
      column
    } = this.props;

    if (this._isFirstCalled) {
      this.setState({ loading: true });
    }
    else {
      let newNameRef = [];
      if (valueSelect !== undefined && valueSelect !== null
        && valueSelect !== ''
        && sources.length > 0) {
        let objectSelect = sources.find(f => f.value === valueSelect);
        objectSelect && newNameRef.push(objectSelect);
      }

      this.setState({
        sources: newNameRef,
        loading: true
      });
    }
    let check = this.checkDataRef(this.checkCondition(), true, this.props.expect, isFullSearch);
    if (sort) check += sort;
    try {
      this.setState({ loading: true });
      const fieldKey = column['field']; //lay key

      const result = await callApiNotLoading(check);
      const value = result.value;
      var datas = [];

      if (originfield && valueSelect !== null) {
        if (parentchange === true) {
          datas = [...value];
        }
      }

      if (datas.length === 0) {
        datas = [...this.state.sources];

        // loc du lieu bi trung trong sources
        value.forEach(value => {
          // kiem tra du lieu response co ton tai trong state.sources?
          if (!this.state.sources.some(s => s[fieldKey] === value[fieldKey])) {
            datas.push(value);
          }
        })
      };
      // neu nhu load sources nhung trong sources khong ton tai record chua valueSelect thi phai load
      if (!datas.some(s => s[fieldKey] === valueSelect)) {
        if (!querySearch && querySearch !== '') {
          const defaultSource = await this.requestDefaultSource(column, valueSelect);
          defaultSource && datas.push(defaultSource);
        }
      }
      this.setState({ sources: datas, totalRow: Number(result['@odata.count']), loading: false });
      this._isFirstCalled = false;
    } catch (error) {
      // alert('Loi roi may anh oi');
    } finally {
      this.setState({ loading: false });
    }
  }

  loadDefaultSource(column, value, multiLabel, isFullSearch) {
    const { querySearch } = this.state;
    this.setState({ loading: true });
    if (!querySearch && querySearch !== '') {
      this.requestDefaultSource(column, value, multiLabel, isFullSearch)
        .then(val => {
          return this.setState({
            sources: val ? [val] : [],
            valueSelect: val[column.field],
            loading: false
          })
        }

        ).catch(err => this.setState({ loading: false }));
    }
  }

  async requestDefaultSource(column, value, multiLabel, isFullSearch) {
    const { typeFieldKey } = this.props;
    if (value === undefined || value === null || value === '') return null;
    const strCondition = isFullSearch ? `&$filter=${OdataUtils.filterField(column.field, column.type, value)}`
      : OdataUtils.filterField(column.field, column.type, value)
    let editURL = this.checkDataRef(strCondition, false, this.props.expect, isFullSearch);
    const _value_1 = await callApiNotLoading(editURL);
    const { value: _value } = await callApiNotLoading(editURL);
    return typeFieldKey && typeFieldKey === 'guid' ? _value_1 : _value[0];


  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.value !== props.value) {
      this.setState({
        valueSelect: props.value
      });
      const { originfield, parentchange } = this.props;
      const { sources } = this.state;
      this.loadSources(props.value, sources, originfield, parentchange);
    }

    if (
      (props.value !== null || props.value !== undefined || props.value !== '')
      && ((this.state.sources && this.state.sources.length === 0) || !this.state.sources)
      && (this.props.column || props.column)
    ) {
      // this.loadDefaultSources((this.props.column || props.column),props.value);
    }

  }


  handleMenuClose = () => {
    this.setState({
      totalRow: -1,
      page: 0,
      querySearch: null
    });
  }

  onOpenDialog = () => this.setState({ loading: true, dialogIsOpen: true });
  onCloseDialog = () => this.setState({ loading: false, dialogIsOpen: false });

  render() {
    const { dialogOdata, placeholder, name, onChange, column, renderRow, originfield, multiLabel, funLabel, isClearable, isDisabled } = this.props;
    // const { valueSelect, sources } = this.state;
    const { route } = this.state;
    return (
      <>
        <div style={{ display: 'flex' }}>
          <SelectSingle2
            originfield={originfield}
            multiLabel={multiLabel}
            funLabel={funLabel}
            value={this.state.valueSelect}
            name={name} valueRef={column.field} labelRef={column.label}
            placeholder={placeholder}
            options={this.state.sources}
            onChange={(e, meta) => this.setState({ valueSelect: e ? e.value : null }, () => onChange(e, meta))}
            renderOption={(row, i) => renderRow(row, i)}
            onMenuScrollToBottom={this.handleScrolledToBottom}
            isLoading={this.state.loading}
            onInputChange={this.handleInputThrottled}
            onMenuOpen={this.handleMenuOpen}
            onMenuClose={this.handleMenuClose}
            isClearable={isClearable}
            isDisabled={isDisabled}
          />
          {dialogOdata && route && <ButtonIcon color="success" icon="plus" onClick={this.onOpenDialog} />}
        </div>
        {dialogOdata && route && this.state.dialogIsOpen &&
          <DialogCustomize open={this.state.dialogIsOpen} handleClose={this.onCloseDialog} title={route.name} maxWidth="lg">
            <Suspense fallback={loading()}>
              <route.component odata={route.odata} />
            </Suspense>
          </DialogCustomize>
        }
      </>
    );
  }
}

DataSelect2.propTypes = {
  url: PropTypes.string.isRequired,
  column: PropTypes.object.isRequired
};

DataSelect2.defaultProps = {
  pageSize: 20,
  placeholder: 'Chọn...',
  dialogOdata: true
};
