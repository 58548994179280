/* eslint-disable array-callback-return */
import { CHECK_WARNING } from "../alert/Alert";

export const delimiter = ".";
export const space = "/";

export const getDataID = (columns) => columns.filter(e => e.dataID)[0];
export const getPrimaryKey = (columns) => getDataID(columns).field;

export const checkDelimiter = (value) => value && value.includes(delimiter);
export const checkSpace = (value) => value && value.includes(space);
export const convertDelimiter = (value) => value.split(delimiter).join(space);
export const checkHasField = (hasField, column) => (hasField ? column.field : column);

export const getPrimaryURL = (url, columns, data) => {
  const ID = getDataID(columns);
  //const dataField = ID.type === 'numeric' ? data[ID.field] : "'" + data[ID.field] + "'";
  //return `${url}(${ID.type === 'guid' ? 'guid' : ''}${dataField})`;
  return `${url}(${data[ID.field]})`;
}

// CheckData
export const handleCheckData = (columns, newData) => {
  let columnsValue = columns.filter(column => column.dataCheck);
  for (var i = 0; i < columnsValue.length; i++) {
    if (checkDelimiter(columnsValue[i].field) && columnsValue[i].dataRef) {
      if (newData[columnsValue[i].dataRef[0]] === null || newData[columnsValue[i].dataRef[0]] === undefined) {
        CHECK_WARNING("Vui lòng nhập dữ liệu cột " + columnsValue[i].title);
        return false;
      }
    } else {
      if (newData[columnsValue[i].field] === undefined || newData[columnsValue[i].field] === null) {
        CHECK_WARNING("Vui lòng nhập dữ liệu cột " + columnsValue[i].title);
        return false;
      }
    }
  }
  return true;
}



// EXPAND
export const filterExpandURL = (list_join, hasField = true, originfield = false, multiLabel = null) => {
  let list_join_filter_only = [];
  let list2_join_filter_only = [];
  let list_join_filter_only_check = [];
  let i1 = 0;
  let index_table = [];
  let index_real = '';
  let expand = '';

  list_join.forEach((current, i) => {
    list_join_filter_only_check.push(current.field);
  });
  let arrayOfUniques = [];
  let index = [];
  list_join_filter_only_check.forEach((element, i) => {
    if (!arrayOfUniques.includes(element)) {
      arrayOfUniques.push(element)
    } else {
      index.push(i)
    }
  });
  index.forEach((num) => {
    list_join.splice(num, 1);
  })
  //tạo 1 mãng colunm và 1 mãng field theo column
  list_join.forEach((current, i) => {
    let split = '';
    if (originfield) {
      split = checkHasField(hasField, current)
        .split(delimiter).reverse().splice(1).reverse().join(',');
    } else {
      split = checkHasField(hasField, current)
        .split(delimiter).reverse().splice(1).reverse().join(space);
    }
    let splitField = checkHasField(hasField, current)
      .split(delimiter).splice(1).reverse().join(space);
    if (checkSpace(splitField)) {
      splitField = splitField.split(space)[0];
    }

    if (i === 0) {
      list_join_filter_only.push(split);
      list2_join_filter_only.push(splitField);
      index_table[i] = split;
    } else {
      if (!list_join_filter_only.some(arr => arr === split)) {
        list_join_filter_only.push(split);
        index_table[i] = split;
        i1 = i;
        list2_join_filter_only[i1] = splitField;
      }
      else {
        index_table.forEach((current, i2) => {
          if (current === split) {
            index_real = i2
          }
        })
        if (list2_join_filter_only[index_real] !== splitField) {
          list2_join_filter_only[index_real] = list2_join_filter_only[index_real] + ',' + splitField;
        }
      }
    }
  });
  //sắp xếp thứ tự cho list2_join_filter_only
  let list2_join_filter_only_fake = [];
  list2_join_filter_only.forEach((curr) => {
    list2_join_filter_only_fake.push(curr);
  })
  list2_join_filter_only = list2_join_filter_only_fake;
  //create query expand and select
  list_join_filter_only.forEach((curr, i3) => {
    if (i3 === 0) {
      if (checkSpace(curr)) {
        let arr_list = curr.split(space);
        let arr_list2 = list2_join_filter_only[i3].split(space);
        let text = '';
        let text1 = '';
        let ngoac = '';
        let ngoac_end = '';
        arr_list.forEach((element, i4) => {
          ngoac = (i4 === (arr_list.length - 1)) ? ngoac + ')' : '';
          ngoac_end = ngoac_end + ')';
          if (i4 === 0) {
            text1 = element + '(' + ngoac;
            text = text1;
          } else {
            if (i4 === (arr_list.length - 1)) {
              text1 = '$expand=' + element + '($select=' + arr_list2[0] + ngoac_end;
            } else {
              text1 = '$expand=' + element + '(' + ngoac;
            }
            text = text + '' + text1;
          }
        });
        expand = text;
      } else {
        if (multiLabel !== null) {
          let stringLabel = '';
          multiLabel.map((multiLB, i) => {
            if (checkDelimiter(multiLB.field)) {
              let labelMuti = multiLB.field.split('.');
              stringLabel += (i === 0) ? labelMuti[labelMuti.length - 1] : stringLabel + ',' + labelMuti[labelMuti.length - 1];
            }
            return
          })
          expand = curr + '($select=' + list2_join_filter_only[i3] + ',' + stringLabel + ')';
        } else {
          expand = curr + '($select=' + list2_join_filter_only[i3] + ')';
        }
      }
    } else {
      if (checkSpace(curr)) {
        let arr_list = curr.split(space);
        let arr_list2 = list2_join_filter_only[i3].split(space);
        let text = '';
        let text1 = '';
        let ngoac = '';
        let ngoac_end = '';
        arr_list.forEach((element, i4) => {
          ngoac = (i4 === (arr_list.length - 1)) ? ngoac + ')' : '';
          ngoac_end = ngoac_end + ')';
          if (i4 === 0) {
            text1 = element + '(' + ngoac;
            text = text1;
          } else {
            if (i4 === (arr_list.length - 1)) {
              text1 = '$expand=' + element + '($select=' + arr_list2[0] + ngoac_end;
            } else {
              text1 = '$expand=' + element + '(' + ngoac;
            }
            text = text + '' + text1;
          }
        });
        expand = expand + ',' + text;
      } else {
        expand = expand + ',' + curr + '($select=' + list2_join_filter_only[i3] + ')';
      }
    }
  })
  return expand;
}

// SELECT
export const selectURL = (columns, selectType, hasField = true) => {
  if (selectType) {
    if (selectType === 'withColumns') {
      return `${columns.reduce((accum, current, index, array) => {
        let field = checkHasField(hasField, current);
        let key_select = `${accum}`;
        let arr_key_select = key_select.split(',');
        let check_point = true;
        if (checkDelimiter(field)) {
          let check_connect_multi = field.split('.');
          if (arr_key_select.indexOf(current.dataRef[0]) === -1) {
            if (check_connect_multi.length < 3) {
              key_select += `${current.dataRef === undefined ? '' : (current.dataRef[0] + ',')}`;
            }
          }
        } else {
          if (arr_key_select.indexOf(field) === -1) {
            key_select += `${field}`;
          } else {
            check_point = false;
          }
        }
        let a = `${key_select}${index === (array.length - 1) ? '' : ((checkDelimiter(field) === true) ? '' : (check_point === true) ? ',' : '')}`;
        return a;
      }, ``)}`;
    }
  } else {
    return `${columns.filter(column => checkDelimiter(column.field))
      .reduce((accum, current) => `${accum},${convertDelimiter(checkHasField(hasField, current))}`, `*`)}`;
  }
  return '';
}

export const filterTypeField = (type, value) => {
  if (type === 'string') {
    return `'${value}'`;
  }
  if (type === 'datetime') {
    return `datetime'${value}'`;
  }
  if (type === 'guid') {
    return `${value}`;
  }
  return `${value}`;
}

export const filterField = (fieldName, type, value, isSearch = false, where = null, multiLabel = null) => {
  if (isSearch) {
    let arr_field = fieldName.split('.')[0].split('/');
    if (arr_field.length < 3) {
      if (!checkDelimiter(fieldName)) {
        return where ? `contains(${fieldName},'${value}') ${where}` : `contains(${fieldName},'${value}')`;
      } else {
        if (multiLabel) {
          let fieldChange = fieldName.split('.').join('/')
          let querySeachDF = `contains(${fieldChange},'${value}')`;
          multiLabel.map(x => {
            if (x.isSearch) {
              let fieldSeach = x.field.split('.').join('/');
              querySeachDF += ` or contains(${fieldSeach},'${value}')`
            }

          })
          return querySeachDF;
        } else {
          let fieldChange = fieldName.split('.').join('/')
          return `contains(${fieldChange},'${value}')`;
        }

      }

    }
  } else {
    return `${fieldName} eq ${filterTypeField(type, value)}`;
  }

}

// FILTER
export const filterURL = (query, columns, fieldFilter) => {
  if (query.filters && query.filters.length > 0) {
    const querys = query.filters
      .filter(filter => filter.column.field && filter.column.type)
      .map(filter => {
        let key_filter = ''
        if (fieldFilter) {
          key_filter = `${checkDelimiter(filter.column.field) ? convertDelimiter(fieldFilter) : filter.column.field}`;
        } else {
          key_filter = `${checkDelimiter(filter.column.field) ? filter.column.dataRef[0] : filter.column.field}`;
        }
        return filterField(key_filter, filter.column.type, filter.value);
      });
    return querys && querys.join(' and ');
  }
  if (query.search && query.search.length >= 1) {
    const querys = columns
      .filter(column => column.searchable && column.field && column.type)
      .map(column => {
        const key_filter = `${checkDelimiter(column.field) ? convertDelimiter(column.field) : column.field}`;
        if (column.type === 'numeric' && !checkDelimiter(column.field)) {
          if (!isNaN(Number(query.search))) {
            return filterField(key_filter, column.type, query.search, false);
          }
        } else {
          return filterField(key_filter, column.type, query.search, true, column.where);
        }

      });
    querys.map((que, ii) => {
      if (que === undefined) {
        querys.splice(ii, 1);
      }
    });
    let fakeQuery = querys;
    fakeQuery.forEach((cur, index) => {
      if (typeof cur === "undefined") {
        querys.splice(index, 1)
      }
    })
    // querys.splice(querys.indexOf(undefined),1);
    return querys && querys.join(' or ');
  }
  return '';
}

// Paginate
export const generatePaginate = (page, pageSize) => {
  return `$top=${pageSize}&$skip=${page * pageSize}&$count=true`;
}

// OrderBy
export const generateOrderBy = (field, orderDirection) => {
  return `&$orderby=${checkDelimiter(field) ? convertDelimiter(field) : field} ${orderDirection}`;
}

// Expand
export const generateExpand = (columns, hasField = true, originfield = false, multiLabel = null) => {
  let list_join = columns.filter(column => checkDelimiter(checkHasField(hasField, column)));

  if (list_join.length === 0) {
    return '';
  }
  if (multiLabel !== null) {
    return `&$expand=${filterExpandURL(list_join, hasField, originfield, multiLabel)}`;
  } else {
    return `&$expand=${filterExpandURL(list_join, hasField, originfield)}`;
  }

}

// Select
export const generateSelect = (columns, selectType = 'withColumns', hasField = true, originfield, multiLabel = null) => {
  const select_value = selectURL(columns, selectType, hasField);
  if (originfield) {
    return select_value ? `&$select=${select_value}` : '';
  } else {
    if (multiLabel !== null) {
      var field_MultiLabel = '';
      multiLabel.map(cur => {
        field_MultiLabel += `,${cur.field}`
        return field_MultiLabel
      })
      return select_value ? `&$select=${select_value} ${field_MultiLabel}` : '';
    } else {
      return select_value ? `&$select=${select_value}` : '';
    }
  }

}

// EXPAND
export const generatedExpandSelectURL = (columns, where, hasField = true, selectType = 'withColumns', expect = null, originfield = false, multiLabel = null) => {
  if (expect !== null) {
    let a = `${"&$filter=" + expect.field + " ne " + expect.data}`;
    let b = `${" and " + expect.field + " ne " + expect.data}`;
    // let a = `${"&$filter=" + expect.field + " ne " + expect.data}`;
    // let b = `${" and " + expect.field + " ne " + expect.data}`;
    return `${generateExpand(columns, hasField)}${generateSelect(columns, selectType, hasField)}${where ? ("&$filter=" + where + b) : a}`;
  } else {
    if (originfield === false) {
      if (multiLabel !== undefined) {
        return `${generateExpand(columns, hasField)}${generateSelect(columns, selectType, hasField, originfield, multiLabel)}${where ? ("&$filter=" + where) : ""}`;
      } else {
        return `${generateExpand(columns, hasField)}${generateSelect(columns, selectType, hasField, originfield)}${where ? ("&$filter=" + where) : ""}`;
      }

    } else {
      return `${generateExpand(columns, hasField, originfield, multiLabel)}${where ? ("&$filter=" + where) : ""}`;
    }

  }
}
