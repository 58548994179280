export const Vietnamese = {
    weekdays: {
        shorthand: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
        longhand: [
            "Chủ nhật",
            "Thứ hai",
            "Thứ ba",
            "Thứ tư",
            "Thứ năm",
            "Thứ sáu",
            "Thứ bảy",
        ]
    },
    months: {
        shorthand: [
            "Th01",
            "Th02",
            "Th03",
            "Th04",
            "Th05",
            "Th06",
            "Th07",
            "Th08",
            "Th09",
            "Th10",
            "Th11",
            "Th12",
        ],
        longhand: [
            "Tháng 01",
            "Tháng 02",
            "Tháng 03",
            "Tháng 04",
            "Tháng 05",
            "Tháng 06",
            "Tháng 07",
            "Tháng 08",
            "Tháng 09",
            "Tháng 10",
            "Tháng 11",
            "Tháng 12",
        ]
    },
    firstDayOfWeek: 1,
    rangeSeparator: " đến "
};