import React, { Component } from 'react';
import InputDate from './InputDate';

class InputTime extends Component {
    render() {
        const { ...attribute } = this.props;
        return (
            <InputDate {...attribute} />
        )
    }
}

InputTime.defaultProps = {
    dateFormat: 'HH:mm',
    parseFormat: 'HH:mm:ss',
    value: new Date(),
    mask: [/\d/, /\d/, ":", /\d/, /\d/],
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 15,
    timeCaption: "Chọn giờ"
}

export default InputTime