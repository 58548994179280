import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

const IContainer = (props) => {
    const { children, ...attribute } = props;
    return (
        <Container {...attribute}>
            {children}
        </Container>
    );
}

IContainer.propTypes = {
    fluid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

IContainer.defaultProps = {

};

export default IContainer;