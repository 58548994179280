import { FormGroup } from '@material-ui/core';
import React, { memo, forwardRef, useCallback, useState, useEffect } from 'react';
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { Col, Row } from 'reactstrap';
import { ButtonAdd } from '../base/Button';

const selectProps = {
    noOptionsMessage: () => "Không có lựa chọn",
    placeholder: "Nhấp để chọn",
    loadingMessage: () => "Đang tìm kiếm"
}
const Index = forwardRef(({ onChange, loadData, id, name, placeholder, getOptionLabel,
    getOptionValue, onAdd, value, disabled, ...props }, ref) => {
    const [selected, setSelected] = useState(null);
    if (placeholder) selectProps['placeholder'] = placeholder;
    const promiseOptions = useCallback(inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(loadData(inputValue));
            }, 1000);
        }), [loadData])
    useEffect(() => {
        value && setSelected(value);
    }, [value])
    // eslint-disable-next-line no-unused-vars
    const getDefaultValue = useCallback(async (value) => {
        const options = await promiseOptions();
        const [op] = options.filter(option => option[getOptionValue || 'value'] === value)
        return op ? op : null
    }, [getOptionValue, promiseOptions])
    const hanldeChange = useCallback((e) => { onChange(e) }, [onChange])
    return (
        <FormGroup>
            <Row className={onAdd && "d-flex justify-content-between"} >
                <Col className="pr-0">
                    <AsyncSelect
                        {...props}
                        isDisabled={disabled}
                        defaultOptions
                        value={selected}
                        name={name}
                        isClearable
                        onChange={hanldeChange}
                        getOptionValue={option => option}
                        inputId={name}
                        id={id}
                        instanceId={name}
                        loadOptions={promiseOptions}
                        {...selectProps} />
                </Col>
                {onAdd && <Col md='1' className="pl-0">
                    <ButtonAdd className="h-100" onClick={onAdd}>
                    </ButtonAdd>
                </Col>}
            </Row>
        </FormGroup>

    )
})
export default memo(Index)