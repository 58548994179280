import React, { Component } from 'react';
import _propTypes from 'prop-types';
import _classnames from 'classnames';
const __MIN_VALID_YEAR = 1;
const isBrowser = typeof window !== "undefined" && typeof document !== "undefined";

class MonthPicker extends Component {
    constructor(props) {
        super(props);
        this._handleOverlayClick = this._handleOverlayClick.bind(this);
        this.handleClickMonth = this.handleClickMonth.bind(this);
        this.goPrevYear = this.goPrevYear.bind(this);
        this.goNextYear = this.goNextYear.bind(this);
        this._keyDown = this._keyDown.bind(this);
        this.state = {
            years: [],
            values: [],
            labelYears: [false, false],
            showed: false,
            closeable: false, //special, must not be changed with setState
            yearIndexes: [0],
            lastRange: null,
            lastValue: null
        }
    }
    componentDidMount() {
        let yearArr = getYearArray(this.props.years),
            yearIndexes = [0],
            values = this.validValues(this.props.range || this.props.value, yearArr, yearIndexes);
        this.setState({
            years: yearArr,
            values: values,
            labelYears: [false, false],
            showed: this.props.show,
            closeable: this.props.show, //special, must not be changed with setState
            yearIndexes: yearIndexes,
            lastRange: this.props.range,
            lastValue: this.props.value
        });
        if (isBrowser) {
            document.addEventListener('keydown', this._keyDown);
        }
    }
    componentWillUnmount() {
        if (isBrowser) {
            document.removeEventListener('keydown', this._keyDown);
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            let yearArr = getYearArray(this.props.years),
                yearIndexes = this.state.yearIndexes,
                nextValues = this.props.range || this.props.value, //|| this.props.range || this.props.value
                values = this.validValues(nextValues, yearArr, yearIndexes);
            this.setState({
                years: yearArr,
                values: values,
                labelYears: [false, false],
                yearIndexes: yearIndexes,
                lastRange: this.props.range,
                lastValue: this.props.value,
                showed: this.props.show,
                closeable: this.props.show
            });
        }
    }
    validate = (d, years, idx, yearIndexes) => {
        let now = new Date(),
            thisYear = now.getFullYear(),
            ym = void 0;
        if (d && typeof d.year === 'number' && d.year > __MIN_VALID_YEAR && typeof d.month === 'number' && d.month >= 1 && d.month <= 12) {
            ym = d;
        }

        let foundThisYear = void 0;
        for (let i = 0; i < years.length; i++) {
            if (ym && years[i].year === ym.year) {
                yearIndexes[idx] = i;
                return ym;
            } else if (years[i].year === thisYear) {
                foundThisYear = i;
            }
        }

        if (typeof foundThisYear === 'number') {
            yearIndexes[idx] = foundThisYear;
            return { year: thisYear };
        }

        let last = yearIndexes[idx] = years.length - 1;
        return { year: years[last].year };
    }
    validValues = (v, years, yearIndexes) => {
        if (!v) return [];
        if (v.from || v.to) {
            let from = this.validate(v.from, years, 0, yearIndexes),
                to = this.validate(v.to, years, 1, yearIndexes);
            if (from.year > to.year || (from.year === to.year && from.month > to.month)) {
                from.year = to.year;
                from.month = to.month;
                if (from.month < 1) {
                    from.year--;
                    from.month += 12;
                }
            }
            return [from, to];
        }
        return [this.validate(v, years, 0, yearIndexes)];
    }
    optionPad(padIndex) {
        let values = this.state.values,
            value = values[padIndex],
            labelYears = this.state.labelYears,
            labelYear = labelYears[padIndex] = labelYears[padIndex] || value.year,
            ymArr = this.state.years,
            lang = this.props.lang || [],
            months = Array.isArray(lang) ? lang : Array.isArray(lang.months) ? lang.months : [],
            yearMaxIdx = ymArr.length - 1,
            yearIdx = this.state.yearIndexes[padIndex]; //yearMaxIdx


        let yearActive = labelYear === value.year,
            atMinYear = labelYear === ymArr[0].year,
            atMaxYear = labelYear === ymArr[yearMaxIdx].year,
            otherValue = false;
        (values.length > 1) && (otherValue = values[1 - padIndex]);

        let labelTextKey = padIndex === 0 ? 'từ' : 'đến';
        return (
            <div key={padIndex} className='rmp-pad'>
                <div className="rmp-header">
                    <label>
                        {otherValue && typeof this.props.lang[labelTextKey] !== 'undefined' && (<b>{this.props.lang[labelTextKey]}</b>)}
                        {labelYear}
                    </label>
                    <i className={_classnames("rmp-tab", "rmp-btn", "prev", { 'disable': yearIdx === 0 })} data-id={padIndex} onClick={this.goPrevYear}>{'\u22d8'}</i>
                    <i className={_classnames("rmp-tab", "rmp-btn", "next", { 'disable': yearIdx === yearMaxIdx })} data-id={padIndex} onClick={this.goNextYear}>{'\u22d9'}</i>
                </div>
                <ul>
                    {mapToArray(12, i => {
                        var css = '',
                            m = i + 1;
                        if (yearActive && m === value.month) {
                            css = 'active';
                        }
                        if (values.length > 1 && padIndex === 0 && ((labelYear > value.year || labelYear === value.year) && m > value.month)) {
                            css = 'select';
                        }
                        if (values.length > 1 && padIndex === 1 && ((labelYear < value.year || labelYear === value.year) && m < value.month)) {
                            css = 'select';
                        }
                        if (atMinYear && m < ymArr[0].min) {
                            css = 'disable';
                        }
                        if (atMaxYear && m > ymArr[yearMaxIdx].max) {
                            css = 'disable';
                        }
                        if (otherValue) {
                            var y = otherValue.year,
                                _m = otherValue.month || 0,
                                vy = labelYear,
                                vm = i + 1;
                            if (y === vy && _m && ((padIndex === 0 && vm > _m) || (padIndex === 1 && vm < _m))) {
                                css = 'disable';
                            } else if ((y > vy && padIndex === 1) || (y < vy && padIndex === 0)) {
                                css = 'disable';
                            }
                        }
                        var clickHandler = css !== 'disable' ? this.handleClickMonth : undefined;
                        return (
                            <li key={i} className={_classnames("rmp-btn", css)} data-id={padIndex + ':' + (i + 1)} onClick={clickHandler}>{months.length > i ? months[i] : i}</li>
                        )
                    })}
                </ul>
                <ul className="rmp-footer">
                    <li data-id={padIndex} onClick={this.setCurMonth}>Tháng này</li>
                    <li data-id={padIndex} onClick={this.clearValue}>Xóa</li>
                    <li data-id={padIndex} onClick={this.dismiss}>Đóng lại</li>
                </ul>
            </div>
        )
    }

    render() {
        let pads = [];
        if (this.state.values.length > 1) {
            pads.push(this.optionPad(0), this.optionPad(1));
        } else if (this.state.values.length === 1) {
            pads.push(this.optionPad(0));
        }
        return (
            <div className={_classnames('month-picker', this.props.className)}>
                {this.props.children}
                <div className={_classnames("rmp-container", "rmp-table", this.props.className, { 'show': this.state.showed })} >
                    <div className="rmp-overlay" onClick={this._handleOverlayClick}></div>
                    <div className="rmp-cell">
                        <div className={_classnames("rmp-popup", this.props.theme, { 'range': this.state.values.length > 1, 'show': this.state.showed })}>
                            {pads.length > 0 && pads}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    value = () => {
        var values = this.state.values;
        if (values.length >= 2) return { from: values[0], to: values[1] };
        else if (values.length === 1) return values[0];
        return {};
    }
    dismiss = () => {
        if (this.state.closeable) {
            this._onDismiss();
        }
    }
    show = () => {
        // prevent rapid show/hide
        this._onShow();
    }
    setCurMonth = e => {
        if (this.state.showed) {
            var refid = this.getDID(e).split(':'),
                idx = parseInt(refid[0], 10),
                month = new Date().getMonth() + 1,
                year = new Date().getFullYear(),
                values = this.state.values;
            values[idx] = { year: year, month: month };
            this.setState({ values: values });
            this.props.onChange(year, month, idx);
        }
    }
    clearValue = () => {
        this.setState({ value: null })
        this.dismiss();
        this.props.onChange && this.props.onChange();
    }
    _handleOverlayClick = e => {
        if (this.state.closeable) {
            this._onDismiss();
            this.props.onClickAway && this.props.onClickAway(e);
        }
    }
    _onShow = () => {
        setTimeout(() => this.setState({ closeable: true }), 250);
        this.setState({ showed: true });
        this.props.onShow && this.props.onShow();
    }
    _onDismiss = s => {
        this.setState({ showed: false, loading: false, ...s });
        this.props.onDismiss && this.props.onDismiss(this.value());
    }
    handleClickMonth = e => {
        if (this.state.showed) {
            var refid = this.getDID(e).split(':'),
                idx = parseInt(refid[0], 10),
                month = parseInt(refid[1], 10),
                year = this.state.labelYears[idx],
                values = this.state.values;
            values[idx] = { year: year, month: month };
            this.setState({ values: values });
            this.props.onChange(year, month, idx);
        }
    }
    goPrevYear = e => {
        var idx = parseInt(this.getDID(e), 10);
        if (this.state.yearIndexes[idx] > 0) {
            this.setYear(idx, -1);
        }
    }
    goNextYear = e => {
        var idx = parseInt(this.getDID(e), 10);
        if (this.state.yearIndexes[idx] < this.state.years.length - 1) {
            this.setYear(idx, 1);
        }
    }
    setYear = (idx, step) => {
        const yearIndex = this.state.yearIndexes[idx] + step;
        const { yearIndexes } = this.state;
        yearIndexes[idx] = yearIndex;
        var labelYears = this.state.labelYears,
            theYear = this.state.years[yearIndex].year;
        labelYears[idx] = theYear;
        this.setState({
            labelYears: labelYears,
            yearIndexes
        });
        this.props.onYearChange && this.props.onYearChange(theYear);
    }
    getDID = e => {
        var el = e.target;
        return el.dataset ? el.dataset.id : el.getAttribute('data-id');
    }
    _reset = () => {
        var values = this.validValues(this.state.lastRange || this.state.lastValue, this.state.years, this.state.yearIndexes);
        return { values: values };
    }
    _keyDown = e => {
        if (!this.state.showed) return;

        if (e.key === 'Escape') {
            this._onDismiss(this._reset());
            e.stopPropagation();
        } else if (e.key === 'Enter') {
            this._onDismiss();
            e.stopPropagation();
        } else if (this.state.values.length === 1) {
            //console.log(e.key, e.keyCode)
            // const value = this.state.values[0]
            //     , year = value.year
            // let month = value.month
            // if (e.key === 'ArrowLeft') {
            //     month--
            // }
            // else if (e.key === 'ArrowRight') {
            //     month++
            // }
            // else if (e.key === 'ArrowUp') {
            //     month -= 3
            // }
            // else if (e.key === 'ArrowDown') {
            //     month += 3
            // }
            // if (month > 0 && month < 13 && month !== value.month) {
            //     this.setState({ values: [{ year, month }] })
            //     this.props.onChange(year, month, 0)
            //     e.stopPropagation()
            // }
        }
    }
}

MonthPicker.propTypes = {
    years: _propTypes.oneOfType([_propTypes.array, _propTypes.object, _propTypes.number]),
    value: _propTypes.object,
    range: _propTypes.object,
    lang: _propTypes.oneOfType([_propTypes.array, _propTypes.object]),
    onChange: _propTypes.func,
    onYearChange: _propTypes.func,
    onShow: _propTypes.func,
    onDismiss: _propTypes.func,
    onClickAway: _propTypes.func,
    theme: _propTypes.string,
    show: _propTypes.bool
};
MonthPicker.defaultProps = {
    years: getYearsByNum(5),
    onChange: function onChange(year, month, idx) { },
    theme: 'light',
    show: false
};

function mapToArray(num, callback) {
    let arr = [];
    for (let i = 0; i < num; i++) {
        arr.push(callback(i));
    }
    return arr;
}

function getYearMon(year, min, max) {
    let ym = typeof year === 'object' && year.year ? { year: year.year, month: year.month } : { year: year };
    ym.min = min || 1;
    ym.max = max || 12;
    return ym;
}

function getYearsByNum(n, minYear) {
    let maxYear = new Date().getFullYear();
    // n is number of years
    if (n && n > 0 && n < 1000) {
        minYear = minYear || maxYear - n + 1;
    }
    // n is invalid value
    else {
        // n is max year
        if (n && n >= 1000) maxYear = n;

        if (minYear) {
            n = maxYear - minYear + 1;
        } else {
            n = 5;
            minYear = maxYear - n + 1;
        }
    }
    return mapToArray(n, function (i) {
        return getYearMon(minYear + i);
    });
}

function getYearArray(years) {
    if (Array.isArray(years)) return years.map(function (y, i) {
        return getYearMon(y);
    });
    if (typeof years === 'object') {
        let n = 0,
            min = 0,
            ymin = getYearMon(years.min),
            ymax = getYearMon(years.max);
        if (typeof ymin.year === 'number' && ymin.year > __MIN_VALID_YEAR) min = ymin.year;
        if (typeof ymax.year === 'number' && ymax.year >= min) n = ymax.year;
        let arr = getYearsByNum(n, min),
            last = arr.length - 1;
        if (last >= 0) {
            arr[0].min = ymin.month || arr[0].month;
            arr[last].max = ymax.month || arr[last].month;
        }
        return arr;
    } else if (typeof years === 'number' && years > 0) return getYearsByNum(years); else return getYearsByNum(5);
}

export default MonthPicker