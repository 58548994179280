import React from 'react';
import Input from "./Input";

const Radio = (props) => {
    const { disabled, name, label, checked, type, children, ...attribute } = props;
    return (
        <label className="radio">
            <Input {...attribute} type="radio" name={name} checked={checked} disabled={disabled}>
                {children}
            </Input>
            <span className={disabled ? "disabled" : ""}>{label}</span>
        </label>
    );
};

Radio.propTypes = {

};

Radio.defaultProps = {

};

export default Radio;