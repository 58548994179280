import React, { Component } from 'react';
import SelectSingle from './SelectSingle';

class SelectCustom extends Component {
    render() {
        const { value, onChange, options, disabled, placeholder, isClearable } = this.props;
        const val = value ? value : '';

        var valueText = 'value'
        var labelText = 'label'
        return (
            <SelectSingle
                isClearable={isClearable}
                disabled={disabled}
                value={val}
                onChange={onChange}
                options={options}
                valueRef={valueText}
                labelRef={labelText}
                placeholder={placeholder || "Chọn..."}
            />
        );
    }
}

SelectCustom.propTypes = {
};

SelectCustom.defaultProps = {
    placeholder: 'Chọn...'
};

export default SelectCustom;
