import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Radio from '../base/Input/Radio';

function RadioList({ value, name, disabled, data, numberCol, onChange }) {
    const [valueData, setValueData] = useState(null);

    useEffect(() => {
        setValueData(value)
    }, [value])

    return (
        <Row className='mr-0 ml-0'>
            {
                data && data.map((item, index) => {
                    return (
                        <Col className='ml-2' md={numberCol} key={index}>
                            <Radio
                                name={name}
                                disabled={disabled || item.disabled}
                                value={item.value}
                                label={item.label}
                                checked={valueData === item.value && !item.disabled}
                                onChange={(e) => {
                                    if (valueData !== e.target.value) {
                                        setValueData(e.target.value)
                                        onChange(e.target.value);
                                    }
                                }}
                            />
                        </Col>
                    )
                })
            }

        </Row>

    )
}

export default RadioList
