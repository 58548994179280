import { CHECK_ERROR } from "../../components/alert/Alert";
import { Meet } from "./Meet";

class SoDoGhesEndpoint extends Meet {
    constructor() {
        super("/SoDoGhes");
    }

    getDataWithIdSoDo = async (id) => {
        const getPaginateData = async (soDoGhes, preData) => {
            const odataNextLink = soDoGhes['@odata.nextLink'];
            if (odataNextLink) {
                const getNext = async (odataNextLink, preData) => {
                    try {
                        const nextLink = odataNextLink.slice(odataNextLink.indexOf('SoDoGhes') + 8);
                        const value = await this.GetAllow(nextLink);
                        return value;
                    } catch (error) { CHECK_ERROR('Thao tác thất bại') }
                }
                const { value, ...dataGhes } = await getNext(odataNextLink, data);
                return getPaginateData(dataGhes, [...preData, ...value])
            } else { return preData }
        }
        const { value, ...dataGhes } = await this.GetAllow(`?$filter=soDoId eq ${id}&$expand=ghe`);
        let data = value;
        if (dataGhes['@odata.nextLink']) {
            data = await getPaginateData(dataGhes, data);
        }
        return data
    }
}
export default new SoDoGhesEndpoint();