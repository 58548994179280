import React, { useRef, useImperativeHandle, useCallback, useState, useEffect } from "react";
import { Col } from "../../components/gird";
import Menus from "./Menus";
import { FormGroup } from "../../components/base/Form";
import { InputText } from "../../components/base/Input";
import { Label } from "../../components/base/Label";
import './style.scss';

const FormInputMenu = React.forwardRef(
  (
    {
      manipulation,
      path,
      id,
      name,
      icon,
      ordinal,
      parent,
      setLabel,
      setLink,
      setIcon,
      setParent,
      setOrdinal,
      refeshMenu,
      iniMenus
    },
    ref
  ) => {
    const [nameMenu, setNameMenu] = useState(null)
    // refs
    const menuRef = useRef(null);
    useEffect(() => {
      if (parent) {
        const menuParent = iniMenus.find(cur => cur.id === parent);
        if (menuParent) setNameMenu(menuParent.label)
      } else { setNameMenu(null) }
    }, [parent, iniMenus])

    //reload menu
    const reloadTable = useCallback(() => menuRef.current && menuRef.current.queryData(), [])
    useImperativeHandle(ref, () => ({
      refeshMenu: () => menuRef.current && menuRef.current.queryData()
    }));
    return (
      <>
        <FormGroup row>
          <Label sm={2}>URL</Label>
          <Col sm={10}>
            <InputText
              value={path}
              onChange={e => setLink(e.target.value || "")}
              placeholder="Nhập URL"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Tên menu</Label>
          <Col sm={10}>
            <InputText
              value={name}
              onChange={e => setLabel(e.target.value || "")}
              placeholder="Nhập tên"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Icon</Label>
          <Col sm={8}>
            <InputText
              value={icon}
              onChange={e => setIcon(e.target.value || "")}
              placeholder="Nhập icon"
            />
          </Col>
          <Col sm={2}>
            <i className={icon} aria-hidden="true"></i>
          </Col>
        </FormGroup>
        {/* <FormGroup row>
          <Label sm={2}>Mô tả</Label>
          <Col sm={10}>
            <InputText
              value={description}
              onChange={e => setDescription(e.target.value || "")}
              placeholder="Nhập mô tả"
            />
          </Col>
        </FormGroup> */}
        <FormGroup row>
          <Label sm={2}>Độ ưu tiên</Label>
          <Col sm={10}>
            <InputText
              value={ordinal}
              onChange={e => setOrdinal(e.target.value ? e.target.value : "")}
              placeholder="Nhập độ ưu tiên"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Con của menu</Label>
          <Col sm={10}>
            <span>{nameMenu} {nameMenu && <i className="fa-times span-remove" onClick={() => setParent(null)}></i>}</span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2}>Thuộc menu</Label>
          <Col sm={10}>
            <Menus
              ref={menuRef}
              reloadTable={reloadTable}
              refeshMenu={refeshMenu}
              manipulation={manipulation}
              selectedKeys={parent ? [parent] : [""]}
              onSelect={(e, info) => {
                if (info.NodeId) {
                  const menuParent = iniMenus.find(cur => cur.id === info.NodeId);
                  if (menuParent && menuParent.id !== id) {
                    setNameMenu(menuParent.label); setParent(info.NodeId)
                  } else { setNameMenu(null); setParent(null) }
                } else {

                }


              }}
            />
          </Col>
        </FormGroup>
      </>
    );
  }
);

export default FormInputMenu;
