import React, { useState, useEffect } from "react";
import UserContext from "./UserContext";
import { AccountsEndpoint, UploadCaNhanEndpoint } from "../../service/Admin";
import { logoutAccount } from '../../routes/permission';

export default function UserProvider({ children }) {
    // Info User
    const [name, setName] = useState(null);
    const [idAvatar, setIdAvatar] = useState(null);
    const [authorities, setAuthorities] = useState(null);
    // Call API
    useEffect(() => {
        // if (Cookies.get("GDTECABINET")) {
        if (localStorage.info) {
            const name = JSON.parse(localStorage.info) || '';
            // Lấy thông tin tài khoản
            name && setName(name.display_Name)
            // Lấy thông tin vai trò tài khoản
            AccountsEndpoint.getAuthorities()
                .then(result => {
                    setAuthorities(result.resultObj)
                })
                .catch(() => { });
            // Lấy thông tin avatar tài khoản
            // setIdAvatar('/api/admin/allow/Upload/Download?' + Date.now())
            UploadCaNhanEndpoint.GetAvatar().then(result => {
                if (result.type !== "application/json") {
                    const imageObjectURL = URL.createObjectURL(result);
                    setIdAvatar(imageObjectURL);
                } else { setIdAvatar(null); }
            }).catch(() => { });
        } else {
            logoutAccount();
        }

    }, []);
    return <UserContext.Provider value={{ name, idAvatar, authorities }}>{children}</UserContext.Provider>
}