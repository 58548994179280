import React from "react";
import { Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { Col, Row } from "reactstrap"
import { Fragment } from "react";

function TeamPending({ loading, numberRow, ...props }) {
    return (
        <Fragment>
            {
                loading ? <div style={{ padding: '0.5rem' }}>
                    {Array.from(Array(numberRow || 6).keys()).map((cur, i) => {
                        return <Row key={'ske3' + i} className="m-1">
                            <Col md='12'>
                                <Typography component="div" variant={'body1'}>
                                    <Skeleton variant="rect" />
                                </Typography>
                            </Col>
                        </Row>
                    })}
                </div> : props.children
            }
        </Fragment>

    )
}
export default TeamPending