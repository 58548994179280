import { Account } from './Account';
import AuthorityEndpoint from './AuthorityEndpoint';
// import { _URL_EDIT } from '../../actions/constants';

class MenuAdministrationEndpoint extends Account {
    constructor() {
        super("/MenuAdministration");
    }
    // Lấy dữ liệu theo cây
    getMenuTree = () => this.GetAuthenADMINODATA(`?$select=uuid,name,icon,path,parentUuid,Ordinal,Description&$orderby=Ordinal asc&$filter=status eq true`);
    // Danh sách Menu với Authority
    getWithAuthorityWithID = (id) => this.GetAuthenPUBLIC(`${AuthorityEndpoint.path}?id=${id}&select=uuid`);

    // Lấy dữ liệu theo cây với Authority
    getMenuTreeWithAuthority = () => this.GetAuthenADMIN(`${AuthorityEndpoint.path}/getMenusTree`);
    // Lấy dữ liệu theo cây với Authority
    // setAllMenuAdministrationWithAuthority = (id, body) => this.PostAuthenADMIN(`${AuthorityEndpoint.path}${_URL_EDIT}?id=${id}`, body);
    // Cập nhật dữ liệu
    updateMenuAdmin = (menu, conf, odata) => this.UpdateODATA(`(${menu.Uuid})`, menu, conf, odata);
}

export default new MenuAdministrationEndpoint();