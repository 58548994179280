import React from "react";
import ViewImage from "../image/ViewImage";

const Avatar = ({ url, ...attribute }) => {
  return url ? (
    <ViewImage url={url} {...attribute} alt="avatar" />
  ) : (
    <ViewImage {...attribute} concat={`/assets/img/avatar.png`} alt="avatar" />
  );
};

Avatar.defaultProps = {
  style: { width: 35, borderRadius: "50%", height: 'auto' }
};

export default Avatar;
