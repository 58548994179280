import Swal from "sweetalert2";

async function TimeToast(title, message, func) {
    let timerInterval = null;
    const result_4 = await Swal.fire({
        title: title,
        html: message + '<b></b> giây.',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading();
            timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer();
                if (content) {
                    const b = content.querySelector('b');
                    if (b) {
                        b.textContent = Swal.getTimerLeft();
                    }
                }
            }, 100);
        },
        willClose: () => {
            clearInterval(timerInterval);
        }
    });
    /* Read more about handling dismissals below */
    if (result_4.dismiss === Swal.DismissReason.timer) {
        func();
    }
}


/**
 * Cấu hình cho Sweetalert hiển thị kiểu toast ở góc trên bên phải giao diện
 */
function ToastConfig() {
    return Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: toast => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
    });
}

/**
 * Cấu hình cho Sweetalert hiển thị kiểu modal
 */
export function ModalConfig() {
    return Swal.mixin({
        showCancelButton: true,
        confirmButtonText: "Chấp nhận",
        cancelButtonText: "Không",
        showClass: {
            popup: 'animate__animated animate__fadeInDown animate__faster'
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOutUp animate__faster'
        }
    });
}

export function CHECK_TIME(title, mes, func) { return TimeToast(title, mes, func); }

export function CHECK_SUCCESS(mes, titles = "Thông báo", html) {
    return ToastConfig().fire({ icon: "success", title: titles, text: mes, html });
}

export function CHECK_WARNING(mes, titles = "Cảnh báo") {
    return ToastConfig().fire({ icon: "warning", title: titles, text: mes });
}

export function CHECK_ERROR(mes, titles = "Lỗi") {
    return ToastConfig().fire({ icon: "error", title: titles, text: mes });
}

export function CHECK_CONFIRM(mes, titles, icon = "info") {
    return ModalConfig().fire({
        title: titles,
        text: mes,
        icon: icon
    })
        .then(result => result.value && Promise.resolve(result))
        .catch(err => Promise.reject(err));
}

export function CHECK_CONFIRM_INPUT({
    mes,
    titles,
    placeholder = "Vui lòng nhập thông tin",
    icon = "info",
    isValidator,
    typeInput,
    inputValue
}) {
    return ModalConfig().fire({
        title: titles,
        text: mes,
        icon: icon,
        input: typeInput || "textarea",
        inputPlaceholder: placeholder,
        inputValue,
        inputAttributes: { "aria-label": placeholder },
        didOpen: () => {
            const input = Swal.getInput()
            input.setSelectionRange(0, input.value.length)
        },
        inputValidator: value => {
            if (isValidator) {
                if (!value) {
                    return "Vui lòng nhập đầy đủ thông tin";
                }
            }
        },
    }).then(result => {
        return result.value && Promise.resolve({ isConfirmed: true, value: result.value })
    }).catch(err => Promise.reject(err));
}

export function CHECK_CONFIRM_TIME({ mes, titles, icon = "info", }) {

    function updateValue(e) {
        // eslint-disable-next-line no-console
        console.log(e)
    }
    var content = document.getElementById("hour");
    content && content.addEventListener('input', updateValue);
    return ModalConfig().fire({
        title: titles,
        text: mes,
        icon: icon,
        html:
            '<div>' +
            '<div>' +
            '<input type="number" id="hour" min="1" maxlength="2" placeholder="00" max="24" class="swal2-input ml-1 mr-1 mb-1" oninput="javascript: if (this.value.length > this.maxLength || (parseInt(this.value) < parseInt(this.min) || parseInt(this.value) > parseInt(this.max))) this.value = this.value.slice(0, this.maxLength);"><label>Giờ</label>' +
            '<input type="number" id="minute" min="1" max="60" maxlength="2" placeholder="00" class="swal2-input ml-1 mr-1 mb-1" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"><label>Phút</label>' +
            '</div>' +
            '<span class="d-flex justify-content-center align-items-baseline">' +
            '<h4 class="text-blue">Nếu không quy định thời gian vui lòng tích vào ô này</h4>' +
            '<input type="checkbox" id="nonDuration" class="ml-2">' +
            '</span>' +
            '</div>',
        focusConfirm: false,
        preConfirm: () => {
            const noneTime = document.querySelector('#nonDuration').checked;
            const hours = document.querySelector('#hour').value;
            const minutes = document.querySelector('#minute').value;
            if (!noneTime && (!hours && !minutes)) {
                Swal.showValidationMessage('Vui lòng nhập thời gian biểu quyết');
                setTimeout(() => Swal.resetValidationMessage(), 2000)
                return false;
            } else { return { noneTime, times: { hours: parseInt(hours), minutes: parseInt(minutes) } }; }
        }
    }).then(result => {
        return result.value && Promise.resolve({ isConfirmed: true, value: result.value })
    }).catch(err => Promise.reject(err));
}

export function ALERT_SUCCESS(text, title, conf) {
    return ModalConfig().fire({ icon: 'success', title, text, ...conf });
}

export function ALERT_WARNING(text, title, conf) {
    return ModalConfig().fire({ icon: 'warning', title, text, ...conf });
}

export function ALERT_ERROR(text, title, conf) {
    return ModalConfig().fire({ icon: 'error', title, text, ...conf });
}

export function ALERT(conf) {
    return ModalConfig().fire(conf);
}

/**
 * @description sử dụng swal như 1 modal chứa form bên trong,
 * có thể set get giá trị form mà không cần re-render
 * @constructor
 */
export function SwalFormManager() {

    /**
     *
     * @param formElement document.getElementById("my-swal-form")
     * @return {*}
     */
    this.initFormElement = (formElement) => this.formElement = formElement

    this.getFormValue = () => Object.entries(this.formElement)
        .reduce((form, [, { name, value }]) => {
            form[name] = value
            return form
        }, {})

    /**
     *
     * @param formValueObj kiểu obj, những input nào trong form có name trùng với key của obj này sẽ được set value tương ứng
     */
    this.setFormValue = formValueObj => Object.entries(formValueObj)
        .forEach(([key, value]) => {
            if (this.formElement.elements[key]) this.formElement.elements[key].value = value
        })
}
