import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import FontAwesome from "../../icon/FontAwesome";

const ButtonSave = props => {
  const { children, color, ...attribute } = props;
  return (
    <Button color={color || "blue"}  {...attribute}>
      <FontAwesome fa="save" /> {children}
    </Button>
  );
};

ButtonSave.propTypes = {
  children: PropTypes.any
};

ButtonSave.defaultProps = {};

export default ButtonSave;
