import React from 'react';
import _classnames from 'classnames';
import { useNProgress, } from '@tanem/react-nprogress';
import { Container, Progress, Spinner } from 'reactstrap';

export default function NProgress({ isAnimating, color = 'light' }) {
    const { progress, isFinished } = useNProgress({ isAnimating })
    // return the loaded component
    return (
        <Container fluid className={_classnames('fixed-top', { 'collapse': isFinished })}>
            <Progress value={progress * 100} color={color} animated striped className="progress-sm" />
            <Spinner type="grow" color={color} className="float-right" />
        </Container>
    );
}