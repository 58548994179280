import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Col } from "reactstrap";
import Label from '../Label/Label';
import Required from '../Label/Required';
import InputText from '../Input/InputText';
import InputSelect from '../Input/InputSelect';
import InputDate from '../Input/InputDate';
import TextArea from '../Input/TextArea';
import DropdownListForm2 from '../../select/DropdownListForm2';
import RadioList from '../../select/RadioList';
import CheckboxList from '../../select/CheckboxList';
import NumberFormat from "../../numberformat/NumberFormat";
import { Password, InputBoolean, InputNumber, InputFile } from '../Input';
import SelectYear from '../../select/SelectYear';
import SelectCustom from '../../select/SelectCustom';
import SelectMonth from '../../select/SelectMonth';
import { formatDate } from '../../../actions/util';
import { DataSelect2, SelectMulti } from '../../select';
import SelectAsync from '../../select/SelectAsync';
import SelectAsyncMulti from '../../select/SelectAsyncMulti';
import { RenderDownload } from '../../../others/DocViewer';
import { ButtonEdit } from '../Button';

const renderControl = (props, disabled) => {
  let { data, onChange, value, isDetail } = props;
  const setPlaceholder = (name) => `Nhập ${name.toLowerCase()}`;
  let type = isDetail && data.type !== 'data-select' ? 'label' : data.type;
  // eslint-disable-next-line default-case
  switch (type) {
    case "label":
      return (
        <span>
          {data.type === 'datetime' ? formatDate(value) : data.type === 'radio-list' ? value === 2 ? value = 'Thông báo giá' : value = 'Kê khai giá' : value}
        </span>
      )
    case "textbox":
      return (
        <InputText
          disabled={disabled || (data.disabled ? data.disabled : false)}
          name={data.field_name} value={value == null ? "" : value}
          onChange={e => onChange && onChange(e.target.name, e.target.value)}
          placeholder={setPlaceholder(data.title)}
        />
      )
    case "password":
      return (
        <Password
          name={data.field_name} value={value == null ? "" : value}
          onChange={e => onChange && onChange(e.target.name, e.target.value)}
          placeholder={setPlaceholder(data.title)}
        />
      )
    case "textarea":
      return (
        <TextArea
          disabled={(disabled || data.disabled) ? true : false}
          name={data.field_name} value={value == null ? "" : value}
          onChange={e => onChange && onChange(e.target.name, e.target.value)}
          placeholder={setPlaceholder(data.title)}
          style={data.style}
        />
      )
    case "checkbox":
      return (
        <InputBoolean
          disabled={data.disabled ? data.disabled : false}
          name={data.field_name}
          checked={value ? value : false}
          onChange={checked => {
            onChange && onChange(data.field_name, checked)
          }}
          placeholder={setPlaceholder(data.title)}
        />
      )
    case "datetime":
      return (
        <InputDate
          disabled={disabled}
          type={data.type}
          isClearable={data.isClearable}
          enableTime={data.enableTime}
          placeholder={data.placeholder}
          style={{ height: '2rem' }}
          className="date-time"
          name={data.field_name} value={value}
          onChange={date => onChange && onChange(data.field_name, date)}
          placeholderText={setPlaceholder(data.title)}
          maxDate={data.max}
          minDate={data.min}
          enableTimeNotSecond={data.notSecond}
        />
      )
    case "data-select":
      return (
        data.dataSelect &&
        <DropdownListForm2
          isDetail={isDetail}
          isMulti={data.dataSelect.isMulti ? data.dataSelect.isMulti : false}
          disabled={data.disabled ? data.disabled : false}
          url={data.dataSelect.url}
          odata={data.dataSelect.odata}
          where={data.dataSelect.where}
          group_by={data.dataSelect.group_by}
          columns={data.dataSelect.columns}
          valueField={data.dataSelect.value_field}
          textField={data.dataSelect.text_field}
          options={data.dataSelect.options}
          value={value}
          getFullValue={data.getFullValue}
          onChange={(selectedItems, info) => {
            onChange && onChange(data.field_name, selectedItems, info);
          }} />
      )
    case "numeric":
      return (
        <InputNumber
          disabled={disabled}
          name={data.field_name} value={value}
          onChange={e => onChange && onChange(e.target.name, e.target.value)}
          placeholder={setPlaceholder(data.title)}
        />
      )
    case "currency":
      return (
        <NumberFormat
          id={`ctl_${data.field_name}`}
          className="number-format"
          disabled={data.disabled ? data.disabled : false}
          value={value}
          placeholder={"0"}
          thousandSeparator={data.thousandSeparator}
          decimalSeparator={data.decimalSeparator}
          onValueChange={({ values }) => {
            onChange && onChange(data.field_name, parseInt(values.value));
          }}
          onFocus={(event) => event.target.select()}
        />
      )
    case "radio-list":
      return (
        <RadioList
          name={data.field_name}
          disabled={data.disabled ? data.disabled : false}
          data={data.data}
          value={value}
          onChange={(val) => onChange && props.onChange(data.field_name, val)} />
      )
    case "checkbox-list":
      return (
        <CheckboxList
          name={data.field_name}
          disabled={data.disabled ? data.disabled : false}
          data={data.data}
          value={value}
          onChange={(val) => onChange && props.onChange(data.field_name, val)} />
      )
    case "select-year":
      //console.log(value);
      //var val = value ? value.value : null;
      return (
        <SelectYear
          disabled={data.disabled ? data.disabled : false}
          value={value}
          onChange={(selected) => {
            onChange && props.onChange(data.field_name, selected && selected.value)
          }}
          placeholder={setPlaceholder(data.title)}
        />
      )
    case "select-custom":
      return (
        <SelectCustom
          placeholder={data?.placeholder}
          disabled={disabled}
          value={value}
          onChange={event => {
            onChange && props.onChange(data.field_name, event && event.value)
          }}
          options={props.data.options}
          isClearable={data.isClearable}
        />
      )
    case "select-month":
      return (
        <SelectMonth
          value={value}
          onChange={(selected) => {
            onChange && props.onChange(data.field_name, selected && selected.value)
          }}
        />
      )
    case "data-select-odata":
      return (
        <DataSelect2
          isDisabled={disabled}
          fields={data.fields}
          originfield={data.originfield}
          url={data.url}
          name={data.field_name}
          column={{ field: data.value_field, label: data.value_label, type: data.value_type }}
          value={value || null}
          onChange={e => props.onChange(data.field_name, e && data.getFullValue ? e : e.value)}
          whereOne={data.where}
          multiLabel={data.multiLabel}
          eleUrl={1}
          isFullSearch={data.isFullSearch}
          placeholder={data.placeholder}
        />
      )
    case "file":
      return (
        <InputFile
          name={data.field_name}
          onChange={file => props.onChange(data.field_name, file, true)}
          title="Chọn tập tin đính kèm trước khi tải lên"
          accept="application/pdf"
          required={data.required}
          validation={data.validation}
        />
      )
    case "async-select":
      return (
        <SelectAsync
          isMulti={data.isMulti}
          name={data.field_name}
          onChange={value => props.onChange(data.field_name, value)}
          placeholder={data.placeholder || 'Chọn...'}
          loadData={data.loadOptions}
          getOptionValue={data.getOptionValue}
          getOptionLabel={data.getOptionLabel}
          onAdd={data.onAdd}
          value={value}
          disabled={disabled}
        />
      )
    case "async-select-multi":
      return (
        <SelectAsyncMulti
          isMulti={data.isMulti}
          name={data.field_name}
          onChange={value => props.onChange(data.field_name, value)}
          placeholder={data.placeholder || 'Chọn...'}
          loadData={data.loadOptions}
          getOptionValue={data.getOptionValue}
          getOptionLabel={data.getOptionLabel}
          onAdd={data.onAdd}
          value={value}
          disabled={disabled}
          endPoint={data.endPoint}
          {...data}
        />
      )
    case "multi-select":
      return (
        <SelectMulti
          defaultValue={data.defaultValue || []}
          options={data.options}
          column={data.column || { field: 'value', label: 'label', type: 'string' }}
          onChange={value => props.onChange(data.field_name, value)}
        />
      )
  }
}
const FormControl = props => {
  const [isView, setIsView] = useState(false);
  const { data, value, onChange, onChangeRender, inline, colLabel, colValue, classValue, disabled } = props;
  useEffect(() => {
    if (data.type === 'file' && value) setIsView(true);
  }, [value, data])
  const setPlaceholder = (name) => `Nhập ${name.toLowerCase()}`;
  return (
    data && data.type !== "hidden" &&
    <FormGroup row className={`form-control-${data.field_name} align-items-center`} >
      {
        !data.hidden_label && <Col md={colLabel ? colLabel : inline === false ? "12" : "3"}>
          {data.required ? <Required>{data.title}</Required> : <Label>{data.title}</Label>}
        </Col>
      }
      {(isView && value?.path) ? <Col md={(colValue ? colValue : inline === false || data.hidden_label === true ? "12" : "9") + `${classValue || ''}`}>
        <div className="form-control-value" style={data.width && { maxWidth: data.width }}>
          {value && value.path && <>
            <RenderDownload key={value.path} file={value} />
            <ButtonEdit className="h-100 p-1" onClick={() => setIsView(false)} />
          </>}
        </div>
      </Col> : <Col md={(colValue ? colValue : inline === false || data.hidden_label === true ? "12" : "9") + `${classValue || ''}`}>
        <div className="form-control-value" style={data.width && { maxWidth: data.width }}>
          {
            data.render ?
              data.render(value, onChangeRender)
              : data.lookup ?
                <InputSelect
                  name={data.field_name} value={value}
                  onChange={e => onChange && onChange(e.target.name, e.target.value)}
                  placeholder={setPlaceholder(data.title)}
                >
                  {Object.keys(data.lookup).map((key, index) => (
                    <option key={index} value={key}>{data.lookup[key]}</option>)
                  )}
                </InputSelect>
                : renderControl(props, disabled)
          }
        </div>
      </Col>}

    </FormGroup>
  );
}

FormControl.propTypes = {
  data: PropTypes.object.isRequired
};

FormControl.defaultProps = {

}

export default FormControl;
