import React, { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalHeader, ModalFooter, Label, Card, CardBody, Badge, Input, Row } from 'reactstrap';
import { FontAwesome } from '../../../../../../components/icon';
import { BoSungVanDesEndpoint } from '../../../../../../service/Meet';
import { Enums_Mau, _Enums_Loai, _Enum_Loai_Value, _Enum_Trang_Thai_Van_De } from '../../../../../action/defaultEnums';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { AppBar, makeStyles, Tab, Tabs, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import TeamPending from './Pending';
import { CHECK_CONFIRM, CHECK_WARNING } from '../../../../../../components/alert/Alert';
import { TOKEN_CLIENT_INFO } from '../../../../../../actions/constants';
import { useDispatch, useSelector } from 'react-redux';
import { TypeEnums } from '../../../../../../proto/reloadroute_pb';
import { actionGRPC } from '../../../Redux/ActionsMeeting';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import FormAttachFile from '../../FormAttachFile';
import DeniedBoSungVanDesEndpoint from '../../../../../../service/Meet/DeniedBoSungVanDesEndpoint';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: { backgroundColor: '#eee' },
}));

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {(percent * 100).toFixed(0) === '0' ? '' : (percent * 100).toFixed(0) % 5 !== 0 ? `${(percent * 100).toFixed(2)}%` : `${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

function generateRandomColor(numberColor) {
    var letters = '0123456789ABCDEF';
    const colors = Array.from(Array(numberColor).keys()).map(c => {
        var color = '#';
        for (var i = 0; i < 6; i++) { color += letters[Math.floor(Math.random() * 16)]; }
        return color
    })
    return colors;
};

const ModalKetQua = forwardRef((props, ref) => {
    const { _dataKQBQ, isOpen, isClose, isDienBien, loading } = props;
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [data, setData] = useState(null);
    const [dataBieuDo, setDataBieuDo] = useState([]);
    // const [colorChart, setColorChart] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [thamGiaBQ, setThamGiaQB] = useState(0);
    const { noiDung, thamDus, grpc, phienHop } = useSelector(state => state.meetingRProcess);
    const dispatch = useDispatch();

    useEffect(() => {
        if (data?.id && thamDus) {
            // if (data.loaiBieuQuyet === "IN") {
            //     setSoLuongThamDu(thamDus.thamGia.filter(f => f.nhanSuId===true).length)
            // } else if (data.loaiBieuQuyet === "OUT") {
            DeniedBoSungVanDesEndpoint.getListDeny(data.id).then(({ resultObj }) => {
                const canBos = thamDus.thamGia.filter(cur => resultObj.includes(cur.id) === false)
                setThamGiaQB(canBos)
            }).catch(err => { CHECK_WARNING('Lấy dữ liệu không thành công') });

            // }
        }
    }, [thamDus, data])

    const renderLegend = useCallback((dataChart) => {
        return (
            <React.Fragment>
                <Card className="card-rechart-info">
                    <Label className="label-rechart-custom">Thông tin nội dung</Label>
                    <CardBody className="p-3 card-body-rechart-info">
                        <FormGroup row>
                            <Col xs="12">
                                <Input type="textarea" value={data?.name} disabled={true} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col xs="6">Mẫu: {Enums_Mau.find(f => f.value === data?.mau)?.label}</Col>
                            <Col xs="6">Loại: {_Enums_Loai[data?.loai]}</Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col xs="6">Số lượng tham dự:</Col>
                            <Col xs="6">
                                <Badge className="bg-blue text-white">{thamGiaBQ.length}</Badge>
                            </Col>
                        </FormGroup>
                    </CardBody>
                </Card>
                <Card className="card-rechart-info">
                    <Label className="label-rechart-custom">Chú thích & Kết quả</Label>
                    <CardBody className="p-3">
                        <ul className="ul-legend-chart pl-4">
                            {
                                dataChart.map((entry, index) => (
                                    <li key={`item-${index}`}>
                                        <span style={{ border: `1px solid ${entry.fill}`, backgroundColor: `${entry.fill}` }} className="mr-2 span-badge-custom"></span>
                                        <Badge style={{ width: '20%' }} color="primary">{entry.value} / {thamGiaBQ.length}</Badge>
                                        <span style={{ width: '80%' }} className="ml-2">{entry.name}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }, [data, thamGiaBQ.length])

    const handleChange = (event, newValue) => { setValue(newValue); };

    const getThongTinBQ = useCallback((bieuQuyet, status) => {
        status && setIsLoading(status)
        BoSungVanDesEndpoint.getKetQuaBieuQuyet(bieuQuyet.id)
            .then(({ resultObj: { ketQuas, chuaPhanHoi } }) => {
                bieuQuyet.phuongAnBoSungs.forEach((pa, i, arr) => {
                    const indexKQ = ketQuas ? ketQuas.findIndex(kq => Object.keys(kq).includes(pa.id)) : -1;
                    arr[i].soCauTL = indexKQ > -1 ? ketQuas[indexKQ][pa.id] : [];
                });
                setData(pre => ({ ...pre, ..._dataKQBQ, phuongAnBoSungs: bieuQuyet.phuongAnBoSungs }));
            })
    }, [_dataKQBQ])

    const getDataBieuQuyet = useCallback((idVanDe, isLoading) => {
        BoSungVanDesEndpoint.getVanDesById(idVanDe).then(res => {
            getThongTinBQ(res, isLoading);
            isLoading && setIsLoading(false)
        }).catch(err => { CHECK_WARNING(err) })
    }, [getThongTinBQ])

    useEffect(() => {
        if (_dataKQBQ) {
            getDataBieuQuyet(_dataKQBQ.id, loading);
        } else {
            setData(null);
            setIsLoading(false)
        }
    }, [_dataKQBQ, loading, getDataBieuQuyet]);

    const reloadData = useCallback((_grpc) => {
        if (!isOpen) return;
        const { data } = _grpc;
        if (data.type === TypeEnums.KETQUA) {
            getDataBieuQuyet(data.key);
            dispatch(actionGRPC({ ..._grpc, isViewed: true }));
        } else if (data.type === TypeEnums.VANDEHOANTHANH) {
            isClose();
            dispatch(actionGRPC({ ..._grpc, isViewed: true }));
        }
    }, [dispatch, getDataBieuQuyet, isClose, isOpen])

    useEffect(() => {
        if (grpc && !grpc.isViewed) { reloadData(grpc) }
    }, [grpc, reloadData])

    useImperativeHandle(ref, () => ({
        setKetQuaBQ: (item) => { getThongTinBQ(item) },
    }), [getThongTinBQ])

    const hoanThanh = useCallback(() => {
        let tolalAns = 0;
        data.phuongAnBoSungs.forEach(f => tolalAns += f.soCauTL.length);
        const mes = thamGiaBQ.length - tolalAns !== 0 ? `Có ${thamGiaBQ.length - tolalAns} thành viên chưa phản hồi ý kiến` : '';
        CHECK_CONFIRM(mes, 'Bạn có muốn hoàn thành vấn đề ?')
            .then(res => {
                if (res && res.isConfirmed) {
                    BoSungVanDesEndpoint.editVanDes(_dataKQBQ.id, { trangThai: _Enum_Trang_Thai_Van_De.HoanThanh })
                }
            })
    }, [data, thamGiaBQ, _dataKQBQ]);

    useEffect(() => {
        if (data) {
            // if (ketQuaBieuQuyet && ketQuaBieuQuyet.length === 0) return setDataBieuDo([]);
            let tolalAns = 0;
            data.phuongAnBoSungs.forEach(f => {
                if (data.loai === _Enum_Loai_Value.CongKhai) {
                    tolalAns += f.soCauTL.length;
                } else {
                    tolalAns += f.daChon;
                }
            });
            if (data.mau === "BQ") {
                const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#cd201f'];

                let dataBieuDoBQ = [];

                switch (data.loai) {
                    case _Enum_Loai_Value.CongKhai:
                        dataBieuDoBQ.push(
                            { name: 'Đồng ý', value: data.phuongAnBoSungs.find(f => f.noiDung === 'Đồng ý')?.soCauTL.length || 0, fill: COLORS[0] },
                            { name: 'Không đồng ý', value: data.phuongAnBoSungs.find(f => f.noiDung === 'Không đồng ý')?.soCauTL.length || 0, fill: COLORS[1] },
                            { name: 'Ý kiến khác', value: data.phuongAnBoSungs.filter(f => f.yKienKhac).reduce((sum, kq) => sum + kq?.soCauTL.length, 0), fill: COLORS[2] },
                            { name: 'Không phản hồi', value: thamGiaBQ.length - tolalAns, fill: COLORS[3] }
                        )
                        break;
                    default:
                        dataBieuDoBQ.push(
                            { name: 'Đồng ý', value: data.phuongAnBoSungs.find(f => f.noiDung === 'Đồng ý')?.daChon || 0, fill: COLORS[0] },
                            { name: 'Không đồng ý', value: data.phuongAnBoSungs.find(f => f.noiDung === 'Không đồng ý')?.daChon || 0, fill: COLORS[1] },
                            { name: 'Ý kiến khác', value: data.phuongAnBoSungs.filter(f => f.yKienKhac).reduce((sum, kq) => sum + kq?.daChon, 0), fill: COLORS[2] },
                            { name: 'Không phản hồi', value: thamGiaBQ.length - tolalAns, fill: COLORS[3] }
                        )
                        break;
                }
                setDataBieuDo([...dataBieuDoBQ])
            } else {
                const yKienKhacs = data.phuongAnBoSungs.filter(f => f.yKienKhac);
                let cauTL = 0;
                yKienKhacs.forEach(yKienKhac => {
                    switch (data.loai) {
                        case _Enum_Loai_Value.CongKhai:
                            cauTL += yKienKhac?.soCauTL.length;
                            break;
                        default:
                            cauTL += yKienKhac?.daChon;
                            break;
                    }
                })

                data.phuongAnBoSungs.concat({
                    name: 'Ý kiến khác',
                    value: cauTL || 0
                }, {
                    name: 'Không phản hồi',
                    value: thamGiaBQ.length - tolalAns
                })
                const colors = generateRandomColor(data.phuongAnBoSungs.length + 2);
                let colorForOtherAndNot = colors.slice(-2);
                data.phuongAnBoSungs.forEach((pa, i, arr) => { arr[i]['fill'] = colors[i] })
                const dataBieuDoPA = data.phuongAnBoSungs.filter(f => !f.yKienKhac)
                    .map(item => ({
                        name: item.noiDung,
                        value: data.loai === _Enum_Loai_Value.CongKhai ? item.soCauTL.length : item.daChon,
                        fill: item.fill,
                    })).concat({
                        name: 'Ý kiến khác',
                        value: cauTL || 0,
                        fill: colorForOtherAndNot[0]
                    }, {
                        name: 'Không phản hổi',
                        value: thamGiaBQ.length - tolalAns,
                        fill: colorForOtherAndNot[1]
                    })
                setDataBieuDo([...dataBieuDoPA])
            }
        }
    }, [data, thamGiaBQ])

    const dataResult = useMemo(() => {
        let tolalAns = data?.phuongAnBoSungs.reduce((tolalAns, pa) => {
            if (data?.loai === _Enum_Loai_Value.CongKhai) {
                return tolalAns + pa?.soCauTL.length
            } else { return tolalAns + pa?.daChon }
        }, 0);
        return <Form>
            <FormGroup row>
                <Col xs="3">Nội dung: </Col>
                <Col xs="9"> <Input type="textarea" value={data?.name} disabled={true} />  </Col>
            </FormGroup>
            <FormGroup row>
                <Col xs="3">Mẫu: </Col>
                <Col xs="9">
                    <span>{Enums_Mau.find(f => f.value === data?.mau)?.label}</span>
                    <span className="ml-4 mr-4 devider-vertical" />
                    <span>Số lượng tham dự: <Badge className="bg-blue text-white">{thamGiaBQ.length}</Badge></span>
                    <span className="ml-4 mr-4 devider-vertical" />
                    <span>Loại: <b>{_Enums_Loai[data?.loai]}</b></span>
                </Col>

            </FormGroup>
            <FormGroup row>
                <Col xs="3">Văn bản </Col>
                <Col xs="5">
                    <FormAttachFile
                        id={data?.fileId}
                        phienHopId={phienHop?.id}
                        TEndpoint={BoSungVanDesEndpoint}
                        isEdit={true}
                        stylePDF="PDF" />
                </Col>
            </FormGroup>
            {/* <FormGroup row>
                <Col xs="3">Loại biểu quyết:</Col>
                <Col xs="5">
                    {data && <Badge style={{ fontFamily: 'system-ui' }}>
                        {Enums_LoaiBieuQuyet.find(f => f.value === data.loaiBieuQuyet)?.label}
                    </Badge>}
                </Col>
            </FormGroup> */}
            {
                data && <FormGroup row>
                    <Col xs="3">
                        <span>Kết quả phương án: </span>
                        {thamGiaBQ.length - tolalAns !== 0 &&
                            <span className="d-block" style={{ fontSize: 12, color: 'red' }}>
                                Không phản hồi: {thamGiaBQ.length - tolalAns}
                            </span>}
                    </Col>
                    <Col xs="9">
                        {
                            data.phuongAnBoSungs?.filter(cur => !cur.yKienKhac).map((item, index) => {
                                const soTL = data.loai === _Enum_Loai_Value.CongKhai ? item.soCauTL.length : item.daChon;
                                return <Fragment key={'par-' + index}>
                                    <Card style={{ boxShadow: '2px 2px 3px #ccc' }} key={index} id={'result' + index} className="result-item mb-2">
                                        <CardBody className="p-2 ">
                                            <Badge color="primary">{soTL || 0} / {thamGiaBQ.length}</Badge>
                                            <span className="ml-1">{item.noiDung}</span>
                                        </CardBody>
                                    </Card>
                                    {
                                        data?.loai !== _Enum_Loai_Value.Kin && <UncontrolledPopover placement="top" target={'result' + index}>
                                            <PopoverBody>
                                                {item.soCauTL.map(cb => <Card className="p-1 mb-0">{cb}</Card>)}
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    }

                                </Fragment>
                            })
                        }
                        <div className='color-dimgray font-weight-bold'>Ý kiến khác</div>
                        {
                            data.phuongAnBoSungs?.filter(cur => cur.yKienKhac).map((item, index) => {
                                const soTL = data.loai === _Enum_Loai_Value.CongKhai ? item.soCauTL.length : item.daChon;
                                return <Fragment key={'park-' + index}>
                                    <Card className="result-item mb-2" style={{ boxShadow: '2px 2px 3px #ccc' }} id={'resultOther' + index} key={index}>
                                        <CardBody className="p-2">
                                            <Badge color="primary">{soTL || 0} / {thamGiaBQ.length}</Badge>
                                            <span className="ml-1">{item.noiDung}</span>
                                        </CardBody>
                                    </Card>
                                    {
                                        data?.loai !== _Enum_Loai_Value.Kin && <UncontrolledPopover placement="top" target={'resultOther' + index}>
                                            <PopoverBody>
                                                {item.soCauTL.map(cb => <Card className="p-1 mb-0">{cb}</Card>)}
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    }

                                </Fragment>
                            })
                        }
                    </Col>
                </FormGroup>
            }
        </Form>
    }, [data, phienHop, thamGiaBQ])

    const renderButton = useMemo(() => {
        return <Fragment>
            <Button onClick={isClose} color="danger" size="sm">
                <FontAwesome fa="times" /><span className="ml-1">Đóng</span>
            </Button>
            {
                (isOpen && _dataKQBQ?.trangThai === _Enum_Trang_Thai_Van_De.HoatDong
                    && !isDienBien && TOKEN_CLIENT_INFO?.mainRole !== 'CV'
                    && noiDung.trangThai === 'DangHop')
                && <Button color="primary" size="sm" onClick={hoanThanh}>
                    <FontAwesome fa="save" /><span className="ml-1">Hoàn thành</span>
                </Button>
            }
        </Fragment>
    }, [_dataKQBQ, noiDung, hoanThanh, isClose, isDienBien, isOpen])

    return (
        <Modal isOpen={isOpen ? true : false} size="lg">
            <ModalHeader toggle={isClose}>Kết quả biểu quyết</ModalHeader>
            <ModalBody className="p-0 modal-result">
                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Kết quả" {...a11yProps(0)} />
                            <Tab label="Biểu đồ" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <TeamPending loading={isLoading && data}>{dataResult}</TeamPending>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TeamPending loading={isLoading}>
                            {dataBieuDo.length !== 0 ? <Row style={{ height: '23rem' }}>
                                <Col md='6'>
                                    <ResponsiveContainer>
                                        <PieChart>
                                            {/* <Legend
                                            content={renderLegend}
                                            width={300}
                                            iconType="circle"
                                            layout="vertical"
                                            align="right"
                                            verticalAlign={'top'} /> */}
                                            <Pie
                                                outerRadius={140}
                                                data={dataBieuDo}
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                fill="#fff"
                                                dataKey="value"
                                            >
                                                {dataBieuDo.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.fill} />)}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </Col>
                                <Col md='6'>{renderLegend(dataBieuDo)}</Col>
                            </Row> : <div className="not-found-data">
                                <div className="info">
                                    <img style={{ width: '50%' }} alt="" src="/assets/img/data-not-found.png" />
                                    <span className="d-block text">Không có dữ liệu !</span>
                                </div>
                            </div>}
                        </TeamPending>
                    </TabPanel>
                </div>
            </ModalBody>
            <ModalFooter className="pt-2 pb-2">
                {renderButton}
            </ModalFooter>
        </Modal>
    )
})
export default ModalKetQua