// eslint-disable-next-line no-unused-vars
import { LinearProgress } from "@material-ui/core";
import { memo, useEffect, useState, useRef, useCallback } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
// import { TextArea } from "../../../../../../components/base/Input";
// import useAudioToText from "../../../../../../hooks/useAudioToText";
// import useSpeechToTextCloud from "../../../../../../hooks/useSpeechToTextCloud";

import Tiptap from "../../../StartMeeting/Note/Tiptap/Component/TipTap";
import './style.scss';
import DangKyPhatBieusEndpoint from '../../../../../../service/Meet/DangKyPhatBieusEndpoint';
import { TOKEN_CLIENT_PARTNER, _PARTNER } from "../../../../../../actions/constants";
import { AgentEndpoint } from "../../../../../../service/SpeechToText";
import Cookies from "js-cookie";
import { useAsync } from "../../../../../../hooks";

// eslint-disable-next-line no-unused-vars
// import Note from "../Note/Tiptap";

function Index({ isOpen, onClose }) {
    const [dataSpeech, setDataSpeech] = useState(null);
    // const { stopConnect, startConnect, data, status } = useSpeechToTextCloud(null, false);

    // eslint-disable-next-line no-unused-vars
    const saveTextToPhatBieu = useCallback((text) => {
        return isOpen?.idPhatBieu && DangKyPhatBieusEndpoint.updateData(isOpen.idPhatBieu, { text }, { noLoading: true })
    }, [isOpen])

    // eslint-disable-next-line no-unused-vars
    const checkAccessSpeechToText = async () => {
        if (!TOKEN_CLIENT_PARTNER) {
            try {
                const res = await AgentEndpoint.login({ email: 'toan2@aimed.edu.vn', password: 'toan2@2022' })
                const { access_token, refresh_token, expire_after, expire_at, user_id } = res;
                if (access_token && refresh_token) {
                    Cookies.set('PARTNER_ACCESS_TOKEN', access_token);
                    Cookies.set('PARTNER_REFRESH_TOKEN', refresh_token);
                }
                localStorage.setItem(_PARTNER, JSON.stringify({ expire_after, expire_at, agent_id: user_id }));
            } catch (error) { return false }
        } else { return true }
    }
    
    useEffect(() => {
        // checkAccessSpeechToText()
    }, [])

    // useEffect(() => {
    //     if (data) {
    //         saveTextToPhatBieu(data);
    //         refNote.current && refNote.current.insertData(data);
    //     }
    // }, [data, saveTextToPhatBieu])

    const refNote = useRef();

    const handleSpeechToText = useCallback(async({path})=>{
        try {
            const {resultObj:text} = await DangKyPhatBieusEndpoint.speechToText(path);
            refNote.current && refNote.current.clearData();
            refNote.current && refNote.current.insertData(text);
            setDataSpeech(text);
            return text;
        } catch (error) {
            throw new Error();
        }
    },[])

    const { execute, status } = useAsync(handleSpeechToText, false); 

    useEffect(() => {
        if (isOpen) {  execute(isOpen) }
        return () => { setDataSpeech(null); }
    }, [execute, handleSpeechToText, isOpen])

   

    return <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>
            <i className="fa-solid fa-magnifying-glass-chart mr-1"></i>
            Chuyển đổi giọng nói thành văn bản
        </ModalHeader>
        <ModalBody style={{ padding: '0.5rem 1.5rem 1.5rem 1.5rem' }}>
            {/* <div className="container-reload" id="container-reload">
                <div className="icons" onClick={() => {handleSpeechToText(isOpen)}} aria-describedby='pencil'>
                    <i className="fa-solid fa-file-audio fa-w-11 icon-pencil show transform" />
                </div>
            </div>
            <UncontrolledTooltip placement="top" target="container-reload" >Phân tích lại Audio</UncontrolledTooltip> */}
            <div className="d-flex area-audio-text flex-column">
                {status === 'pending' && <LinearProgress>Đang xử lý</LinearProgress>}
                <div style={{ height: '25rem', overflowY: 'auto', overflowX: 'hidden' }} className="position-relative">
                    {dataSpeech && <Tiptap
                        withPlaceholderExtension={true}
                        ref={refNote}
                        content={dataSpeech || ''}
                        withToolbar={true}
                        withTaskListExtension={true}
                        withLinkExtension={true}
                        isFullExtension={false}
                    />} 
                </div>
            </div>
        </ModalBody>
    </Modal >
}
export default memo(Index) 