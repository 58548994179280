import React, { useCallback, useEffect, useState } from 'react';
import Checkbox from '../base/Input/InputBoolean';

function CheckboxList({ value, name, disabled, data, onChange }) {
    const [selected, setSelected] = useState([])

    useEffect(() => {
        setSelected(value);
    }, [value])

    const handleChange = useCallback((id, value) => {
        var index = selected.indexOf(id);
        if (index < 0) {
            selected.push(id);
        } else {
            selected.splice(index, 1);
        }
        setSelected(selected)
        onChange(selected);
    }, [onChange, selected])

    return (
        data && data.map((item, index) => {
            return (
                <Checkbox key={index}
                    name={name}
                    disabled={disabled || item.disabled}
                    value={item.value}
                    label={item.label}
                    checked={selected && selected.indexOf(item.value) >= 0 && !item.disabled}
                    onChange={(val) => handleChange(item.value, val)}
                />
            )
        })
    )

}

export default CheckboxList