import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from './Tabs';
import './style.scss';
import { _Enum_Trang_Thai_Noi_Dung } from "../../../../action/defaultEnums";


function a11yProps(index) { return { id: `vertical-tab-${index}`, 'aria-controls': `vertical-tabpanel-${index}` }; }

const Vertical = (props) => {
    // props
    const { children, tabs, height, tabWidth, onClick, ...attribute } = props;
    // Styled
    const useStyles = makeStyles(theme => ({
        root: { height, flexGrow: 1, backgroundColor: theme.palette.background.paper, display: 'flex' },
        tab: { overflowWrap: 'anywhere', maxWidth: 'inherit', width: '100%', fontWeight: 'Bold', alignItems: 'left', borderBottom: '1px solid beige' }
    }));
    // classes
    const classes = useStyles();

    const renderTabs = useMemo(() => {
        return <Tabs width={tabWidth} title="Nội dung phiên họp" style={{ borderRadius: '0.3rem 0.3rem 0px 0px' }} {...attribute}>
            {tabs.map((tab, index) => <div key={'tab12' + index}
                onClick={() => onClick({ activeTab: index + 1, id: tab.id })}
                className='position-relative'>
                {tab.trangThai === _Enum_Trang_Thai_Noi_Dung.DangHop && <div className='dot-active pulsate-bck' />}
                <Tab className={classes.tab}
                    value={index}
                    wrapped={true}
                    key={'nd' + index} style={{ textAlign: 'start' }}
                    label={`${index + 1}. ${tab.noiDung}`} {...a11yProps(index)} >
                </Tab>
            </div>)}
        </Tabs>
    }, [attribute, classes.tab, onClick, tabWidth, tabs])
    return tabs && (
        <div className={classes.root}>
            {renderTabs}
            {children}
        </div>
    );
}

export default Vertical;