import { Enums_Loai, Enums_LoaiBieuQuyet, Enums_Mau } from "../../../../../action/defaultEnums";

export const formDataVD = {
    name: {
        field_name: 'name',
        title: 'Vấn đề',
        type: 'textarea',
        required: true,
    },
    mau: {
        field_name: 'mau',
        title: 'Mẫu',
        type: 'select-custom',
        required: true,
        options: Enums_Mau,
    },
    loai: {
        field_name: 'loai',
        title: 'Loại',
        type: 'select-custom',
        options: Enums_Loai,
        // hidden_label: true,
        required: true,
        placeholder: 'Chọn'
    },
    loaiBieuQuyet: {
        field_name: 'loaiBieuQuyet',
        title: 'Loại biểu quyết',
        type: 'select-custom',
        options: Enums_LoaiBieuQuyet,
        hidden_label: true,
        placeholder: 'Loại biểu quyết'
    },
    fileDinhKem: {
        field_name: 'fileDinhKem',
        title: 'File đính kèm',
        type: 'file',
        validation: { size: 25 }
    },
    thamDus: {
        field_name: 'thamDus',
        title: 'D/Sách biểu quyết',
        type: 'radio-list',
        required: true,
        data: [
            {
                value: 'all',
                label: 'Tất cả cán bộ'
            },
            {
                value: 'option',
                label: 'Lựa chọn'
            }
        ]
    }
}

export const dataFormKhac = {
    yKienKhac: {
        field_name: 'yKienKhac',
        title: 'Ý kiến khác',
        type: 'textarea',
        required: true,
    }
}