import { Categories } from "../Categories";
class StreetEndpoint extends Categories {
  constructor() {
    super("/Street");
  }

  getOptionsByDistrictUuid(uuid) {
    return this.getAll(`/District?id=${uuid}&select=name%2Cuuid`);
  }
}
export default new StreetEndpoint();
