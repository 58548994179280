import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    margin: 0,
    backgroundColor: "#006fca",
    color: "#FFF",
    padding: "6px 12px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: "0 6px"
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="subtitle2">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    marginLeft: 0
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { title, open, children, renderFooter, handleClose, maxWidth } = props;
  const useStyles = makeStyles({
    root: {
      overflowY: "unset"
    }
  });
  const classes = useStyles();
  return (
    <Dialog
      scroll={"body"}
      className={classes.root}
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {renderFooter && <DialogActions>{renderFooter} </DialogActions>}
    </Dialog>
  );
}

CustomizedDialogs.defaultProps = {
  open: false
};
