import { useCallback } from 'react';

const getTableQueryUrl = ([table, column], value, i) => `${table}/any(e${i}:e${i}/${column} eq ${value} )`

const isParamsValid = params => {
    const isExistDot = params.indexOf(".");
    if (isExistDot > -1) return params.split(".");
    return '';
}

function getArrColSeachParams(arrColSeach, value) {
    let type = typeof (value);
    let newValue = type === 'boolean' ? value : `'${value}'`
    const strFilter = `${arrColSeach.map((col, i) => {
        if (Array.isArray(isParamsValid(col))) return getTableQueryUrl(isParamsValid(col), value, i);
        return `${col} eq ${newValue}`
    })}`
    return strFilter.replace(",", ` or `);
}
/**
 * @template T
 * @typedef {Object} ColParam 
 * @property {Array<String>} cols Tên cột cần filter
 * @property {String} value Giá trị filter
*/
/**
 * Query odata
 * @param {import('../../../service/Service').Service} TEndpoint 
 * @param {Array<ColParam>} seach 
 * @property {String} isFullTextSeach Tên cột cần filter
*/
function QueryFilterOdata() {
    const QueryOdata = useCallback((TEndpoint, seachs, fullTextSeach, arrExpand = []) => {
        let query = '?';
        if (fullTextSeach) { query += `search=${encodeURIComponent(fullTextSeach)}` }
        if (seachs && seachs.length !== 0) {
            query += `&$filter=`;
            const strQuery = seachs.map(({ cols, value }) => getArrColSeachParams(cols, value))
            query += strQuery.join('or');
        }
        if (arrExpand.length !== 0) {
            query += `&$expand=${arrExpand.join(',')}`
        }
        return TEndpoint ? TEndpoint.SearchAllow(query, null, null, true) : query;
    }, [])
    return { QueryOdata }
}
export default QueryFilterOdata