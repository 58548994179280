import React from 'react';
import PropTypes from 'prop-types';
import { Form } from "reactstrap";

const IForm = (props) => {
    const { children, ...attribute } = props;
    return (
        <Form {...attribute}>
            {children}
        </Form>
    );
};

IForm.propTypes = {
    children: PropTypes.node,
    inline: PropTypes.bool,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
    className: PropTypes.string,
    cssModule: PropTypes.object,
};

IForm.defaultProps = {

};

export default IForm;