import saveAs from '../../actions/file-saver';
import { Service } from '../Service';
import { ALLOWS_API_URL, _API_URL } from '../../actions/constants';
class GateWay extends Service {
    constructor(path) {
        super('', path);
    }
    // Thêm
    add = (data) => this.InsertAllow(data);
    // Sửa
    edit = (Id, data) => this.UpdateODATA(`(${Id})`, data);
    // Xóa
    delete = (id) => this.DeleteADMINODATA(id);

    Upload = (url, file) => this.UploadAuth(url, file)

    addWithBatch = (data) => this.InsertBatch(``, data)

    update(ID, body) {
        return this.edit(ID, body);
    }
    downloadDoc = ({ Id, Subject }) => this.GetAuthen(`/GetFile?id=${Id}`,
        { responseType: "blob", noAlert: true, noLoading: true })
        .then(res => saveAs(res, Subject))
    getAllVanBans = (param, cof) => this.SearchAllOdata(param, cof);

    getVanBans = () => this.SearchAllOdata(`?$select=Id,Name,LoaiVanBanId,OrganId&$filter=Status eq true`);

    downloadVanBan = ({ Id, Name }) => this.GetAuthen(`/GetFile?id=${Id}`,
        { responseType: "blob", noAlert: true, noLoading: true })
        .then(res => saveAs(res, Name))

    getDocUrlByPath = (path) => _API_URL + ALLOWS_API_URL + `/Storage/Download/${path}`;
}

export { GateWay };