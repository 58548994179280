import { Meet } from "./Meet";

class LoaiPhiensEndpoint extends Meet {
    constructor() {
        super("/LoaiPhiens");
    }
    seachFullField(value) {
        return value ? this.SearchAllow(`?$filter=name le '${value}' and code ne 'KM'`) : this.SearchAllow(`?$filter=code ne 'KM'`);
    }
    getAllNhomThanhVien = (id) => this.GetAllow(`(${id})`)
    addLoai= (body) => this.InsertAUTH(body);
    deleteNhomThanhVien = (id) => this.DeleteAUTH(`(${id})`);
    editNhomThanhVien = (id, body) => this.UpdateAUTH(`(${id})`, body);
}
export default new LoaiPhiensEndpoint();