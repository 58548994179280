
//NOI DUNG
export const formDataTTPH = {
    batDau: {
        field_name: 'batDau',
        title: 'Bắt đầu',
        type: 'datetime',
        enableTime: true,
        required: true,
        notSecond: true
    },
    ketThuc: {
        field_name: 'ketThuc',
        title: 'Kết thúc',
        enableTime: true,
        type: 'datetime',
        notSecond: true
    },
    organizations: {
        isMulti: true,
        field_name: 'organizations',
        title: 'Cơ quan chủ trì',
        type: 'async-select-multi',
        required: true,
        fieldLabel: 'organName',
        fieldValue: 'id',
        isClearable: false
    },
    nhanSuId: {
        field_name: 'nhanSuId',
        title: 'Chuyên viên phụ trách',
        type: 'async-select',
    },
}
