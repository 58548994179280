import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import FontAwesome from "../../icon/FontAwesome";

const ButtonSearch = props => {
  const { children, color, ...attribute } = props;
  return (
    <Button color={color || "blue"}  {...attribute}>
      <FontAwesome fa="search" /> {children}
    </Button>
  );
};

ButtonSearch.propTypes = {
  children: PropTypes.any
};

ButtonSearch.defaultProps = {};

export default ButtonSearch;
