import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

const CustomCheckbox = (props) => {
    const { ...attribute } = props;
    return (
        <Checkbox size="small"  {...attribute} />
    )
}

export default CustomCheckbox;