import { Meet } from "./Meet";

class KetLuansEndpoint extends Meet {
    constructor() {
        super("/DeniedBoSungVanDes");
    }

    addDeny = (body) => this.PostAuthen('/Add', body, { noLoading: true });
    deleteDeny = ({ boSungVanDeId, thanhPhanThamDuId }) => {
        const strThamDu = thanhPhanThamDuId ? `&thanhPhanThamDuId=${thanhPhanThamDuId}` : '';
        return this.DeleteAUTH(`/Delete?boSungVanDeId=${boSungVanDeId}${strThamDu}`, { noLoading: true })
    }
    getListDeny = (id, body) => this.GetAllow(`/GetAll?key=${id}`, body);

}
export default new KetLuansEndpoint()