import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const ViewImage = ({ link, style, url, concat, alt, ...attribute }) => {
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    if (url) {
      setAvatar(url)
    }
  }, [url])
  if (url) {
    return <div style={{ textAlign: 'center' }}>
      <img
        style={style}
        src={avatar || '/assets/img/avatar.png'}
        // src={`${UPLOAD_API_URL}/Picture/image?id=${id}`}
        alt={alt}
        {...attribute}
      />
    </div>
  } else {
    if (link) {
      return <div style={{ textAlign: 'center' }}><img style={style} src={link} alt={alt} {...attribute} /></div>;
    }
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <img
        style={style}
        src={`${concat}`}
        alt={alt}
        {...attribute}
      />
    </div>

  );
};

ViewImage.propTypes = {
  link: PropTypes.string,
};

ViewImage.defaultProps = {
  style: { textAlign: 'center' }
};

export default ViewImage;
