import { Meet } from "./Meet";

class KhungNoiDungsEndpoint extends Meet {
    constructor() {
        super("/KhungNoiDungs");
    }
    UploadFile(key, data, config) {
        return this.UploadAuth('/Upload', { ...data, key: key }, config)
    }
    addKhungNoiDung(data) {
        return this.PostAuthen('/ThemKhungNoiDung', data)
    }
    getDataByIdPhien = (id) => this.SearchAllow(`?$expand=boSungVanDes&$filter=phienHopId eq ${id}&$orderby=thuTu`);

    getDataByID = (id) => {
        return this.SearchAllow(`(${id})?$expand=nguoiThucHien($expand=thongTinCaNhan($select=ten,hoVaTenDem)),nhanSu($expand=thongTinCaNhan($select=ten,hoVaTenDem)),organizations($select=id,organName)&$select=id,noiDung,batDau,ketThuc,nhanSuId,fileId`)
    }
    getDataDetailByID = (id) => {
        return this.SearchAllow(`(${id})?$expand=thanhPhanThamDus,nguoiThucHien($expand=thongTinCaNhan($select=ten,hoVaTenDem)),organizations($select=id,organName),nhanSu($expand=thongTinCaNhan($select=ten,hoVaTenDem))&$select=id,noiDung,batDau,ketThuc,trangThai,phienHopId,nhanSuId,tatDangKi,fileId`)
    }

    deleteNoiDung = (id) => {
        return this.DeleteAUTH(`(${id})`);
    }
    getAllFile = (id) => {
        return this.GetAuthen(`/GetAttachments?key=${id}`)
    }
    updateData = (id, data,conf=null) => { return this.PatchAuthen(`(${id})`, data, conf) }

    updateOrganizations = (data) => this.PostAuthen('/AddKhungOrganization', data)

    getDataByOrderAndSession = (idPhien, thuTu) => { return this.SearchAllow(`?$filter=phienHopId eq ${idPhien} and thuTu eq ${thuTu}`) }

    getFullThanhPhanThamDu = (idNoiDung) => {
        return this.SearchAllow(`(${idNoiDung})?$expand=thanhPhanThamDus&$select=id`)
    }
    addKhungThanhPhanThamDu = (data) => {
        return this.PostAuthen('/AddKhungThanhPhanThamDu', data)
    }
    DeleteFileEmpty(fileId) { return this.DeleteAllow(`/XoaFileNoiDungEmpty?fileId=${fileId}`,{ noLoading: true }) }
}
export default new KhungNoiDungsEndpoint();