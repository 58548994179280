import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const ICol = (props) => {
    const { children, ...attribute } = props;
    return (
        <Col {...attribute}>
            {children}
        </Col>
    );
}

const stringOrNumberProp = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);
const columnProps = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape({
        size: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
        order: stringOrNumberProp,
        offset: stringOrNumberProp
    })
]);

ICol.propTypes = {
    xs: columnProps,
    sm: columnProps,
    md: columnProps,
    lg: columnProps,
    xl: columnProps,
    widths: PropTypes.array,
}

ICol.defaultProps = {

};

export default ICol;