import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Badge, FormGroup, Button } from 'reactstrap';
import { CHECK_ERROR, CHECK_WARNING } from '../../../../../../components/alert/Alert';
import { ButtonSave } from '../../../../../../components/base/Button';
import FormControl from '../../../../../../components/base/Form/FormControl';
import { BatchEndpoint, BoSungVanDesEndpoint } from '../../../../../../service/Meet';
import { formDataVD } from './data';
import PhuongAns from './PhuongAns';
import { Enums_LoaiBieuQuyet } from '../../../../../action/defaultEnums';
import { FontAwesome } from '../../../../../../components/icon';
import { v4 as uuid } from "uuid";
import ThamDus from './ThamDus';
import DeniedBoSungVanDesEndpoint from '../../../../../../service/Meet/DeniedBoSungVanDesEndpoint';

const ModalVanDe = React.forwardRef((props, ref) => {
    const { isOpen, isClose, khungNoiDungId, data } = props;
    const [dataVD, setDataVD] = useState({ mau: 'BQ', loaiBieuQuyet: Enums_LoaiBieuQuyet[1].value, thamDus: 'all' });
    const refPhuongAns = useRef();
    const refOldPath = useRef();
    const refDeny = useRef();

    const setNullData = useCallback(() => setDataVD({ mau: 'BQ', loaiBieuQuyet: Enums_LoaiBieuQuyet[1].value, fileId: uuid(), thamDus: 'all' }), [])

    const getDataVanDe = useCallback(async (data) => {
        try {
            const { resultObj: files } = await BoSungVanDesEndpoint.getAllFile(data.fileId);
            const { resultObj: deniedBoSungVanDes } = await DeniedBoSungVanDesEndpoint.getListDeny(data.id);
            const fileDinhKem = {};
            if (files.length > 0) {
                fileDinhKem['name'] = files[0].fileName;
                fileDinhKem['path'] = files[0].filePath;
                refOldPath.current = files[0].filePath
            }
            let thamDus = 'all';
            if (deniedBoSungVanDes.length > 0) {
                thamDus = 'option'
            }
            setDataVD({
                ...data,
                phuongAns: data.phuongAnBoSungs,
                fileDinhKem,
                deniedBoSungVanDes,
                thamDus
            });
        } catch (error) { }
    }, [])

    useEffect(() => {
        if (data) { getDataVanDe(data) }
        else {
            if (khungNoiDungId) setNullData()
        }
    }, [data, getDataVanDe, khungNoiDungId, setNullData])

    const handleChange = useCallback(async (name, value, fileId = null) => {
        setDataVD((pre) => ({ ...pre, [name]: value }));
    }, [])

    const handleValidate = useCallback(() => {
        const initialNameData = ['dataVD'];
        const initialFormData = ['formDataVD']
        const data = [{ dataVD, formDataVD }];
        for (let i = 0; i < data.length; i++) {
            const listRequired = Object.values(data[i][initialFormData[i]]).filter(field => field.required);
            for (let index = 0; index < listRequired.length; index++) {
                const field = listRequired[index];
                let value = (data[i][initialNameData[i]] && data[i][initialNameData[i]][field.field_name]) || null;
                if (value === null || value === '' || value === undefined || value.length === 0) {
                    CHECK_WARNING('Vui lòng nhập "' + field.title + '"');
                    return false;
                }
            }
        }
        return true;
    }, [dataVD]);

    const save = useCallback(async () => {
        if (handleValidate()) {
            try {
                const saveAllFile = (data) => Promise.all(data.map((cur) => BoSungVanDesEndpoint.UploadFile(cur)))
                if (data && data.id) {
                    const { fileDinhKem, thamDus, ...dataEdit } = dataVD;
                    const formPhuongAn = refPhuongAns.current;
                    const dataUpdate = JSON.parse(JSON.stringify(dataEdit));
                    delete dataUpdate['id']; delete dataUpdate['phuongAns'];
                    delete dataUpdate['deniedBoSungVanDes']
                    if (thamDus === 'all') {
                        DeniedBoSungVanDesEndpoint.deleteDeny({ boSungVanDeId: dataVD.id })
                    }
                    await BoSungVanDesEndpoint.UpdateAUTH(`(${data.id})`, dataUpdate);
                    if (fileDinhKem.size) await saveAllFile([{ file: fileDinhKem, type: 'BoSungVanDes', key: dataEdit.fileId, filePathRemove: refOldPath.current }])
                    if (formPhuongAn) await BatchEndpoint.batchRequest('PhuongAnBoSungs', formPhuongAn.getBatchData());
                    isClose()
                } else {
                    const { fileDinhKem, thamDus, ...dataEdit } = dataVD;
                    const phuongAns = refPhuongAns.current ? refPhuongAns.current.getData() : [];
                    const d = { ...dataEdit, khungNoiDungId: khungNoiDungId, phuongAnBoSungs: phuongAns };
                    const listDenied = refDeny.current ? refDeny.current.getDeny() : null;
                    if (listDenied) d['deniedBoSungVanDes'] = listDenied.map(cur => ({ thanhPhanThamDuId: cur }));
                    await BoSungVanDesEndpoint.addVanDes(d);
                    fileDinhKem && await saveAllFile([{ file: fileDinhKem, type: 'BoSungVanDes', key: dataEdit.fileId, filePathRemove: refOldPath.current }])
                    isClose()
                }
            } catch (error) {
                CHECK_ERROR('Không thành công')
            }
        }
    }, [handleValidate, data, dataVD, isClose, khungNoiDungId])

    const isShowThamDu = dataVD?.thamDus !== 'all';
    return (
        <Modal className='prob-form' size={!isShowThamDu ? 'md' : 'lg'} isOpen={isOpen ? true : false} toggle={isClose}>
            <ModalHeader toggle={isClose}><span className="text-white">Tạo mới vấn đề</span></ModalHeader>
            <ModalBody>
                <Row className='d-flex'>
                    <Col className='prop-info' row md={!isShowThamDu ? '12' : '8'}>
                        <Col md='12'><FormControl colLabel="2" colValue="10" data={formDataVD.name} value={(dataVD && dataVD.name) || null} onChange={handleChange} /></Col>
                        <Row className='mr-0 ml-0'>
                            <Col md='6'><FormControl colLabel="4" colValue="8" data={formDataVD.mau} value={(dataVD && dataVD.mau) || null} onChange={handleChange} /></Col>
                            <Col md='6'><FormControl data={formDataVD.loai} value={(dataVD && dataVD.loai) || null} onChange={handleChange} /></Col>
                        </Row>
                        <Col md='12'><FormControl colLabel="2" colValue="10" data={formDataVD.thamDus} value={(dataVD && dataVD.thamDus) || null} onChange={handleChange} /></Col>
                        <Col md='12'><FormControl colLabel="2" colValue="10" data={formDataVD.fileDinhKem} value={(dataVD && dataVD.fileDinhKem) || null} onChange={handleChange} /></Col>
                        <Col md="12">
                            {
                                dataVD && dataVD.mau === 'PA' ? <PhuongAns vanDeId={dataVD && dataVD.id} ref={refPhuongAns} data={dataVD && dataVD.phuongAns} /> :
                                    <FormGroup row>
                                        <Col md='2'></Col>
                                        <Col md='10'>
                                            <Badge color="secondary" pill>Đồng ý</Badge>
                                            <Badge color="secondary" pill className='ml-1'>Không đồng ý</Badge>
                                            <Badge color="secondary" pill className='ml-1'>Ý kiến khác</Badge>
                                        </Col>
                                    </FormGroup>
                            }
                        </Col>
                    </Col>
                    {
                        isShowThamDu && <Col md='4' className='prop-thamDus' >
                            <div className="devider"></div>
                            <ThamDus
                                ref={refDeny}
                                dataVD={dataVD} />
                        </Col>
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <ButtonSave onClick={save}>Lưu</ButtonSave>
                <Button onClick={isClose} color="danger" size="sm">
                    <FontAwesome fa="times" /><span className="ml-1">Đóng</span>
                </Button>
            </ModalFooter>
        </Modal >
    )
})
export default ModalVanDe;
