import { Meet } from "./Meet";

class ConfigMeetsEndpoint extends Meet {
  constructor() {
    super("/ConfigMeets");
  }

  getTemplateByCode = (code) => this.GetAllow(`?$filter=code eq '${code}'`)
  deleteTemplate = (id) => this.DeleteAUTH(`(${id})`);
  editTemplate = (id, body) => this.UpdateAUTH(`(${id})`, body);
  addTemplate = (body) => this.PostAuthen('', body);

}
export default new ConfigMeetsEndpoint()