import { Meet } from "./Meet";

class BatchEndpoint extends Meet {
    constructor() {
        super("");
    }
    batchRequest(endpoint, phuongAns) {
        return this.InsertBatchAUTH(endpoint, phuongAns)
    }
}
export default new BatchEndpoint();