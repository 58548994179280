import { useState, useEffect, useCallback, useRef } from "react"
// eslint-disable-next-line no-unused-vars
import { _API_URL_AGENT, TOKEN_CLIENT_PARTNER } from '../actions/constants'
import { randomCode } from "../actions/util";
import { PhienHopsEndpoint } from "../service/Meet";
import { AgentEndpoint } from "../service/SpeechToText";

export const Action = { GET_MIC: 'GET_MIC', SPEECH_TO_TEXT: 'SPEECH_TO_TEXT' };

// channel tuan : 6295942d6456c57534be6ac4
const typeAction = {
    'GET_MIC': {
        socketMessage: {
            path: "/channel/join",
            body: {
                channel_id: "6294865f6456c57534be6ac3",
                channel_group: "agent"
            }
        },
        leaveChannel: {
            path: "/channel/leave",
            body: {
                channel_id: "6294865f6456c57534be6ac3",
                channel_group: "agent"
            }
        }
    },
    'SPEECH_TO_TEXT': {
        socketMessage: {
            path: "/channel/join",
            body: {
                channel_id: "6296e33234f1fc3976fb6f55",
                channel_group: "live_meeting"
            }
        },
        leaveChannel: {
            path: "/channel/leave",
            body: {
                channel_id: '6296e33234f1fc3976fb6f55',
                channel_group: "live_meeting"
            }
        }
    }
}

const useSpeechToText = (phienHopId, action, isAutoConnect) => {
    const [data, setData] = useState();
    // const [idAgent, setIdAgent] = useState(null);
    const [status, setStatus] = useState(false);
    const agentRef = useRef('');
    // const [idAgent, setIdAgent] = useState(null);
    const refSocket = useRef();

    const startConnect = useCallback(async (action, agent_id, dataChannel) => {
        setStatus(true);
        const { socketMessage } = typeAction[action];
        const { channelId, channelStatus, agentId } = dataChannel;
        let host = '';
        const curAgentId = agent_id || agentId;
        // setIdAgent(curAgentId);
        agentRef.current = curAgentId;
        if (curAgentId) {
            switch (action) {
                case Action.GET_MIC:
                    host = encodeURI(`wss://agent.gdtvietnam.com/on-premise/v1/live/ws/agent/${curAgentId}/device`);
                    break;
                case Action.SPEECH_TO_TEXT:
                    host = encodeURI(`wss://agent.gdtvietnam.com/on-premise/v1/ws`);
                    break;
                default:
                    break;
            }
            if (action === Action.SPEECH_TO_TEXT) {
                if (!channelId || (channelId && channelStatus !== 'online')) {
                    const { id: idChannel } = await AgentEndpoint.createChannel({
                        agent_id: curAgentId, config_allow_identify: false,
                        name: randomCode(9)
                    });
                    socketMessage.body.channel_id = idChannel;
                    AgentEndpoint.startChannel(curAgentId);
                    PhienHopsEndpoint.updateData(phienHopId, { channelId: idChannel, channelStatus: 'online', agentId: curAgentId });
                } else {
                    socketMessage.body.channel_id = channelId;
                }
            }
            let socket = new WebSocket(host);
            refSocket.current = socket;
            socket.onopen = function (e) {
                socket.send(JSON.stringify(socketMessage));
            };
            socket.onmessage = function (mes) {
                switch (action) {
                    case Action.GET_MIC:
                        const json = JSON.parse(mes.data)
                        setData({ listMic: json.data.list_mic, mic: json.data.mic.device });
                        break;
                    case Action.SPEECH_TO_TEXT:
                        const jsonSpeechTotext = JSON.parse(mes.data)
                        if (jsonSpeechTotext.object === 'live_text' && jsonSpeechTotext?.data?.is_final &&
                            !jsonSpeechTotext?.data?.is_processed) {
                            setData(jsonSpeechTotext?.data?.text);
                        }
                        break;
                    default:
                        break;
                }
            };

            socket.onclose = function (event) { setStatus(false); };

            socket.onerror = function (error) {
                setStatus(false);
                alert(`Mất kết nối đến server Speech to text`);
            };

        }
    }, [phienHopId]);

    const stopConnect = useCallback((id) => {
        const agentId = agentRef.current || id;
        if (Action.SPEECH_TO_TEXT === action && agentId) {
            AgentEndpoint.stopChannel(agentRef.current || id);
            PhienHopsEndpoint.updateData(phienHopId, { channelId: null, channelStatus: null });
        }
        setStatus(false);
        refSocket.current && refSocket.current.close();
    }, [action, phienHopId])

    useEffect(() => {
        isAutoConnect && startConnect(action);
        return () => { refSocket.current && refSocket.current.close() }
    }, [action, isAutoConnect, startConnect])

    return { data, startConnect, stopConnect, status }
}
export default useSpeechToText