import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

const Meeting = forwardRef(({ children }, ref) => {
    // eslint-disable-next-line no-unused-vars
    const [count, setCount] = useState(0);
    const updateData = useCallback(() => {
        setCount(pre => pre += 1);
    }, [])
    useImperativeHandle(ref, () => ({
        updateData,
    }));
    // The click event on this button will bubble up to parent,
    // because there is no 'onClick' attribute defined
    return (
        <div>
            {/* <ButtonAdd>{count}</ButtonAdd> */}
            {children}
        </div>
    );
})
export default Meeting
