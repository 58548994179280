import { Meet } from "./Meet";

class DangKyPhatBieusEndpoint extends Meet {
    constructor() {
        super("/DangKyPhatBieus");
    }

    getDataFullByID = (id) => { return this.SearchAllow(`(${id})?&$select=path`) }

    getDataByIDNoiDung = (id) => this.SearchAllow(`?$filter=khungNoiDungId eq ${id}&$expand=thanhPhanThamDu($expand=nhanSu,khachMoi)`);

    getDataByIDOnMeet = (id) => { return this.SearchAllow(`(${id})?$expand=thanhPhanThamDu($expand=nhanSu,khachMoi)`) }

    getDataByID = (id) => { return this.SearchAllow(`(${id})?&$select=id,noiDung`) }

    updateData = (id, data, config) => { return this.PatchAuthen(`(${id})`, data, config) }

    deletePhatBieu = (id) => { return this.DeleteAUTH(`(${id})`); }

    xoaPhatBieu = (id) => { return this.DeleteAllow(`/XoaDangKyPhatBieu?key=${id}`); }

    dangKyPhatBieu = (data) => { return this.PostAuthen('/DangKyPhatBieu', data) }

    xemPhatBieu = (data) => { return this.GetAuthen(`/XemPhatBieu?key=${data.key}&key2=${data.key2}`) }

    findDangKyByThamDu = (thanhPhanThamDu, noiDungId) => {
        return this.SearchAllow(`?$filter=thanhPhanThamDuId eq ${thanhPhanThamDu} and khungNoiDungId eq ${noiDungId}`)
    }
    findDangKyByNoiDung = (noiDungId) => {
        return this.SearchAllow(`?$filter= khungNoiDungId eq ${noiDungId}`)
    }
    saveAudio = (key, file, option) => { return this.UploadAuth('/UploadGhiAm', { key, file }, option) }

    openRecordAudio = (idPhatBieu) => { return this.GetAuthen(`/GhiAmPhatBieu?phatBieuId=${idPhatBieu}`) }

    //Dừng phát biểu
    dungPhatBieu = (data) => this.PostALLow(`/DungPhatBieu`, data)

    speechToText = (patch) => {
        return this.GetAuthen(`/SpeechToText?path=${patch}`, { noLoading: true })
    }
}
export default new DangKyPhatBieusEndpoint();