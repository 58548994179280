import React, { Component, Fragment } from "react";
import ReactSelect, { components } from "react-select";
// import makeAnimated from 'react-select/animated';
import FontAwesome from "../icon/FontAwesome";
import SpinnerSmall from "../spinners/SpinnerSmall";
import PropTypes from 'prop-types';

const colourStyles = {
  multiValueLabel: styles => ({
    ...styles,
    padding: "0px 0px 0px 6px",
    fontSize: 13
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  control: styles => ({
    ...styles,
    backgroundColor: "white",
    minHeight: "38px",
    // height: 'calc(1.5em + 0.5rem + 2px)',
    fontSize: "0.76563rem",
    lineHeight: "1.5",
    cursor: "pointer",
    borderRadius: "0.2rem"
  }),
  menu: styles => ({ ...styles, zIndex: 2300 }),
  option: styles => ({ ...styles, fontSize: "0.76563rem", cursor: "pointer" }),
  input: styles => ({ ...styles, position: "unset" }),
  placeholder: styles => ({ ...styles, marginLeft: 5, marginRight: 5 }),
  singleValue: (styles, { data }) => ({
    ...styles,
    marginTop: 1,
    transform: "inherit",
    top: "unset"
  }),
  container: styles => ({ ...styles, width: "100%" }),
  valueContainer: styles => ({ ...styles, padding: "2px 8px" })
};

const LoadingIndicator = props => {
  return <SpinnerSmall {...props} />;
};

const IndicatorsContainer = props => (
  <components.IndicatorsContainer
    {...props}
    getStyles={() => ({ display: "flex", height: "100%" })}
  />
);

const ClearIndicator = props => {
  const {
    innerProps: { ref, ...restInnerProps }
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={{
        color: "rgb(204, 204, 204)",
        transition: "color 150ms ease 0s",
        boxSizing: "border-box"
      }}
    >
      <FontAwesome
        fa="times"
        style={{
          marginTop: 2,
          padding: "5px 5px 5px 5px",
          verticalAlign: "bottom"
        }}
      />
    </div>
  );
};

const IndicatorSeparator = ({ innerProps }) => (
  <span
    {...innerProps}
    style={{
      alignSelf: "stretch",
      width: 1,
      backgroundColor: "rgb(204, 204, 204)",
      marginTop: 2,
      marginBottom: 2,
      marginRight: 5,
      marginLeft: 5
    }}
  />
);

const DropdownIndicator = props => (
  <components.DropdownIndicator
    {...props}
    getStyles={() => ({ color: "hsl(0,0%,80%)" })}
  >
    <FontAwesome
      fa="angle-down"
      style={{ marginTop: 2, padding: "5px 9px 5px 5px" }}
    />
  </components.DropdownIndicator>
);

const MenuList = props => {
  return (
    <components.MenuList
      {...props}
      getStyles={() => ({ maxHeight: 300, zIndex: 1021, overflowY: "auto" })}
    >
      {props.children}
    </components.MenuList>
  );
};

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = { valueRequire: null };
    this.RefReactSelect = React.createRef();
  }
  render() {
    const {
      placeholder,
      noOptionsMessage,
      loadingMessage,
      required,
      isDisabled,
      menuPlacement,
      ...attribute
    } = this.props;
    const { valueRequire } = this.state;
    return (
      <Fragment>
        <ReactSelect
          {...attribute}
          ref={this.RefReactSelect}
          classNamePrefix="select"
          menuPosition={'fixed'}
          menuPlacement={'auto'}
          styles={colourStyles}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          isDisabled={isDisabled}
          components={{
            IndicatorsContainer,
            ClearIndicator,
            IndicatorSeparator,
            DropdownIndicator,
            LoadingIndicator,
            MenuList
          }}
          onChange={(option) => {
            this.props.onChange(option)
            this.setState({ valueRequire: option ? option.name : null })
          }}
          menuPortalTarget={document.body}
        />
        <input
          readOnly
          name='requireInput'
          type='text'
          tabIndex={1}
          autoComplete="off"
          style={{
            opacity: 0,
            height: 0,
            position: "absolute"
          }}
          value={valueRequire || ''}
          onFocus={() => {
            this.RefReactSelect.current && this.RefReactSelect.current.focus()
          }}
        />
      </Fragment>
    );
  }
}

Select.propTypes = {
  isDisabled: PropTypes.bool.isRequired
};

Select.defaultProps = {
  placeholder: "Chọn...",
  isDisabled: false,
  noOptionsMessage: () => "Không có lựa chọn",
  loadingMessage: () => "Đang tải"
};

export default Select;
