import { Admin } from "./Admin";

class UserRolesEndpoint extends Admin {
    constructor() {
        super("/UserRoles");
    }

    // lấy danh sách quyền
    getRolesByUser = (userId) => this.GetAuthenADMINODATA(`?$expand=AppRoles&$filter=UserId eq ${userId}`, null, true)
}

export default new UserRolesEndpoint()