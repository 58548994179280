// eslint-disable-next-line no-unused-vars
import { ALLOWS_API_URL, _API_URL } from "../../actions/constants";
import { Meet } from "./Meet";

class BoSungVanDesEndpoint extends Meet {
    constructor() {
        super("/BoSungVanDes");
    }
    getDataByIDNoiDung = (id) => this.SearchAllow(`?$expand=phuongAnBoSungs($orderby=thuTu asc),ketQuaBieuQuyets&$filter=khungNoiDungId eq ${id}&$select=id,name,mau,loaiBieuQuyet,khungNoiDungId,trangThai,ngayBatDau,ngayKetThuc,loai,fileId`);
    getDataByIDPhieu = (id) => this.SearchAllow(`?$expand=phuongAnBoSungs&$filter=phieuLayYKienId eq ${id}`);
    getDataByIdPhien = (id) => this.SearchAllow(`?$expand=boSungVanDes&$filter=phienHopId eq ${id}&$orderby=thuTu`);
    addVanDes(data) { return this.PostAuthen('', data); }
    getKetQuaYKien = (id) => this.GetAuthen(`/KetQuaYKien?key=${id}`)
    getKetQuaBieuQuyet = (id) => this.GetAuthen(`/KetQuaBieuQuyet?key=${id}`)
    deleteVD = (id) => this.DeleteAUTH(`(${id})`);
    xoaPhuongAnBoSung = (id) => this.DeleteAUTH(`/XoaPhuongAnBoSung?key=${id}`)

    getVanDesById = (Id) => this.SearchAllow(`(${Id})?$expand=phuongAnBoSungs($orderby=thuTu asc)`);
    getVanDesByIdaFilter = (Id, filter) => this.SearchAllow(`(${Id})?$expand=ketQuaYKiens,phuongAnBoSungs($filter=${filter.join(' or ')};$orderby=thuTu asc)`);
    editVanDes = (IdVanDe, body) => this.UpdateAUTH(`(${IdVanDe})`, body);

    getVanDeByParam = (param) => this.SearchAllow(param);

    getChiTietKetQua = (param) => this.GetAuthen(param);

    createOtherOption = (data) => { return this.PostAuthen('/PatchKetQuaKhac', data); }

    reportPDF = ({ khungNoiDungId, boSungVanDeId }) => {
        const khungNoiDung = khungNoiDungId ? `khungNoiDungId=${khungNoiDungId}` : '';
        const boSungVanDe = boSungVanDeId ? `&boSungVanDeId=${boSungVanDeId}` : '';
        return this.GetAllow(`/PDFBieuQuyet?${khungNoiDung}${boSungVanDe}`, { responseType: 'blob' });
        // return _API_URL + `/meet${ALLOWS_API_URL}/BoSungVanDes/PDFBieuQuyet?${khungNoiDung}${boSungVanDe}`
    }
    UploadFile(data, config) {
        return this.UploadAuth('/Upload', data, config)
    }
    getAllFile(key) {
        return this.GetAuthen(`/GetAttachments?key=${key}`)
    }
    DeleteFileEmpty(fileId) { return this.DeleteAllow(`/XoaFileVanDeEmpty?fileId=${fileId}`,{ noLoading: true }) }
}
export default new BoSungVanDesEndpoint();