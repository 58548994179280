import { useEffect, useRef, useCallback, forwardRef } from 'react';
import noUiSlider from 'nouislider';
import _classnames from 'classnames';

/**
 * @module src/components/Input/InputRange
 * @typedef {Object} InputRangeProps
 * @property {string} className
 * @property {number  | [number, number]} initValues Giá trị bắt đầu của slider
 * @property {number} min Giá trị nhỏ nhất của slider
 * @property {number} max Giá trị lớn nhất của slider
 * @property {string} color màu sắc của slider
 * @property {boolean} tooltips hiển thị tooltip
 * @property {(values: [number, number]) => void} onChange
 * @property {number} minDistance Khoảng cách gần nhất giữa 2 giá trị
 * @property {number} maxDistance Khoảng cách xa nhất giữa 2 giá trị
 * @property {number} step Giá trị nhỏ nhất mỗi lần tăng giảm
 * @property {(value: number) => -1 | 0 | 1 | 2} filterSteps
 * ### hiển thị các mức giá trị
 * - **-1**: `không hiển thị chữ và gạch đánh dấu`
 * - **0**: `không hiển thị chữ`
 * - **1**: `hiển thị chữ lớn`
 * - **2**: `hiển thị chữ nhỏ`
 */

/** Format số mặc định */
const NUM_FORMAT = {
    from: Number,
    to: String
}

/** @type {import('react').ForwardRefExoticComponent<InputRangeProps & import('react').RefAttributes<noUiSlider.noUiSlider>>} */
const InputRange = forwardRef((
    { initValues, min, max, color, tooltips, onChange, minDistance, maxDistance, step, filterSteps, className },
    ref
) => {
    const containerRef = useRef(null);

    /** @type {noUiSlider.Callback} */
    const handleStart = useCallback(values => {
        /** @type {noUiSlider.noUiSlider} */
        const slider = containerRef.current?.noUiSlider;
        if (slider && values.length > 1) slider.updateOptions({ limit: maxDistance })

    }, [maxDistance])

    /** @type {noUiSlider.Callback} */
    const handleChange = useCallback((values, handle, unencodedValues) => {
        onChange && onChange(unencodedValues)
    }, [onChange])

    /** Khởi tạo slider */
    useEffect(() => {
        /** @type {noUiSlider.Options} */
        const options = {
            start: initValues,
            step,
            connect: true,
            range: { min, max },
            behaviour: 'drag-tap',
            margin: minDistance,
            tooltips,
            format: NUM_FORMAT,
            ariaFormat: NUM_FORMAT,
            pips: {
                mode: 'steps',
                format: NUM_FORMAT,
                filter: filterSteps,
            },
        };
        if (Array.isArray(initValues) && initValues.length > 1) options.limit = maxDistance;
        const slider = noUiSlider.create(containerRef.current, options);
        slider.on('start', handleStart);
        slider.on('set', handleChange)
        if (typeof ref === 'function') ref(slider);
        else if (ref) ref.current = slider;
        return () => {
            if (slider) {
                slider.off('start');
                slider.off('set')
                slider.destroy();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /** Cập nhật tùy chỉnh slider khi có thay đổi */
    useEffect(() => {
        /** @type {noUiSlider.noUiSlider} */
        const slider = containerRef.current?.noUiSlider;
        if (slider) {
            slider.updateOptions({
                range: { min, max },
                margin: minDistance,
                step,
                tooltips,
                pips: {
                    mode: 'steps',
                    format: NUM_FORMAT,
                    filter: filterSteps,
                }
            })
            const values = slider.get();
            if (Array.isArray(values) && values.length > 1) slider.updateOptions({ limit: maxDistance })
        }
    }, [minDistance, maxDistance, step, filterSteps, min, max, tooltips])

    return <div ref={containerRef} className={_classnames(className, "form-range mb-5", { [`text-${color}`]: color })} />;
})

InputRange.defaultProps = {
    initValues: 50,
    min: 0,
    max: 100,
    step: 5,
    tooltips: true,
    filterSteps: val => val % 5 ? 0 : 2,
}

export default InputRange;