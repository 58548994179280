import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import Button from './Button';
import FontAwesome from '../../icon/FontAwesome';

const StyledButton = styled(Button)`
    padding: 2px 6px;
    font-size: 14px;
`;

const ButtonIcon = props => {
    const { children, ...attribute } = props;
    return (
        <StyledButton {...attribute}>
            <FontAwesome style={{ marginRight: '0.1rem' }} fa={props.icon} {...props} />{children}
        </StyledButton>
    );
}

ButtonIcon.propTypes = {
    icon: propTypes.string.isRequired,
    color: propTypes.string.isRequired
}
ButtonIcon.defaultProps = {
    color: 'primary'
}

export default ButtonIcon