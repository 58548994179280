import {useCallback, useState} from "react";

function useForm(initialForm = null) {
    const [formValue, setFormValue] = useState(() => initialForm)

    const handleInput = useCallback(
        ({target: {name, value}}) =>
            setFormValue(prevState => ({...prevState, [name]: value})), [])

    return {formValue, handleInput, setFormValue}

}

export default useForm
