import React from 'react';
// import PropTypes from 'prop-types';
import IInput from "./Input";

const InputText = (props) => {
    const { type, children, ...attribute } = props;
    return (
        <IInput {...attribute} type="text">
            {children}
        </IInput>
    );
};

InputText.propTypes = {

};

InputText.defaultProps = {

};

export default InputText;