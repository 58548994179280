import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import FontAwesome from '../../icon/FontAwesome';

const ButtonReset = (props) => {
  const { children, ...attribute } = props;
  return (
    <Button color="blue" {...attribute} >
      <FontAwesome fa="sync" />{' '}{children}
    </Button>
  );
};

ButtonReset.propTypes = {
  children: PropTypes.any
};

ButtonReset.defaultProps = {
};

export default ButtonReset;
