import React from 'react';
// import PropTypes from 'prop-types';
import IInput from "./Input";

const TextArea = (props) => {
    const { children, ...attribute } = props;
    return (
        <IInput {...attribute} type="textarea">
            {children}
        </IInput>
    );
};

// TextArea.propTypes = {

// };

// TextArea.defaultProps = {

// };

export default TextArea;