import React, { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Popover, Table, UncontrolledTooltip } from 'reactstrap';
import './style.scss'
import { ButtonCustom, ButtonCancel } from '../../../../../components/base/Button';
import styled from 'styled-components';
import DangKyPhatBieusEndpoint from '../../../../../service/Meet/DangKyPhatBieusEndpoint';
import { CHECK_CONFIRM, CHECK_CONFIRM_INPUT, CHECK_ERROR, CHECK_SUCCESS, CHECK_WARNING } from '../../../../../components/alert/Alert';
import { PopoverBody } from 'reactstrap/lib';
import { Enum_Trang_Thai_Phat_Bieu, _Enum_Loai_Phat_Bieu, _Enum_Trang_Thai_Noi_Dung, _Enum_Trang_Thai_Phat_Bieu } from '../../../../action/defaultEnums';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import ButtonMate from '@material-ui/core/Button';
import Chart from './Chart';
import { TOKEN_CLIENT_INFO } from '../../../../../actions/constants';
import { StorageEndpoint } from '../../../../../service/GateWay';
import { formatDatetime } from '../../../../../actions/util';
import { Avatar } from '../../../../../others';
import { cumulativeOffset } from '../Main';
// import MicRecorder from 'mic-recorder-to-mp3';
import Tranfer from './Tranfer';
import { useSelector } from 'react-redux';
import { TypeEnums } from '../../../../../proto/reloadroute_pb';
import ProcessAudioToText from './ProcessAudioToText'
// import Recording from './Recording';
import { toDataUrlAvatar } from '../../../../../components/avatar';
import { Fab, FormControlLabel, makeStyles, Switch, Tooltip } from '@material-ui/core';
import { KhungNoiDungsEndpoint } from '../../../../../service/Meet';
import RecordAudio from './RecordAudio';
import { FontAwesome } from '../../../../../components/icon';

// Firefox 1.0+
//  var isFirefox = typeof InstallTrigger !== 'undefined';
// var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
export const Action = styled.td`
vertical-align: middle !important;
    text-align: center;
`
export const ActionText = styled.td`
vertical-align: middle !important;
    text-align: left;
`
export function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const thuTuA = a.thuTu
  const thuTuB = b.thuTu

  let comparison = 0;
  if (thuTuA > thuTuB) {
    comparison = 1;
  } else if (thuTuA < thuTuB) {
    comparison = -1;
  }
  return comparison;
}
//color media
const ENUM_COLOR_PHATBIEU = {
  [_Enum_Trang_Thai_Phat_Bieu.DONE]: 'green',
  [_Enum_Trang_Thai_Phat_Bieu.ON]: 'blue',
  [_Enum_Trang_Thai_Phat_Bieu.OFF]: 'orange',
}

//-------------------
const ListenAudio = memo(({ path }) => {
  // eslint-disable-next-line no-unused-vars
  // const [audio, setAudio] = useState(null)
  // const getAudio = useCallback(async (path) => {
  //   const data = await StorageEndpoint.getImgByPath(path);
  //   setAudio(data)
  // }, [])
  // useEffect(() => { if (path) getAudio(path); }, [getAudio, path]);
  console.log(path)
  return path && <audio id="player" controls src={path && StorageEndpoint.getDocUrlByPath(path)} />
})

const ActionPhatBieu = memo((props) => {
  const { isChuTri, isDienBien, handleAllow, deletePhatBieu, item, i, showDelete, stopPhatBieu } = props;
  if (isChuTri) {
    switch (item.daPhatBieu) {
      case _Enum_Trang_Thai_Phat_Bieu.OFF:
        return !isDienBien ? <span className='d-flex justify-content-center'>
          <ButtonCustom className="mr-1" id={"allow" + i} fa="user-check" onClick={() => handleAllow(item)} />
          <UncontrolledTooltip placement="top" target={"allow" + i} >Phát biểu</UncontrolledTooltip>
          {showDelete && <>
            <ButtonCancel id={"cancel" + i} onClick={() => deletePhatBieu(item)} />
            <UncontrolledTooltip placement="top" target={"cancel" + i} >Xóa</UncontrolledTooltip>
          </>}
        </span> : <></>
      case _Enum_Trang_Thai_Phat_Bieu.ON:
        return !isDienBien ? <span className='d-flex justify-content-center align-items-center' style={{ color: 'blue' }}>
          <div className={`badge bg-${ENUM_COLOR_PHATBIEU['ON']}-lt`} >Đang phát biểu</div>
          <ButtonCustom
            id={"allow" + i}
            color="red"
            fa="stop-circle"
            onClick={() => stopPhatBieu(item)} />
          <UncontrolledTooltip placement="right" target={"allow" + i} >
            Dừng phát biểu
          </UncontrolledTooltip>
        </span> : <></>
      default:
        return <div className={`badge bg-${ENUM_COLOR_PHATBIEU[item.daPhatBieu]}-lt`}>
          <i className="far fa-clock mr-1" />{formatDatetime(item.phatBieuTai)}
        </div>
    }
  } else {
    return <div className='d-flex justify-content-center align-items-center'>
      <span className={`badge bg-${ENUM_COLOR_PHATBIEU[item.daPhatBieu]}-lt`} >
        {Enum_Trang_Thai_Phat_Bieu[item.daPhatBieu]}
        {item.phatBieuTai && <div>
          <small><i className="far fa-clock mr-1" />{formatDatetime(item.phatBieuTai)}</small>
        </div>}
      </span>
      {showDelete && <span>
        <ButtonCancel id={"cancel" + i} onClick={() => deletePhatBieu(item)} />
        <UncontrolledTooltip placement="right" target={"cancel" + i} >Hủy</UncontrolledTooltip>
      </span>}
    </div>
  }
})

const useStyles = makeStyles((theme) => ({
  fab: {
    minHeight: '20px',
    width: 'auto',
    height: 'auto'
  },
  secondText: {
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    marginBottom: '0px',
    color: 'darkgray'
  }
}));
//-----------------------
const DangKyPhatBieu = forwardRef(({ khungNoiDungId, isView, isDienBien, dataND }, ref) => {
  const [phatBieus, setPhatBieus] = useState([]);
  const [specified, setSpecified] = useState(null);
  //audio
  const [openPopAudio, setOpenPopAudio] = useState(null);
  const [stateMedia, setStateMedia] = useState(null);
  const [openAudioToText, setOpenAudioToText] = useState(null);
  const { isChuTri, thamDus, noiDung } = useSelector(state => state.meetingRProcess);
  const [isRegister, setIsRegister] = useState(true);
  const visible = useRef();
  //------------
  const refChart = useRef();
  const refAudio = useRef();
  const classes = useStyles();

  useEffect(() => {
    dataND && setIsRegister(!dataND.tatDangKi)
  }, [dataND])

  const getDangKyPhatBieu = useCallback(async (noiDungId, dataNotify) => {
    const { isnew, isChuTri } = dataNotify || {};
    const { value: phatBieus } = await DangKyPhatBieusEndpoint.getDataByIDNoiDung(noiDungId);
    phatBieus.sort(compare);
    phatBieus.forEach((pb, i, arr) => {
      const { thanhPhanThamDu: { nhanSu, khachMoi }, ...data } = pb;
      arr[i] = {
        ...data, userId: nhanSu ? nhanSu.userId : khachMoi?.userId,
        nhanSuId: nhanSu?.id, khachMoiId: khachMoi?.id
      }
    });
    if (isnew && isChuTri) CHECK_SUCCESS(null, 'Thông báo', `<span>Cán bộ <Label className="bold"> ${phatBieus[0].name}</Label> vừa đăng ký phát biểu</span>`)
    setPhatBieus(phatBieus);
  }, [])

  const requestRecorder = useCallback(async () => {
    const { state } = await navigator.permissions.query({ name: 'microphone' })
    if (state === 'denied') {
      return Promise.reject('Vui lòng cho phép ứng dụng truy cập Microphone');
    } else { return true }
  }, [])

  const startRecording = useCallback(async (phatBieu) => {
    try {
      if (isChuTri) {
        await DangKyPhatBieusEndpoint.openRecordAudio(phatBieu.id);
      } else {
        if (!['hidden', ''].includes(visible.current)) {
          const res = await requestRecorder();
          if (res) {
            // await recorder.current.start()
            setStateMedia(pre => {
              let state = { ...pre };
              state = { ...state, data: phatBieu, isOpen: true };
              return state;
            });
          }
        }
      }
    } catch (error) { CHECK_WARNING(error || 'Thao tác không thành công!') }
  }, [isChuTri, requestRecorder])

  const getDangKyPhatBieuWithID = useCallback(async (idPhatBieu, dataNotify) => {
    const { isnew, isChuTri, type } = dataNotify || {};
    const { thanhPhanThamDu: { nhanSu, khachMoi }, khungNoiDungId, ...dangKyPhatBieu } = await DangKyPhatBieusEndpoint.getDataByIDOnMeet(idPhatBieu, { noLoading: true });
    const nhanSuId = nhanSu?.id || khachMoi?.id;
    dangKyPhatBieu['userId'] = nhanSu ? nhanSu.userId : khachMoi?.userId;
    dangKyPhatBieu['nhanSuId'] = nhanSuId;
    //thông báo
    if (isChuTri && type === 4) {
      // check thêm cho cán bộ
      isnew ? CHECK_SUCCESS(null, 'Thông báo', `<span>Cán bộ <Label className="bold"> ${dangKyPhatBieu.name}</Label> vừa đăng ký phát biểu</span>`)
        : dangKyPhatBieu.daPhatBieu === Enum_Trang_Thai_Phat_Bieu.DONE && CHECK_SUCCESS(null, 'Thông báo', `<span>Cán bộ <Label className="bold"> 
      ${dangKyPhatBieu.name}</Label> hoàn thành phát biểu</span>`)
    }
    if (isnew && type === 8 && !isChuTri && [TOKEN_CLIENT_INFO.nhanSuId, TOKEN_CLIENT_INFO.khachMoiId].includes(nhanSuId)) {
      CHECK_SUCCESS(null, 'Thông báo', `<span>Cán bộ <Label className="bold"> ${dangKyPhatBieu.name}</Label> vừa được chỉ định phát biểu</span>`);
    };
    if (khungNoiDungId !== noiDung?.id) {
      const isAllowPhatBieu = dangKyPhatBieu.daPhatBieu === _Enum_Trang_Thai_Phat_Bieu.ON
        && dangKyPhatBieu.thanhPhanThamDuId === thamDus.userThamDu?.id
      if (isAllowPhatBieu) {
        CHECK_SUCCESS(null, 'Thông báo', `Bạn đã được phép phát biểu`);
        startRecording(dangKyPhatBieu);
      }
      return;
    }
    setPhatBieus(pre => {
      const phatBieus = [...pre];
      const i = phatBieus.findIndex(cur => cur.id === dangKyPhatBieu.id);
      if (i > -1) {
        const isAllowPhatBieu = dangKyPhatBieu.daPhatBieu === _Enum_Trang_Thai_Phat_Bieu.ON && dangKyPhatBieu.thanhPhanThamDuId === thamDus.userThamDu?.id
        if (isAllowPhatBieu) {
          CHECK_SUCCESS(null, 'Thông báo', `Bạn đã được phép phát biểu`);
          startRecording(dangKyPhatBieu);
        }
        phatBieus[i] = dangKyPhatBieu;
        return phatBieus;
      } else {
        return [dangKyPhatBieu, ...phatBieus];
      }
    });

  }, [thamDus, startRecording, noiDung])

  const xoaPhatBieuWithID = useCallback((idPhatBieu) => {
    setPhatBieus(pre => {
      const phatBieus = [...pre];
      const i = phatBieus.findIndex(cur => cur.id === idPhatBieu.toLowerCase());
      if (i > -1) {
        phatBieus.splice(i, 1);
        return phatBieus;
      } else { return phatBieus; }
    });
  }, [])

  const addAudioToPhatBieu = useCallback(async (audio, phatBieu) => {
    let pathFile = '';
    if (audio) {
      const { resultObj: path } = await DangKyPhatBieusEndpoint.saveAudio(phatBieu.id, audio, { notLoading: true })
      pathFile = path;
    }
    return DangKyPhatBieusEndpoint.updateData(phatBieu.id, { path: pathFile, daPhatBieu: _Enum_Trang_Thai_Phat_Bieu.DONE }, { noLoading: true })
      .then(res => {
        setStateMedia(null);
        CHECK_SUCCESS('Đã lưu dữ liệu ghi âm');
        return true;
      }).catch(err => { throw new Error(err) })
  }, [])

  // eslint-disable-next-line no-unused-vars
  // const handleStop = useCallback((isRecoding) => {
  //   if (!recorder.current.activeStream) {
  //     return addAudioToPhatBieu(null, isRecoding);
  //   }
  //   return recorder.current.stop().getMp3().then(([buffer, blob]) => {
  //     const file = new File(buffer, `${isRecoding.data.name}.mp3`, {
  //       type: blob.type,
  //       lastModified: Date.now()
  //     });
  //     return addAudioToPhatBieu(file, isRecoding.data);
  //     // const player = new Audio(URL.createObjectURL(file));
  //     // player.controls = true;
  //   }).catch((e) => {
  //     CHECK_ERROR('Ghi âm thất bại');
  //     throw new Error(e);
  //   });
  // }, [addAudioToPhatBieu])

  const handleStop = useCallback(async (content) => {
    if (content) {
      const { audio, data } = content;
      audio.lastModifiedDate = Date.now();
      audio.name = 'Test cán bộ.mp3';
      return addAudioToPhatBieu(audio, data);
    } else {
      throw new Error()
    }
  }, [addAudioToPhatBieu])

  const onFocus = () => { visible.current = document.visibilityState; };

  useEffect(() => { window.addEventListener("visibilitychange", onFocus) }, [])

  useEffect(() => {
    if (khungNoiDungId) getDangKyPhatBieu(khungNoiDungId)
  }, [getDangKyPhatBieu, khungNoiDungId])

  const ghiAmPhatBieu = useCallback(async (id) => {
    const curPhatBieu = await DangKyPhatBieusEndpoint.getDataByID(id.toLowerCase());
    if (curPhatBieu) startRecording(curPhatBieu);
  }, [startRecording])

  //reload grpc
  const updateReload = useCallback(async ({ idDangKyPhatBieu, ...dataNotify }) => {
    if (idDangKyPhatBieu) {
      switch (dataNotify.type) {
        case TypeEnums.XOAPHATBIEU:
          xoaPhatBieuWithID(idDangKyPhatBieu, dataNotify);
          break;
        case TypeEnums.GHIAMPHATBIEU:
          ghiAmPhatBieu(idDangKyPhatBieu);
          break
        case TypeEnums.DUNGPHATBIEU:
          refAudio.current.stopRecording();
          break
        default:
          getDangKyPhatBieuWithID(idDangKyPhatBieu, dataNotify);
          break;
      }
    } else { getDangKyPhatBieu(khungNoiDungId, dataNotify); }
    return true;
  }, [getDangKyPhatBieu, getDangKyPhatBieuWithID, ghiAmPhatBieu, khungNoiDungId, xoaPhatBieuWithID])

  useImperativeHandle(ref, () => ({ updateReload }), [updateReload]);

  const handleDangKy = useCallback(() => {
    CHECK_CONFIRM_INPUT({ mes: `Nhập nội dung phát biểu ?` }).then(res => {
      if (res && res.isConfirmed) {
        DangKyPhatBieusEndpoint.dangKyPhatBieu({ khungNoiDungId, type: _Enum_Loai_Phat_Bieu.IN, noiDung: res.value }).then(res => {
          CHECK_SUCCESS('Đăng ký thành công');
        }).catch(err => CHECK_WARNING(err.message))
      }
    })
  }, [khungNoiDungId]);

  const handleAllow = useCallback((data) => {
    if (data) {
      const { name, thanhPhanThamDuId } = data;
      const phatBieuCanBo = phatBieus.find(cur => cur.daPhatBieu === _Enum_Trang_Thai_Phat_Bieu.ON);
      if (!phatBieuCanBo) {
        CHECK_CONFIRM(`Cho phép cán bộ "${name}" phát biểu?`).then(res => {
          if (res && res.isConfirmed) {
            DangKyPhatBieusEndpoint.xemPhatBieu({ key: khungNoiDungId, key2: thanhPhanThamDuId })
              .then(res => { return }).catch(err => CHECK_ERROR('Không thành công!'))
          }
        })
      } else { CHECK_WARNING('Đang có 1 cán bộ phát biểu') }
    }
  }, [khungNoiDungId, phatBieus])

  // eslint-disable-next-line no-unused-vars
  const changeAudio = useCallback((e) => {
    const player = document.getElementById('player');
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    player.src = url;
  }, [])

  const deletePhatBieu = useCallback(async (phatBieu) => {
    try {
      CHECK_CONFIRM(`Xóa phát biểu cán bộ ${phatBieu.name}?`).then(res => {
        if (res && res.isConfirmed) {
          DangKyPhatBieusEndpoint.xoaPhatBieu(phatBieu.id)
            .then(res => { return }).catch(err => CHECK_ERROR('Không thành công!'))
        }
      })
    } catch (error) { CHECK_ERROR('Thao tác không thành công') }
  }, [])

  const stopPhatBieu = useCallback((phatBieu) => {
    const { khachMoiId, nhanSuId } = phatBieu;
    CHECK_CONFIRM(`Dừng phát biểu cán bộ "${phatBieu.name}" ?`).then(res => {
      if (res && res.isConfirmed) {
        const canBo = thamDus.thamGia.find(cur => cur.nhanSuId === nhanSuId);
        if (canBo && canBo?.diemDanh) {
          DangKyPhatBieusEndpoint.dungPhatBieu(
            {
              phienHopId: noiDung.phienHopId,
              dataId: phatBieu.id,
              nhanSuId: khachMoiId || nhanSuId,
              isKhachMoi: khachMoiId ? true : false
            }).then(res => { return })
        } else {
          DangKyPhatBieusEndpoint.updateData(phatBieu.id, { daPhatBieu: _Enum_Trang_Thai_Phat_Bieu.DONE }, { noLoading: true })
        }
      }
    })
  }, [noiDung?.phienHopId, thamDus])

  const handleOpenAudioToText = useCallback(async ({ id, path }) => {
    // const {resultObj:text} = await DangKyPhatBieusEndpoint.speechToText(path);
    // const file = await StorageEndpoint.getImgByPath(path);
    // file.lastModifiedDate = new Date();
    // file.name = randomCode();
    setOpenAudioToText({ isExistText: true, idPhatBieu: id, path })
  }, [])

  const renderDangKy = useMemo(() => {
    if (phatBieus && phatBieus.length !== 0) {
      const indexOn = phatBieus.findIndex(cur => cur.daPhatBieu === _Enum_Trang_Thai_Phat_Bieu.ON);
      if (indexOn > -1) {
        const on = phatBieus[indexOn];
        phatBieus.splice(indexOn, 1);
        phatBieus.unshift(on)
      }
      return phatBieus.map((f, i) => {
        const getPatch = (pb) => {
          const patch = Object.keys(thamDus.urlCanBos).find(cur => cur === pb.userId);
          return thamDus.urlCanBos[patch] ? StorageEndpoint.getDocUrlByPath(thamDus.urlCanBos[patch])
            : toDataUrlAvatar({ shape: 'circle', name: pb.name });
        }
        const checkShowDelete = (phatBieu) => {
          if (phatBieu.daPhatBieu === _Enum_Trang_Thai_Phat_Bieu.OFF) {
            switch (phatBieu.loai) {
              case _Enum_Loai_Phat_Bieu.CHIDINH:
                if (isChuTri) return true
                return false;
              default:
                if (TOKEN_CLIENT_INFO.nhanSuId === phatBieu.nhanSuId
                  || TOKEN_CLIENT_INFO.khachMoiId === phatBieu.khachMoiId) return true
                return false;
            }
          }
          return false;
        }
        const showDelete = checkShowDelete(f);
        return <tr key={"PB" + i}>
          <Action><Avatar url={() => getPatch(f)} /></Action>
          <ActionText>
            <span className='d-flex justify-content-between align-items-baseline'>
              <p className='mb-0'>{f.name}</p>
              <Tooltip title={f.noiDung} aria-label="add">
                <Fab className={classes.fab}>
                  <FontAwesome fa="circle-info"></FontAwesome>
                </Fab>
              </Tooltip>
            </span>
            <p className={classes.secondText}>{f.tenChucDanh} </p>

          </ActionText>
          <Action>
            {
              f.path ? <>
                <div className='d-flex justify-content-center'>
                  <ButtonCustom id={"Popover-" + i + f.trangThai} data-toggle="tooltip" data-placement="top" title="Nghe phát biểu" fa="play-circle" onClick={() => setOpenPopAudio(f)} color="blue" />
                  <ButtonCustom data-toggle="tooltip" data-placement="top" title="Chuyển audio sang text" className="ml-1" fa="file-audio" onClick={() => handleOpenAudioToText(f)} color="green" />
                </div>
                {openPopAudio && <Popover
                  isOpen={(f && f.id === openPopAudio.id)}
                  target={"Popover-" + i + f.trangThai}
                  toggle={() => setOpenPopAudio(false)}
                  trigger="legacy"
                >
                  <PopoverBody><ListenAudio path={f.path} /></PopoverBody>
                </Popover>}
              </> : <>
                {f.daPhatBieu === _Enum_Trang_Thai_Phat_Bieu.ON && (f.thanhPhanThamDuId === thamDus.userThamDu?.id || isChuTri)
                  && !isDienBien && <>
                    <ButtonCustom id={"audio" + i} fa="microphone-alt" onClick={() => startRecording(f)} color="warning" />
                    <UncontrolledTooltip placement="right" target={"audio" + i} >Ghi âm</UncontrolledTooltip>
                  </>}
              </>
            }
          </Action>
          <Action>
            <ActionPhatBieu
              key={i + 'action'}
              {...{ isChuTri, stopPhatBieu, showDelete, isDienBien, handleAllow, deletePhatBieu, i, item: f }}
            />
          </Action>
        </tr >
      })
    } else {
      return <tr key={'none-data'}><td colSpan="5" className="text-center">Chưa có dữ liệu</td></tr>
    }
  }, [phatBieus, classes, openPopAudio, thamDus.userThamDu?.id, thamDus.urlCanBos, isChuTri, isDienBien, stopPhatBieu, handleAllow, deletePhatBieu, handleOpenAudioToText, startRecording]);

  useEffect(() => {
    const a = document.getElementById('content-register');
    if (a) { a.style.height = window.screen.availHeight - cumulativeOffset(a) - 120; }
  })

  const tranferCanBo = useMemo(() => <Tranfer isOpen={specified} close={() => setSpecified(false)} />, [specified]);

  // const handleAdd = useCallback(() => {
  //   const input = document.getElementById('VbUpload');
  //   input.click();
  // }, []);
  // eslint-disable-next-line no-unused-vars
  const handleVBChange = useCallback(({ target: { files: [file] } }) => {
    setOpenAudioToText(file)
  }, [])

  const renderAction = useMemo(() => {
    return !isView && !isDienBien && <Col md='6' className="text-right d-flex justify-content-end">
      {(TOKEN_CLIENT_INFO?.mainRole === 'CB' && !isChuTri && noiDung?.trangThai === _Enum_Trang_Thai_Noi_Dung.DangHop) && <ButtonMate
        style={{ padding: '1px 7px', background: '#799b00' }}
        onClick={handleDangKy}
        size="small"
        variant="contained" color="secondary">
        <i className="fa-plus mr-1" /> Đăng ký
      </ButtonMate>}
      {(isChuTri && noiDung?.trangThai === _Enum_Trang_Thai_Noi_Dung.DangHop) && <ButtonMate
        style={{ padding: '1px 6px', background: '#799b00' }}
        onClick={() => setSpecified(khungNoiDungId)}
        size="small" variant="contained"
        color="secondary">
        <i className="fa-plus mr-1" />Chỉ định
      </ButtonMate>}
      <ButtonMate className="ml-1"
        onClick={() => refChart.current.setOpenDash(true)}
        style={{ padding: '1px 6px', background: '#0399d7' }}
        size="small"
        variant="contained"
        color="secondary">
        <i className="fa-chart-line mr-1" /> Thống kê
      </ButtonMate>
      {/* <Button className="ml-1" onClick={handleAdd} style={{ height: '100%' }} variant="contained" size="small" color="primary">
        <i class="fa-solid fa-file-audio mr-1" />  Gỡ băng
      </Button> */}
      {/* <input hidden type="file" id="VbUpload" accept=".mp3" onChange={handleVBChange}></input> */}
    </Col>
  }, [noiDung, handleDangKy, isChuTri, isDienBien, isView, khungNoiDungId])

  const handleSwitchStated = useCallback(async (bool) => {
    try {
      setIsRegister(bool);
      await KhungNoiDungsEndpoint.updateData(khungNoiDungId, { tatDangKi: !bool }, { noLoading: true })
    } catch (error) { console.log(error) }
  }, [khungNoiDungId])

  return (
    <Card className="mb-0">
      <CardHeader className="p-2">
        <Col md='6' className="text-white d-flex align-items-center col-md-6">
          <div>Phát biểu ({phatBieus.length})</div>
          {isChuTri && <FormControlLabel
            className='m-0'
            control={<Switch size="small"
              color="primary"
              checked={isRegister}
              onChange={(e) => handleSwitchStated(e.target.checked)} />}
          />
          }
        </Col>
        {renderAction}
      </CardHeader>
      <CardBody className="p-0 table-sticky-dk" id="content-register" >
        <Table size="sm" bordered striped>
          <thead>
            <tr >
              <td className="text-center">Ảnh</td>
              <td className="text-center">Họ Tên</td>
              <td className="text-center">Ghi âm</td>
              <td className="text-center">{isChuTri ? 'Thao tác' : 'Trạng thái'} </td>
            </tr>
          </thead>
          <tbody className="position-sticky overflow-auto">
            {renderDangKy}
          </tbody>
        </Table>
      </CardBody>
      <Chart ref={refChart} phatBieus={phatBieus} />
      <RecordAudio ref={refAudio} {...{ handleStop, stateMedia }} />
      {/* <Recording ref={refAudio} {...{ handleStop, stateMedia }} /> */}
      <ProcessAudioToText isOpen={openAudioToText} onClose={() => setOpenAudioToText(null)} />
      {tranferCanBo}
    </Card>
  )
})
export default DangKyPhatBieu;