import { ALLOWS_API_URL, _API_URL } from "../../actions/constants";
import { Admin } from "./Admin";
const dir = 'canhan';
class UploadCaNhanEndpoint extends Admin {
    constructor() {
        super("/Upload");
    }
    GetAvatar() { return this.GetAllow(`/Download?` + Date.now()) };
    UploadFile(data) { return this.UploadAllow('/Upload', { ...data, dir }) };
    getAllFile = () => { return this.GetAllow(`/ListFiles?dir=${dir}&tree=true`) };
    downloadFileCaNhan = (path) => { return this.GetAllow('/Download/' + path) };
    getDocUrlByPath = (path) => _API_URL + `/admin${ALLOWS_API_URL}/Upload/Download/${path}`;
    deleteFile = (path) => { return this.DeleteAllow('/Delete/' + path) }
}

export default new UploadCaNhanEndpoint()