import { Meet } from "./Meet";

class ThongTinCaNhansEndpoint extends Meet {
    constructor() {
        super("/ThongTinCaNhans");
        // super("");
    }
    findByCMND(id) {
        return this.SearchAllow(`?search=${encodeURIComponent(id)}`)
    }

    findByUuid(uuid) {
        return this.SearchAllow(`/findById?id=${uuid}&select=name,uuid,prefix`)
    }

    getOptionsByDistrictUuid(uuid) {
        return this.SearchAllow(`/District?id=${uuid}&select=name,uuid,prefix`);
    }
    getAllData() {
        return this.SearchAllow('?$select=name%2CUuid%2Ccode&count=true');
    }
    // Thêm
    add = (data) => this.PostAuthen('', data);

    update(ID, body) {
        return this.UpdateAUTH(`(${ID})`, body);
    }
    getDataByID(ID) {
        return this.SearchAllow(`(${ID})`)
    }
    seachFullField(value) {
        return this.SearchAllow('?search=' + encodeURIComponent(value));
    }
    getWithName(data) {
        return this.getAll(`?search=${encodeURIComponent(data)}`)
    }
}

export default new ThongTinCaNhansEndpoint();