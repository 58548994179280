import React from 'react';
// import PropTypes from 'prop-types';
import IInput from "./Input";

const InputNumber = (props) => {
    const { type, children, value, ...attribute } = props;
    return (
        <IInput value={value || ''} {...attribute} type="number">
            {children}
        </IInput>
    );
};

InputNumber.propTypes = {

};

InputNumber.defaultProps = {

};

export default InputNumber;