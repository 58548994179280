import React from 'react';
import PropTypes from 'prop-types';
import { FormText } from "reactstrap";

const IFormText = (props) => {
    const { children, ...attribute } = props;
    return (
        <FormText {...attribute}>
            {children}
        </FormText>
    );
};

IFormText.propTypes = {
    children: PropTypes.node,
    inline: PropTypes.bool,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    color: PropTypes.string,
    className: PropTypes.string,
    cssModule: PropTypes.object,
};

IFormText.defaultProps = {

};

export default IFormText;