import { Admin } from "./Admin";

class UsersEndpoint extends Admin {
    constructor() {
        super("");
    }

    // login Form
    loginAccount = (data) => this.PostAuthenPUBLIC("/Authenticate/Login", data, { noLoading: true })
    // logout 
    // logoutAccount = () => this.Delete("/all", '/Logout')

    // login Account
    logoutAccount = () => this.DeleteAuthenALL('/Logout')

    // lấy danh sách vai trò theo tài khoản
    getAuthorities = () => this.GetAuthen(`/DanhSachVaiTro`)

    // cập nhật thông tin chi tiết tài khoản
    UpdateChiTietTaiKhoan = (body) => this.PostAuthen('/UpdateChiTietTaiKhoan', body)

    // get ID upload
    getDefault = () => this.GetAuthen('/GetIdUpload', { responseType: 'json' })

    // đổi mật khẩu cho tài khoản
    changePassword = (body) => this.PostAuthenALL(`/ChangePasswordAsync`, body)

    //upload
    uploadFile(file) {
        return this.UploadAuthImg('/AvatarUploadAsync', file)
    }

    // Kiểm tra user đã thay đổi password lần đầu hay chưa
    isChangePass = () => this.GetAuthenAll(`/IsChangePass`)

    // Thêm mới tài khoản
    addNewUsers = (body) => this.PostAuthen('/ThemTaiKhoan', body)


    // Thêm vai trò
    addAuthorities = (body) => this.PostAuthen('/ThemVaiTro', body)

}

export default new UsersEndpoint()