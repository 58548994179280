import React, { IconButton, InputBase, Paper } from "@material-ui/core"
import { useCallback, useState } from "react"
import ButtonReset from "../Button/ButtonReset"
import _ from "lodash";

/**
 * @param {Function} onChange
 * @param {Function} onReload
 * @param {string} placeholder
*/

function InputSeach({ onChange, onReload, placeholder, className }) {
    const [seach, setSeach] = useState('')
    const handleReload = useCallback(() => onReload(), [onReload]);
    const onSeach = useCallback((value) => onChange(value), [onChange])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveDebounce = useCallback(_.debounce(onSeach, 1000), []);
    const hanldeSeach = useCallback((value) => {
        saveDebounce(value); setSeach(value);
    }, [saveDebounce])
    return (
        <div className={className}>
            <Paper component="form" className="d-flex justify-content-between" style={{ marginBlockEnd: '0em' }}>
                {onReload && <ButtonReset onClick={() => { setSeach(''); handleReload() }} className="mr-3">Tải</ButtonReset>}
                <InputBase
                    style={{ padding: '6px !important' }}
                    className="p-10"
                    placeholder={placeholder || "Tìm kiếm"}
                    value={seach || ''}
                    onChange={e => hanldeSeach(e.target.value)}
                />
                <IconButton type="submit" disabled={true} aria-label="search" size="small">
                    <i className="fa-solid fa-search"></i>
                </IconButton>
            </Paper>
        </div>
    )
}
export default InputSeach