import { Meet } from "./Meet"

class PhieuLogsEndpoint extends Meet {
    constructor() {
        super("/PhienLogs");
    }
    /////
    getData = (id) => this.GetAllow(`?$filter=phienHopId eq ${id}&$orderby=thoiGian`)

    getAllPhieu = (Id, expand = []) => this.GetAuthen(`(${Id})?$expand=${expand}`)

    getDanhSachThamGia = (key) => this.GetAuthen(`/DanhSachThamGiaYKien?key=${key}`)

    UploadFile(key, data) {
        return this.UploadAuth('/Upload', { ...data, key: key }, null)
    }

}
export default new PhieuLogsEndpoint()