import { memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Fragment } from "react";
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, PopoverBody, Row, Table } from "reactstrap";
import { formatDatetime } from "../../../../actions/util";
import { KhungNoiDungsEndpoint, PhienHopsEndpoint, ThanhPhanThamDusEndpoint } from "../../../../service/Meet";
import Vertical from "./VerticalNoiDung/Vertical";
import Content from "./VerticalNoiDung/Content";
import { Enum_Meeting_Status, _Enum_Trang_Thai_Noi_Dung, _Enum_Trang_Thai_Tham_Du } from "../../../action/defaultEnums";
import StreamMeeting from "./VerticalNoiDung/StreamMeeting";
import Meeting from "./VerticalNoiDung/StreamMeeting/Meeting";
import { UncontrolledPopover } from "reactstrap/lib";
import { Action, ActionText, compare } from "./DangKyPhatBieu";
import FormAttachFile from "./FormAttachFile";
import { TOKEN_CLIENT_INFO } from "../../../../actions/constants";
import { CHECK_CONFIRM, CHECK_ERROR, CHECK_TIME, CHECK_WARNING } from "../../../../components/alert/Alert";
import { forwardRef } from "react";
import DrawerDocument from './DrawerDocument';
import { AccountsEndpoint } from "../../../../service/Admin";
import { useDispatch } from 'react-redux';
import { actionIsChuTri, actionIsUBND, actionPhienHop, actionThanhPhanThamDu } from '../Redux/ActionsMeeting';
import { useSelector } from 'react-redux';
import ButtonMate from "@material-ui/core/Button";
import SpeechToText from "./SpeechToText"
import { Divider, Icon, List, ListItem, ListItemIcon, ListItemText, makeStyles, Popover } from "@material-ui/core";
import { FontAwesome } from "../../../../components/icon";
// eslint-disable-next-line no-unused-vars
import ShareScreen from './ShareScreen';
// eslint-disable-next-line no-unused-vars
import SignalR from './ShareScreen/SignalR'
import LogActivity from "./LogActivity";

export const cumulativeOffset = function (element) {
    var top = 0
    do {
        top += element.offsetTop || 0;
        element = element.offsetParent;
    } while (element);
    return top
};
const useStyles = makeStyles((theme) => ({
    root: { padding: '3px' },
    icon: { minWidth: '2rem' },
    text: { color: 'green' },
    share: { color: 'orange' }
}));

const MenuMeet = memo(({ setShowDocDraw,
    setOpenSpeechToText,
    phienHopId, isDienBien, isChuTri, handleEndMeeting,
    setShowLogActivity
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [shareScreen, setShareSreen] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return <>
        <ButtonMate onClick={handleClick}>
            <FontAwesome style={{ fontSize: 'x-large' }} fa="ellipsis"></FontAwesome>
        </ButtonMate>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
            >
                <ListItem button onClick={() => {
                    setShowDocDraw(true);
                    setAnchorEl(null);
                }}>
                    <ListItemIcon className={classes.icon}>
                        <Icon color="secondary" fontSize="small">file_copy</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Tài liệu phiên họp" />
                </ListItem>
                <Divider />
                <ListItem button onClick={() => {
                    setShowLogActivity(phienHopId);
                    setAnchorEl(null);
                }}>
                    <ListItemIcon className={classes.icon}>
                        <Icon color="secondary" fontSize="small">history</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Diễn biến phiên họp" />
                </ListItem>
                <Divider />
                <ListItem button onClick={() => {
                    setShareSreen(true);
                    setAnchorEl(null);
                }}>
                    <ListItemIcon className={classes.icon}>
                        <Icon className={classes.share} color="secondary" fontSize="small">screen_share_outlined</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Chia sẽ màn hình" />
                </ListItem>
                {
                    TOKEN_CLIENT_INFO.mainRole === 'TK' && <>
                        <Divider />
                        <ListItem button onClick={() => {
                            setOpenSpeechToText(phienHopId)
                            setAnchorEl(null)
                        }}>
                            <ListItemIcon className={classes.icon}>
                                <Icon style={{ color: 'green' }} fontSize="small">check_circle</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Kết luận" />
                        </ListItem>
                    </>
                }
                {
                    !isDienBien && isChuTri && <>
                        <Divider />
                        <ListItem button onClick={() => {
                            setAnchorEl(null)
                            handleEndMeeting(phienHopId)
                        }}>
                            <ListItemIcon className={classes.icon}>
                                <Icon color="primary" fontSize="small">assignment_turned_in</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Kết thúc phiên họp" />
                        </ListItem>
                    </>
                }
            </List>
        </Popover>
        <Modal isOpen={shareScreen} toggle={() => { setShareSreen(null) }} className={'modal-lg'}>
            <ModalHeader> Chia sẽ màn hình </ModalHeader>
            <ModalBody>
                {/* {shareScreen &&
                    // <ShareScreen {...{ phienHopId }} />
                    <SignalR {...{ phienHopId }}></SignalR>
                } */}
            </ModalBody>
        </Modal>
    </>
})

const Main = forwardRef(({ phienHopId, handleClose, isDienBien, nameAgent }, ref) => {
    const [tabActive, setTabActive] = useState({ activeTab: 0, id: null });
    const [isShowStream, setIsShowStream] = useState(false);
    const refMeet = useRef();
    const [showDocDraw, setShowDocDraw] = useState(false);
    const [showLogActivity, setShowLogActivity] = useState(null)
    const [openSpeechToText, setOpenSpeechToText] = useState(null);

    const { isChuTri, thamDus, phienHop } = useSelector(state => state.meetingRProcess);
    const refContent = useRef();
    const refFormFile = useRef();
    const dispatch = useDispatch();

    const endStream = useCallback(() => { refContent.current && refContent.current.endStream(); }, [])

    const handleExit = useCallback(async () => {
        if (TOKEN_CLIENT_INFO?.role === 'TGH' && phienHop.trangThai === Enum_Meeting_Status.DangHop) {
            const thamDu = thamDus && thamDus.thamGia.find(cur => cur.nhanSuId === TOKEN_CLIENT_INFO.nhanSuId);
            try {
                await ThanhPhanThamDusEndpoint.updateThamDus(thamDu.id, { diemDanh: false });
            } catch (error) { CHECK_WARNING('Lỗi kết nối!'); }
        }
    }, [phienHop, thamDus])


    useImperativeHandle(ref, () => ({ endStream, setOpenSpeechToText, handleExit }));

    const getThamDu = useCallback(async (id) => {
        const { value: thamDus } = await ThanhPhanThamDusEndpoint.getFullWithPhienHopId(id);
        const { resultObj: duocDiThay } = await PhienHopsEndpoint.getDuocDiThay(id);
        //get avatar
        const userIds = [];
        thamDus.forEach(ns => ns.nhanSu && userIds.push(ns.nhanSu.userId));
        const { resultObj: urlCanBos } = await AccountsEndpoint.getUrlAvatars(userIds, { noLoading: true });
        // check UBND
        if (TOKEN_CLIENT_INFO.khachMoiId) {
            dispatch(actionIsUBND(false))
        } else {
            const curThamDu = thamDus.find(cur => cur.nhanSuId === TOKEN_CLIENT_INFO.nhanSuId);
            if (!curThamDu?.isGuest || TOKEN_CLIENT_INFO?.role === 'QLH') { dispatch(actionIsUBND(true)); } else {
                const { resultObj } = await PhienHopsEndpoint.CheckUBND(id);
                dispatch(actionIsUBND(resultObj));
            }
        }
        //-----Vang-ThamDu------
        const thamGia = thamDus.filter(cur => cur.trangThaiThamDu === _Enum_Trang_Thai_Tham_Du.ThamGia);
        thamGia.forEach((cur, i, arr) => {
            const exist = duocDiThay.find(dt => dt.id === cur.parentId);
            if (exist) arr[i]['diThayCho'] = exist;
        })
        const vang = thamDus.filter(cur => [_Enum_Trang_Thai_Tham_Du.ThamGia, _Enum_Trang_Thai_Tham_Du.DiThay].includes(cur.trangThaiThamDu) === false);
        let { nhanSuId, khachMoiId } = TOKEN_CLIENT_INFO;
        nhanSuId = nhanSuId === "" ? null : nhanSuId;
        khachMoiId = khachMoiId === "" ? null : khachMoiId;
        const userThamDu = thamDus.find(cur => cur[nhanSuId ? 'nhanSuId' : 'khachMoiId'] === (nhanSuId || khachMoiId))
        return { thamGia, vang, userThamDu, urlCanBos };
    }, [dispatch])

    const updateDataThamDu = useCallback(async (id) => {
        const dataThamDu = await getThamDu(id);
        dispatch(actionThanhPhanThamDu(dataThamDu));
        return true;
    }, [dispatch, getThamDu])

    const getPhienHop = useCallback(async (id) => {
        try {
            const phienHop = await PhienHopsEndpoint.getAllData(id);
            const { khungNoiDungs } = phienHop;
            khungNoiDungs.sort(compare); phienHop['khungNoiDungs'] = khungNoiDungs;
            const activeNoiDung = khungNoiDungs.findIndex(cur => cur.trangThai === _Enum_Trang_Thai_Noi_Dung.DangHop);
            const dataThamDu = await getThamDu(id);
            dispatch(actionPhienHop(phienHop));
            dispatch(actionThanhPhanThamDu(dataThamDu));
            dispatch(actionIsChuTri([phienHop.chuTriId, phienHop.thuKyId].includes(TOKEN_CLIENT_INFO?.nhanSuId)))
            if (activeNoiDung > -1) setTabActive({ activeTab: activeNoiDung + 1, id: khungNoiDungs[activeNoiDung].id });
            if (isDienBien) setTabActive({ activeTab: 1, id: khungNoiDungs[0].id });
        } catch (error) { console.log(error) }
    }, [dispatch, getThamDu, isDienBien])

    useEffect(() => { if (phienHopId) { getPhienHop(phienHopId) } }, [getPhienHop, phienHopId])

    const { name, batDau, ketThuc, khungNoiDungs } = phienHop || {};

    const ListCanBo = ({ canBos, isThamGia }) => {
        return canBos && <Table size="sm" bordered striped className="mb-0">
            <thead>
                <tr style={{ background: 'rgb(0, 111, 202)' }} className="text-white" >
                    <td className="text-center">STT</td>
                    <td style={{ minWidth: '9rem' }} className="text-center">Họ và tên</td>
                    <td className="text-center">Chức danh</td>
                    {isThamGia && <>
                        <td className="text-center">Đi thay cho</td>
                    </>}
                </tr>
            </thead>
            <tbody className="position-sticky overflow-auto">
                {canBos && canBos.map((cb, i) => {
                    const { nhanSu, khachMoi, diThayCho } = cb;
                    return <tr key={i}>
                        <Action>{i + 1}</Action>
                        <ActionText>{nhanSu ? nhanSu?.thongTinCaNhan?.hoVaTenDem + ` ` + nhanSu?.thongTinCaNhan?.ten : khachMoi?.name}</ActionText>
                        <ActionText>{nhanSu ? nhanSu?.chucDanh?.name : khachMoi?.about}</ActionText>
                        {isThamGia && <>
                            <ActionText>{diThayCho?.hoVaTen}</ActionText>
                        </>}
                    </tr>
                })}
            </tbody>
        </Table>
    }
    const renderThamDu = useMemo(() => {
        const thamGias = thamDus ? thamDus.thamGia.filter(cur => cur.diemDanh === true) : 0;
        const vangs = thamDus ? thamDus.thamGia.filter(cur => cur.diemDanh === false) : 0;
        return thamDus && <>
            <div id="thamgia" style={{ color: 'cornflowerblue' }}
                className={`status-count d-flex font-weight-bold`} >
                <div className="mr-1">
                    {thamGias.length} <i className={`ml-1 fa-user-tie`} />
                </div>
                <div>Trực tuyến</div>
            </div>
            <div id="vang" style={{ color: 'cadetblue' }}
                className={`status-count d-flex font-weight-bold`} >
                <div className="mr-1">{vangs.length} <i className={`ml-1 fa-user-tie`} /></div>
                <div>Không trực tuyến</div>
            </div>
            <UncontrolledPopover placement="left" trigger="legacy" target="thamgia">
                <PopoverBody><ListCanBo canBos={thamGias} isThamGia={true} /></PopoverBody>
            </UncontrolledPopover>
            <UncontrolledPopover placement="left" trigger="legacy" target="vang">
                <PopoverBody><ListCanBo canBos={vangs} /></PopoverBody>
            </UncontrolledPopover>
        </>
    }, [thamDus])

    const finishMeet = useCallback(async (phienHopId) => {
        try {
            await KhungNoiDungsEndpoint.update(tabActive.id, { trangThai: _Enum_Trang_Thai_Noi_Dung.HoanThanh })
            await PhienHopsEndpoint.updateData(phienHopId, { trangThai: Enum_Meeting_Status.KetThuc });
            CHECK_TIME('Phiên họp đã kết thúc', 'Chuyển về sảnh chờ', handleClose);
        } catch (error) { CHECK_ERROR('Thao tác thất bại') }
    }, [handleClose, tabActive])

    const setActiveNextNoiDung = useCallback(async (idPreNoiDung, isNext = true, arrNoiDung) => {
        const { khungNoiDungs } = phienHop;
        const indexPre = khungNoiDungs.findIndex(cur => cur.id === idPreNoiDung);
        if (indexPre > -1) {
            // tìm nội dung tiếp theo
            const activeNoiDung = isNext ? khungNoiDungs[indexPre + 1] : khungNoiDungs[indexPre];
            if (activeNoiDung) {
                if (isNext) {//hoàn thành nội dung tiếp theo
                    KhungNoiDungsEndpoint.updateData(activeNoiDung.id, { trangThai: _Enum_Trang_Thai_Noi_Dung.DangHop })
                        .then(() => { }).catch(err => CHECK_ERROR('Không thành công!'));
                } else { //cập nhật danh sách nội dung hiện tại
                    phienHop.khungNoiDungs = arrNoiDung;
                    setTabActive({ activeTab: indexPre + 1, id: idPreNoiDung });
                }
            } else { finishMeet(phienHopId) }
        }
        return;
    }, [phienHop, finishMeet, phienHopId])

    const handleEndMeeting = useCallback(() => {
        CHECK_CONFIRM('Chắc chắn kết thúc phiên họp?').then(res => {
            if (res && res.isConfirmed) finishMeet(phienHopId);
        })
    }, [finishMeet, phienHopId])

    useEffect(() => {
        const a = document.getElementById('content-vertical');
        if (a) { a.style.height = window.screen.availHeight - cumulativeOffset(a) - 120; }
    }, [])

    const renderDocumentTreeFile = useMemo(() => {
        return <DrawerDocument isOpen={showDocDraw} onClose={() => setShowDocDraw(false)}>
            <div className="text-center text-white p-1 bg-blue position-sticky"
                style={{ top: '0px' }}>
                <h2>Tài liệu phiên họp</h2>
            </div>
            <FormAttachFile
                showDocDraw={showDocDraw}
                ref={refFormFile}
                id={phienHopId}
                phienHopId={phienHopId}
                TEndpoint={PhienHopsEndpoint}
                isTree={true}
            />
        </DrawerDocument>
    }, [phienHopId, showDocDraw])

    // eslint-disable-next-line no-unused-vars
    const handleStream = useCallback(() => {
        setIsShowStream(true)
        // const driver = new webdriver.Builder().forBrowser("chrome").build();
        // // Instantiate a web browser page 
        // driver.navigate().to("Yahoo")
        //     .then(() => driver.findElement(By.css("#login-username")))
        //     .then(element => element.getAttribute("value"))
        //     .then(value => console.log(value));
        // const until = webdriver.until; // useful utility to wait command
        // const By = webdriver.By; // useful Locator utility to describe a query for a WebElement
        // driver.navigate().to("Yahoo")
        //     .then(() => driver.findElement(By.css('#login-username')).sendKeys('xyz@yahoo.com'))
        //     .then(() => driver.wait(until.elementLocated(By.css('#login-signin'))))
        //     .then(() => driver.findElement(By.css('#login-signin')).click())
    }, [])

    const renderLogActivity = useMemo(() => {
        return <LogActivity isOpen={showLogActivity} close={() => { setShowLogActivity(null) }}></LogActivity>
    }, [showLogActivity])

    return (
        <Fragment>
            <Card className="mb-1">
                <CardBody className="p-2 d-flex justify-content-between">
                    <Row className="w-100 mr-0 ml-0">
                        <Col md='2' className="text-left">
                            {renderThamDu}
                        </Col>
                        <Col md='8' className="text-center">
                            <div className="main-meeting">
                                <div><h3>{name}</h3></div>
                                <div className="main-time d-flex">
                                    <div className="start-time">Bắt đầu:<span>{` ${formatDatetime(batDau)}`}</span></div>
                                    <div className="end-time pl-3">Kết thúc:<span>{` ${formatDatetime(ketThuc)}`}</span></div>
                                </div>
                            </div>
                            {phienHop && <div>Chủ trì:
                                <span className="font-weight-bold ml-1">{phienHop?.chuTri?.thongTinCaNhan?.hoVaTenDem + ` ` + phienHop?.chuTri?.thongTinCaNhan?.ten}</span>

                            </div>}
                            {/* <ButtonCustom onClick={handleStream}>Kết nối video</ButtonCustom> */}
                        </Col>
                        <Col md='2' className="text-right pr-0">
                            <MenuMeet {...{ setShowDocDraw, setShowLogActivity, setOpenSpeechToText, handleEndMeeting, isChuTri, phienHopId }}></MenuMeet>
                            {renderDocumentTreeFile}
                            {renderLogActivity}
                            <SpeechToText {...{ phienHopId, nameAgent }} isOpen={openSpeechToText} onClose={() => setOpenSpeechToText(null)} />
                        </Col>
                    </Row>
                    {/* <ButtonCustom onClick={() => {refMeet.current.updateData()}}>Click zô</ButtonCustom> */}
                </CardBody>
            </Card>
            <Vertical
                id='content-vertical'
                tabWidth={400}
                value={tabActive.activeTab}
                onClick={(dataTab) => { setTabActive(dataTab) }}
                tabs={khungNoiDungs || []}
            >
                {
                    tabActive && tabActive.id ? <Content
                        index={tabActive.activeTab}
                        ref={refContent}
                        setTabActive={setTabActive}
                        title="Nội dung chi tiết"
                        phienHopId={phienHopId}
                        khungNoiDungId={tabActive.id}
                        handleClose={handleClose}
                        isDienBien={isDienBien}
                        setActiveNextNoiDung={setActiveNextNoiDung}
                        updateDataThamDu={updateDataThamDu}
                    /> : <div className="sprinter-bg">
                        <div className="spinner-2"></div>
                    </div>
                }
            </Vertical>
            {
                isShowStream && <StreamMeeting close={() => setIsShowStream(false)}>
                    <Meeting ref={refMeet}>
                        {/* {
                            tabActive && <Vertical
                                height={450}
                                tabWidth={400}
                                value={tabActive.activeTab}
                                onChange={(event, newValue) => { setTabActive(newValue) }}
                                tabs={khungNoiDungs || []}
                            >
                                <Content isView={true}
                                setTabActive={setTabActive}
                                title="Nội dung chi tiết"
                                phienHopId={phienHopId}
                                khungNoiDungId={tabActive} />
                            </Vertical>
                        } */}
                        <iframe
                            width="100%"
                            height="100%"
                            src={'https://mcu.veda.vn:8443/webrtc/call.html'}
                            title={'streamVideo'}
                            frameBorder="0"
                            allow="camera *;microphone *"
                            allowFullScreen />
                    </Meeting>
                </StreamMeeting>
            }
        </Fragment>
    )
})
export default Main
