import React, { Component } from 'react';
import Select from './Select';
export const delimiter = ".";
export const checkDelimiter = (value) => value && value.includes(delimiter);
class SelectSingle extends Component {
  render() {
    const {
      name, options, placeholder, onChange, valueRef, labelRef, value, isDisabled,
      onMenuScrollToBottom, isLoading, onInputChange, onMenuOpen, onMenuClose,
      originfield, multiLabel, funLabel, isClearable
    } = this.props;

    const filterOption = (e) => e && e.length !== 0 && e.map(k => {
      k.value = k[valueRef];
      if (!originfield) {

        if (multiLabel !== undefined) {
          let stringLabelStart = '';
          let stringLabelEnd = '';
          multiLabel.forEach(cur => {
            if (checkDelimiter(cur.field)) {
              let fieldMuti = cur.field.split('.');
              if (cur.position === 'start') {
                stringLabelStart += k[eleField[0]][fieldMuti[fieldMuti.length - 1]] ? ` ${k[eleField[0]][fieldMuti[fieldMuti.length - 1]]} ${cur.ligature} ` : '';
              } else {
                stringLabelEnd += ` ${cur.ligature} ${k[eleField[0]][fieldMuti[fieldMuti.length - 1]]}`
              }
            } else {
              if (cur.position === 'start') {
                stringLabelStart += k[cur.field] ? ` ${k[cur.field]} ${cur.ligature} ` : ''
              } else {
                stringLabelEnd += ` ${cur.ligature} ${k[cur.field]}`
              }
            }
          })
          k.label = stringLabelStart + k[labelRef] + stringLabelEnd;
        } else {
          k.label = k[labelRef];
        }

      } else {
        var eleField = labelRef.split('.');
        if (eleField.length > 2) {
          const eleFieldFake = [];
          eleField.forEach((curr, i) => {
            if (i === eleField.length - 1 || i === eleField.length - 2)
              eleFieldFake.push(curr);
          })
          eleField = eleFieldFake;

          if (multiLabel !== undefined) {
            let stringLabelStart = '';
            let stringLabelEnd = '';
            multiLabel.forEach(cur => {
              if (funLabel !== undefined) {
                if (checkDelimiter(cur.field)) {
                  let fieldMuti = cur.field.split('.');
                  if (cur.position === 'start') {
                    stringLabelStart += ` ${k[eleField[0]][fieldMuti[fieldMuti.length - 1]]} ${funLabel(k[eleField[0]][cur.fieldChange])} ${cur.ligature} `
                  } else {
                    stringLabelEnd += ` ${cur.ligature} ${k[eleField[0]][fieldMuti[fieldMuti.length - 1]]} ${funLabel(k[eleField[0]][cur.fieldChange])}`
                  }
                } else {
                  if (cur.position === 'start') {
                    stringLabelStart += ` ${k[cur.field]} ${funLabel(k[cur.fieldChange])} ${cur.ligature}`
                  } else {
                    stringLabelEnd += ` ${cur.ligature} ${k[cur.field]} ${funLabel(k[cur.fieldChange])}`
                  }
                }
              } else {
                if (checkDelimiter(cur.field)) {
                  let fieldMuti = cur.field.split('.');
                  if (cur.position === 'start') {
                    stringLabelStart += ` ${k[eleField[0]][fieldMuti[fieldMuti.length - 1]]} ${cur.ligature} `
                  } else {
                    stringLabelEnd += ` ${cur.ligature} ${k[eleField[0]][fieldMuti[fieldMuti.length - 1]]}`
                  }
                } else {
                  if (cur.position === 'start') {
                    stringLabelStart += ` ${k[cur.field]} ${cur.ligature} `
                  } else {
                    stringLabelEnd += ` ${cur.ligature} ${k[cur.field]}`
                  }
                }
              }
            })
            k.label = stringLabelStart + k[eleField[0]][eleField[eleField.length - 1]] + stringLabelEnd;
          } else {
            k.label = k[eleField[0]][eleField[eleField.length - 1]];
          }
        } else {
          if (multiLabel !== undefined) { //add multi label
            let stringLabelStart = '';
            let stringLabelEnd = '';
            multiLabel.forEach((cur, index) => {
              if (funLabel !== undefined && cur.fieldChange !== undefined) {
                if (checkDelimiter(cur.field)) {
                  let fieldMuti = cur.field.split('.');
                  let valueLabel = k[eleField[0]][fieldMuti[fieldMuti.length - 1]];
                  if (valueLabel) {
                    if (cur.position === 'start') {
                      stringLabelStart += ` ${valueLabel} ${funLabel(k[eleField[0]][cur.fieldChange])} ${cur.ligature} `
                    } else {
                      stringLabelEnd += ` ${cur.ligature} ${valueLabel} ${funLabel(k[eleField[0]][cur.fieldChange])}`
                    }
                  }
                } else {
                  if (cur.position === 'start') {
                    stringLabelStart += ` ${k[cur.field]} ${funLabel(k[cur.fieldChange])} ${cur.ligature} `
                  } else {
                    // if (index !== 0) {
                    //   stringLabelEnd += ` ${k[cur.field]}${funLabel(k[cur.fieldChange])} ${cur.ligature} `
                    // } else {
                    stringLabelEnd += ` ${cur.ligature} ${k[cur.field]} ${funLabel(k[cur.fieldChange])} `
                    // }
                  }
                }
              } else {
                if (checkDelimiter(cur.field)) {
                  let fieldMuti = cur.field.split('.');
                  const valueField = (k[eleField[0]] && k[eleField[0]][fieldMuti[fieldMuti.length - 1]]) || null
                  if (valueField) {
                    if (cur.position === 'start') {
                      stringLabelStart += ` ${valueField} ${cur.ligature} `
                    } else {
                      stringLabelEnd += valueField ? ` ${cur.ligature} ${valueField}` : ''
                    }
                  }
                } else {
                  if (cur.position === 'start') {
                    stringLabelStart += k[cur.field] ? ` ${k[cur.field]} ${cur.ligature} ` : ''
                  } else {
                    stringLabelEnd += k[cur.field] ? ` ${cur.ligature} ${k[cur.field]}` : ''
                  }
                }
              }
            })
            k.label = stringLabelStart + (k[eleField[0]] && k[eleField[0]][eleField[eleField.length - 1]]) + stringLabelEnd;
          } else {
            k.label = k[eleField[0]][eleField[eleField.length - 1]];
          }
        }
      }
      return k;
    });

    //parentchange
    const values = options && options.length !== 0 ? filterOption(options) : [];
    const select = value === null || value === '' ? null : values.filter(e => e.value === value)

    return (
      <Select
        className="basic-single"
        name={name}
        value={select}
        defaultValue={select}
        isClearable={isClearable}
        options={values}
        placeholder={placeholder}
        onChange={(e, meta) => onChange(e, meta)}
        onMenuScrollToBottom={onMenuScrollToBottom}
        isLoading={isLoading}
        onInputChange={onInputChange}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        isDisabled={isDisabled}
      />
    );
  }
};

SelectSingle.propTypes = {

};

SelectSingle.defaultProps = {
  placeholder: 'Chọn...'
};

export default SelectSingle;
