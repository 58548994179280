import { Meet } from "./Meet";

class LichPhongHopsEndpoint extends Meet {
    constructor() {
        super("/LichPhongHops");
    }

    getAllPhienHopById = (IdPhienHop, { tuNgay, denNgay }) => this.GetAllow(`(${IdPhienHop})?$filter=batDau ge ${tuNgay} and ketThuc le ${denNgay} `)


}
export default new LichPhongHopsEndpoint();