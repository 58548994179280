import React from 'react';
import PropTypes from 'prop-types';
import _classnames from 'classnames';

const FontAwesome = (props) => {
    const { fa, far, fab, size, ariaHidden, color, style,className } = props;
    const faStyle = fab ? 'fa-brand' : (far ? 'fa-regular' : 'fa-solid');
    const classes = _classnames(faStyle, 'fa-' + fa, 'fa-fw', size && ('fa-' + size), className);
    return (
        <i
            style={color ? { color, ...style } : style}
            className={classes}
            aria-hidden={ariaHidden}
        ></i>
    );
}

FontAwesome.propTypes = {
    fa: PropTypes.string.isRequired,
    far: PropTypes.bool,
    fab: PropTypes.bool,
    size: PropTypes.oneOf([null, 'xs', 'sm', 'lg', '2x', '3x', '5x', '7x', '10x']),
    ariaHidden: PropTypes.bool.isRequired
};

FontAwesome.defaultProps = {
    ariaHidden: true,
    far: false,
    fab: false
};

export default FontAwesome;