import { AccountOdata } from "./AccountOdata";

class AuthorityMenuAdministrationEndpoint extends AccountOdata {
    constructor() {
        super("/AuthorityMenuAdministrations");
    }
    // Danh sách Menu với Authority
    getWithAuthorityWithID = (id) => {
        return this.GetAuthenADMINODATA(`?$filter=AuthorityUuid eq ${id} and status eq true &$select=MenuAdministrationUuid`);
    }
    // Danh sách Menu với Authority
    getMenuWithAuthority = () => this.GetAuthenADMINODATA(`?$select=name,icon,path,parentUuid&$filter=status eq true`);
}

export default new AuthorityMenuAdministrationEndpoint();