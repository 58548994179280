import React, { Component } from 'react';
import SelectSingle from './SelectSingle';

class SelectMonth extends Component {
    render() {
        const { value, onChange } = this.props;
        const data = () => {
            let option = [];
            for (var i = 1; i <= 12; i++) {
                option.push({ value: i, label: `Tháng ${i}` })
            }
            return option;
        }
        // const month = (new Date()).getMonth() + 1;
        return (
            <SelectSingle
                // value={value === null ? month : value}
                value={value}
                onChange={onChange}
                options={data()}
                valueRef="value"
                labelRef="label"
                placeholder="Chọn tháng"
            />
        );
    }
};

SelectMonth.propTypes = {
};

SelectMonth.defaultProps = {
    placeholder: 'Chọn...'
};

export default SelectMonth;