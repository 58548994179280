import { Meet } from "./Meet";

class KetLuansEndpoint extends Meet {
  constructor() {
    super("/KetLuans");
  }
  addKetLuan = (body) => this.InsertAUTH(body);
  deleteKetLuan = (id) => this.DeleteAUTH(`(${id})`);
  editKetLuan = (id, body) => this.UpdateAUTH(`(${id})`, body);

}
export default new KetLuansEndpoint()