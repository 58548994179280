import React, {useCallback, useMemo, useState} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * @description Example: const {renderAlert,fire} = useAlert()
 * <div>
 *     <button onClick={()=>fire.success("TEST")}/>
 *     {renderAlert}
 * </div>
 * @param autoHideDuration: Thời gian alert tự tắt
 * @return {{renderAlert: *, fire: {success: success, warning: warning, error: error, info: info}}}
 */
function useAlert(autoHideDuration = 6000) {
    const [alertProps, setAlertProps] = useState(null);

    const fire = useMemo(function () {
        return {
            error: function (message = "Đã xảy ra lỗi, vui lòng thử lại") {
                setAlertProps({isOpen: true, message, severity: "error"})
            },
            success: function (message) {
                setAlertProps({isOpen: true, message, severity: "success"})
            },
            warning: function (message) {
                setAlertProps({isOpen: true, message, severity: "warning"})
            },
            info: function (message) {
                setAlertProps({isOpen: true, message, severity: "info"})
            }
        }
    }, [setAlertProps])

    const handleClose = useCallback(() => setAlertProps(null), [setAlertProps]);

    const renderAlert = useMemo(() => {

        if (!alertProps) return null

        const {isOpen = false, severity, message} = alertProps;

        return (
            <Snackbar
                open={isOpen}
                autoHideDuration={autoHideDuration}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        );
    }, [alertProps, handleClose, autoHideDuration]);

    return {renderAlert, fire};
}

export default useAlert;