import React from 'react';
import './style.scss';
function Index({ isOpen, children, onClose }) {
    return (
        <content className='drawer-document'>
            <input id="hamburger" className="hamburger" hidden={true} onChange={onClose} type="checkbox" checked={isOpen} />
            <label className="hamburger" htmlFor="hamburger" hidden={!isOpen}>
                <i></i>
            </label>
            <section className="drawer-list">
                {children}
            </section>
        </content>
    )
}
export default Index