import { ALLOWS_API_URL, _API_URL } from "../../actions/constants";
import { GateWay } from "./GateWay";

class StorageEndpoint extends GateWay {
    constructor() {
        super("/Storage");
    }
    getImgByPath = (path) => {
        return this.GetAllow('/Download/' + path, { responseType: "blob", noAlert: true, noLoading: true })
    }
    getDocUrlByPath = (path) => _API_URL + ALLOWS_API_URL + `/Storage/Download/${path}`;

    deleteFileWithPatch = (path) => this.DeleteAUTHPATH('/Delete', path);

    savePositionFile = (files) => {
        return this.PostAuthen('/UpdateTagPosition', files, { noLoading: true })
    }

}
export default new StorageEndpoint();